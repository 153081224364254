import { notification } from 'antd'

interface Notification {
  message: string
  desc?: string
  type?: 'error' | 'info' | 'warning' | 'success'
}

export const sendNotification = ({
  message,
  type = 'info',
  desc,
}: Notification) => {
  notification.open({
    message,
    description: desc,
    duration: 12,
    type,
  })
}
