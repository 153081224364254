import { Button, Drawer, Input, message, Modal } from 'antd'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { useMessageAlert } from '../alert/MessageAlert'
import { UploadImage } from '../upload/Upload'

interface EditBannerDrawerProps {
  onClose: () => void
  onUpdate: () => void
  visible: boolean
  bannerId: string
}

const GET_BANNER_BY_ID = gql`
  query banner($id: ID!) {
    banner(id: $id) {
      id
      title
      content {
        svg {
          content
        }
      }
      contentImage {
        id
        uri
      }
      type
    }
  }
`

const UPDATE_BANNER = gql`
  mutation updateBanner($id: ID!, $payload: UpdateBannerPayload!) {
    updateBanner(id: $id, payload: $payload) {
      banner {
        id
        title
        content {
          svg {
            content
          }
        }
        contentImage {
          id
          uri
        }
        type
      }
    }
  }
`

const initState = {
  inputTitleValue: '',
  inputContentValue: '',
  logoId: '',
}

export default ({
  onClose,
  visible,
  bannerId,
  onUpdate,
}: EditBannerDrawerProps) => {
  const [updateBanner] = useMutation(UPDATE_BANNER)
  const [bannerType, setBannerType] = useState<'Svg' | 'Image'>('Svg')
  const [state, dispatch] = useMessageAlert()
  const [inputTitleValue, setInputTitleValue] = useState<string>(
    initState.inputTitleValue,
  )
  const [imageUrl, setImageUrl] = useState('')
  const [logoId, setLogoId] = useState(initState.logoId)
  const onChangeInputTitle = (e: any) => {
    setInputTitleValue(e.target.value)
  }

  const [inputContentValue, setInputContentValue] = useState<string>(
    initState.inputContentValue,
  )

  const onChangeInputContent = (e: any) => {
    setInputContentValue(e.target.value)
  }

  const { loading, data, error, refetch } = useQuery(GET_BANNER_BY_ID, {
    variables: {
      id: bannerId,
    },
    skip: !bannerId,
    onCompleted: (data) => {
      if (data?.banner?.title) {
        setInputTitleValue(data.banner.title)
      }
      if (data?.banner?.content?.svg?.content) {
        setInputContentValue(data?.banner?.content?.svg?.content)
      }
      if (data?.banner?.type) {
        setBannerType(data.banner.type)
      }
      if (data?.banner?.contentImage?.id) {
        setLogoId(data.banner.contentImage.id)
      }
      if (data?.banner?.contentImage?.uri) {
        setImageUrl(data?.banner?.contentImage?.uri)
      }
    },
  })

  const onUpdateBanner = () => {
    if (
      inputTitleValue.length === 0 ||
      (inputContentValue.length === 0 && bannerType === 'Svg') ||
      (logoId.length === 0 && bannerType === 'Image')
    ) {
      Modal.warning({
        title: '*Required',
        content: 'Banner Title & Content must be entered',
      })
      return
    }

    let params: any = {
      title: inputTitleValue,
    }
    if (bannerType === 'Svg') {
      params = {
        ...params,
        content: {
          svg: {
            content: inputContentValue.trim(),
          },
        },
      }
    } else {
      params = {
        ...params,
        contentImageId: logoId,
      }
    }
    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure to edit banner?',
      onOk() {
        updateBanner({
          variables: {
            id: bannerId,
            payload: params,
          },
        })
          .then((banner) => {
            console.log('updated banner: ', banner?.data)
            message.success('Update banner successfully')
            refetch()
              .catch((e) => {
                console.error(e)
              })
              .then(() => {
                onUpdate()
              })
          })
          .catch(() => {
            message.error('Failed to update banner')
          })
      },
      onCancel() {
        console.log('Cancel Update Banner')
      },
    })
  }

  return (
    <Drawer
      width={600}
      title={'Update Banner'}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      headerStyle={{ fontSize: 'xx-large	', fontWeight: 900 }}
    >
      {/* {!loading && data && <h1>{JSON.stringify(data)}</h1>} */}
      <h6 className="label" style={{ marginBottom: 10 }}>
        Title:
      </h6>
      <Input
        value={inputTitleValue}
        onChange={onChangeInputTitle}
        name="title"
        placeholder="Title"
      />
      {bannerType === 'Svg' ? (
        <>
          <h6 className="label" style={{ marginBottom: 10 }}>
            Content:
          </h6>
          <Input.TextArea
            rows={3}
            style={{ height: 'auto' }}
            value={inputContentValue}
            onChange={onChangeInputContent}
          ></Input.TextArea>
        </>
      ) : (
        <div style={{ marginTop: '10px' }}>
          <UploadImage
            dispatch={dispatch}
            setLogoId={setLogoId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
          ></UploadImage>
        </div>
      )}
      <h6 className="label" style={{ marginBottom: 10 }}>
        Preview:
      </h6>

      {bannerType === 'Svg' && (
        <div dangerouslySetInnerHTML={{ __html: inputContentValue }} />
      )}
      <div dangerouslySetInnerHTML={{ __html: inputContentValue }} />
      <Button type="primary" onClick={onUpdateBanner} style={{ marginTop: 10 }}>
        Update Banner
      </Button>
    </Drawer>
  )
}
