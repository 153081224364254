import React from 'react'
import * as Yup from 'yup'
import {
  MessageAlert,
  MessageAlertType,
  useMessageAlert,
  MESSAGE_ALERT_STATUS,
} from '../alert/MessageAlert'
import { useForm } from 'react-hook-form'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'
import {
  LoanApplicationStatus,
  DisburseLoanApplicationPayload,
} from '../../graphql/models'
import { LOAN_APPLICATION_UPDATE_STATUS_SUCCESS } from '../constants'
import { CustomButton } from '../form/CustomButton'
import { CustomInput } from '../form/CustomForm'
import { Button } from 'antd'

export const DISBURSE_LOAN_APPLICATION = gql`
  mutation disburseLoanApplication(
    $id: ID!
    $payload: DisburseLoanApplicationPayload!
  ) {
    disburseLoanApplication(id: $id, payload: $payload) {
      loanApplication {
        id
        status
      }
    }
  }
`
const FromSchema = Yup.object().shape({
  externalRef: Yup.string().required('Required'),
})

type LoanApplicationDisburseProps = {
  id: string
  status: LoanApplicationStatus.Disbursed
  setCurrentStatus: (status: LoanApplicationStatus.Disbursed) => void
  history: any
}
export function LoanApplicationDisburse({
  id,
  status,
  setCurrentStatus,
  history,
}: LoanApplicationDisburseProps) {
  const [disburseLoanApplication] = useMutation(DISBURSE_LOAN_APPLICATION)
  const [state, dispatch] = useMessageAlert()
  const { handleSubmit, errors, register } = useForm<
    DisburseLoanApplicationPayload
  >({ validationSchema: FromSchema })

  const onSubmit = async ({ externalRef }: DisburseLoanApplicationPayload) => {
    try {
      dispatch({ type: 'pending' })
      await disburseLoanApplication({
        variables: { id, payload: { externalRef } },
      })
      dispatch({
        type: 'success',
        message: LOAN_APPLICATION_UPDATE_STATUS_SUCCESS,
      })
      setCurrentStatus(status)
    } catch (err) {
      dispatch({ type: 'error', message: err.message })
    }
  }

  return (
    <>
      {(state.status === MESSAGE_ALERT_STATUS.Init ||
        state.status === MESSAGE_ALERT_STATUS.Pending) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <p>Are you sure disburse this application?</p>
          <CustomInput
            name="externalRef"
            errors={errors}
            register={register}
            required={true}
            className="ant-input"
          >
            {(props: any, name, register: any) => (
              <input {...props} name={name} ref={register} />
            )}
          </CustomInput>

          <div className="ant-div-right">
            <CustomButton
              type="primary"
              value={
                state.status === MESSAGE_ALERT_STATUS.Pending
                  ? 'Submiting...'
                  : 'Submit'
              }
              disabled={state.status === MESSAGE_ALERT_STATUS.Pending}
            />
          </div>
        </form>
      )}
    </>
  )
}
