import { PlusOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Drawer,
  Input,
  message,
  Modal,
  Radio,
  Tag,
} from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import gql from 'graphql-tag'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import { useDebounce } from 'react-use'
import {
  Employment,
  EmploymentOrderable,
  OrderDirection,
} from '../../graphql/models'
import MessageEditor from './MessageEditor'

interface SendMessageDrawerProps {
  onClose: () => void
  visible: boolean
  company: string | null
}

enum SEND_OPTIONS {
  'ALL',
  'GROUP',
  'EMPLOYMENT',
}

const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      shortName
      groupsAsTree {
        parentId
        group {
          id
          name
        }
      }
    }
  }
`

const GET_INDIVIDUALS = gql`
  query individuals($query: CompanyEmploymentQuery, $companyId: ID!) {
    company(id: $companyId) {
      id
      employments(query: $query) {
        total
        nodes {
          id
          employeeId
          fullName
        }
        total
        limit
        offset
      }
    }
  }
`

export const SEND_MESSAGE_TO_EMPLOYMENTS = gql`
  mutation sendMessageToEmployments(
    $payload: SendMessageToEmploymentsPayload!
  ) {
    sendMessageToEmployments(payload: $payload)
  }
`

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}

const initState = {
  sendOption: SEND_OPTIONS.ALL,
  selectedEmployments: [],
  inputEmploymentValue: '',
  inputEmploymentDebounce: '',
  inputEmploymentVisible: false,
  selectedGroups: [],
  inputTitleValue: '',
  inputDescriptionValue: '',
}

export default ({ onClose, visible, company }: SendMessageDrawerProps) => {
  const [sendOption, setSendOption] = useState(initState.sendOption)
  const onChangeSendOption = (e: RadioChangeEvent) => {
    setSendOption(e.target.value)
    setInvalidEmployeeCodes('')
  }

  const [selectedEmployments, setSelectedEmployments] = useState<any[]>(
    initState.selectedEmployments,
  )

  const [inputEmploymentValue, setInputEmploymentValue] = useState(
    initState.inputEmploymentValue,
  )
  const [inputEmploymentDebounce, setInputEmploymentDebounce] = useState(
    initState.inputEmploymentDebounce,
  )
  const [, cancel] = useDebounce(
    () => {
      setInputEmploymentDebounce(inputEmploymentValue)
    },
    500,
    [inputEmploymentValue],
  )

  const {
    loading: companyLoading,
    error: companyError,
    data: companyData,
  } = useQuery(GET_COMPANY, {
    variables: { id: company },
  })

  const {
    loading: employmentsLoading,
    error: employmentsError,
    data: employmentsData,
  } = useQuery(GET_INDIVIDUALS, {
    variables: {
      query: {
        filter: {
          q: inputEmploymentDebounce,
          companyIds: [company],
        },
        orderBy: EmploymentOrderable.EmployeeId,
        orderDirection: OrderDirection.Asc,
      },
      companyId: company,
    },
  })

  const [pastedEmployeeCodes, setPastedEmployeeCodes] = useState([])
  const [invalidEmployeeCodes, setInvalidEmployeeCodes] = useState('')

  const [getEmployeesByCodes, { data, loading }] = useLazyQuery(
    GET_INDIVIDUALS,
    {
      variables: {
        query: {
          filter: {
            employeeIds: pastedEmployeeCodes,
          },
          orderBy: EmploymentOrderable.EmployeeId,
          orderDirection: OrderDirection.Asc,
          limit:
            pastedEmployeeCodes.length > 0 ? pastedEmployeeCodes.length : 20,
        },
        companyId: company,
      },
    },
  )

  const [inputEmploymentVisible, setInputEmploymentVisible] = useState<Boolean>(
    initState.inputEmploymentVisible,
  )
  const inputEmploymentRef = useRef<any>(null)

  const handlePaste = (e: any) => {
    // Catch paste event by css className => dafug
    if (
      e.clipboardData &&
      e.target &&
      e.target.className === 'ant-select-selection-search-input'
    ) {
      // console.log('trigger')
      const paste = e.clipboardData.getData('text/plain')

      const employeeCodeArray = paste
        .replace(/\n/g, ' ')
        .replace(/\r/g, '')
        .split(' ')

      setPastedEmployeeCodes(employeeCodeArray)
    }
  }

  useEffect(() => {
    if (inputEmploymentVisible && inputEmploymentRef?.current) {
      inputEmploymentRef.current.focus()
      return
    }
  }, [inputEmploymentVisible])

  useEffect(() => {
    if (!loading && data) {
      const employees = [
        ...selectedEmployments,
        ...data.company.employments.nodes,
      ]

      const uniqueEmployees = [
        ...new Map(employees.map((it: any) => [it.id, it])).values(),
      ]

      const notFoundEmployees = pastedEmployeeCodes.filter(
        (pastedCode) =>
          !uniqueEmployees.some(({ employeeId }) => employeeId === pastedCode),
      )

      setInvalidEmployeeCodes(notFoundEmployees.join())

      setSelectedEmployments(uniqueEmployees)
      setInputEmploymentValue('')

      setInputEmploymentVisible(false)
    }
  }, [loading])

  useEffect(() => {
    if (pastedEmployeeCodes.length > 0) {
      getEmployeesByCodes()
    }
  }, [pastedEmployeeCodes])

  useEffect(() => {
    window.addEventListener('paste', handlePaste, false)

    return () => window.removeEventListener('paste', handlePaste, false)
  }, [])

  const [selectedGroup, setSelectedGroups] = useState<any[]>(
    initState.selectedGroups,
  )

  const onChangeSelectedGroups = (checkedValues: any) => {
    setSelectedGroups(checkedValues)
  }

  const [inputTitleValue, setInputTitleValue] = useState<string>(
    initState.inputTitleValue,
  )

  const onChangeInputTitle = (e: any) => {
    setInputTitleValue(e.target.value)
  }

  const [inputDescriptionValue, setInputDescriptionValue] = useState(
    initState.inputDescriptionValue,
  )

  const onSendMessage = () => {
    if (inputTitleValue.length === 0) {
      Modal.warning({
        title: 'Require Message Title',
        content: 'Message Title must be entered',
      })
      return
    }
    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure to send the message?',
      onOk() {
        sendMessageToEmployments({
          variables: {
            payload: {
              filter: {
                companyIds: [company],
                ...(sendOption === SEND_OPTIONS.GROUP
                  ? { groupIds: [...selectedGroup] }
                  : null),
                ...(sendOption === SEND_OPTIONS.EMPLOYMENT
                  ? { ids: selectedEmployments.map((e) => e.id) }
                  : null),
              },
              title: inputTitleValue.trim(),
              content: inputDescriptionValue,
              tags: ['primary'],
            },
          },
        })
          .then(() => {
            message.success('Sent message successfully')
          })
          .catch(() => {
            message.error('Failed to send message')
          })
      },
      onCancel() {
        console.log('Cancel Send')
      },
    })
  }

  const [sendMessageToEmployments] = useMutation(SEND_MESSAGE_TO_EMPLOYMENTS)

  const resetToInitState = () => {
    // console.log('reset to init state: ')
    setInvalidEmployeeCodes('')
    setSelectedEmployments(initState.selectedEmployments)
    setInputEmploymentValue(initState.inputEmploymentValue)
    setInputEmploymentDebounce(initState.inputEmploymentDebounce)
    setInputEmploymentVisible(initState.inputEmploymentVisible)
    setSelectedGroups(initState.selectedGroups)
    setInputTitleValue(initState.inputTitleValue)
    setInputDescriptionValue(initState.inputDescriptionValue)
  }

  useEffect(() => {
    resetToInitState()
  }, [sendOption, company])

  return (
    <Drawer
      width={'30vw'}
      title={companyData?.company?.shortName || 'Loading Company'}
      placement="right"
      closable={false}
      onClose={() => {
        setInvalidEmployeeCodes('')
        setSendOption(SEND_OPTIONS.ALL)
        onClose()
      }}
      visible={visible}
      headerStyle={{ fontSize: 'xx-large	', fontWeight: 900 }}
    >
      <Card title={`Send To`}>
        <Radio.Group value={sendOption} onChange={onChangeSendOption}>
          <Radio style={radioStyle} value={SEND_OPTIONS.ALL}>
            All {companyData?.company?.shortName}
          </Radio>
          <Radio style={radioStyle} value={SEND_OPTIONS.GROUP}>
            Group(s)
          </Radio>
          {sendOption === SEND_OPTIONS.GROUP &&
            companyData?.company?.groupsAsTree && (
              <div style={{ marginLeft: '30px' }}>
                <Checkbox.Group
                  options={companyData?.company?.groupsAsTree.map((g: any) => ({
                    value: g.group.id,
                    label: g.group.name,
                  }))}
                  onChange={onChangeSelectedGroups}
                  value={selectedGroup}
                />
              </div>
            )}
          <Radio style={radioStyle} value={SEND_OPTIONS.EMPLOYMENT}>
            Employment(s)
          </Radio>
          {sendOption === SEND_OPTIONS.EMPLOYMENT &&
            employmentsData?.company?.employments?.nodes && (
              <div style={{ marginLeft: '30px', marginTop: '15px' }}>
                {selectedEmployments &&
                  selectedEmployments.length > 0 &&
                  selectedEmployments.map(
                    (employment: Employment, index: number) => (
                      <Tag
                        closable={true}
                        key={`${employment.id}_${index}`}
                        onClose={() => {
                          setSelectedEmployments(
                            _.filter(
                              selectedEmployments,
                              (e: Employment) => e.id !== employment.id,
                            ),
                          )
                        }}
                        style={{ marginBottom: 10 }}
                      >
                        {`${employment.employeeId} | ${employment.fullName}`}
                      </Tag>
                    ),
                  )}
                {inputEmploymentVisible &&
                  employmentsData?.company?.employments?.nodes && (
                    <AutoComplete
                      ref={inputEmploymentRef}
                      value={inputEmploymentValue}
                      style={{ width: 200 }}
                      placeholder="Select Employee"
                      options={
                        _.differenceBy(
                          employmentsData.company.employments.nodes,
                          selectedEmployments,
                          'id',
                        ).map((e: any) => ({
                          value: `${e.employeeId} | ${e.fullName}`,
                          employment: e,
                        })) as any
                      }
                      onSelect={(value, option) => {
                        setSelectedEmployments([
                          ...selectedEmployments,
                          option.employment,
                        ])
                        setInputEmploymentValue('')
                      }}
                      onBlur={() => {
                        setInputEmploymentVisible(false)
                      }}
                      onChange={(value: string) => {
                        setInputEmploymentValue(value)
                      }}
                    />
                  )}
                {!inputEmploymentVisible && (
                  <Tag
                    className="site-tag-plus"
                    onClick={() => {
                      setInputEmploymentVisible(true)
                    }}
                  >
                    <PlusOutlined /> Add Employee
                  </Tag>
                )}
              </div>
            )}
        </Radio.Group>
        {sendOption === SEND_OPTIONS.EMPLOYMENT && invalidEmployeeCodes && (
          <div
            style={{ color: 'red', marginTop: 8 }}
          >{`Mã nhân viên không hợp lệ: ${invalidEmployeeCodes}`}</div>
        )}
      </Card>
      <Card title={`Compose Message`} bodyStyle={{ paddingTop: 0 }}>
        <h6 className="label">Title:</h6>
        <Input
          value={inputTitleValue}
          onChange={onChangeInputTitle}
          name="title"
          placeholder="Title"
        />
        <h6 className="label">Description:</h6>
        <MessageEditor
          content={inputDescriptionValue}
          changeContent={setInputDescriptionValue}
        />
        <Button
          type="primary"
          onClick={onSendMessage}
          style={{ marginTop: 10 }}
        >
          Send
        </Button>
      </Card>
    </Drawer>
  )
}
