import { Alert, Button, Card, Col, Divider, Row, Spin, Typography } from 'antd'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { sendNotification } from '../../utils/notification'
import { GET_COMPANY } from './CompanyList'
import fileDownload from 'js-file-download'
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger'
import { CloudUploadOutlined } from '@ant-design/icons'

export const EXPORT_COMPANY = gql`
  mutation($slug: String) {
    exportCompany(code: $slug) {
      taxCode
      name
      slug
      description
      status
      logo {
        filename
        contentType
        content
      }
      email
      address
      code
      isIntegrated
      contractCompanyName
      contractCompanyTaxCode
      contractCompanyAddress
      shortName
      configs {
        key
        value
      }
      termAndConditions {
        status
        file {
          filename
          contentType
          content
        }
      }
      workplaces {
        name
        code
      }
      departments {
        name
        code
        phone
        email
        status
      }
      employmentGroups {
        fixedFee
        transactionFeePercentage
        limitPercentageBySalaryPeriod
        name
        code
        workerType
        dailyLimit
        numberOfAdvanceBySalaryPeriod
        numberOfAdvanceByDay
        minimumFee
        payPeriodStartDayInMonth
        payPeriodEndDayInMonth
        limitPerAdvance
        salaryPeriodFreeAmount
        shiftCountUnit
        extensions {
          key
          value
        }
        workPeriods {
          type
          entries {
            startDate
            expectedEndDate
            actualEndDate
            status
          }
        }
        subGroups {
          fixedFee
          transactionFeePercentage
          limitPercentageBySalaryPeriod
          name
          code
          workerType
          dailyLimit
          numberOfAdvanceBySalaryPeriod
          numberOfAdvanceByDay
          minimumFee
          payPeriodStartDayInMonth
          payPeriodEndDayInMonth
          limitPerAdvance
          salaryPeriodFreeAmount
          shiftCountUnit
          workPeriods {
            type
            entries {
              startDate
              expectedEndDate
              actualEndDate
              status
            }
          }
        }
      }
      children {
        taxCode
        name
        slug
        description
        status
        logo {
          filename
          contentType
          content
        }
        email
        address
        code
        isIntegrated
        contractCompanyName
        contractCompanyTaxCode
        contractCompanyAddress
        shortName
        configs {
          key
          value
        }
        termAndConditions {
          status
          file {
            filename
            contentType
            content
          }
        }
        workplaces {
          name
          code
        }
        departments {
          name
          code
          phone
          email
          status
        }
        employmentGroups {
          fixedFee
          transactionFeePercentage
          limitPercentageBySalaryPeriod
          name
          code
          workerType
          dailyLimit
          numberOfAdvanceBySalaryPeriod
          numberOfAdvanceByDay
          minimumFee
          payPeriodStartDayInMonth
          payPeriodEndDayInMonth
          limitPerAdvance
          salaryPeriodFreeAmount
          shiftCountUnit
          extensions {
            key
            value
          }
          workPeriods {
            type
            entries {
              startDate
              expectedEndDate
              actualEndDate
              status
            }
          }
          subGroups {
            fixedFee
            transactionFeePercentage
            limitPercentageBySalaryPeriod
            name
            code
            workerType
            dailyLimit
            numberOfAdvanceBySalaryPeriod
            numberOfAdvanceByDay
            minimumFee
            payPeriodStartDayInMonth
            payPeriodEndDayInMonth
            limitPerAdvance
            salaryPeriodFreeAmount
            shiftCountUnit
            workPeriods {
              type
              entries {
                startDate
                expectedEndDate
                actualEndDate
                status
              }
            }
            subGroups {
              name
            }
          }
        }
        children {
          name
        }
      }
    }
  }
`

const IMPORT_COMPANY = gql`
  mutation($importEntity: CompanyImport!, $id: ID!) {
    importCompany(id: $id, import: $importEntity)
  }
`

const { Title } = Typography

export interface ImportExportCompanyProps {
  id: string
}

const ImportExportCompany: React.FC<ImportExportCompanyProps> = ({ id }) => {
  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { id },
  })

  const [exportCompany, { loading: exportLoading }] = useMutation(
    EXPORT_COMPANY,
  )

  const [importCompany, { loading: importLoading }] = useMutation(
    IMPORT_COMPANY,
  )

  const handleExport = async () => {
    try {
      const response = await exportCompany({
        variables: { slug: data.company.slug },
      })

      const omitTypename = (key: string, value: string) =>
        key === '__typename' ? undefined : value
      const exportData = JSON.parse(
        JSON.stringify(response.data?.exportCompany),
        omitTypename,
      )

      fileDownload(
        JSON.stringify(exportData),
        `${data.company.slug}-config.json`,
      )
    } catch (error) {
      console.log(error)
      sendNotification({
        type: 'error',
        message: 'Something wrong',
      })
    }
  }

  const handleImport = (options: any) => {
    const { file, onError, onSuccess } = options

    const reader = new FileReader()
    reader.onload = async (e) => {
      try {
        const jsonString = e?.target?.result

        await importCompany({
          variables: {
            importEntity: JSON.parse(jsonString as string),
            id: data.company.id,
          },
        })

        onSuccess()
        sendNotification({
          type: 'success',
          message: 'Upload complete!',
        })
      } catch (error) {
        console.log(error)
        sendNotification({
          type: 'error',
          message: 'Something wrong',
        })
        onError()
      }
    }
    reader.readAsText(file)
  }

  const dragProps: DraggerProps = {
    accept: '.json',
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: false,
      showRemoveIcon: false,
    },

    customRequest: (options) => {
      handleImport(options)
    },
  }

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  if (loading) {
    return <Spin />
  }

  if (!data) {
    return <Alert message="Data is empty" />
  }

  return (
    <>
      <Row>
        <Col span={12}>
          <Title level={4} style={{ color: '#08554E' }}>
            Restore company config
          </Title>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handleExport} type="primary" loading={exportLoading}>
            Export Company Data
          </Button>
        </Col>
      </Row>

      <Divider />

      <Card>
        <Dragger {...dragProps}>
          <div
            style={{
              minHeight: 280,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined
                  style={{ fontSize: '56px', color: '#08554E' }}
                />
              </p>
              <p className="ant-upload-text">Click or drag here to import</p>
              <p className="ant-upload-hint">Only support json file</p>
            </div>
          </div>
        </Dragger>
      </Card>
    </>
  )
}

export default ImportExportCompany
