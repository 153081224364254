import React, { useEffect } from 'react'

import { IPageProps, IPageData } from '../../interfaces/page-data'
import { useParams } from 'react-router-dom'
import CompanyDetail from '../../components/Company/CompanyDetail'
import UploadTOC from '../../components/Company/UploadTOC'
import { CompanyAuditLogList } from '../../components/Company/CompanyAuditLogList'
import { CompanyGroup } from '../../components/Company/CompanyGroupList'
import { Tabs } from 'antd'
import HtmlConvert from '../../components/Company/HtmlConvert'
import ImportExportCompany from '../../components/Company/ImportExportCompany'
import Intergration from '../../components/Company/Intergration'

interface ITab {
  title: string
  component: JSX.Element
}

export const CompanyDetailPage: React.FunctionComponent<IPageProps> = (
  props,
) => {
  const { slug }: any = useParams()
  const { onSetPage } = props

  const pageData: IPageData = {
    title: 'Company Detail',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: '/vertical/dashboard',
      },
      {
        title: 'Companies',
        route: '/vertical/companies',
      },
      {
        title: 'Company Detail',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  const tabs: ITab[] = [
    {
      title: 'Detail',
      component: <CompanyDetail id={slug} />,
    },
    {
      title: 'Upload TOC',
      component: <UploadTOC id={slug} />,
    },
    {
      title: 'Audit log',
      component: <CompanyAuditLogList id={slug} />,
    },
    {
      title: 'Group',
      component: <CompanyGroup id={slug} />,
    },
    {
      title: 'Create TOC',
      component: <HtmlConvert id={slug} />,
    },
    {
      title: 'Import Export',
      component: <ImportExportCompany id={slug} />,
    },
    {
      title: 'One Touch Intergration',
      component: <Intergration id={slug} />,
    },
  ]

  if (!slug) {
    return null
  }

  return (
    <>
      <div style={{ background: 'white', padding: '30px 16px' }}>
        <Tabs defaultActiveKey="0" tabPosition="left">
          {tabs.map((tab, index) => (
            <Tabs.TabPane tab={tab.title} key={index}>
              {tab.component}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </>
  )
}
