import { EditOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Radio, Select, Space, Table, Tag } from 'antd'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-apollo'
import { Employment } from '../../graphql/models'
import ButtonLink from '../ButtonLink'
import { LAYOUT_PATH } from '../constants'
import { DEFAULT_PAGE_SIZE, useTable } from '../hooks/useTable'
import { InputTableHeader, SelectTableHeader } from '../table/CustomTable'

const { TextArea } = Input
const { Option } = Select
interface Props {
  status: string
}

export const GET_EMPLOYMENTS_V2 = gql`
  query employments($query: EmploymentQuery) {
    employmentSearch_v2(query: $query) {
      nodes {
        id
        employeeId
        bank {
          name
          alias
        }
        status
        salary
        fullName
        employeeWarningStatus
        employeeWarningSource
        leaveRequest {
          id
          createdDate
          executionStatus
          resignStatus
          archivedDueDate
          resignDate

          note
        }
        company {
          name
        }
        user {
          phoneNumber
        }
      }
      total
      limit
      offset
    }
  }
`

export const GET_OR_CREATE_LEAVE_REQUEST = gql`
  mutation getOrCreateLeaveRequest($employmentId: ID!) {
    getOrCreateLeaveRequest(employmentId: $employmentId) {
      leaveRequest {
        id
        createdDate
        executionStatus
        resignStatus
        archivedDueDate
        resignDate
        note
      }
    }
  }
`
type State =
  | 'settle'
  | 'archive'
  | 'operation_note'
  | 'cancel_settle'
  | 'manual_execute'

const EXECUTE_LEAVE_REQUEST = gql`
  mutation executeLeaveRequest($employmentId: ID!, $note: String) {
    executeLeaveRequest(employmentId: $employmentId, note: $note) {
      leaveRequest {
        id
        # resignStatus
        # executionStatus
        # createdDate
        # lastModifiedDate
      }
    }
  }
`
const ARCHIVE_LEAVE_REQUEST = gql`
  mutation archiveLeaveRequest(
    $leaveRequestId: ID!
    $payload: LeaveRequestArchivePayload!
  ) {
    archiveLeaveRequest(leaveRequestId: $leaveRequestId, payload: $payload) {
      leaveRequest {
        archivedDueDate
        id
      }
    }
  }
`

const UPDATE_LEAVE_REQUEST = gql`
  mutation updateLeaveRequest(
    $leaveRequestId: ID!
    $payload: LeaveRequestUpdatePayload!
  ) {
    updateLeaveRequest(leaveRequestId: $leaveRequestId, payload: $payload) {
      leaveRequest {
        archivedDueDate
        id
        note
      }
    }
  }
`
const CONFIRM_RESIGN_MANUAL_REQUEST = gql`
  mutation confirmResignManualLeaveRequest($leaveRequestId: ID!) {
    confirmResignManualLeaveRequest(leaveRequestId: $leaveRequestId) {
      leaveRequest {
        id
      }
    }
  }
`
const CANCEL_SETTLEMENT_LEAVE_REQUEST = gql`
  mutation cancelSettlementLeaveRequest($leaveRequestId: ID!) {
    cancelSettlementLeaveRequest(leaveRequestId: $leaveRequestId) {
      leaveRequest {
        id
      }
    }
  }
`
const LeaveRequestTool: FC<Props> = ({ status }) => {
  const [selectEmployee, setSelectEmployee] = useState<null | Employment>(null)
  const [employeeWarningSourceState, setEmployeeWarningSourceState] =
    useState('None')
  const [modalVisible, setModalVisible] = useState(false)
  const [localLoading, setLocalLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [note, setNote] = useState('')
  const filterFromParams = useMemo<any>(() => {
    return {
      // ...(status === 'Saving' && { withSaving: true }),
      ...(status === 'Warning' && { employeeWarningStatus: 'Warning' }),
      ...(status === 'InProgress' && { employeeWarningStatus: 'InProgress' }),
      ...(status === 'Settled' && { employeeWarningStatus: 'Settled' }),
      ...(status === 'Archived' && { employeeWarningStatus: 'Archived' }),
      isLinked: true,
      withSaving: true,
    }
  }, [status])
  const [executeLeaveRequest] = useMutation(EXECUTE_LEAVE_REQUEST)
  const [archiveLeaveRequest] = useMutation(ARCHIVE_LEAVE_REQUEST)
  const [updateLeaveRequest] = useMutation(UPDATE_LEAVE_REQUEST)
  const [getOrCreateLeaveRequest] = useMutation(GET_OR_CREATE_LEAVE_REQUEST)
  const [cancelSettlementLeaveRequest] = useMutation(
    CANCEL_SETTLEMENT_LEAVE_REQUEST,
  )
  const [confirmResignManualRequest] = useMutation(
    CONFIRM_RESIGN_MANUAL_REQUEST,
  )
  const [archiveTime, setArchiveTime] = useState('30')
  const [manualArchive, setManualArchive] = useState(false)
  const [state, setState] = useState<null | State>(null)
  const [
    loading,
    error,
    data,
    filter,
    page,
    setFilter,
    setPage,
    resetFilter,
    queryVariables,
    setNewQueryVariables,
    refetch,
    networkStatus,
  ] = useTable(GET_EMPLOYMENTS_V2, {
    filter: filterFromParams,
  })
  useEffect(() => {
    setLocalLoading(true)
    refetch({
      query: {
        limit: 15,
        offset: 0,
        filter: filterFromParams,
      },
    }).then(() => {
      setLocalLoading(false)
    })
  }, [filterFromParams])

  const {
    phoneNumber,
    fullNameSearch,
    companyNameSearch,
    employeeWarningStatus,
    employeeWarningSource,
  } = filter
  const columns = [
    {
      title: InputTableHeader({
        value: phoneNumber,
        key: 'phoneNumber',
        inputType: 'text',
        name: 'Số điện thoại',
        onPressEnter: setFilter,
      }),
      width: 250,
      key: 'phoneNumber',
      render: (props: Employment) => (
        <ButtonLink
          title={props?.user?.phoneNumber}
          routing={`/vertical/leave-request-tool/${props.id}/detail`}
          size="middle"
          layout={LAYOUT_PATH}
        />
      ),
    },
    {
      title: InputTableHeader({
        value: fullNameSearch,
        key: 'fullNameSearch',
        inputType: 'text',
        name: 'Họ tên',
        onPressEnter: setFilter,
      }),
      key: 'fullName',
      render: (props: Employment) => (
        <ButtonLink
          title={props.fullName}
          routing={`/vertical/leave-request-tool/${props.id}/detail`}
          size="middle"
          layout={LAYOUT_PATH}
        />
      ),
    },
    {
      title: InputTableHeader({
        value: companyNameSearch,
        key: 'companyNameSearch',
        inputType: 'text',
        name: 'Công ty',
        onPressEnter: setFilter,
      }),

      key: 'companyName',
      render: (props: Employment) => props.company.name,
    },

    {
      title: SelectTableHeader({
        value: employeeWarningSourceState,
        key: 'employeeWarningSource',
        options: [
          { value: 'None', text: 'All' },
          { value: 'Bot', text: 'Hệ thống quét' },
          { value: 'CanceledSettlement', text: 'Đã ngưng kích hoạt' },
          { value: 'ManualResignClicked', text: 'Báo nghỉ việc' },
        ],
        name: 'Nguồn thông báo',
        onChange: setFilter,
      }),
      width: 250,
      render: (props: Employment) => {
        let status = ''

        switch (props.employeeWarningSource) {
          case 'Bot':
            status = 'Hệ thống quét'
            break
          case 'CanceledSettlement':
            status = 'Đã ngưng kích hoạt'

            break
          case 'ManualResignClicked':
            status = 'Báo nghỉ việc'

            break
          default:
            break
        }
        return <>{status}</>
      },
    },
    ...(status === 'Archived'
      ? [
          {
            title: 'Ngày lưu trữ',
            key: 'archivedDueDate',
            width: 150,

            render: (props: Employment) =>
              props?.leaveRequest?.archivedDueDate &&
              moment(props?.leaveRequest?.archivedDueDate).format('DD/MM/YYYY'),
          },
        ]
      : []),
    ...(status !== 'Saving'
      ? [
          {
            title: 'Ghi chú',
            key: 'operation_note',
            width: 150,
            render: (props: Employment) => {
              return (
                <Button
                  title="Edit"
                  onClick={() => onModalVisible(props, 'operation_note')}
                >
                  <EditOutlined />
                </Button>
              )
            },
          },
        ]
      : []),
    ...(status === 'InProgress'
      ? [
          {
            title: 'Ngừng tất toán',
            key: 'cancel_settle',
            width: 150,
            render: (props: Employment) => {
              return (
                <Button
                  onClick={() => onModalVisible(props, 'cancel_settle')}
                  type="primary"
                >
                  Ngung tất toán
                </Button>
              )
            },
          },
        ]
      : []),

    ...(status === 'Warning'
      ? [
          {
            title: 'Lưu trữ người dùng',
            key: 'archive',
            width: 150,
            render: (props: Employment) => {
              return (
                <Button
                  title="Edit"
                  onClick={() => onModalVisible(props, 'archive')}
                  type="primary"
                >
                  Lưu trữ
                </Button>
              )
            },
          },
          {
            title: 'Kích hoạt gửi SMS và chờ tất toán',
            key: 'operation_1',
            width: 150,
            render: (props: Employment) => {
              return (
                <Button
                  title="Edit"
                  onClick={() => onModalVisible(props, 'settle')}
                  danger
                >
                  Kích hoạt
                </Button>
              )
            },
          },
        ]
      : []),
    ...(status === 'Saving'
      ? [
          {
            title: SelectTableHeader({
              value: status,
              key: 'employeeWarningStatus',
              options: [
                { value: 'Normal', text: 'Đang làm việc' },
                { value: 'Warning', text: 'Cần xử lí' },
                { value: 'InProgress', text: 'Đã kích hoạt' },
                { value: 'Settled', text: 'Đã tất toán' },
                { value: 'Archived', text: 'Lưu trữ' },
              ],
              name: 'Trạng thái',
              onChange: setFilter,
            }),
            width: 250,
            render: (props: Employment) => {
              let status = ''
              let color = 'green'
              switch (props.employeeWarningStatus) {
                case 'Normal':
                  status = 'Đang làm việc'
                  break
                case 'Warning':
                  status = 'Cần xử lí'
                  color = 'magenta'
                  break
                case 'InProgress':
                  status = 'Đã kích hoạt'
                  color = 'orange'
                  break
                case 'Settled':
                  color = 'cyan'
                  status = 'Đã tất toán'
                  break
                case 'Archived':
                  status = 'Lưu trữ'
                  color = 'purple'
                  break
                default:
                  break
              }
              return <Tag color={color}>{status}</Tag>
            },
          },
          {
            title: 'Xử lý tất toán',
            key: 'manual_execute',
            width: 150,
            render: (props: Employment) => {
              if (
                props.employeeWarningStatus === 'Normal' ||
                props.employeeWarningStatus === 'Archived'
              ) {
                return (
                  <Button
                    title="Edit"
                    onClick={() => onModalVisible(props, 'manual_execute')}
                    danger
                  >
                    Báo nghỉ
                  </Button>
                )
              }
              return <></>
            },
          },
        ]
      : []),
  ]
  const onModalVisible = (e: Employment, state: State) => {
    setSelectEmployee(e)
    setModalVisible(true)
    setState(state)
  }
  const onCloseModal = () => {
    setModalVisible(false)
    setNote('')
    setSelectEmployee(null)
    setArchiveTime('30')
  }

  const handleOk = async () => {
    if (!state || !selectEmployee) {
      return
    }

    setConfirmLoading(true)
    try {
      const leaveRequestData = await getOrCreateLeaveRequest({
        variables: {
          employmentId: selectEmployee.id,
        },
      })
      if (state === 'settle' || state === 'manual_execute') {
        const data: any = await executeLeaveRequest({
          variables: {
            employmentId: selectEmployee.id,
          },
        })
        if (state === 'manual_execute') {
          await archiveLeaveRequest({
            variables: {
              leaveRequestId: data.data.executeLeaveRequest.leaveRequest.id,
              payload: {
                archiveDate: new Date(Date.now()),
              },
            },
          })
          await confirmResignManualRequest({
            variables: {
              leaveRequestId: data.data.executeLeaveRequest.leaveRequest.id,
            },
          })
        }
      }
      if (state === 'archive') {
        await archiveLeaveRequest({
          variables: {
            leaveRequestId:
              leaveRequestData.data.getOrCreateLeaveRequest.leaveRequest.id,
            payload: {
              archiveDate: new Date(
                Date.now() +
                  (manualArchive ? Number(archiveTime) : 30) *
                    24 *
                    60 *
                    60 *
                    1000,
              ),
            },
          },
        })
      }
      if (state === 'cancel_settle') {
        await cancelSettlementLeaveRequest({
          variables: {
            leaveRequestId:
              leaveRequestData.data.getOrCreateLeaveRequest.leaveRequest.id,
          },
        })
      }
      if (state === 'operation_note') {
        await updateLeaveRequest({
          variables: {
            leaveRequestId:
              leaveRequestData.data.getOrCreateLeaveRequest.leaveRequest.id,
            payload: {
              note: note.trim(),
            },
          },
        })
      }
      await refetch()
      await setConfirmLoading(false)
    } catch (e) {
      setConfirmLoading(false)
    }

    onCloseModal()
  }
  const handleOnChangeDate = (value: string) => {
    setArchiveTime(value)
  }
  const renderModalContent = () => {
    if (!state || !selectEmployee) {
      return <></>
    }

    switch (state) {
      case 'settle':
        return (
          <p>
            Sau khi kích hoạt, {selectEmployee.fullName} sẽ nhận được tin SMS
            thông báo và hệ thống sẽ tự động tất toán số dư tài khoản sau 24h.
            Tin nhắn thông báo này sẽ không thể thu hồi, bạn chắc chắn chứ?
          </p>
        )
      case 'manual_execute':
        return (
          <p>
            Sau khi Đồng ý, {selectEmployee.fullName} sẽ được chuyển vào Danh
            sách Cần xử lý để kích hoạt tất toán. Hành vi này không thể thay đổi
            lại, bạn chắc chắn chứ?
          </p>
        )
      case 'cancel_settle':
        return (
          <p>
            Sau khi ngưng tất toán, hệ thống sẽ dừng tự động tất toán{' '}
            {selectEmployee.fullName}
            và chuyển về danh sách cần xử lý.
          </p>
        )
      case 'archive':
        return (
          <>
            <p>
              Nếu Nhân sự xác nhận {selectEmployee.fullName} chưa nghỉ việc, hãy
              lưu trữ người dùng này. Sau khi lưu trữ, hệ thống sẽ tạm thời
              không quét {selectEmployee.fullName} trong một khoảng thời gian.
            </p>
            <Radio.Group
              onChange={(val) => {
                setManualArchive(val.target.value)
              }}
              value={manualArchive}
            >
              <Space direction="vertical">
                <Radio value={false}>Lưu trữ trong 30 ngày</Radio>
                <Radio value={true}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Lưu trữ trong{'  '}
                    <Input
                      min={1}
                      style={{
                        width: '100px',
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}
                      disabled={!manualArchive}
                      type="number"
                      value={Number(archiveTime)}
                      required={!manualArchive}
                      onChange={(val) => {
                        setArchiveTime(val.target.value || '0')
                        console.log(val.target.value)
                      }}
                    ></Input>{' '}
                    ngày
                  </div>
                </Radio>
              </Space>
            </Radio.Group>
          </>
        )
      case 'operation_note':
        return (
          <TextArea
            rows={10}
            style={{ minHeight: '200px' }}
            defaultValue={selectEmployee.leaveRequest?.note}
            onChange={(e) => setNote(e.target.value)}
          />
        )
      default:
        return <></>
        break
    }
  }

  const renderModalTitle = () => {
    if (!state || !selectEmployee) {
      return ''
    }

    switch (state) {
      case 'settle':
        return `Bạn có muốn kích hoạt quy trình tất toán đối với ${selectEmployee.fullName}?`
      case 'manual_execute':
        return `Bạn xác nhận ${selectEmployee.fullName} nằm trong danh sách nghỉ việc?`
      case 'cancel_settle':
        return `Bạn có muốn ngưng tất toán ${selectEmployee.fullName}?`

      case 'archive':
        return `Bạn muốn lưu trữ ${selectEmployee.fullName}?`
      default:
        return <></>
        break
    }
  }

  return (
    <>
      <Table
        pagination={{
          pageSize: data?.employmentSearch_v2.limit || DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: () => {
            return data ? data?.employmentSearch_v2?.total : 0
          },
          total: data?.employmentSearch_v2?.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={data ? data.employmentSearch_v2.nodes : []}
        columns={columns}
        loading={loading || localLoading}
        size="middle"
        bordered={true}
        rowKey="id"
        scroll={{ x: 1000 }}
      />
      {selectEmployee && (
        <>
          <Modal
            title={renderModalTitle()}
            visible={modalVisible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={onCloseModal}
          >
            {renderModalContent()}
          </Modal>
        </>
      )}
    </>
  )
}

export default LeaveRequestTool
