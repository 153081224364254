import gql from 'graphql-tag'
import { useState, useEffect, useContext } from 'react'
import { LoanApplicationStatus, Maybe } from '../../graphql/models'
import { API_URL } from '../../graphql/constants'
import { GET_EMPLOYMENTS } from '../Employment/EmploymentList'
import { GET_TRANSACTIONS } from '../Transaction/TransactionList'
import { GET_USERS } from '../User/UserList'
import { GET_LOAN_APPLICATIONS } from '../LoanApplication/LoanApplicationList'
import { KeycloakContext } from '../../context/KeycloakContext'
import { useQuery } from 'react-apollo'
import { currentMoment } from '../../utils/helper'
import { message, Modal, Spin } from 'antd'
import React from 'react'

export enum ExportEntityType {
  LoanApplication = 'LoanApplication',
  ApprovedLoanApplication = 'ApprovedLoanApplication',
  User = 'User',
  Company = 'Company',
  Employment = 'Employment',
  Transaction = 'Transaction',
}

export const EXPORT_GET_BY_TASKID = gql`
  query export_getByTaskId($taskId: ID!) {
    export_getByTaskId(taskId: $taskId) {
      id
      taskId
      status
      storageObject {
        id
        uri
      }
    }
  }
`

export const EXPORT_LOAN_APPLICATION = gql`
  query loanApplications($query: LoanApplicationQuery) {
    loanApplications(query: $query) {
      nodes {
        id
        createdDate
        lastModifiedDate
        employment {
          id
          fullName
          employeeId
          company {
            id
            name
          }
        }
        status
        amount
        disbursementAccount
        fee
        actuallyReceived
        code
      }
    }
  }
`

export function getLoanApplicationExport(filter: any) {
  return {
    graphql: {
      operationName: null,
      variables: { query: { limit: 10000, offset: 0, filter } },
      query: GET_LOAN_APPLICATIONS.loc!.source.body,
    },
    rootJsonPath: '$.loanApplications.nodes',
    columnMapping: [
      { title: 'Loan Code', jsonPath: '$.code' },
      { title: 'Employee Id', jsonPath: '$.employment.employeeId' },
      { title: 'Employment Name', jsonPath: '$.employment.fullName' },
      { title: 'Disbursement Account', jsonPath: '$.disbursementAccount' },
      { title: 'Bank', jsonPath: '$.employment.bank.name' },
      { title: 'Amount', jsonPath: '$.amount' },
      { title: 'Created Date', jsonPath: '$.createdDate' },
      { title: 'Status', jsonPath: '$.status' },
    ],
  }
}

export const EXPORT_USER_APPLICATION = gql`
  query users($query: UserQuery) {
    users(query: $query) {
      nodes {
        id
        phoneNumber
        fullName
        currentEmployment {
          fullName
          status
          company {
            name
          }
        }
      }
      total
      limit
      offset
    }
  }
`

export function getUserExport(filter: any) {
  return {
    graphql: {
      operationName: null,
      variables: { query: { limit: 10000, offset: 0, filter } },
      query: GET_USERS.loc!.source.body,
    },
    rootJsonPath: '$.users.nodes',
    columnMapping: [
      { title: 'Employee Id', jsonPath: '$.currentEmployment.employeeId' },
      { title: 'Employment Name', jsonPath: '$.currentEmployment.fullName' },
      { title: 'Employment DOB', jsonPath: '$.currentEmployment.dob' },
      { title: 'Company', jsonPath: '$.currentEmployment.company.name' },
      { title: 'Phone Number', jsonPath: '$.phoneNumber' },
      { title: 'Status', jsonPath: '$.currentEmployment.status' },
    ],
  }
}

export const EXPORT_COMPANY_APPLICATION = gql`
  query companies($query: CompanyQuery) {
    companies(query: $query) {
      nodes {
        id
        lastModifiedDate
        name
        createdDate
        taxCode
        slug
        status
        email
        address
      }
      total
      limit
      offset
    }
  }
`

export function getCompanyExport(filter: any) {
  return {
    graphql: {
      operationName: null,
      variables: { query: { limit: 10000, offset: 0, filter } },
      query: EXPORT_COMPANY_APPLICATION.loc!.source.body,
    },
    rootJsonPath: '$.companies.nodes',
    columnMapping: [
      { title: 'Company Slug', jsonPath: '$.slug' },
      { title: 'Name', jsonPath: '$.name' },
      { title: 'Email', jsonPath: '$.email' },
      { title: 'Address', jsonPath: '$.address' },
      { title: 'Status', jsonPath: '$.status' },
    ],
  }
}

export const EXPORT_EMPLOYMENT_APPLICATION = gql`
  query employments($query: EmploymentQuery) {
    employments(query: $query) {
      nodes {
        id
        bank {
          name
        }
        company {
          name
        }
        status
        fullName
        bankAccount
      }
      total
      limit
      offset
    }
  }
`

export function getEmploymentExport(filter: any) {
  return {
    graphql: {
      operationName: null,
      variables: { query: { limit: 10000, offset: 0, filter } },
      query: GET_EMPLOYMENTS.loc!.source.body,
    },
    rootJsonPath: '$.employments.nodes',
    columnMapping: [
      { title: 'EmployeeId Id', jsonPath: '$.employeeId' },
      { title: 'Name', jsonPath: '$.fullName' },
      { title: 'Company', jsonPath: '$.company.name' },
      { title: 'Bank', jsonPath: '$.bank.name' },
      { title: 'Bank Account', jsonPath: '$.bankAccount' },
      { title: 'Earned Balance', jsonPath: '$.earnedBalance' },
      { title: 'Current Balance', jsonPath: '$.currentBalance' },
      { title: 'Withdrawable Balance', jsonPath: '$.withdrawableBalance' },
      { title: 'Status', jsonPath: '$.status' },
    ],
  }
}

export const EXPORT_TRANSACTION_APPLICATION = gql`
  query disbursements($query: DisbursementQuery) {
    disbursements(query: $query) {
      nodes {
        id
        loanApplication {
          code
        }
        amount
        bankAccountId
        externalTransactionId
        status
        lastModifiedDate
      }
      total
      limit
      offset
    }
  }
`

export function getTransactionExport(filter: any) {
  return {
    graphql: {
      operationName: null,
      variables: {
        query: {
          limit: 10000,
          offset: 0,
          filter,
        },
        // ...queryVariables,
      },
      query: GET_TRANSACTIONS.loc!.source.body,
    },
    rootJsonPath: '$.loanApplications.nodes',
    columnMapping: [
      {
        title: `Advance Application Code`,
        jsonPath: '$.code',
      },
      {
        title: `Employee Id`,
        jsonPath: '$.employment.employeeId',
      },
      {
        title: `Employee Name`,
        jsonPath: '$.employment.fullName',
      },
      {
        title: `Disbursement Account`,
        jsonPath: '$.disbursement.bankAccountId',
      },
      {
        title: `Advance Amount`,
        jsonPath: '$.amount.value',
        format: '#,##0',
        dataType: 'Number',
      },
      {
        title: `Transfer Amount`,
        jsonPath: '$.disbursement.amount.value',
        format: '#,##0',
        dataType: 'Number',
      },
      {
        title: `Employee Fee`,
        jsonPath: '$.fee.value',
        format: '#,##0',
        dataType: 'Number',
      },
      {
        title: `Employer Fee`,
        jsonPath: '$.employerFee.value',
        format: '#,##0',
        dataType: 'Number',
      },
      {
        title: `Receivable Amounts`,
        jsonPath: '$.receivableAmount.value',
        format: '#,##0',
        dataType: 'Number',
      },
      {
        title: `Currency`,
        jsonPath: '$.amount.currency',
      },
      {
        title: `External Ref`,
        jsonPath: '$.disbursement.externalTransactionId',
      },
      {
        title: `Advanced Application Time`,
        jsonPath: '$.createdDate',
        dateFormat: 'HH:mm:ss',
      },
      {
        title: `Advanced Application Date`,
        jsonPath: '$.createdDate',
        dateFormat: 'dd/MM/yyyy',
      },
      {
        title: `Status`,
        jsonPath: '$.disbursement.status',
        valueMapping: {
          Released: `Released`,
        },
      },
      { title: `Source Bank`, jsonPath: '$.disbursement.sourceBank.alias' },
      {
        title: `Source Bank Account`,
        jsonPath: '$.disbursement.sourceBankAccount',
      },
    ],
    footers: [
      [],
      [
        {
          value: {
            value: 'Tổng cộng',
          },
        },
        null,
        null,
        null,
        {
          formula: true,
          value: {
            dynamic: 'SUM(E{{firstDataRow}}:E{{lastDataRow}})',
          },
          format: '#,##0',
        },
        {
          formula: true,
          value: {
            dynamic: 'SUM(F{{firstDataRow}}:F{{lastDataRow}})',
          },
          format: '#,##0',
        },
        {
          formula: true,
          value: {
            dynamic: 'SUM(G{{firstDataRow}}:G{{lastDataRow}})',
          },
          format: '#,##0',
        },
        {
          formula: true,
          value: {
            dynamic: 'SUM(H{{firstDataRow}}:H{{lastDataRow}})',
          },
          format: '#,##0',
        },
        {
          formula: true,
          value: {
            dynamic: 'SUM(I{{firstDataRow}}:I{{lastDataRow}})',
          },
          format: '#,##0',
        },
        {
          value: {
            value: 'VND',
          },
        },
      ],
    ],
  }
}

interface ExportAapprovedLoanApplication {
  filter: {
    statuses: [LoanApplicationStatus.Approved]
  }
  limit?: Maybe<number>
  offset?: Maybe<number>
}
export const EXPORT_APPROVED_LOAN_APPLICATION = `
  mutation {
    lockLoanApplicationForDisbursement(query: {filter: {statuses: [Approved]}, limit: 1000}) {
      id
      disbursementAccount
      amountCurrency
      actuallyReceivedValue
      code
      employment {
        bankAccount
        fullNameNormalized
        bank {
          id
          transferCode
          alias
        }
      }
      status
    }
  }
`

export function getApprovedLoanApplicationExport(filter: any) {
  return {
    graphql: {
      operationName: null,
      variables: {
        query: {
          limit: 10000,
          offset: 0,
          filter: {
            statuses: [LoanApplicationStatus.Approved],
          },
        },
      },
      query: EXPORT_APPROVED_LOAN_APPLICATION,
    },
    rootJsonPath: '$.lockLoanApplicationForDisbursement',
    columnMapping: [
      {
        title: 'STT',
        value: {
          index: true,
        },
      },
      {
        title: 'Account',
        value: {
          jsonPath: '$.disbursementAccount',
        },
      },
      {
        title: 'Currency',
        value: {
          jsonPath: '$.amountCurrency',
        },
      },
      {
        title: 'Ben_Name',
        value: {
          jsonPath: '$.employment.fullNameNormalized',
        },
      },
      {
        title: 'Bank_Code',
        value: {
          jsonPath: '$.employment.bank.transferCode',
        },
      },
      {
        title: 'Bank_Name',
        value: {
          jsonPath: '$.employment.bank.alias',
        },
      },
      {
        title: 'Branch_Name',
        value: {
          value: 'ALL',
        },
      },
      {
        title: 'City_Name',
        value: {
          value: 'Ha Noi',
        },
      },
      {
        title: 'Amount',
        value: {
          jsonPath: '$.actuallyReceivedValue',
        },
      },
      {
        title: 'Details',
        value: {
          jsonPath: '$.code',
        },
      },
      {
        title: 'Charges',
        value: {
          value: 'OUR',
        },
      },
    ],
  }
}

export enum ExportType {
  Xlsx = 'xlsx',
  Txt = 'txt',
}
// export function useExportEntity(): any {
//   const keycloak = useContext(KeycloakContext)
//   try {
//     const [exportLoading, setExportLoading] = useState(false)
//     function exportEntity(
//       type: ExportEntityType,
//       filter: any,
//       exportType: ExportType = ExportType.Xlsx,
//       exportFilename: string = 'export',
//     ) {
//       let contentType =
//         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
//       if (exportType === ExportType.Txt) {
//         contentType = 'text/plain;charset=UTF-8'
//       }
//       setExportLoading(true)
//       const xhr = new XMLHttpRequest()
//       xhr.open('POST', `${API_URL}/v2/graphql_exports/${exportType}`)
//       xhr.setRequestHeader('Content-Type', 'application/json')
//       xhr.setRequestHeader('Accept-Language', 'vi-VN')
//       xhr.setRequestHeader('Authorization', `Bearer ${keycloak?.token}`)
//       xhr.responseType = 'blob'
//       xhr.onload = function () {
//         if (this.status === 200) {
//           const blob = new Blob([xhr.response], {
//             type: contentType,
//           })
//           const link = document.createElement('a')
//           link.href = window.URL.createObjectURL(blob)
//           link.download = exportFilename
//           link.click()
//           link.remove()
//         } else if (this.status === 401) {
//           if (keycloak) {
//             keycloak
//               .updateToken(5)
//               .success(function (refreshed) {
//                 if (refreshed) {
//                   exportEntity(type, filter, exportType, exportFilename)
//                 } else {
//                   console.log('Token is still valid')
//                 }
//               })
//               .error((error: any) => {
//                 // No refresh or client token available, we force user to login
//                 window.alert(error)
//               })
//           }
//         }
//         setExportLoading(false)
//       }
//       switch (type) {
//         case ExportEntityType.LoanApplication:
//           xhr.send(JSON.stringify(getLoanApplicationExport(filter)))
//           return
//         case ExportEntityType.ApprovedLoanApplication:
//           xhr.send(JSON.stringify(getApprovedLoanApplicationExport(filter)))
//           return
//         case ExportEntityType.User:
//           xhr.send(JSON.stringify(getUserExport(filter)))
//           return
//         case ExportEntityType.Company:
//           xhr.send(JSON.stringify(getCompanyExport(filter)))
//           return
//         case ExportEntityType.Employment:
//           xhr.send(JSON.stringify(getEmploymentExport(filter)))
//           return
//         case ExportEntityType.Transaction:
//           xhr.send(JSON.stringify(getTransactionExport(filter)))
//           return
//         default:
//           throw new Error('Dont support export type')
//       }
//     }
//     return [exportLoading, exportEntity]
//   } catch (e) {
//     console.error(e)
//   }
// }

export enum Status {
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending',
  Success = 'Success',
  Unknown = 'Unknown',
}

export function useExportEntity(): any {
  const keycloak = useContext(KeycloakContext)
  const [taskId, setTaskId] = useState<null | string>(null)
  const [downloadFilename, setDownloadFilename] = useState('export')
  const {
    data: taskData,
    startPolling,
    stopPolling,
  } = useQuery<any>(EXPORT_GET_BY_TASKID, {
    fetchPolicy: 'network-only',
    variables: {
      taskId: taskId,
    },
    skip: taskId === null,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (
        taskData?.export_getByTaskId.status === Status.Success &&
        taskId !== null
      ) {
        setTaskId(null)
        setDownloadFilename('export')
        const link = document.createElement('a')
        link.href = taskData?.export_getByTaskId.storageObject.uri
        link.download =
          downloadFilename + '_' + currentMoment().format('HH_mm_ss_DD_MM_YYYY')
        link.click()
        link.remove()
        Modal.destroyAll()
        stopPolling()
        message.success({
          content: 'Tải xuống thành công',
        })
        return
      }
      if (
        (taskData?.export_getByTaskId.status === Status.Failed ||
          taskData?.export_getByTaskId.status === Status.Unknown) &&
        taskId !== null
      ) {
        setTaskId(null)
        setDownloadFilename('export')
        Modal.destroyAll()
        stopPolling()
        message.error({
          content: 'Tải xuống thất bại',
        })
        return
      }
    },
  })

  const defaultQueryVariables = {
    loanApplicationsQuery: {
      filter: {
        statuses: [LoanApplicationStatus.Disbursed],
      },
    },
  }

  try {
    const [exportLoading, setExportLoading] = useState(false)
    // tslint:disable-next-line: no-inner-declarations
    function exportEntity(
      type: ExportEntityType,
      filter: any,
      queryVariables: any = defaultQueryVariables,
      exportFilename: string = 'export',
      exportType: ExportType = ExportType.Xlsx,
    ) {
      setExportLoading(true)
      const accessToken = keycloak && keycloak?.token
      if (!accessToken) {
        window.alert('Expired Token')
        keycloak && keycloak.logout()
        return
      }
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${API_URL}/v2/graphql_exports/${exportType}`)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Accept-Language', 'vi-VN')
      xhr.setRequestHeader('x-format-money', `json`) // hacker
      xhr.setRequestHeader(
        'Authorization',
        `Bearer ${keycloak && keycloak.token}`,
      )
      xhr.onload = function () {
        if (this.status === 200) {
          const taskObject = JSON.parse(xhr.responseText)
          if (taskObject) {
            setTaskId(taskObject.id)
            setDownloadFilename(
              exportFilename +
                '_' +
                currentMoment().format('HH_mm_ss_DD_MM_YYYY'),
            )
            startPolling(1000)
            Modal.info({
              title: 'Đang chuẩn bị tải xuống...',
              okText: 'Huỷ',
              okType: 'text',
              content: (
                <>
                  <Spin size="large" style={{ marginTop: 10 }} />
                </>
              ),
              onOk: stopPolling,
            })
          }
        }
        setExportLoading(false)
      }
      switch (type) {
        case ExportEntityType.LoanApplication:
          xhr.send(JSON.stringify(getLoanApplicationExport(filter)))
          return
        case ExportEntityType.ApprovedLoanApplication:
          xhr.send(JSON.stringify(getApprovedLoanApplicationExport(filter)))
          return
        case ExportEntityType.User:
          xhr.send(JSON.stringify(getUserExport(filter)))
          return
        case ExportEntityType.Company:
          xhr.send(JSON.stringify(getCompanyExport(filter)))
          return
        case ExportEntityType.Employment:
          xhr.send(JSON.stringify(getEmploymentExport(filter)))
          return
        case ExportEntityType.Transaction:
          xhr.send(JSON.stringify(getTransactionExport(filter)))
          return
        default:
          throw new Error('Dont support export type')
      }
    }
    return [exportLoading, exportEntity]
  } catch (e) {
    console.log(e)
  }
}
