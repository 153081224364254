import React, { useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import { t } from 'ttag'
import moment from 'moment'
import { Instant, Company } from '../../graphql/models'

const getChartConfig = (xData: any[], yData: any[]) => {
  return {
    color: ['#7cdb86'],
    grid: {
      left: 35,
      right: 35,
      top: 35,
      bottom: 20,
    },
    legend: {
      data: [t`Total Loan Applications`],
      selectedMode: false,
    },
    tooltip: {
      trigger: 'axis',
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        // data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
        data: xData,
        axisLine: {
          lineStyle: {
            color: '#c5c4c2',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#7cdb86',
          },
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: t`Total Loan Applications`,
        type: 'bar',
        label: {
          show: true,
          position: 'top',
        },
        data: yData,
        barWidth: 12,
        itemStyle: {
          normal: {
            barBorderRadius: 5,
          },
        },
        // markPoint: {
        //   data: [
        //     { type: 'max', name: 'Highest' },
        //     { type: 'min', name: 'Lowest' },
        //   ],
        // },
      },
    ],
  }
}

type ChartTotalLoanApplicationsProps = {
  data: {
    company: Company
  }
  isTotalLoanByWeek: boolean
}

export default ({
  data,
  isTotalLoanByWeek,
}: ChartTotalLoanApplicationsProps) => {
  return (
    // <Card
    //   title={t`Total Loan Applications Statistics`}
    //   style={{
    //     flexGrow: 1,
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'space-between',
    //   }}
    // >

    <ReactEcharts
      option={getChartConfig(
        data && data.company
          ? data.company.loanApplicationCountGraph.map((point: Instant) =>
              isTotalLoanByWeek
                ? moment(point.x).format('DD/MM')
                : moment(point.x).format('MMM'),
            )
          : [0],
        data && data.company
          ? data.company.loanApplicationCountGraph.map(
              (point: Instant) => point.y,
            )
          : [0],
      )}
      className="chart-container container-h-350"
    />
    // </Card>
  )
}
