import React, { useEffect } from 'react'

import { IPageProps, IPageData } from '../../interfaces/page-data'
import CreateCompany from '../../components/Company/CreateCompany'

export const CreateCompanyPage: React.FunctionComponent<IPageProps> = (
  props,
) => {
  const { onSetPage, getPageData } = props

  const pageData: IPageData = {
    title: 'Create Company',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: '/vertical/dashboard',
      },
      {
        title: 'Companies',
        route: '/vertical/companies',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <div className="row">
      <div className="col-md-6 col-sm-12">
        <CreateCompany />
      </div>
    </div>
  )
}
