import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Alert, Button, Modal, Row, Skeleton, Spin } from 'antd'
import { useMutation, useQuery } from 'react-apollo'
import { GET_COMPANY } from './CompanyList'
import { CREATE_STORAGE_OBJECT, CREATE_TOC_COMPANY } from './UploadTOC'
import { sendNotification } from '../../utils/notification'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { DownloadOutlined } from '@ant-design/icons'
import { notify } from '../../utils/helper'
import { message } from 'antd'

export interface HtmlConvertProps {
  id: string
}

const HtmlConvert: React.FC<HtmlConvertProps> = ({ id }) => {
  const editorRef = useRef(null)
  const [html, setHtml] = useState('')
  const [enable, setEnable] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [editorLoaded, setEditorLoaded] = useState(false)

  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { id },
  })
  const [createStorageObject] = useMutation(CREATE_STORAGE_OBJECT)
  const [createToc] = useMutation(CREATE_TOC_COMPANY)

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleSave = async () => {
    try {
      setUploadLoading(true)

      if (!data.company.contractCompanyName) {
        sendNotification({
          type: 'error',
          message: 'Tên công ty legal đang bị trống (contractCompanyName)',
        })

        return
      }

      const res = await createStorageObject({
        variables: {
          payload: {
            filename: `ttbs-${data.company.shortName}`,
            contentType: 'text/html',
          },
        },
      })

      if (
        res.data?.createStorageObject?.uploadUri &&
        res.data?.createStorageObject?.storageObject
      ) {
        const myblob = new Blob([html5Transfrom(html)], {
          type: 'text/html',
        })

        await axios.put(res.data.createStorageObject.uploadUri, myblob, {
          headers: {
            'Content-Type': 'text/html',
          },
        })

        await createToc({
          variables: {
            companyId: id,
            payload: {
              storageId: res.data.createStorageObject.storageObject.id,
            },
          },
        })

        sendNotification({ message: 'Upload successfully' })
        setUploadLoading(false)

        return
      }

      sendNotification({ message: 'Something wrong', type: 'error' })
      setUploadLoading(false)
    } catch (error) {
      sendNotification({ message: 'Something wrong', type: 'error' })
      setUploadLoading(false)
    }
  }

  const handlePreview = () => {
    if (!data.company.contractCompanyName) {
      sendNotification({
        type: 'error',
        message: 'Tên công ty legal đang bị trống (contractCompanyName)',
      })

      return
    }

    if (editorRef.current) {
      setHtml((editorRef as any).current.getContent())
      setIsModalVisible(true)
      setEnable(true)

      // console.log((editorRef as any).current.getContent())
    }
  }

  const signForm = `<p class="p1" style="text-align: center;"><strong>X&aacute;c nhận giao kết Thỏa Thuận Bổ Sung</strong></p>
  <p class="p3">T&ocirc;i l&agrave; người đại diện theo ph&aacute;p luật của Hợp đồng lao động với {{companyName}},
      c&oacute; giấy chứng nhận đăng k&yacute; doanh nghiệp số {{taxCode}} trụ sở tại {{companyAddress}}
      (<strong>C&ocirc;ng Ty</strong>).</p>
  <p class="p3">Th&ocirc;ng tin c&aacute; nh&acirc;n của t&ocirc;i như sau:</p>
  <p class="p3">Họ v&agrave; T&ecirc;n: {{fullName}}</p>
  <p class="p3">T&agrave;i liệu định danh c&aacute; nh&acirc;n (chứng minh thư/thẻ căn cước/hộ chiếu): {{idNumber}}</p>
  <p class="p3">Bằng văn bản n&agrave;y, t&ocirc;i thể hiện rằng t&ocirc;i thực hiện h&agrave;nh vi giao kết Thỏa Thuận Bổ
      Sung th&ocirc;ng qua Phần Mềm Nano.</p>
  <p class="p3">V&igrave; mục đ&iacute;ch văn bản n&agrave;y:</p>
  <p class="p3"><strong>Phần Mềm</strong> l&agrave; c&aacute;c chương tr&igrave;nh m&aacute;y t&iacute;nh, d&ugrave; ở
      dạng m&atilde; m&aacute;y (machine code) hay m&atilde; nguồn (source code), c&aacute;c t&agrave;i liệu hướng dẫn sử
      dụng, v&agrave; c&aacute;c t&agrave;i liệu kỹ thuật, được C&ocirc;ng ty TNHH Dịch vụ C&ocirc;ng nghệ Nano Việt Nam
      c&oacute; giấy chứng nhận đăng k&yacute; doanh nghiệp số 0109172053, c&oacute; địa chỉ trụ sở ch&iacute;nh tại
      To&agrave; nh&agrave; Capital Building, 58 Kim M&atilde;, Phường Kim M&atilde;, Q. Ba Đ&igrave;nh, H&agrave; Nội,
      cung cấp cho C&ocirc;ng Ty.</p>
  <p class="p3"><strong>Phần Mềm Nano</strong></p>
  <p class="p3">l&agrave; c&aacute;c Phần Mềm:</p>
  <p class="p4">NanoApp l&agrave; một Phần Mềm do C&ocirc;ng ty TNHH Dịch vụ C&ocirc;ng nghệ Nano Việt Nam sở hữu
      v&agrave; vận h&agrave;nh tr&ecirc;n c&aacute;c nền tảng Android (Google Play) v&agrave; iOS (Apple) với t&ecirc;n
      gọi Vui App. (Nano c&oacute; thể cập nhật v&agrave; th&ocirc;ng b&aacute;o cho Kh&aacute;ch H&agrave;ng c&aacute;c
      đường dẫn v&agrave; url n&agrave;y t&ugrave;y từng thời điểm m&agrave; kh&ocirc;ng cần c&oacute; sự chấp thuận của
      Kh&aacute;ch H&agrave;ng); v&agrave;</p>
  <p class="p4">NanoWebsite l&agrave; một Phần Mềm do C&ocirc;ng ty TNHH Dịch vụ C&ocirc;ng nghệ Nano Việt Nam sở hữu
      v&agrave; hoạt động dưới dạng một website v&agrave; c&oacute; địa chỉ tại: m.vuiapp.vn (Nano c&oacute; thể cập nhật
      v&agrave; th&ocirc;ng b&aacute;o cho Kh&aacute;ch H&agrave;ng địa chỉ của NanoWebsite n&agrave;y t&ugrave;y từng
      thời điểm m&agrave; kh&ocirc;ng cần c&oacute; sự chấp thuận của Kh&aacute;ch H&agrave;ng);</p>
  <p class="p3">được Nano sử dụng để:</p>
  <p class="p5">mở t&agrave;i khoản cho Người Lao Động;</p>
  <p class="p5">theo d&otilde;i thu nhập được chấm c&ocirc;ng thực tế của Người Lao Động</p>
  <p class="p5">tiếp nhận Y&ecirc;u Cầu Tạm Ứng;<span class="Apple-converted-space">&nbsp;</span></p>
  <p class="p5">t&iacute;nh to&aacute;n hạn mức lương Người Lao Động được Ứng;</p>
  <p class="p5">thanh to&aacute;n Khoản Tạm Ứng; v&agrave;</p>
  <p class="p5">c&aacute;c nghiệp vụ hay dịch vụ kh&aacute;c của Nano.</p>
  <p class="p3"><strong>Thỏa Thuận Bổ Sung</strong> l&agrave; một thỏa thuận giữa người lao động của {{companyName}}
      v&agrave; {{companyName}} với nội dung cơ bản theo mẫu được đ&iacute;nh k&egrave;m với văn bản n&agrave;y.</p>
  <p class="p3" id='otp'><strong>ĐỂ L&Agrave;M BẰNG CHỨNG, h&atilde;y lựa chọn mục &ldquo;T&ocirc;i đồng &yacute;&rdquo;
          dưới
          đ&acirc;y<span class="Apple-converted-space">&nbsp;</span></strong></p>`

  const newSignForm = `<p>-----------------------</p>
  <p>T&ocirc;i l&agrave; Người lao động c&oacute; giao kết hợp đồng lao động với {{companyName}} với th&ocirc;ng tin
      c&aacute; nh&acirc;n như sau:</p>
  <p>Họ v&agrave; t&ecirc;n: <strong><em>{{fullName}}</em></strong></p>
  <p>T&agrave;i liệu định danh c&aacute; nh&acirc;n (chứng minh thư/ thẻ căn cước/ hộ chiếu):
      <strong><em>{{idNumber}}</em></strong> &nbsp;
  </p>
  <p>-----------------------</p>
  <p>Phụ lục n&agrave;y được dẫn chiếu đến Hợp Đồng v&agrave; c&aacute;c phụ lục đ&iacute;nh k&egrave;m giữa
      C&ocirc;ng Ty TNHH Dịch vụ C&ocirc;ng nghệ Nano Việt Nam v&agrave; {{companyName}}.</p>
  <p id='otp'><strong>ĐỂ L&Agrave;M BẰNG CHỨNG, h&atilde;y lựa chọn mục &ldquo;T&ocirc;i đồng &yacute;&rdquo; dưới
          đ&acirc;y&nbsp;</strong></p>`

  const fillCompanyInformation = (input: string) => {
    return input
      .replaceAll('{{companyName}}', data.company.contractCompanyName || '')
      .replaceAll('{{taxCode}}', data.company.contractCompanyTaxCode || '')
      .replaceAll(
        '{{companyAddress}}',
        data.company.contractCompanyAddress || '',
      )
  }

  const html5Transfrom = (html: string) => `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
    </head>
    <body>
        ${html}
        ${fillCompanyInformation(newSignForm)}
    </body>
    </html>`

  const handleDownload = () => {
    if (editorRef.current && html) {
      const myblob = new Blob([html5Transfrom(html)], {
        type: 'text/html',
      })

      fileDownload(myblob, `${data.company.slug}.html`)
    }
  }

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  if (loading) {
    return <Skeleton />
  }

  if (!data) {
    return <Alert message="Data is empty" />
  }

  return (
    <Spin spinning={!editorLoaded} size="large" tip="Loading....">
      <div style={{ marginBottom: 16, minHeight: 300 }}>
        <Editor
          onInit={(evt, editor) => {
            ;(editorRef as any).current = editor
            setEditorLoaded(true)
          }}
          initialValue="<p>Copy thoả thuận bổ sung vào đây và kiểm tra bản preview mới được upload</p>"
          init={{
            height: 700,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
              'table',
            ],
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help' +
              'table',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            table_default_attributes: {
              border: 1,
            },
            table_default_styles: {
              'border-collapse': 'collapse',
              width: '100%',
            },
            table_responsive_width: true,
          }}
        />
      </div>
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button onClick={handlePreview} style={{ marginRight: 16 }}>
          Preview
        </Button>
        <Button
          onClick={handleDownload}
          style={{ marginRight: 16 }}
          disabled={!enable}
        >
          Download
        </Button>
        <Button onClick={handleSave} disabled={!enable} loading={uploadLoading}>
          Upload
        </Button>
      </Row>
      <Modal
        title="Xem trước thoả thuận bổ sung sẽ upload"
        visible={isModalVisible}
        onOk={handleOk}
        closable={false}
        onCancel={handleOk}
        width={460}
      >
        <div
          dangerouslySetInnerHTML={{ __html: html5Transfrom(html) }}
          style={{
            border: '1px solid #CCCCCC',
            padding: 20,
            overflow: 'auto',
            height: 800,
          }}
        />
      </Modal>
    </Spin>
  )
}

export default HtmlConvert
