import React, { useState } from 'react'
import { Upload, Button } from 'antd'
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'
import { CreateStorageObjectResponse } from '../../graphql/models'
import { RcCustomRequestOptions, RcFile } from 'antd/lib/upload/interface'

export const CREATE_STORAGE_OBJECT = gql`
  mutation createStorageObject($payload: CreateStorageObjectPayload!) {
    createStorageObject(payload: $payload) {
      uploadUri
      storageObject {
        id
      }
    }
  }
`

export const PROCESS_DISBURSEMENT_FILE = gql`
  mutation processDisbursementFile($storageId: ID!) {
    processDisbursementFile(storageId: $storageId)
  }
`

function UploadBtn({ loading }: { loading: boolean }) {
  return (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  )
}

export async function useStorageObject(filename: string, contentType: string) {
  const [createStorageObject] = useMutation<CreateStorageObjectResponse>(
    CREATE_STORAGE_OBJECT,
  )
  const data = await createStorageObject({
    variables: { payload: { filename, contentType } },
  })

  return [data.data!.storageObject.id, data.data!.uploadUri]
}

function getBase64(img: any, callback: any) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function getBody(xhr: XMLHttpRequest) {
  const text = xhr.responseText || xhr.response
  if (!text) {
    return text
  }

  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}
const checkImageDimension = (
  file: RcFile,
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target?.result
      const image = document.createElement('img')
      image.src = _loadedImageUrl as string

      image.addEventListener('load', () => {
        const { width, height } = image
        resolve({ width, height })
      })
    })
  })
}
export function UploadImage({
  dispatch,
  setLogoId,
  imageUrl,
  setImageUrl,
  ratio,
}: {
  dispatch: any
  setLogoId: any
  imageUrl: string
  setImageUrl: (url: string) => void
  ratio?: number
}) {
  const [uploadUri, setUploadUri] = useState('')
  const [loading, setLoading] = useState(false)
  const [contentType, setContentType] = useState('image/png')
  const [createStorageObject] = useMutation<{
    createStorageObject: CreateStorageObjectResponse
  }>(CREATE_STORAGE_OBJECT)

  async function beforeUpload(file: RcFile) {
    dispatch({ type: 'pending' })
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      dispatch({ type: 'error', message: 'You can only upload image file!' })
      return
    }
    const isLt2M = file.size / 1024 / 1024 < 20
    if (!isLt2M) {
      dispatch({ type: 'error', message: 'Image must smaller than 2MB!' })
      return
    }
    if (ratio) {
      const { width, height } = await checkImageDimension(file)

      if (width / height !== ratio) {
        dispatch({ type: 'error', message: 'Image must be in correct ratio' })
        return
      }
    }
    const data = await createStorageObject({
      variables: { payload: { filename: file.name, contentType: file.type } },
    })
    setLoading(true)
    setUploadUri(data.data!.createStorageObject.uploadUri)
    setLogoId(data.data!.createStorageObject.storageObject.id)
    setContentType(file.type)
  }

  function handleChange(info: any) {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url: string) => {
        setImageUrl(url)
        setLoading(false)
      })
    }
  }

  function handleUpload(option: RcCustomRequestOptions) {
    var xhr = new XMLHttpRequest()
    xhr.open('PUT', uploadUri)
    xhr.setRequestHeader('Content-Type', contentType)

    xhr.onload = function onload() {
      if (xhr.status < 200 || xhr.status >= 300) {
        return
        // return option.onError(getError(option, xhr), getBody(xhr))
      }
      return option.onSuccess(getBody(xhr), option.file)
    }
    xhr.send(option.file)
  }

  return (
    <Upload
      accept="image/*"
      name="avatar"
      // action={uploadUri}
      // headers={{ 'Content-Type': 'image/png' }}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      customRequest={handleUpload}
      // method="PUT"
    >
      {imageUrl ? (
        <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
      ) : (
        <UploadBtn loading={loading} />
      )}
    </Upload>
  )
}

export function UploadImport({ dispatch }: { dispatch: any }) {
  const [uploadUri, setUploadUri] = useState('')
  const [storageId, setStorageId] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [contentType, setContentType] = useState(
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  )
  const [createStorageObject] = useMutation<{
    createStorageObject: CreateStorageObjectResponse
  }>(CREATE_STORAGE_OBJECT)
  const [processDisbursementFile] = useMutation(PROCESS_DISBURSEMENT_FILE)

  async function beforeUpload(file: any) {
    const isXlsx =
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    if (!isXlsx) {
      dispatch({ type: 'error', message: 'You can only upload xlsx file!' })
      return
    }

    const data = await createStorageObject({
      variables: { payload: { filename: file.name, contentType: file.type } },
    })
    setLoading(true)
    setUploadUri(data.data!.createStorageObject.uploadUri)
    setStorageId(data.data!.createStorageObject.storageObject.id)
    setContentType(file.type)
  }

  async function handleChange(info: any) {
    if (info.file.status === 'done') {
      setLoading(false)
      await processDisbursementFile({ variables: { storageId } })
      dispatch({ type: 'success', message: 'Import file successfully' })
    }
  }

  function handleUpload(option: RcCustomRequestOptions) {
    var xhr = new XMLHttpRequest()
    xhr.open('PUT', uploadUri)
    xhr.setRequestHeader('Content-Type', contentType)

    xhr.onload = function onload() {
      if (xhr.status < 200 || xhr.status >= 300) {
        return
        // return option.onError(getError(option, xhr), getBody(xhr))
      }
      return option.onSuccess(getBody(xhr), option.file)
    }
    xhr.send(option.file)
  }

  return (
    <Upload
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      name="import"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      customRequest={handleUpload}
    >
      <Button loading={loading}>
        <UploadOutlined /> Select File
      </Button>
    </Upload>
  )
}
