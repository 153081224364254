import React from 'react'
import gql from 'graphql-tag'
import { t } from 'ttag'
import { useQuery } from '@apollo/react-hooks'
import {
  LoanApplication,
  Money,
  LoanApplicationBatchConnection,
} from '../../graphql/models'
import { Table, Alert, Card } from 'antd'
import ButtonLink from '../../components/ButtonLink'
import { formatMoney } from '../../utils/helper'

type PendingApplicationListProps = {
  data: LoanApplication[]
  loading: any
  error: any
}

export function PendingLoadApplicationList({
  data,
  loading,
  error,
}: PendingApplicationListProps) {
  const columns = [
    {
      title: t`Employment Name`,
      render: (loanApplication: LoanApplication) => {
        return (
          <ButtonLink
            title={loanApplication.employment.fullName}
            routing={`/vertical/loan-applications/${loanApplication.id}/detail?status=Dashboard`}
            size="middle"
            style={{ padding: 0, whiteSpace: 'nowrap' }}
          />
        )
      },
      // width: 200,
    },
    {
      title: t`Company`,
      render: (loanApplication: LoanApplication) => {
        return loanApplication.employment.company.shortName
      },
      width: 150,
    },
    {
      title: t`Amount`,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: Money) => {
        return formatMoney(amount)
      },
      width: 150,
    },
  ]

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <Card
      title={t`Processing Loan Applications`}
      className="d-flex flex-column"
      style={{ flexGrow: 1 }}
    >
      <Table
        pagination={false}
        dataSource={!data ? [] : data}
        columns={columns}
        loading={loading}
        size="middle"
        bordered={true}
        rowKey="id"
        locale={{ emptyText: t`No Processing Loan Applications` }}
      />
    </Card>
  )
}
