import React, { useEffect, useRef, useState, useContext } from 'react'
import gql from 'graphql-tag'

import { IPageProps, IPageData } from '../../interfaces/page-data'
import {
  Card,
  Upload,
  message,
  Popover,
  Timeline,
  Radio,
  Button,
  Modal,
} from 'antd'
import { t } from 'ttag'
import { useMutation } from '@apollo/react-hooks'
import { useMessageAlert } from '../../components/alert/MessageAlert'
import axios from 'axios'
import { UploadFile } from 'antd/lib/upload/interface'
import { StorageObject, Viewer } from '../../graphql/models'
import {
  CloudUploadOutlined,
  FileOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import { CompanyDetailProps } from './CompanyDetail'

message.config({ top: 100 })

export const CREATE_STORAGE_OBJECT_SUCCESS =
  'Create storage object successfully'

const { Dragger } = Upload

export const CREATE_STORAGE_OBJECT = gql`
  mutation createStorageObject($payload: CreateStorageObjectPayload!) {
    createStorageObject(payload: $payload) {
      uploadUri
      storageObject {
        id
      }
    }
  }
`

// export const PROCESS_EMPLOYMENT_FILE = gql`
//   mutation processEmploymentFile($storageId: ID!, $companyId: ID!) {
//     processEmploymentFile(storageId: $storageId, companyId: $companyId)
//   }
// `

// export const PROCESS_ATTENDANCE_FILE = gql`
//   mutation processAttendanceFile($storageId: ID!, $companyId: ID!) {
//     processAttendanceFile(storageId: $storageId, companyId: $companyId)
//   }
// `

export const CREATE_TOC_COMPANY = gql`
  mutation createActiveTermAndConditionCompany(
    $companyId: ID!
    $payload: CreateCompanyTermAndConditionPayload!
  ) {
    createActiveTermAndConditionCompany(
      companyId: $companyId
      payload: $payload
    ) {
      id
      createdDate
      lastModifiedDate
    }
  }
`

const SYNC_COMPANY_AUTH = gql`
  mutation system_syncCompanyAuth($companyId: ID!) {
    system_syncCompanyAuth(id: $companyId)
  }
`

const UploadTOC = (props: { id: string }) => {
  const [syncCompanyAuthVisible, setSyncCompanyAuthVisible] = useState(false)
  const [
    syncCompanyAuthFunc,
    { loading: syncCompanyAuthLoading },
  ] = useMutation(SYNC_COMPANY_AUTH, {
    variables: {
      companyId: props.id,
    },
  })

  const { id: companyId } = props
  const [filetypeOption, setFiletypeOption] = useState<number>(1)
  const [filename, setFilename] = useState<string>()
  const [uploadAction, setUploadAction] = useState<string>()
  const [contentType, setContentType] = useState<string>()
  const [storageObject, setStorageObject] = useState<StorageObject>()

  const [state, dispatch] = useMessageAlert()

  const [createStorageObject] = useMutation(CREATE_STORAGE_OBJECT)
  // const [processEmploymentFile] = useMutation(PROCESS_EMPLOYMENT_FILE)
  // const [processAttendanceFile] = useMutation(PROCESS_ATTENDANCE_FILE)
  const [createToc] = useMutation(CREATE_TOC_COMPANY)

  const dragProps: any = {
    accept: '.html,text/html',
    beforeUpload: async (file: UploadFile) => {
      const extension = file.name.split('.').pop()
      const contentType =
        extension === 'xls' ? 'application/vnd.ms-excel' : file.type
      try {
        dispatch({ type: 'pending' })
        const res = await createStorageObject({
          variables: {
            payload: {
              filename: file.name,
              contentType,
            },
          },
        })
        setFilename(file.name)
        setContentType(contentType)
        setUploadAction(res.data.createStorageObject.uploadUri)
        setStorageObject(res.data.createStorageObject.storageObject)

        dispatch({
          type: 'success',
          message: CREATE_STORAGE_OBJECT_SUCCESS,
        })
      } catch (e) {
        dispatch({ type: 'error', message: e.message })
      }
    },
    onChange: async (info: any) => {
      if (info.file.status === 'done') {
        try {
          if (!storageObject || !companyId) {
            message.error(
              `${info.file.name} ` +
                t`uploaded successfully, but the processing error.`,
            )
            return
          }
          if (storageObject && filetypeOption === 1) {
            await createToc({
              variables: {
                companyId: companyId,
                payload: {
                  storageId: storageObject.id,
                }, // Annam Gourmet
              },
            })
            message.success(
              `${info.file.name} ` +
                t`file uploaded successfully. We are processing your toc file`,
            )
          }
        } catch (e) {
          message.error(
            `${info.file.name} ` +
              t`uploaded successfully, but the processing error.`,
          )
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} ` + t`file upload failed.`)
      }
    },
  }

  const pageData: IPageData = {
    title: t`Upload`,
    loaded: true,
    breadcrumbs: [
      {
        title: t`Home`,
        route: 'dashboard',
      },
      {
        title: t`Upload`,
      },
    ],
    fullFilled: true,
  }

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <Card title={t`Upload TOC`}>
          <Timeline>
            <Timeline.Item
              dot={<FileOutlined style={{ fontSize: 20, color: '#41ba7a' }} />}
            >
              <Card
                title={t`Select File Category`}
                className="component-demo-card"
              >
                <Radio.Group
                  onChange={(e) => {
                    setFiletypeOption(e.target.value)
                  }}
                  value={filetypeOption}
                >
                  <Radio value={1}>{t`Upload TOC`}</Radio>
                </Radio.Group>
              </Card>
            </Timeline.Item>
            <Timeline.Item
              dot={
                <CloudUploadOutlined
                  style={{ fontSize: 20, color: '#41ba7a' }}
                />
              }
            >
              <Card>
                <Dragger
                  {...dragProps}
                  // name={filename}
                  // action={uploadAction}
                  // headers={{ 'Content-Type': contentType }}
                  // method="PUT"
                  showUploadList={{
                    showPreviewIcon: true,
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  customRequest={async (options) => {
                    const { file, onProgress, onError, onSuccess } = options
                    try {
                      if (uploadAction) {
                        axios
                          .put(uploadAction, file, {
                            headers: {
                              'Content-Type': contentType,
                            },
                            onUploadProgress: (e) => {
                              onProgress(
                                { percent: (e.loaded / e.total) * 100 },
                                file,
                              )
                            },
                          })
                          .then((respones) => {
                            onSuccess(respones, file)
                          })
                          .catch((err) => {
                            onError(err)
                          })
                      }
                    } catch (e) {
                      onError(e)
                    }
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined style={{ fontSize: '56px' }} />
                  </p>
                  <p className="ant-upload-text">
                    {t`Click or drag file to this area to upload`}
                  </p>
                  <p className="ant-upload-hint">
                    {t`Support for extension .html`}
                  </p>
                </Dragger>
              </Card>
            </Timeline.Item>
            <Timeline.Item
              dot={<SmileOutlined style={{ fontSize: 20, color: '#41ba7a' }} />}
            >
              {t`Upload result`}
            </Timeline.Item>
          </Timeline>
        </Card>
        <Card title={t`Sync Company Auth`}>
          <Button
            type={'primary'}
            onClick={() => {
              setSyncCompanyAuthVisible(!syncCompanyAuthVisible)
            }}
          >{t`Sync`}</Button>
          <Modal
            title="Warning"
            visible={syncCompanyAuthVisible}
            onOk={() => {
              syncCompanyAuthFunc({
                variables: {
                  companyId: props.id,
                },
              })
                .then(() => {
                  setSyncCompanyAuthVisible(false)
                })
                .catch(() => {
                  setSyncCompanyAuthVisible(false)
                })
            }}
            onCancel={() => setSyncCompanyAuthVisible(false)}
            confirmLoading={syncCompanyAuthLoading}
          >
            Are you sure to sync company auth?
          </Modal>
        </Card>
      </div>
    </div>
  )
}

export default UploadTOC
