import React, { useEffect, useState } from 'react'

import { IPageProps, IPageData } from '../../interfaces/page-data'
import { Card, Slider, Button } from 'antd'
import * as Yup from 'yup'
import { GenerateCompanyList } from '../../components/Company/GenerateCompanyList'
import gql from 'graphql-tag'
import { GenerateEmploymentList } from '../../components/Employment/GenerateEmploymentList'

export const CREATE_COMPANY = gql`
  mutation createCompany($payload: CreateCompanyPayload!) {
    createCompany(payload: $payload) {
      company {
        id
        taxCode
        name
        slug
        description
        status
        email
        address
        logo {
          uri
        }
      }
    }
  }
`

const InnerFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  description: Yup.string(),
  taxCode: Yup.string(),
  status: Yup.string().required('Required'),
})

const GenDataPage: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage, getPageData } = props

  const pageData: IPageData = {
    title: 'Users',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'Users',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <div className="row">
      <div className="col-12">
        <Card>
          <h3>*** Only use in sandbox/dev environment ***</h3>
          <div className="row">
            <GenerateCompanyList />
          </div>
          <div style={{ height: 5, color: '#41ba7a' }} />
          <div className="row">
            <GenerateEmploymentList />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default GenDataPage
