import React from 'react'
import { Select } from 'antd'
import { Company } from '../../graphql/models'

type SelectCompanyProps = {
  companies: Company[]
  onChange?: any
  onSearch?: (value: string) => void
  width?: string | number
}

export const SelectCompany: React.FC<SelectCompanyProps> = ({
  companies,
  onChange,
  onSearch,
  width,
}) => {
  return (
    <Select
      showSearch
      style={{ width: width || 450 }}
      placeholder="Select a company"
      optionFilterProp="children"
      onChange={(value) => onChange(value)}
      onSearch={onSearch}
      allowClear
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
    >
      {companies.map((item, index) => (
        <Select.Option value={item.id} key={index}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  )
}
