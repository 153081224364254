import { PlusOutlined } from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Drawer,
  message,
  Modal,
  Radio,
  Tabs,
  Tag,
} from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import gql from 'graphql-tag'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { useDebounce } from 'react-use'
import {
  BannerAssociationStatus,
  Employment,
  EmploymentOrderable,
  OrderDirection,
  CompanyOrderable,
  Company,
} from '../../graphql/models'
import BannerList from '../Banner/BannerList'
import CreateBanner from '../Banner/CreateBanner'
import BannerAssociationList from './BannerAssociationList'

interface BannerAssociationDrawerProps {
  onClose: () => void
  visible: boolean
  company: string | null
}

export enum SEND_OPTIONS {
  'ALL',
  'GROUP',
  'EMPLOYMENT',
  'COMPANY',
}

enum BANNER_OPTIONS {
  'NEW',
  'EXISTING',
}

enum BANNER_TYPE {
  'SVG',
  'PNG',
}

const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      shortName
      groupsAsTree {
        parentId
        group {
          id
          name
        }
      }
    }
  }
`

const GET_INDIVIDUALS = gql`
  query individuals($query: CompanyEmploymentQuery, $companyId: ID!) {
    company(id: $companyId) {
      id
      employments(query: $query) {
        total
        nodes {
          id
          employeeId
          fullName
        }
        total
        limit
        offset
      }
    }
  }
`
const GET_COMPANIES = gql`
  query companies($query: CompanyQuery = {}) {
    companies(query: $query) {
      nodes {
        id
        name
        shortName
      }
    }
  }
`
export const SEND_MESSAGE_TO_EMPLOYMENTS = gql`
  mutation sendMessageToEmployments(
    $payload: SendMessageToEmploymentsPayload!
  ) {
    sendMessageToEmployments(payload: $payload)
  }
`
export const CREATE_BANNER_ASSOCICATION = gql`
  mutation createBannerAssociation($payload: CreateBannerAssociationPayload!) {
    createBannerAssociation(payload: $payload) {
      bannerAssociation {
        id
        banner {
          id
          title
          content {
            svg {
              content
            }
          }
        }
      }
    }
  }
`

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}

export default ({
  onClose,
  visible,
  company,
}: BannerAssociationDrawerProps) => {
  const [initState, setInitState] = useState<any>({
    sendOption: SEND_OPTIONS.ALL,
    selectedEmployments: [],
    selectedCompanies: [],
    inputCompanyValue: '',
    inputCompanyDebounce: '',
    inputCompanyVisible: false,
    inputEmploymentValue: '',
    inputEmploymentDebounce: '',
    inputEmploymentVisible: false,
    selectedGroups: [],
    inputTitleBannerValue: '',
    inputContentBannerValue: '',
    bannerOption: BANNER_OPTIONS.NEW,
    displayBannerTime: null,
    selectedBannerId: null,
  })
  const {
    loading: companyLoading,
    error: companyError,
    data: companyData,
  } = useQuery(GET_COMPANY, {
    variables: { id: company },
    onCompleted: (data) => {
      setInitState((init) => ({
        ...init,
        selectedCompanies: [
          {
            id: companyData?.company?.id,
            name: companyData?.company?.name,
            shortName: companyData?.company?.shortName,
          },
        ],
      }))

      setSelectedCompanies([
        {
          id: companyData?.company?.id,
          name: companyData?.company?.name,
          shortName: companyData?.company?.shortName,
        },
      ])
    },
  })

  const [sendOption, setSendOption] = useState(initState.sendOption)
  const [bannerOption, setBannerOption] = useState(initState.bannerOption)

  const onChangeSendOption = (e: RadioChangeEvent) => {
    setSendOption(e.target.value)
  }

  const onChangeBannerOption = (e: RadioChangeEvent) => {
    setBannerOption(e.target.value)
  }

  const [displayBannerTime, setDisplayBannerTime] = useState<any[] | null>(
    initState.displayBannerTime,
  )
  // initState.inputEmploymentValue,

  const onChangeTime = (value: any, dateString: any) => {
    console.log('Selected Time: ', value)
    console.log('Formatted Selected Time: ', dateString)
    setDisplayBannerTime(value)
  }

  const [selectedEmployments, setSelectedEmployments] = useState<any[]>(
    initState.selectedEmployments,
  )
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>(
    initState.selectedCompanies,
  )
  const [inputCompanyValue, setInputCompanyValue] = useState(
    initState.inputCompanyValue,
  )
  const [inputCompanyDebounce, setInputCompanyDebounce] = useState(
    initState.inputEmploymentDebounce,
  )

  const [inputCompanyVisible, setInputCompanyVisible] = useState<Boolean>(
    initState.inputCompanyVisible,
  )
  const [inputEmploymentValue, setInputEmploymentValue] = useState(
    initState.inputEmploymentValue,
  )
  const [inputEmploymentDebounce, setInputEmploymentDebounce] = useState(
    initState.inputEmploymentDebounce,
  )
  const [, cancel] = useDebounce(
    () => {
      setInputEmploymentDebounce(inputEmploymentValue)
      setInputCompanyDebounce(inputCompanyValue)
    },
    500,
    [inputEmploymentValue, inputCompanyValue],
  )

  const {
    loading: employmentsLoading,
    error: employmentsError,
    data: employmentsData,
  } = useQuery(GET_INDIVIDUALS, {
    variables: {
      query: {
        filter: {
          q: inputEmploymentDebounce,
        },
        orderBy: EmploymentOrderable.EmployeeId,
        orderDirection: OrderDirection.Asc,
      },
      companyId: company,
    },
  })
  const {
    loading: companiesLoading,
    error: companiesError,
    data: companiesData,
  } = useQuery(GET_COMPANIES, {
    variables: {
      query: {
        filter: {
          q: inputCompanyDebounce,
        },
        orderBy: CompanyOrderable.Id,
        orderDirection: OrderDirection.Asc,
      },
    },
  })
  const [inputEmploymentVisible, setInputEmploymentVisible] = useState<Boolean>(
    initState.inputEmploymentVisible,
  )
  const inputEmploymentRef = useRef<any>(null)
  const inputCompanyRef = useRef<any>(null)

  useEffect(() => {
    if (inputEmploymentVisible && inputEmploymentRef?.current) {
      inputEmploymentRef.current.focus()
      return
    }
  }, [inputEmploymentVisible])

  useEffect(() => {
    if (inputCompanyVisible && inputCompanyRef?.current) {
      inputCompanyRef.current.focus()
      return
    }
  }, [inputCompanyVisible])

  const [selectedGroup, setSelectedGroups] = useState<any[]>(
    initState.selectedGroups,
  )

  const onChangeSelectedGroups = (checkedValues: any) => {
    setSelectedGroups(checkedValues)
  }

  const [createBannerAssociation] = useMutation(CREATE_BANNER_ASSOCICATION)

  const { createBannerAssociationPayload } = useMemo(
    () => ({
      createBannerAssociationPayload: {
        bannerId: '',
        companyId: company || '',
        description: '',
        startTime: displayBannerTime
          ? displayBannerTime[0].toISOString()
          : null,
        endTime: displayBannerTime ? displayBannerTime[1].toISOString() : null,
        status: BannerAssociationStatus.Active,
        employmentFilter: {
          employmentIds:
            sendOption === SEND_OPTIONS.EMPLOYMENT
              ? selectedEmployments.map((e) => e.id)
              : null,
          groupIds:
            sendOption === SEND_OPTIONS.GROUP ? [...selectedGroup] : null,
        },
        companyIds:
          sendOption === SEND_OPTIONS.COMPANY
            ? selectedCompanies.map((c) => c.id)
            : [company],
      },
    }),
    [
      company,
      sendOption,
      selectedEmployments,
      selectedGroup,
      displayBannerTime,
    ],
  )

  const [selectedBannerId, setSelectedBannerId] = useState<string | null>(null)

  const onSelectBannerRow = (bannerId: string) => {
    setSelectedBannerId(bannerId)
  }

  const onCreateAssociationWithExistBanner = () => {
    if (
      !createBannerAssociationPayload?.startTime ||
      !createBannerAssociationPayload?.endTime
    ) {
      Modal.warning({
        title: '*Required',
        content: 'Display Time must be entered',
      })
      return
    }
    if (bannerOption === BANNER_OPTIONS.EXISTING && !selectedBannerId) {
      Modal.warning({
        title: '*Required',
        content: 'Please select banner',
      })
      return
    }
    createBannerAssociation({
      variables: {
        payload: {
          ...createBannerAssociationPayload,
          bannerId: selectedBannerId,
        },
      },
    })
      .then(() => {
        message.success('Create association successfully', 1.5, () =>
          window.location.reload(),
        )
      })
      .catch(() => {
        message.error('Failed to create association')
      })
  }

  const resetToInitState = () => {
    // console.log('reset to init state: ', initState)
    // setSendOption(initState.sendOption)
    setSelectedEmployments(initState.selectedEmployments)
    setInputEmploymentValue(initState.inputEmploymentValue)
    setInputEmploymentDebounce(initState.inputEmploymentDebounce)
    setInputEmploymentVisible(initState.inputEmploymentVisible)
    setSelectedGroups(initState.selectedGroups)
    setDisplayBannerTime(initState.displayBannerTime)
    setBannerOption(initState.bannerOption)
    setSelectedBannerId(initState.selectedBannerId)
    setSelectedCompanies(initState.selectedCompanies)
  }

  useEffect(() => {
    resetToInitState()
  }, [sendOption, company])

  return (
    <Drawer
      width={600}
      title={companyData?.company?.shortName || 'Loading Company'}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      headerStyle={{ fontSize: 'xx-large	', fontWeight: 900 }}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          tab={
            <h6 style={{ marginTop: 0, marginBottom: 0 }}>
              Create Association
            </h6>
          }
          key="1"
        >
          <Card title={`Show Banner To`}>
            <Radio.Group value={sendOption} onChange={onChangeSendOption}>
              <Radio style={radioStyle} value={SEND_OPTIONS.ALL}>
                All {companyData?.company?.shortName}
              </Radio>
              <Radio style={radioStyle} value={SEND_OPTIONS.GROUP}>
                Group(s)
              </Radio>
              {sendOption === SEND_OPTIONS.GROUP &&
                companyData?.company?.groupsAsTree && (
                  <div style={{ marginLeft: '30px' }}>
                    <Checkbox.Group
                      options={companyData?.company?.groupsAsTree.map(
                        (g: any) => ({
                          value: g.group.id,
                          label: g.group.name,
                        }),
                      )}
                      onChange={onChangeSelectedGroups}
                      value={selectedGroup}
                    />
                  </div>
                )}
              <Radio style={radioStyle} value={SEND_OPTIONS.EMPLOYMENT}>
                Employment(s)
              </Radio>
              {sendOption === SEND_OPTIONS.EMPLOYMENT &&
                employmentsData?.company?.employments?.nodes && (
                  <div style={{ marginLeft: '30px', marginTop: '10px' }}>
                    {selectedEmployments &&
                      selectedEmployments.length > 0 &&
                      selectedEmployments.map(
                        (employment: Employment, index: number) => (
                          <Tag
                            closable={true}
                            key={`${employment.id}_${index}`}
                            onClose={() => {
                              setSelectedEmployments(
                                _.filter(
                                  selectedEmployments,
                                  (e: Employment) => e.id !== employment.id,
                                ),
                              )
                            }}
                            style={{ marginBottom: 10 }}
                          >
                            {`${employment.employeeId} | ${employment.fullName}`}
                          </Tag>
                        ),
                      )}
                    {inputEmploymentVisible &&
                      employmentsData?.company?.employments?.nodes && (
                        <AutoComplete
                          ref={inputEmploymentRef}
                          value={inputEmploymentValue}
                          style={{ width: 200 }}
                          placeholder="Select Employee"
                          options={
                            _.differenceBy(
                              employmentsData.company.employments.nodes,
                              selectedEmployments,
                              'id',
                            ).map((e: any) => ({
                              value: `${e.employeeId} | ${e.fullName}`,
                              employment: e,
                            })) as any
                          }
                          onSelect={(value, option) => {
                            setSelectedEmployments([
                              ...selectedEmployments,
                              option.employment,
                            ])
                            setInputEmploymentValue('')
                          }}
                          onBlur={() => {
                            setInputEmploymentVisible(false)
                          }}
                          onChange={(value: string) => {
                            setInputEmploymentValue(value)
                          }}
                        />
                      )}
                    {!inputEmploymentVisible && (
                      <Tag
                        className="site-tag-plus"
                        onClick={() => {
                          setInputEmploymentVisible(true)
                        }}
                      >
                        <PlusOutlined /> Add Employee
                      </Tag>
                    )}
                  </div>
                )}

              <Radio style={radioStyle} value={SEND_OPTIONS.COMPANY}>
                Company(s)
              </Radio>

              {sendOption === SEND_OPTIONS.COMPANY &&
                companiesData?.companies?.nodes && (
                  <div style={{ marginLeft: '30px', marginTop: '10px' }}>
                    {selectedCompanies &&
                      selectedCompanies.length > 0 &&
                      selectedCompanies.map((com: Company, index: number) => (
                        <Tag
                          closable={com.id !== company}
                          key={`${com.id}_${index}`}
                          onClose={() => {
                            setSelectedCompanies(
                              _.filter(
                                selectedCompanies,
                                (c: Company) => c.id !== com.id,
                              ),
                            )
                          }}
                          style={{ marginBottom: 10 }}
                        >
                          {com.shortName}
                        </Tag>
                      ))}
                    {inputCompanyVisible && companiesData?.companies?.nodes && (
                      <AutoComplete
                        ref={inputCompanyRef}
                        value={inputCompanyValue}
                        style={{ width: 200 }}
                        placeholder="Select Company"
                        options={
                          _.differenceBy(
                            companiesData?.companies?.nodes,
                            selectedCompanies,
                            'id',
                          ).map((c: any) => ({
                            value: `${c.name}`,
                            company: c,
                          })) as any
                        }
                        onSelect={(value, option) => {
                          setSelectedCompanies([
                            ...selectedCompanies,
                            option.company,
                          ])
                          setInputCompanyValue('')
                        }}
                        onBlur={() => {
                          setInputCompanyVisible(false)
                        }}
                        onChange={(value: string) => {
                          setInputCompanyValue(value)
                        }}
                      />
                    )}
                    {!inputCompanyVisible && (
                      <Tag
                        className="site-tag-plus"
                        onClick={() => {
                          setInputCompanyVisible(true)
                        }}
                      >
                        <PlusOutlined /> Add Company
                      </Tag>
                    )}
                  </div>
                )}
            </Radio.Group>
          </Card>
          <Card title={`*Display Time`}>
            <DatePicker.RangePicker
              showTime={{ format: 'HH:mm' }}
              format="DD/MM/YYYY HH:mm"
              onChange={onChangeTime}
              value={displayBannerTime as any}
            />
          </Card>
          <Card title={`*Banner Content`}>
            <Radio.Group value={bannerOption} onChange={onChangeBannerOption}>
              <Radio value={BANNER_OPTIONS.NEW}>New Banner</Radio>
              <Radio value={BANNER_OPTIONS.EXISTING}>Existing Banner</Radio>
            </Radio.Group>
            {bannerOption === BANNER_OPTIONS.NEW && (
              <CreateBanner
                confirmMessage={'Are you sure to create association?'}
                onCreatedBanner={createBannerAssociation}
                createBannerAssociationPayload={createBannerAssociationPayload}
                sendOption={sendOption}
                company={company}
              />
            )}
            {bannerOption === BANNER_OPTIONS.EXISTING && (
              <>
                <BannerList onRowSelection={onSelectBannerRow} />
                <Button
                  type="primary"
                  onClick={onCreateAssociationWithExistBanner}
                  style={{ marginTop: 10 }}
                >
                  Create
                </Button>
              </>
            )}
          </Card>
        </Tabs.TabPane>
        {company && (
          <Tabs.TabPane
            tab={
              <h6 style={{ marginTop: 0, marginBottom: 0 }}>
                Association List
              </h6>
            }
            key="2"
          >
            <BannerAssociationList company={company} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Drawer>
  )
}
