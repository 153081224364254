import { Tag } from 'antd'
import { Alert, Card, Table } from 'antd'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'
import ButtonLink from '../ButtonLink'
import { useTable } from '../hooks/useTable'
import { InputTableHeader } from '../table/CustomTable'
import TenantTableCell from './TenantTableCell'

interface TenantListProps {}

const GET_TENANTS = gql`
  query getTenants($query: TenantQuery) {
    core_tenants(query: $query) {
      nodes {
        code
        createdDate
        id
        employees {
          total
        }
      }
      limit
      offset
      total
    }
  }
`

const TenantList: React.FC<TenantListProps> = () => {
  const [loading, error, data, filter, page, setFilter, setPage] =
    useTable<any>(GET_TENANTS)

  const { codeSearch } = filter

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: (val: string) => <TenantTableCell value={val} />,
      width: 200,
    },
    {
      title: InputTableHeader({
        value: codeSearch,
        key: 'codeSearch',
        inputType: 'text',
        name: 'Code',
        onPressEnter: setFilter,
      }),
      // title: 'Code',
      key: 'code',
      render: (tenant: { code: string; id: string }) => (
        <ButtonLink
          title={tenant.code}
          routing={`/vertical/tenants/${tenant.id}/detail`}
          size="middle"
        />
      ),
      width: 300,
    },
    {
      title: 'Created Date',
      key: 'createdDate',
      dataIndex: 'createdDate',
      render: (val: string) => (
        <TenantTableCell value={moment(val).format('DD/MM/YYYY')} />
      ),
      width: 250,
    },
    {
      title: 'Created Time',
      key: 'createdDate',
      dataIndex: 'createdDate',
      render: (val: string) => (
        <TenantTableCell value={moment(val).format('HH:MM')} />
      ),
      width: 250,
    },
    {
      title: 'Total employee',
      key: 'employees',
      dataIndex: 'employees',
      render: (val: any) => <Tag color="cyan">{val.total}</Tag>,
      width: 250,
    },
  ]

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <Card>
      <Table
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: () => (!data ? 0 : data.core_tenants.total),
          total: !data ? 0 : data.core_tenants.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={!data ? [] : data.core_tenants.nodes}
        columns={columns}
        loading={loading}
        size="middle"
        bordered
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </Card>
  )
}

export default TenantList
