import React from 'react'
import { useEffect } from 'react'
import TenantDetail from '../../components/Tenant/TenantDetail'
import { IPageData, IPageProps } from '../../interfaces/page-data'

const TenantDetailPage: React.FC<IPageProps> = ({ onSetPage }) => {
  const pageData: IPageData = {
    title: 'Tenants',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'tenants',
      },
      {
        title: 'Tenants',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return <TenantDetail />
}

export default TenantDetailPage
