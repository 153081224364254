import React from 'react'
import { Tag } from 'antd'

type ColumnStatusProps = {
  status: string
  key?: string
}
export const ColumnStatus: React.FC<ColumnStatusProps> = (props) => {
  const { status, key } = props
  let color = ''

  switch (status) {
    case 'Approved':
    case 'Active':
    case 'Disbursed':
    case 'Released':
      color = 'green'
      break
    case 'Inactive':
    case 'Rejected':
      color = 'red'
      break
    case 'Pending':
    case 'Unapproved':
    case 'LockForDisbursement':
      color = 'orange'
      break
    case 'Refunded':
    case 'Pilot':
      color = '#e1ebea'
      break
    case 'Closed':
      color = 'blue'
      break
    case 'Repaid':
      color = 'volcano'
      break
    default:
      color = '#4a505c'
  }

  return (
    <Tag key={key} color={color}>
      {status}
    </Tag>
  )
}
