import { Alert, Button, Table } from 'antd'
import gql from 'graphql-tag'
import moment from 'moment'
import React, { useEffect } from 'react'
import {
  BannerAssociation,
  BannerAssociationOrderable,
  OrderDirection,
} from '../../graphql/models'
import { DEFAULT_PAGE_SIZE, useTable } from '../hooks/useTable'

export const GET_BANNER_ASSOCIATIONS = gql`
  query bannerAssociations($query: BannerAssociationQuery!, $id: ID!) {
    company(id: $id) {
      id
      bannerAssociations(query: $query) {
        nodes {
          id
          startTime
          endTime
          employmentFilter {
            employments {
              id
              fullName
            }
            groups {
              id
              name
            }
          }
          banner {
            title
            content {
              svg {
                content
              }
            }
            contentImage {
              id
              uri
            }
          }
        }
        total
        limit
        offset
      }
    }
  }
`

interface BannerAssociationListProps {
  company: string | null
}

export default ({ company }: BannerAssociationListProps) => {
  const [
    loading,
    error,
    data,
    filter,
    page,
    setFilter,
    setPage,
    resetFilter,
    queryVariables,
    setNewQueryVariables,
    refetch,
  ] = useTable(
    GET_BANNER_ASSOCIATIONS,
    {
      filter: {},
      orderBy: BannerAssociationOrderable.CreatedDate,
      orderDirection: OrderDirection.Desc,
    },
    { id: company },
  )

  useEffect(() => {
    setNewQueryVariables({ id: company })
  }, [company])

  const columns = [
    { title: 'ID', key: 'id', dataIndex: 'id' },
    {
      title: 'Start Time',
      key: 'startTime',
      render: (association: BannerAssociation) => {
        return moment(association.startTime).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'End Time',
      key: 'endTime',
      render: (association: BannerAssociation) => {
        return moment(association.endTime).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: 'Title',
      key: 'title',
      render: (association: BannerAssociation) => {
        return association.banner.title
      },
    },
    {
      title: 'content',
      key: 'content',
      render: (association: BannerAssociation) => {
        if (association.banner.type === 'Svg') {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: association.banner.content?.svg?.content || '',
              }}
              style={{ maxWidth: 600 }}
            />
          )
        }
        console.log(association.banner)
        return (
          <img
            src={association.banner.contentImage?.uri}
            style={{ objectFit: 'contain', width: '100%', height: '250px' }}
          ></img>
        )
      },
    },
  ]

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          refetch()
        }}
        style={{ marginBottom: 10 }}
      >
        Refresh
      </Button>
      <Table
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: () => (!data ? 0 : data.company.bannerAssociations.total),
          total: !data ? 0 : data.company.bannerAssociations.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={!data ? [] : data.company.bannerAssociations.nodes}
        columns={columns}
        loading={loading}
        size="middle"
        bordered={true}
        rowKey="id"
        scroll={{ x: 1000 }}
      />
    </>
  )
}
