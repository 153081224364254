import { Actions, Menu, Navbar, NavbarSkeleton, Search } from '@nano/ninettheme'
import { Button } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
// import '../BaseLayout/BaseLayout.scss'
// import './Vertical.scss'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import { KeycloakContext, redirectUri } from '../../context/KeycloakContext'
import { IMenuItem } from '../../interfaces/menu'
import { IPageData } from '../../interfaces/page-data'
import { IAppSettings } from '../../interfaces/settings'
import BasePage from '../../pages/BasePage/BasePage'
import { resetPageData, setPageData } from '../../redux/pages/actions'
import { toggleSidebar } from '../../redux/settings/actions'
import { SETTINGS } from '../../redux/settings/DefaultSettings'
import { AppState } from '../../redux/store'
import { defaultRoutes } from '../../routes'
import BaseLayout from '../BaseLayout/BaseLayout'
import Settings from '../components/Settings/Settings'

const getGradientString = (firstColor: string, secondColor: string): string =>
  `linear-gradient(188deg, ${firstColor}, ${secondColor} 65%)`

interface VerticalLayoutProps {
  pageData: IPageData
  settings: IAppSettings
  onSidebarToggle: () => void
  onSettingsReset: () => void
  onSettingsSet: (data: IAppSettings) => void
  onSettingsUpdate: (data: IAppSettings) => void
  onSetPage: (data: IPageData) => void
  onPageReset: () => void
}

const VerticalLayout: React.FunctionComponent<VerticalLayoutProps> = (
  props,
) => {
  const keycloak = useContext(KeycloakContext)
  const { pageData, settings, onSidebarToggle, onPageReset, onSetPage } = props
  const {
    sidebarAccentColor,
    sidebarColor,
    topbarColor,
    topbarBg,
    sidebarBg2,
    sidebarBg,
    sidebarAccentContrastColor,
    sidebarOpened,
    boxed,
  } = settings
  const [menuData, setMenuData] = useState<IMenuItem[]>([])
  const [settingsVisibility, setSettingsVisibility] = useState(false)
  const [isAllowSound, setAllowSound] = useState(false)

  const routes = defaultRoutes

  useEffect(() => {
    async function fetchData() {
      const result = require('./menu.json')
      setMenuData(result)
    }

    fetchData().catch((err) => console.error('Server Error', err))
  }, [])

  const handleToggleSidebar = () => {
    onSidebarToggle()
  }

  const handleSettingsModalClose = () => {
    setSettingsVisibility(false)
  }

  const handleSettingsClick = () => setSettingsVisibility(true)

  return (
    <div className="layout vertical">
      <div className={`app-container ${boxed && 'boxed'}`}>
        <Navbar
          style={{
            backgroundImage: getGradientString(
              sidebarBg || SETTINGS.sidebarBg,
              sidebarBg2 || SETTINGS.sidebarBg2,
            ),
          }}
          orientation="vertical"
          opened={sidebarOpened}
          onCloseNavbar={handleToggleSidebar}
        >
          <button
            className="no-style navbar-close icofont-close-line d-lg-none"
            onClick={onSidebarToggle}
          />
          <div>
            <div className="logo-wrap d-flex justify-content-start align-items-center p-3">
              <a href="/vertical/dashboard/" className="d-flex align-items-end">
                <img
                  src={'/admin_logo.png'}
                  // alt={props.alt}
                  height={75}
                  width={75}
                />
                <h5
                  style={{
                    color: SETTINGS.sidebarAccentColor,
                    // paddingTop: '10px',
                    paddingLeft: '20px',
                    margin: 0,
                  }}
                >
                  Nano Admin
                </h5>
              </a>
            </div>
          </div>
          <Menu
            color={sidebarColor}
            accentContrast={sidebarAccentContrastColor}
            accentColor={sidebarAccentColor}
            data={menuData}
            layout={'vertical'}
          />
          <NavbarSkeleton
            type="vertical"
            loaded={props.pageData.fullFilled || false}
          />
        </Navbar>

        <Navbar
          boxed={boxed}
          style={{ backgroundColor: topbarBg, color: topbarColor }}
          orientation="horizontal"
          className="horizontal-nav"
          minHeight={60}
        >
          <button
            className="navbar-toggle d-lg-none"
            onClick={handleToggleSidebar}
          >
            <span />
            <span />
            <span />
          </button>

          <Search
            style={{ color: topbarColor }}
            data={menuData}
            dataKey="title"
            className="d-none d-md-block"
          />

          {!isAllowSound && (
            <Button
              onClick={() => {
                setAllowSound(true)
              }}
            >
              <i className="icofont-audio" style={{ fontSize: 'large' }} />
              Allow audio
            </Button>
          )}

          <Actions
            onLogout={() => {
              if (keycloak) {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('realm')
                keycloak.logout({
                  redirectUri,
                })
              }
            }}
            // avatarSrc={'/admin_logo.png'}
          />
          {/* <NavbarSkeleton
            type="horizontal"
            loaded={props.pageData.fullFilled || false}
          /> */}
        </Navbar>

        <BaseLayout
          pageData={pageData}
          settings={settings}
          onPageReset={onPageReset}
          onSidebarToggle={onSidebarToggle}
        >
          {routes.map((route, i) => {
            return (
              <Route
                exact
                key={i}
                path={`/vertical${route.path}`}
                render={() => (
                  <BasePage>
                    <route.component onSetPage={onSetPage} />
                  </BasePage>
                )}
              />
            )
          })}
        </BaseLayout>

        {/* <Footer boxed={boxed} loaded={props.pageData.fullFilled} /> */}
      </div>

      <Settings
        opened={settingsVisibility}
        onClose={handleSettingsModalClose}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  pageData: state.pageData,
  settings: state.settings,
})

const mapDispatchToProps = (dispatch: any) => ({
  onSidebarToggle: () => dispatch(toggleSidebar()),
  onSetPage: (data: IPageData) => dispatch(setPageData(data)),
  onPageReset: () => dispatch(resetPageData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(VerticalLayout)
