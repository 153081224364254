export const DATE_FORMAT = 'HH:mm:ss DD/MM/YYYY'
export const LAYOUT_PATH = 'vertical'
export const CURRENCY_VND = 'VND'
export const DEFAULT_TIMEZONE = 'Asia/Ho_Chi_Minh'

export const COMPANY_UPDATE_STATUS_SUCCESS =
  'Update company status successfully'
export const COMPANY_UPDATE_INFO_SUCCESS = 'Update company info successfully'

export const EMPLOYMENT_UPDATE_STATUS_SUCCESS =
  'Update employment status successfully'
export const EMPLOYMENT_UPDATE_INFO_SUCCESS =
  'Update employment info successfully'

export const LOAN_APPLICATION_UPDATE_STATUS_SUCCESS =
  'Update status successfully'
