import React, { useState, useContext } from 'react'
import gql from 'graphql-tag'
import { t } from 'ttag'

import {
  Employment,
  Bank,
  EmploymentQueryFilter,
  Money,
  AttendanceConnection,
  EmploymentSummaryAdvance,
  LoanApplicationStatus,
  LoanApplicationConnection,
  EmploymentStatus,
  Company,
  AuditLogConnection,
  AuditLog,
  AuditLogOrderable,
  OrderDirection,
} from '../../graphql/models'
import { Table, Alert, Card, Button, Skeleton } from 'antd'
import ButtonLink from '../ButtonLink'
import { InputTableHeader, SelectTableHeader } from '../table/CustomTable'
import { ExportEntityType, useExportEntity } from '../hooks/useExportEntity'
import { ColumnRegistration } from '../table/ColumnRegistration'
import { useTable, DEFAULT_PAGE_SIZE } from '../hooks/useTable'
import { formatMoney, currentMoment } from '../../utils/helper'
import { ColumnDate } from '../table/ColumDate'
import { ColumnUsername } from '../table/ColumnUsername'
import { useMutation } from 'react-apollo'

export const GET_AUDITLOGS = gql`
  query company($query: AuditLogQuery!, $companyId: ID!) {
    company(id: $companyId) {
      auditLogs(query: $query) {
        nodes {
          id
          lastModifiedDate
          action
          principal {
            username
          }
          processAttendanceFile {
            storageObject {
              id
              filename
              uri
            }
          }
          processEmploymentFile {
            storageObject {
              id
              filename
              uri
            }
          }
          lanchi_processAttendanceFile {
            storageObject {
              id
              filename
              uri
            }
          }
          lanchi_processAttendanceFileV2 {
            storageObject {
              id
              filename
              uri
            }
          }
          lanchi_processAttendanceFileV3 {
            storageObject {
              id
              filename
              uri
            }
          }
        }
        total
        limit
        offset
      }
    }
  }
`

const SYNC_COMPANY_AUTH = gql`
  mutation system_syncCompanyAuth($companyId: ID!) {
    system_syncCompanyAuth(id: $companyId)
  }
`

export function CompanyAuditLogList(props: { id: string }) {
  const [exportLoading, exportEntity] = useExportEntity()
  const [
    loading,
    error,
    data,
    filter,
    page,
    setFilter,
    setPage,
    resetFilter,
    queryVariables,
    setNewQueryVariables,
  ] = useTable<EmploymentQueryFilter>(
    GET_AUDITLOGS,
    {
      filter: {
        actions: [
          'processAttendanceFile',
          'processEmploymentFile',
          'lanchi_processAttendanceFile',
          'lanchi_processAttendanceFileV2',
          'lanchi_processAttendanceFileV3',
        ],
      },
      orderBy: AuditLogOrderable.CreatedDate,
      orderDirection: OrderDirection.Desc,
    },
    {
      companyId: props.id,
    },
  )

  const columns = [
    {
      title: t`Actor`,
      key: 'actor',
      render: (node: AuditLog) => (
        <ColumnUsername
          username={node.principal?.username}
          style={{ margin: 5 }}
        />
      ),
    },
    {
      title: t`Action`,
      key: 'action',
      render: (node: AuditLog) => {
        switch (node.action) {
          case 'processEmploymentFile':
          case 'lanchi_processEmploymentFile':
          case 'lanchi_processEmploymentFile_V2':
          case 'lanchi_processEmploymentFile_V3':
            return t`Uploaded Employment File`
          case 'processAttendanceFile':
          case 'lanchi_processAttendanceFile':
          case 'lanchi_processAttendanceFileV2':
          case 'lanchi_processAttendanceFileV3':
            return t`Uploaded Attendance File`
          case 'lanchi_processApproveEmploymentFile_V2':
            return t`Uploaded Reviewed File`
          case 'lanchi_finalApproveRequestLinks':
            return t`Approved Request To Use`
          case 'lanchi_rejectRequestLinks':
            return t`Rejected Request To Use`
          case 'updateTenantUser':
            return t`Update Tenant User`
          case 'createTenantUser':
            return t`Create Tenant User`
          case 'resetTenantUserPassword':
            return t`Reset Tenant User Password`
          case 'deleteTenantUser':
            return (
              <strong style={{ color: 'red' }}>{t`Delete Tenant User`}</strong>
            )
          default:
            return node.action
        }
      },
    },
    {
      title: t`Action Property`,
      key: 'action',
      render: (node: any) => {
        switch (node.action) {
          default:
            return (
              <a href={node[node.action]?.storageObject?.uri}>
                {node[node.action]?.storageObject?.filename}
              </a>
            )
        }
      },
    },
    {
      title: t`Action Time`,
      key: 'lastModifiedDate',
      render: (node: AuditLog) => {
        return (
          <ColumnDate
            date={node.lastModifiedDate}
            format={'DD/MM/YYYY HH:mm:ss'}
          />
        )
      },
    },
  ]

  return (
    <>
      <Table
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: () => (!data ? 0 : data.company.auditLogs.total),
          total: !data ? 0 : data.company.auditLogs.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={!data ? [] : data.company.auditLogs.nodes}
        columns={columns}
        loading={loading}
        size="middle"
        bordered={true}
        rowKey="id"
      />
    </>
  )
}
