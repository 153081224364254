import React, { useEffect } from 'react'
import TenantList from '../../components/Tenant/TenantList'
import { IPageData, IPageProps } from '../../interfaces/page-data'

const TenantPage: React.FC<IPageProps> = (props) => {
  const { onSetPage } = props

  const pageData: IPageData = {
    title: 'Tenants',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'tenants',
      },
      {
        title: 'Tenants',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <TenantList />
      </div>
    </div>
  )
}

export default TenantPage
