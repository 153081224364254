import React, { useEffect } from 'react'

import { IPageProps, IPageData } from '../../interfaces/page-data'
import CreateCompanyEmployment from '../../components/Employment/CreateCompanyEmployment'

export const CreateEmploymentPage: React.FunctionComponent<IPageProps> = (
  props,
) => {
  const { onSetPage, getPageData } = props

  const pageData: IPageData = {
    title: 'Create Employment',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: '/vertical/dashboard',
      },
      {
        title: 'Employments',
        route: '/vertical/employments',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <div className="row">
      <div className="col-md-6 col-sm-12">
        <CreateCompanyEmployment />
      </div>
    </div>
  )
}
