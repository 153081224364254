import React from 'react'
import { Tag } from 'antd'
import { t } from 'ttag'

type ColumnLoanApplicationTypeProps = {
  type: string
  key?: string
}
export const ColumnLoanApplicationType: React.FC<ColumnLoanApplicationTypeProps> =
  (props) => {
    const { type, key } = props
    let color = ''

    switch (type) {
      case 'Advance':
        color = 'magenta'
        break
      case 'TopUp':
        color = 'geekblue'
        break
      default:
        color = 'red'
    }

    return (
      <Tag key={key} color={color}>
        {(() => {
          switch (type) {
            case 'Advance':
              return t`Advance`
            case 'TopUp':
              return t`Topup`
            default:
              return t`Other`
          }
        })()}
      </Tag>
    )
  }
