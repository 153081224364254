import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Alert, Spin, Card, Button, Row, Col, Switch, InputNumber } from 'antd'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
  Company,
  UpdateCompanyPayload,
  CompanyStatus,
} from '../../graphql/models'
import { CustomInput } from '../form/CustomForm'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { EntityStatusTree } from '../interface'
import {
  useMessageAlert,
  MESSAGE_ALERT_STATUS,
  MessageAlert,
  MessageAlertType,
} from '../alert/MessageAlert'
import {
  COMPANY_UPDATE_INFO_SUCCESS,
  COMPANY_UPDATE_STATUS_SUCCESS,
} from '../constants'
import { CustomButton } from '../form/CustomButton'
import { UploadImage } from '../upload/Upload'

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      createdDate
      taxCode
      name
      shortName
      slug
      description
      status
      logo {
        uri
      }
      email
      address
      contractCompanyName
      contractCompanyTaxCode
      contractCompanyAddress
      paymentDayInMonth
      paymentMonthAfterCutoff
      contractNo
      reportReceiverEmails
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: ID!, $payload: UpdateCompanyPayload!) {
    updateCompany(id: $id, payload: $payload) {
      company {
        id
        taxCode
        name
        shortName
        slug
        description
        status
        contractCompanyName
        contractCompanyTaxCode
        contractCompanyAddress
        contractNo
        reportReceiverEmails
      }
    }
  }
`

export const DEACTIVATE_COMPANY = gql`
  mutation deactivateCompany($id: ID!) {
    deactivateCompany(id: $id) {
      company {
        id
        status
      }
    }
  }
`

export const ACTIVATE_COMPANY = gql`
  mutation activateCompany($id: ID!) {
    activateCompany(id: $id) {
      company {
        id
        status
      }
    }
  }
`

const InnerFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  shortName: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  description: Yup.string(),
  email: Yup.string(),
  address: Yup.string(),
  status: Yup.string(),
})

export type CompanyDetailProps = RouteComponentProps & {
  id: string
}

export const CompanyStatusTree: EntityStatusTree<CompanyStatus> = {
  Active: {
    from: [CompanyStatus.Inactive],
    to: [CompanyStatus.Inactive],
  },
  Inactive: {
    from: [CompanyStatus.Active],
    to: [CompanyStatus.Active],
  },
}

export const CompanyForm: React.FC<{
  errors: any
  register: any
}> = ({ errors, register }) => {
  return (
    <>
      <CustomInput
        name="slug"
        errors={errors}
        register={register}
        required
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input {...props} name={name} ref={register} />
        )}
      </CustomInput>

      <CustomInput
        name="name"
        errors={errors}
        register={register}
        required
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input {...props} name={name} ref={register} />
        )}
      </CustomInput>

      <CustomInput
        name="shortName"
        errors={errors}
        register={register}
        required
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input {...props} name={name} ref={register} />
        )}
      </CustomInput>

      <CustomInput
        name="contractCompanyName"
        errors={errors}
        register={register}
        required={false}
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input
            {...props}
            name={name}
            ref={register}
            type="contractCompanyName"
          />
        )}
      </CustomInput>

      <CustomInput
        name="contractCompanyTaxCode"
        errors={errors}
        register={register}
        required={false}
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input
            {...props}
            name={name}
            ref={register}
            type="contractCompanyTaxCode"
          />
        )}
      </CustomInput>

      <CustomInput
        name="contractCompanyAddress"
        errors={errors}
        register={register}
        required={false}
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input
            {...props}
            name={name}
            ref={register}
            type="contractCompanyAddress"
          />
        )}
      </CustomInput>

      <CustomInput
        name="contractNo"
        errors={errors}
        register={register}
        required={false}
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input {...props} name={name} ref={register} type="contractNo" />
        )}
      </CustomInput>

      <CustomInput
        name="paymentDayInMonth"
        errors={errors}
        register={register}
        required={false}
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input
            {...props}
            name={name}
            ref={register}
            type="number"
            min={-100}
            step={1}
            max={31}
          />
        )}
      </CustomInput>

      <CustomInput
        name="paymentMonthAfterCutoff"
        errors={errors}
        register={register}
        required={false}
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input
            {...props}
            name={name}
            ref={register}
            type="number"
            min={1}
            step={1}
            max={12}
          />
        )}
      </CustomInput>

      <CustomInput
        name="reportReceiverEmails"
        errors={errors}
        register={register}
        required={false}
        className="ant-input"
      >
        {(props: any, name, register: any) => (
          <input
            {...props}
            name={name}
            ref={register}
            type="reportReceiverEmails"
          />
        )}
      </CustomInput>
    </>
  )
}

const CompanyDetailForm: React.FC<Company & { history: any }> = (props) => {
  const [logoId, setLogoId] = useState<string>('')
  const [imageUrl, setImageUrl] = useState(props.logo ? props.logo.uri : '')
  const [currentStatus, setCurrentStatus] = useState<CompanyStatus>(
    props.status,
  )
  const [state, dispatch] = useMessageAlert()
  const [updateCompany] = useMutation(UPDATE_COMPANY)
  const [activateCompany] = useMutation(ACTIVATE_COMPANY)
  const [deactivateCompany] = useMutation(DEACTIVATE_COMPANY)
  const { register, handleSubmit, errors, getValues } =
    useForm<UpdateCompanyPayload>({
      validationSchema: InnerFormSchema,
      defaultValues: props,
    })

  async function onSubmit(payload: UpdateCompanyPayload) {
    try {
      dispatch({ type: 'pending' })

      console.log(payload)
      await updateCompany({
        variables: {
          id: props.id,
          payload: {
            ...payload,
            ...(payload.reportReceiverEmails
              ? {
                  reportReceiverEmails: payload.reportReceiverEmails.split(','),
                }
              : null),
          },
        },
      })
      dispatch({
        type: 'success',
        message: COMPANY_UPDATE_INFO_SUCCESS,
      })
    } catch (e: any) {
      dispatch({ type: 'error', message: e.message })
    }
  }

  async function handleChangeStatus(checked: boolean, event: Event) {
    try {
      dispatch({ type: 'pending' })
      if (checked) {
        await activateCompany({ variables: { id: props.id } })
      } else {
        await deactivateCompany({ variables: { id: props.id } })
      }

      dispatch({
        type: 'success',
        message: COMPANY_UPDATE_STATUS_SUCCESS,
      })
      setCurrentStatus(checked ? CompanyStatus.Active : CompanyStatus.Inactive)
    } catch (err: any) {
      dispatch({ type: 'error', message: err.message })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {state.status === MESSAGE_ALERT_STATUS.Resolved && (
          <MessageAlert
            message={state.message}
            messageType={MessageAlertType.Success}
            config={{ closable: false }}
          />
        )}
        {state.status === MESSAGE_ALERT_STATUS.Rejected && (
          <MessageAlert
            message={state.message}
            messageType={MessageAlertType.Error}
            config={{ closable: false }}
          />
        )}

        <CustomInput
          name="Avatar"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <UploadImage
              dispatch={dispatch}
              setLogoId={setLogoId}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
            />
          )}
        </CustomInput>
        <CompanyForm errors={errors} register={register} />
        {/* <CustomInput
          name="status"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <ColumnStatus status={currentStatus} />
          )}
        </CustomInput> */}

        <Row className="ant-form-item">
          <Col sm={6} className="ant-form-item-label ant-form-item-label-left ">
            <label title="Status">Status</label>
          </Col>
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            onChange={handleChangeStatus}
            checked={currentStatus === CompanyStatus.Active}
            loading={state.status === MESSAGE_ALERT_STATUS.Pending}
          />
        </Row>

        <CustomButton
          type="primary"
          value={
            state.status === MESSAGE_ALERT_STATUS.Pending
              ? 'Update...'
              : 'Update Company'
          }
          disabled={state.status === MESSAGE_ALERT_STATUS.Pending}
        />
        <Button
          type="link"
          onClick={(e) => props.history.push('/vertical/companies')}
        >
          Cancel
        </Button>
      </form>
    </>
  )
}

function CompanyDetail(props: CompanyDetailProps) {
  const history = useHistory()
  const { id } = props
  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { id },
  })

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  if (loading) {
    return <Spin />
  }

  if (!data) {
    return <Alert message="Data is empty" />
  }

  return <CompanyDetailForm {...data.company} history={history} />
}

export default withRouter(CompanyDetail)
