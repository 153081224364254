import ZenObservable from 'zen-observable-ts'

export class Publisher<T = any> {
  private readonly rx: ZenObservable<T> = new ZenObservable<T>(observe => {
    this.observe = observe
  })

  private observe: ZenObservable.SubscriptionObserver<T> | undefined
  private lastValue: T | undefined

  publish = (item: T) => {
    if (this.observe && !this.observe.closed) {
      this.observe.next(item)
      this.lastValue = item
    }
  }

  error = (error: Error) =>
    this.observe && !this.observe.closed && this.observe.error(error)

  subscribe = (
    observer: (value: T) => void,
  ): ZenObservable.Subscription | null => {
    if (this.observe && !this.observe.closed) {
      if (this.lastValue) {
        observer(this.lastValue)
      }
      return this.rx.subscribe(observer)
    }
    return null
  }
}
