/* tslint:disable */

export type Maybe<T> = T | null

export interface AssignEmploymentToGroupPayload {
  employmentId: string

  groupId: string
}

export interface AttendanceQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<AttendanceQueryFilter>

  orderBy?: Maybe<AttendanceOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface AttendanceQueryFilter {
  ids?: Maybe<string[]>

  q?: Maybe<string>

  employmentIds?: Maybe<string[]>

  createdAt?: Maybe<OffsetDateTimeRange>

  submittedAt?: Maybe<OffsetDateTimeRange>

  statuses?: Maybe<AttendanceStatus[]>

  types?: Maybe<AttendanceType[]>

  notTypes?: Maybe<AttendanceType[]>

  dateRange?: Maybe<LocalDateRange>

  companyIds?: Maybe<string[]>

  employmentEmployeeIds?: Maybe<string[]>
}

export interface AttendanceWarningQueryFilter {
  companyIds?: Maybe<string[]>

  workplaceIds?: Maybe<string[]>

  dateRange?: Maybe<LocalDateRange>
}

export interface AttributeIdPayload {
  group: string

  name: string
}

export interface AttributeValuePayload {
  id: AttributeIdPayload

  value: string
}

export interface AuditLogQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  orderBy?: Maybe<AuditLogOrderable>

  orderDirection?: Maybe<OrderDirection>

  filter?: Maybe<AuditLogQueryFilter>

  filters?: Maybe<AuditLogQueryFilter[]>
}

export interface AuditLogQueryFilter {
  ids?: Maybe<string[]>

  actions?: Maybe<string[]>

  notActions?: Maybe<string[]>

  companyIds?: Maybe<string[]>
}

export interface BankQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<BankQueryFilter>

  filters?: Maybe<BankQueryFilter[]>
}

export interface BankQueryFilter {
  q?: Maybe<string>

  ids?: Maybe<string[]>

  slugs?: Maybe<string[]>
}

export interface BannerActionClientPayload {
  name: string
}

export interface BannerActionExternalPayload {
  uri: Uri
}

export interface BannerActionNoopPayload {
  noop?: Maybe<Void>
}

export interface BannerActionPayload {
  noop?: Maybe<BannerActionNoopPayload>

  popup?: Maybe<BannerActionPopupPayload>

  client?: Maybe<BannerActionClientPayload>

  external?: Maybe<BannerActionExternalPayload>
}

export interface BannerActionPopupPayload {
  content: BannerContentPayload
}

export interface BannerAssociationEmploymentFilterPayload {
  employmentIds?: Maybe<string[]>

  groupIds?: Maybe<string[]>
}

export interface BannerAssociationQuery {
  limit: number

  offset: number

  filter: BannerAssociationQueryFilter

  orderBy: BannerAssociationOrderable

  orderDirection: OrderDirection
}

export interface BannerAssociationQueryFilter {
  id?: Maybe<string>
}

export interface BannerContentHtmlPayload {
  content: string
}

export interface BannerContentPayload {
  html?: Maybe<BannerContentHtmlPayload>

  svg?: Maybe<BannerContentSvgPayload>
}

export interface BannerContentSvgPayload {
  content: string
}

export interface BannerIdFilter {
  eq?: Maybe<string>

  in?: Maybe<string[]>

  and?: Maybe<BannerIdFilter[]>

  or?: Maybe<BannerIdFilter[]>

  not?: Maybe<BannerIdFilter>
}

export interface BannerQuery {
  limit: number

  offset: number

  filter: BannerQueryFilter

  orderBy: BannerOrderable

  orderDirection: OrderDirection
}

export interface BannerQueryFilter {
  id?: Maybe<BannerIdFilter>

  and?: Maybe<BannerQueryFilter[]>

  or?: Maybe<BannerQueryFilter[]>

  not?: Maybe<BannerQueryFilter>
}

export interface BatchDisbursementPayload {
  code?: Maybe<string>

  externalTransactionId?: Maybe<string>

  disbursementDate?: Maybe<Instant>

  amount?: Maybe<Money>
}

export interface CalculateLoanFeePayload {
  amount: Money

  employmentId: string

  voucherId?: Maybe<string>

  userSelection?: Maybe<boolean>
}

export interface CategoryQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  all?: Maybe<boolean>

  orderBy?: Maybe<CategoryOrderable>

  orderDirection?: Maybe<OrderDirection>

  filters?: Maybe<CategoryQueryFilter[]>
}

export interface CategoryQueryFilter {
  ids?: Maybe<string[]>

  slug?: Maybe<string[]>
}

export interface CommunicationEventChartFilter {
  createdDate?: Maybe<OffsetDateTimeRange>

  type?: Maybe<CommunicationEventType>
}

export interface CommunicationEventChartQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<CommunicationEventChartFilter>
}

export interface CommunicationPostFilter {
  title?: Maybe<string>

  type?: Maybe<CommunicationPostType>

  status?: Maybe<CommunicationPostStatus>
}

export interface CommunicationPostQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<CommunicationPostFilter>

  orderBy?: Maybe<CommunicationPostOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface CompanyConfigImport {
  key: string

  value?: Maybe<string>
}

export interface CompanyEmloymentGroupImport {
  fixedFee: Money

  transactionFeePercentage: number

  limitPercentageBySalaryPeriod: number

  name: string

  code?: Maybe<string>

  workerType: WorkerType

  dailyLimit: Money

  numberOfAdvanceBySalaryPeriod: number

  numberOfAdvanceByDay: number

  minimumFee: Money

  payPeriodStartDayInMonth: number

  payPeriodEndDayInMonth: number

  limitPerAdvance?: Maybe<Money>

  salaryPeriodFreeAmount?: Maybe<Money>

  shiftCountUnit: ShiftUnit

  extensions?: Maybe<Maybe<GroupExtensionImport>[]>

  workPeriods?: Maybe<Maybe<WorkPeriodImport>[]>

  subGroups?: Maybe<Maybe<CompanyEmloymentGroupImport>[]>
}

export interface CompanyEmploymentQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<EmploymentQueryFilter>

  orderBy?: Maybe<EmploymentOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface CompanyImport {
  taxCode?: Maybe<string>

  name: string

  slug: string

  description?: Maybe<string>

  status: CompanyStatus

  logo?: Maybe<StorageObjectImport>

  email?: Maybe<Email>

  address?: Maybe<string>

  code: string

  isIntegrated: boolean

  contractCompanyName?: Maybe<string>

  contractCompanyTaxCode?: Maybe<string>

  contractCompanyAddress?: Maybe<string>

  shortName?: Maybe<string>

  configs?: Maybe<Maybe<CompanyConfigImport>[]>

  termAndConditions?: Maybe<Maybe<TermAndConditionImport>[]>

  employmentGroups?: Maybe<Maybe<CompanyEmloymentGroupImport>[]>

  workplaces?: Maybe<Maybe<WorkplaceImport>[]>

  departments?: Maybe<Maybe<DepartmentImport>[]>

  children?: Maybe<Maybe<CompanyImport>[]>
}

export interface CompanyQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<CompanyQueryFilter>

  orderBy?: Maybe<CompanyOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface CompanyQueryFilter {
  q?: Maybe<string>

  ids?: Maybe<string[]>

  statuses?: Maybe<CompanyStatus[]>

  slugSearch?: Maybe<string>

  nameSearch?: Maybe<string>

  emailSearch?: Maybe<string>

  addressSearch?: Maybe<string>

  linkable?: Maybe<boolean>
}

export interface CreateAttendancePayload {
  date: Date

  checkinSecond?: Maybe<number>

  checkoutSecond?: Maybe<number>

  workingTimeHours: number

  workingTimeRatePerHour: Money

  overtimeHours?: Maybe<number>

  overTimeRatePerHour?: Maybe<Money>

  employmentId: string

  submittedAt?: Maybe<Instant>

  workingDayType: WorkingDayType
}

export interface CreateBannerAssociationPayload {
  bannerId: string

  companyId: string

  description: string

  startTime: Instant

  endTime: Instant

  status: BannerAssociationStatus

  employmentFilter: BannerAssociationEmploymentFilterPayload
}

export interface CreateBannerPayload {
  title: string

  content: BannerContentPayload

  action: BannerActionPayload

  status: BannerStatus
}

export interface CreateBatchFilePayload {
  batchId: string

  filename: string

  qualifier: string
}

export interface CreateCommunicationPostPayload {
  title?: Maybe<string>

  shortDescription?: Maybe<string>

  description?: Maybe<string>

  type?: Maybe<CommunicationPostType>

  sendToType?: Maybe<CommunicationPostSendTo>

  sendToDepartmentIds?: Maybe<string[]>

  sendToIndividualIds?: Maybe<string[]>

  startDate?: Maybe<Instant>

  endDate?: Maybe<Instant>

  scheduledDate?: Maybe<Instant>

  imageId?: Maybe<string>
}

export interface CreateCommunicationTemplatePayload {
  sourceId: string

  status: CommunicationTemplateStatus

  name?: Maybe<string>

  description?: Maybe<string>
}

export interface CreateCompanyEmploymentGroupPayload {
  companyId: string

  fixedFee: Money

  transactionFeePercentage: number

  limitPercentageBySalaryPeriod: number

  name: string

  workerType?: Maybe<WorkerType>

  dailyLimit: Money

  numberOfAdvanceBySalaryPeriod: number

  numberOfAdvanceByDay: number

  minimumFee: Money

  payPeriodStartDate: number

  payPeriodEndDate: number
}

export interface CreateCompanyEmploymentPayload {
  type?: Maybe<EmploymentType>

  startDate?: Maybe<Instant>

  email?: Maybe<Email>

  phoneNumber?: Maybe<string>

  socialId?: Maybe<string>

  employeeId: string

  bankAccountMasked?: Maybe<string>

  salary?: Maybe<Money>

  status: EmploymentStatus

  fullName: string

  bankId?: Maybe<string>

  bankAccount?: Maybe<string>

  idNumber?: Maybe<string>

  workingDayPerMonth?: Maybe<number>

  workingHourPerDay?: Maybe<number>

  expireDate?: Maybe<string>

  gender?: Maybe<string>

  dob?: Maybe<string>

  groupId?: Maybe<string>

  socialIdIssuedDate?: Maybe<Instant>

  socialIdIssuedPlace?: Maybe<string>

  metadata?: Maybe<Json>

  departmentName?: Maybe<string>

  workplaceName?: Maybe<string>
}

export interface CreateCompanyPayload {
  name: string

  slug: string

  description?: Maybe<string>

  logoId?: Maybe<string>

  status: CompanyStatus

  email?: Maybe<Email>

  address?: Maybe<string>

  code?: Maybe<string>

  shortName?: Maybe<string>

  tocId?: Maybe<string>

  contractCompanyName?: Maybe<string>

  contractCompanyTaxCode?: Maybe<string>

  contractCompanyAddress?: Maybe<string>

  contractNo?: Maybe<string>

  paymentDayInMonth?: Maybe<Number>

  paymentMonthAfterCutoff?: Maybe<Number>

  reportReceiverEmails?: Maybe<string>
}

export interface CreateCompanyRequestLinkEmployment {
  employeeId: string

  bankId?: Maybe<string>

  bankAccount?: Maybe<string>

  socialId?: Maybe<string>

  socialIdIssuedDate?: Maybe<LocalDate>

  socialIdIssuedPlace?: Maybe<string>

  dataSharingAgreementFileId?: Maybe<string>

  fullName?: Maybe<string>

  employeeCode?: Maybe<string>

  email?: Maybe<Email>
}

export interface CreateCompanyTermAndConditionPayload {
  storageId: string
}

export interface CreateEmployeeWaitListPayload {
  fullName: string

  employeeCode: string
}

export interface CreateEmploymentMessagePayload {
  title: string

  content: string
}

export interface CreateLoanApplicationBatchPayload {
  title: string

  filter?: Maybe<LoanApplicationQueryFilter>
}

export interface CreateLoanApplicationPayload {
  employmentId: string

  amount: Money

  fee: Money

  purpose?: Maybe<string[]>

  voucherId?: Maybe<string>

  userSelection?: Maybe<boolean>
}

export interface CreateRatingPayload {
  ratingNumber?: Maybe<number>

  ratingIcon?: Maybe<string>

  tags?: Maybe<string[]>

  description?: Maybe<string>
}

export interface CreateRewardPayload {
  employmentId: string

  type: RewardType
}

export interface CreateRolePayload {
  name: string

  description?: Maybe<string>

  permissions: TenantPermission[]
}

export interface CreateStorageObjectFromUriPayload {
  uri: Uri
}

export interface CreateStorageObjectPayload {
  filename: string

  contentType: string
}

export interface CreateSurveyPayload {
  employmentId: string

  surveyId: string

  data: Json
}

export interface CreateTenantUserPayload {
  username: string

  email?: Maybe<Email>

  firstName: string

  lastName: string

  status: TenantUserStatus
}

export interface CreateVoucherPayload {
  maxUsageByEmployment?: Maybe<number>

  minAmount?: Maybe<number>

  maxAmount?: Maybe<number>

  total?: Maybe<number>

  fromDate: Instant

  toDate: Instant

  description?: Maybe<string>

  fixedDiscount?: Maybe<number>

  applicableEmploymentIds: string[]
}

export interface CreateVoucherV2Payload {
  employmentId: string

  type: string
}

export interface CutoffCompanyMessagePayload {
  companyId: string

  month: number

  year?: Maybe<number>
}

export interface DepartmentImport {
  name: string

  code: string

  phone?: Maybe<string>

  email?: Maybe<string>

  status: DepartmentStatus
}

export interface DepartmentQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<DepartmentQueryFilter>

  orderBy?: Maybe<DepartmentOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface DepartmentQueryFilter {
  name?: Maybe<string>

  code?: Maybe<string>

  statuses?: Maybe<DepartmentStatus[]>

  q?: Maybe<string>
}

export interface DisburseLoanApplicationPayload {
  externalRef: string
}

export interface DisbursementQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<DisbursementQueryFilter>

  orderBy?: Maybe<DisbursementOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface DisbursementQueryFilter {
  year?: Maybe<number>

  month?: Maybe<number>

  companyId?: Maybe<string>

  companyIds?: Maybe<string[]>

  sourceBankAccount?: Maybe<string>

  amount?: Maybe<MoneyRangeFilter>

  disbursementAccount?: Maybe<string>

  bankAccount?: Maybe<string>

  externalTransactionId?: Maybe<string>

  lastModifiedDate?: Maybe<OffsetDateTimeRange>

  statuses?: Maybe<DisbursementStatus[]>

  loanApplicationCode?: Maybe<string>

  createdDate?: Maybe<OffsetDateTimeRange>

  employmentName?: Maybe<string>

  employmentEmployeeId?: Maybe<string>

  loanApplicationCreatedDate?: Maybe<OffsetDateTimeRange>
}

export interface EmploymentQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<EmploymentQueryFilter>

  orderBy?: Maybe<EmploymentOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface EmploymentQueryFilter {
  linkDate?: Maybe<LocalDateRange>

  resignDate?: Maybe<LocalDateRange>

  q?: Maybe<string>

  type?: Maybe<EmploymentType>

  ids?: Maybe<string[]>

  companyIds?: Maybe<string[]>

  groupIds?: Maybe<string[]>

  userIds?: Maybe<string[]>

  name?: Maybe<string>

  companyName?: Maybe<string>

  phoneNumber?: Maybe<string>

  employeeIdSearch?: Maybe<string>

  fullNameSearch?: Maybe<string>

  companyNameSearch?: Maybe<string>

  bankSearch?: Maybe<string>

  bankAccountSearch?: Maybe<string>

  statuses?: Maybe<EmploymentStatus[]>

  isLinked?: Maybe<boolean>

  isLoaned?: Maybe<boolean>

  hasDisbursedLoanApplicationIn?: Maybe<OffsetDateTimeRange>

  extensions?: Maybe<EmploymentQueryFilterExtension[]>

  workplaceIds?: Maybe<string[]>

  departmentIds?: Maybe<string[]>

  registrationStatus?: Maybe<EmploymentRegistrationStatus[]>

  workplaceSearch?: Maybe<string>
}

export interface EmploymentQueryFilterExtension {
  key: string

  value?: Maybe<Json>
}

export interface EmploymentSignOtpPayload {
  otp: string

  employmentId: string
}

export interface ExchangeVoucherAnnamPayload {
  code: string

  employmentId: string
}

export interface FinaliseUserRegistrationPayload {
  deviceToken: string

  password: string
}

export interface FinalizeChangePasswordRequestPayload {
  token: string

  password: string
}

export interface FinishUpdateEmploymentJobPayload {
  companyId: string

  jobId: string

  payload?: Maybe<string[]>
}

export interface GroupExtensionImport {
  key: string

  value: string
}

export interface ImpersonateUserPayload {
  userId: string

  scope?: Maybe<string>
}

export interface ImportApiAttendancePayload {
  date?: Maybe<string>

  checkinSecond?: Maybe<number>

  checkoutSecond?: Maybe<number>

  employeeId?: Maybe<string>

  workingTimeHours?: Maybe<number>

  overtimeHours?: Maybe<number>

  submittedAt?: Maybe<Instant>

  workingDayType?: Maybe<WorkingDayType>
}

export interface ImportJobPayload {
  key: string

  bucket: string

  jobType?: Maybe<ImportJobType>

  jobMethod?: Maybe<ImportJobMethod>
}

export interface KangarooLinkEmploymentPayload {
  socialId?: Maybe<string>
}

export interface LanchiApproveRequestPayload {
  id: string
}

export interface LanchiRequestLinkQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  all?: Maybe<boolean>

  orderBy?: Maybe<LanchiRequestLinkOrderable>

  orderDirection?: Maybe<OrderDirection>

  filters?: Maybe<LanchiRequestLinkQueryFilter[]>

  filter?: Maybe<LanchiRequestLinkQueryFilter>
}

export interface LanchiRequestLinkQueryFilter {
  ids?: Maybe<string[]>

  status?: Maybe<LanchiLinkRequestStatus[]>

  q?: Maybe<string>

  employeeString?: Maybe<string>

  socialIdString?: Maybe<string>

  phoneNumberString?: Maybe<string>

  createdDate?: Maybe<OffsetDateTimeRange>

  lastModifiedDate?: Maybe<OffsetDateTimeRange>

  signedAgreement?: Maybe<boolean>

  fullInformation?: Maybe<boolean>
}

export interface LoanApplicationBatchQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<LoanApplicationBatchQueryFilter>

  orderBy?: Maybe<LoanApplicationBatchOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface LoanApplicationBatchQueryFilter {
  q?: Maybe<string>

  title?: Maybe<string>

  createdDate?: Maybe<OffsetDateTimeRange>
}

export interface LoanApplicationCountGraphQuery {
  createdDateAfter: OffsetDateTime

  createdDateBefore: OffsetDateTime

  stepPeriod: Duration
}

export interface LoanApplicationQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<LoanApplicationQueryFilter>

  orderBy?: Maybe<LoanApplicationOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface LoanApplicationQueryFilter {
  q?: Maybe<string>

  statuses?: Maybe<LoanApplicationStatus[]>

  employmentId?: Maybe<string>

  name?: Maybe<string>

  employmentName?: Maybe<string>

  companyName?: Maybe<string>

  bankAccount?: Maybe<string>

  createdDate?: Maybe<OffsetDateTimeRange>

  money?: Maybe<MoneyRangeFilter>

  month?: Maybe<number>

  year?: Maybe<number>

  companyIds?: Maybe<string[]>

  employeeId?: Maybe<string>

  phoneNumber?: Maybe<string>

  loanApplicationCode?: Maybe<string>

  ids?: Maybe<string[]>

  codes?: Maybe<string[]>

  disbursementBankSlugs?: Maybe<string[]>

  departmentIds?: Maybe<string[]>

  companySlugs?: Maybe<string[]>
}

export interface LocalDateRange {
  from?: Maybe<LocalDate>

  fromInclusive?: Maybe<LocalDate>

  to?: Maybe<LocalDate>

  toInclusive?: Maybe<LocalDate>
}

export interface LoginPayload {
  deviceToken: string

  password: string

  scope?: Maybe<string>
}

export interface LoginRefreshToken {
  refreshToken: string
}

export interface MessageQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<MessageQueryFilter>
}

export interface MessageQueryFilter {
  q?: Maybe<string>

  fromDate?: Maybe<Instant>

  toDate?: Maybe<Instant>

  statuses?: Maybe<MessageStatus[]>

  tags?: Maybe<string[]>

  notTags?: Maybe<string[]>

  ids?: Maybe<string[]>
}

export interface MoneyRangeFilter {
  from?: Maybe<Money>

  to?: Maybe<Money>
}

export interface OffsetDateTimeRange {
  from?: Maybe<OffsetDateTime>

  fromInclusive?: Maybe<OffsetDateTime>

  to?: Maybe<OffsetDateTime>

  toInclusive?: Maybe<OffsetDateTime>
}

export interface ProcessApproveEmployeeFilePayload {
  storageObjectId: string
}

export interface ProcessAttendanceFileV2Payload {
  storageObjectId: string

  groupId?: Maybe<string>

  employmentFilter?: Maybe<EmploymentQueryFilter>

  qualifier?: Maybe<string>
}

export interface ProcessEmploymentFileV2Payload {
  storageObjectId: string

  groupId?: Maybe<string>
}

export interface ProcessValidationFormFilePayload {
  storageObjectId: string
}

export interface ProcessWorkplaceAttendanceFilePayload {
  storageObjectId: string

  qualifier: string
}

export interface RealmPayload {
  code: string
}

export interface RegisterNotificationTokenPayload {
  token: string
}

export interface RejectLoanApplicationPayload {
  reason: RejectLoanApplicationReason

  message?: Maybe<string>
}

export interface RequestDeviceTokenPayload {
  requestId: string

  otp: string
}

export interface RequestLinkQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  all?: Maybe<boolean>

  orderBy?: Maybe<RequestLinkQueryOrderable>

  orderDirection?: Maybe<OrderDirection>

  filters?: Maybe<RequestLinkQueryFilter[]>
}

export interface RequestLinkQueryFilter {
  ids?: Maybe<string[]>

  phoneNumbers?: Maybe<string[]>

  employeeId?: Maybe<string[]>

  socialIds?: Maybe<string[]>

  status?: Maybe<RequestLinkStatus[]>

  createdDate?: Maybe<OffsetDateTimeRange>

  lastModifiedDate?: Maybe<OffsetDateTime>
}

export interface RequestLoginPayload {
  phoneNumber: PhoneNumber
}

export interface SendMessagePayload {
  userId: string

  title: string

  content: string
}

export interface SendMessageToEmploymentsPayload {
  filter: EmploymentQueryFilter

  title: string

  content: string

  tags?: Maybe<string[]>
}

export interface SendPushNotificationPayload {
  title: string

  content: string
}

export interface StorageObjectImport {
  filename?: Maybe<string>

  contentType?: Maybe<string>

  content?: Maybe<string>
}

export interface StringFilter {
  equalTo?: Maybe<string>

  notEqualTo?: Maybe<string>

  in?: Maybe<string[]>

  notIn?: Maybe<string[]>

  includes?: Maybe<string>

  notIncludes?: Maybe<string>

  includesInsensitive?: Maybe<string>

  notIncludesInsensitive?: Maybe<string>
}

export interface SubjectPayload {
  id: string

  realm: RealmPayload
}

export interface TenantUserQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<TenantUserQueryFilter>
}

export interface TenantUserQueryFilter {
  q?: Maybe<string>

  username?: Maybe<string>

  email?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>
}

export interface TermAndConditionImport {
  file?: Maybe<StorageObjectImport>

  status?: Maybe<TermAndConditionStatus>
}

export interface TimeSlicesPayload {
  from: OffsetDateTime

  to: OffsetDateTime

  step: Duration
}

export interface TryAutoLinkEmploymentPayload {
  companyId: string
}

export interface UnregisterNotificationTokenPayload {
  token: string
}

export interface UpdateBannerAssociationPayload {
  description?: Maybe<string>

  startTime?: Maybe<Instant>

  endTime?: Maybe<Instant>

  status?: Maybe<BannerAssociationStatus>

  employmentFilter?: Maybe<BannerAssociationEmploymentFilterPayload>
}

export interface UpdateBannerPayload {
  title?: Maybe<string>

  content?: Maybe<BannerContentPayload>

  action?: Maybe<BannerActionPayload>

  status?: Maybe<BannerStatus>
}

export interface UpdateCommunicationPostPayload {
  title?: Maybe<string>

  shortDescription?: Maybe<string>

  description?: Maybe<string>

  type?: Maybe<CommunicationPostType>

  sendToType?: Maybe<CommunicationPostSendTo>

  sendToDepartmentIds?: Maybe<string[]>

  sendToIndividualIds?: Maybe<string[]>

  startDate?: Maybe<Instant>

  endDate?: Maybe<Instant>

  scheduledDate?: Maybe<Instant>

  imageId?: Maybe<string>
}

export interface UpdateCompanyPayload {
  name?: Maybe<string>

  slug?: Maybe<string>

  description?: Maybe<string>

  logoId?: Maybe<string>

  email?: Maybe<Email>

  address?: Maybe<string>

  code?: Maybe<string>

  shortName?: Maybe<string>

  tocId?: Maybe<string>

  categoryId?: Maybe<string>

  contractCompanyName?: Maybe<string>

  contractCompanyTaxCode?: Maybe<string>

  contractCompanyAddress?: Maybe<string>

  contractNo?: Maybe<string>

  paymentDayInMonth?: Maybe<Number>

  paymentMonthAfterCutoff?: Maybe<Number>

  reportReceiverEmails?: Maybe<string>
}

export interface UpdateGroupAttendanceFileProcessorPayload {
  qualifier?: Maybe<string>
}

export interface UpdateGroupEmploymentFileProcessorPayload {
  qualifier?: Maybe<string>
}

export interface UpdateGroupPayslipCalculatorPayload {
  qualifier?: Maybe<string>
}

export interface UpdatePasswordPayload {
  oldPassword: string

  password: string
}

export interface UpdateProgressJobPayload {
  rowCount?: Maybe<number>

  successRowCount?: Maybe<number>

  failedRowCount?: Maybe<number>

  failedPayload?: Maybe<Json>
}

export interface UpdateRolePayload {
  description?: Maybe<string>

  permissions?: Maybe<TenantPermission[]>
}

export interface UpdateTenantUserPayload {
  email?: Maybe<Email>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  status?: Maybe<TenantUserStatus>
}

export interface UpdateUserPayload {
  password?: Maybe<UpdatePasswordPayload>

  fullName?: Maybe<string>
}

export interface UserIdFilter {
  equalTo?: Maybe<string>

  notEqualTo?: Maybe<string>

  in?: Maybe<string[]>

  notIn?: Maybe<string[]>
}

export interface UserLinkEmploymentPayload {
  companyId: string

  employeeId?: Maybe<string>

  bankAccount?: Maybe<string>

  fullName?: Maybe<string>

  bankId?: Maybe<string>

  invitorPhone?: Maybe<PhoneNumber>
}

export interface UserQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<UserQueryFilter>

  orderBy?: Maybe<UserOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface UserQueryFilter {
  q?: Maybe<string>

  fullName?: Maybe<string>

  phoneNumber?: Maybe<string>

  companyName?: Maybe<string>
}

export interface ValidationEntityQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  all?: Maybe<boolean>

  orderBy?: Maybe<ValidationEntityOrderable>

  orderDirection?: Maybe<OrderDirection>

  filters?: Maybe<ValidationEntityQueryFilter[]>

  filter?: Maybe<ValidationEntityQueryFilter>
}

export interface ValidationEntityQueryFilter {
  whiteListWorkplace?: Maybe<string>

  fullNameSearch?: Maybe<string>

  employeeIdSearch?: Maybe<string>

  employmentFilter?: Maybe<EmploymentQueryFilter>
}

export interface VerifiyChangePasswordRequestPayload {
  requestId: string

  otp: string
}

export interface VerifiyUserRegistrationPayload {
  requestId: string

  otp: string
}

export interface VtqLinkEmploymentPayload {
  bankId: string

  socialId: string

  bankAccount: string
}

export interface WorkPeriodEntryImport {
  startDate?: Maybe<Instant>

  expectedEndDate?: Maybe<Instant>

  actualEndDate?: Maybe<Instant>

  status?: Maybe<EntryStatus>
}

export interface WorkPeriodImport {
  type?: Maybe<string>

  entries?: Maybe<Maybe<WorkPeriodEntryImport>[]>
}

export interface WorkplaceFilter {
  ids?: Maybe<string[]>

  nameSearch?: Maybe<string>
}

export interface WorkplaceImport {
  name: string

  code: string

  vendorCode?: Maybe<string>
}

export interface WorkplaceQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filters?: Maybe<WorkplaceFilter[]>

  filter?: Maybe<WorkplaceFilter>

  orderBy?: Maybe<WorkplaceOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface CoreEmployeeQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filter?: Maybe<CoreEmployeeQueryFilter>

  orderBy?: Maybe<CoreEmployeeOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface CoreEmployeeQueryFilter {
  code?: Maybe<StringFilter>
}

export interface CoreImportEmployeePayload {
  code: string

  values: AttributeValuePayload[]
}

export interface HasecaProcessAttendanceFilePayload {
  storageObjectId: string

  groupCode?: Maybe<string>
}

export interface HasecaProcessEmploymentFilePayload {
  storageObjectId: string

  groupCode?: Maybe<string>
}

export interface LanchiProcessAttendanceFilePayload {
  storageObjectId: string
}

export interface LanchiProcessEmploymentFilePayload {
  storageObjectId: string
}

export interface LogivanLinkEmploymentPayload {
  companyId: string

  socialId: string

  employeeId: string
}

export interface WaitlistCompanyIdFilter {
  equalTo?: Maybe<string>

  notEqualTo?: Maybe<string>

  in?: Maybe<string[]>

  notIn?: Maybe<string[]>
}

export interface WaitlistCompanyQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filters?: Maybe<WaitlistCompanyQueryFilter[]>

  orderBy?: Maybe<WaitlistCompanyOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface WaitlistCompanyQueryFilter {
  id?: Maybe<WaitlistCompanyIdFilter[]>
}

export interface WaitlistCreateCampaignPayload {
  code: string

  displayName: string

  status: WaitlistCampaignStatus
}

export interface WaitlistCreateCompanyPayload {
  code: string

  displayName: string

  status: WaitlistCompanyStatus

  logoId?: Maybe<string>

  userCountGoal: number

  userCountSeeding: number

  displayOrder: number
}

export interface WaitlistRegisterUserPayload {
  companyId: string
}

export interface WaitlistUpdateCampaignPayload {
  code?: Maybe<string>

  displayName?: Maybe<string>

  status?: Maybe<WaitlistCampaignStatus>
}

export interface WaitlistUpdateCompanyPayload {
  code?: Maybe<string>

  displayName?: Maybe<string>

  status?: Maybe<WaitlistCompanyStatus>

  logoId?: Maybe<string>

  userCountGoal?: Maybe<number>

  userCountSeeding?: Maybe<number>

  displayOrder?: Maybe<number>
}

export interface WaitlistUpdateUserInvitorPayload {
  phoneNumber: PhoneNumber
}

export interface WaitlistUserIdFilter {
  equalTo?: Maybe<string>

  notEqualTo?: Maybe<string>

  in?: Maybe<string[]>

  notIn?: Maybe<string[]>
}

export interface WaitlistUserQuery {
  limit?: Maybe<number>

  offset?: Maybe<number>

  filters?: Maybe<WaitlistUserQueryFilter[]>

  orderBy?: Maybe<WaitlistUserOrderable>

  orderDirection?: Maybe<OrderDirection>
}

export interface WaitlistUserQueryFilter {
  id?: Maybe<WaitlistUserIdFilter[]>

  companyId?: Maybe<WaitlistCompanyIdFilter[]>

  userId?: Maybe<UserIdFilter[]>

  invitorId?: Maybe<WaitlistUserIdFilter[]>
}

export enum AttendanceOrderable {
  Id = 'id',
  Status = 'status',
  LastModifiedDate = 'lastModifiedDate',
  CreatedDate = 'createdDate',
}

export enum AttendanceStatus {
  Draft = 'Draft',
  Approved = 'Approved',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}

export enum AttendanceType {
  Unknown = 'Unknown',
  RecordMissing = 'RecordMissing',
  HalfTime = 'HalfTime',
  Standard = 'Standard',
  PaidAbsent = 'PaidAbsent',
  UnpaidAbsent = 'UnpaidAbsent',
}

export enum AttributeType {
  Boolean = 'Boolean',
  Int = 'Int',
  Long = 'Long',
  String = 'String',
  Float = 'Float',
  Decimal = 'Decimal',
  Instant = 'Instant',
  LocalDate = 'LocalDate',
  LocalTime = 'LocalTime',
  LocalDateTime = 'LocalDateTime',
  ZonedDateTime = 'ZonedDateTime',
  Money = 'Money',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  Uri = 'URI',
  SocialIdNumber = 'SocialIdNumber',
}

export enum AuditLogOrderable {
  Id = 'id',
  CreatedDate = 'createdDate',
}

export enum BannerAssociationOrderable {
  Id = 'id',
  CreatedDate = 'createdDate',
}

export enum BannerAssociationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum BannerOrderable {
  Id = 'id',
  CreatedDate = 'createdDate',
}

export enum BannerStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum CategoryOrderable {
  CategoryId = 'CategoryId',
  CategoryName = 'CategoryName',
  CategoryOrder = 'CategoryOrder',
}

export enum CommunicationEventType {
  Seen = 'Seen',
  Clap = 'Clap',
}

export enum CommunicationPostOrderable {
  Status = 'Status',
  Id = 'Id',
  CreatedDate = 'CreatedDate',
}

export enum CommunicationPostSendTo {
  All = 'All',
  Department = 'Department',
  Individual = 'Individual',
}

export enum CommunicationPostStatus {
  Draft = 'Draft',
  Published = 'Published',
  Pending = 'Pending',
}

export enum CommunicationPostType {
  Announcement = 'Announcement',
}

export enum CommunicationTemplateStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum CompanyFeaturesWidgetAttendanceVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export enum CompanyOrderable {
  Id = 'id',
  CompanyName = 'companyName',
  Slug = 'slug',
  Status = 'status',
  Index = 'index',
  CreatedDate = 'createdDate',
  LastModifiedDate = 'lastModifiedDate',
  ShortName = 'shortName',
}

export enum CompanyStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pilot = 'Pilot',
}

export enum DeductionCategory {
  SocialInsurance = 'SocialInsurance',
  UnionFee = 'UnionFee',
  PersonalIncomeTax = 'PersonalIncomeTax',
  SalaryAdvanced = 'SalaryAdvanced',
}

export enum DepartmentOrderable {
  Id = 'Id',
  DepartmentName = 'DepartmentName',
  Status = 'Status',
  CreatedDate = 'CreatedDate',
  LastModifiedDate = 'LastModifiedDate',
}

export enum DepartmentStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum DisbursementOrderable {
  Id = 'Id',
  Status = 'Status',
  CreatedDate = 'CreatedDate',
  LastModifiedDate = 'LastModifiedDate',
  LoanApplicationCode = 'LoanApplicationCode',
  Amount = 'Amount',
}

export enum DisbursementStatus {
  Released = 'Released',
  Rejected = 'Rejected',
  Refunded = 'Refunded',
}

export enum EarningCategory {
  Salary = 'Salary',
  Allowance = 'Allowance',
}

export enum EmploymentAgreementStatus {
  Generated = 'Generated',
  Agreed = 'Agreed',
}

export enum EmploymentOrderable {
  Id = 'Id',
  EmployeeId = 'EmployeeId',
  Status = 'Status',
}

export enum EmploymentRegistrationStatus {
  NotAcceptAgreement = 'NotAcceptAgreement',
  AcceptedAgreement = 'AcceptedAgreement',
  NotRegistered = 'NotRegistered',
}

export enum EmploymentStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Unapproved = 'Unapproved',
}

export enum EmploymentType {
  Unknown = 'Unknown',
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  Student = 'Student',
  FixedTerm = 'FixedTerm',
  CasualTerm = 'CasualTerm',
  PermanentTerm = 'PermanentTerm',
}

export enum EntryStatus {
  Initialized = 'Initialized',
  Closed = 'Closed',
}

export enum ImportJobMethod {
  Api = 'API',
  Import = 'Import',
}

export enum ImportJobType {
  Employment = 'Employment',
  Attendance = 'Attendance',
}

export enum IndependenceDay2020PrizeType {
  Nothing = 'Nothing',
  PhoneCard10k = 'PhoneCard10k',
  PhoneCard20k = 'PhoneCard20k',
  ExternalVoucher = 'ExternalVoucher',
  LoanVoucher200k = 'LoanVoucher200k',
  LoanVoucher500k = 'LoanVoucher500k',
  LoanVoucher1m = 'LoanVoucher1m',
  LoanVoucher2m = 'LoanVoucher2m',
}

export enum LanchiLinkRequestStatus {
  Initialized = 'Initialized',
  FinalApproved = 'FinalApproved',
  Rejected = 'Rejected',
}

export enum LanchiRequestLinkOrderable {
  Index = 'index',
  Status = 'status',
  CreatedDate = 'createdDate',
  LastModifiedDate = 'lastModifiedDate',
}

export enum LanchiValidationErrCode {
  ErrWrongSocialIdInfo = 'ERR_WRONG_SOCIAL_ID_INFO',
}

export enum LoanApplicationBatchOrderable {
  Id = 'id',
  Title = 'title',
  CreatedDate = 'createdDate',
  LastModifiedDate = 'lastModifiedDate',
}

export enum LoanApplicationOrderable {
  Id = 'Id',
  Status = 'Status',
  LastModifiedDate = 'LastModifiedDate',
  CreatedDate = 'CreatedDate',
  Amount = 'Amount',
}

export enum LoanApplicationStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  LockForDisbursement = 'LockForDisbursement',
  Closed = 'Closed',
  Disbursed = 'Disbursed',
}

export enum LoanApplicationType {
  Advance = 'Advance',
  TopUp = 'TopUp',
  Unknown = 'Unknown',
}

export enum MessageStatus {
  Unread = 'Unread',
  Read = 'Read',
}
/** query order direction */
export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum RejectDisbursementReason {
  Other = 'Other',
  InvalidAccount = 'InvalidAccount',
  Todo = 'TODO',
}

export enum RejectLoanApplicationReason {
  Unknown = 'Unknown',
}

export enum RequestLinkQueryOrderable {
  Index = 'Index',
  CreatedDate = 'CreatedDate',
  LastModifiedDate = 'LastModifiedDate',
  EmployeeId = 'EmployeeId',
}

export enum RequestLinkStatus {
  Initialized = 'Initialized',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum RewardStatus {
  Pending = 'Pending',
  Processed = 'Processed',
}

export enum RewardType {
  PhoneCard10k = 'PhoneCard10k',
  PhoneCard20k = 'PhoneCard20k',
  ExternalVoucher = 'ExternalVoucher',
  LoanVoucher500k = 'LoanVoucher500k',
  LoanVoucher2m = 'LoanVoucher2m',
  LoanVoucher1m = 'LoanVoucher1m',
  LoanVoucher200k = 'LoanVoucher200k',
}

export enum ShiftUnit {
  Hour = 'Hour',
  Day = 'Day',
}

export enum TaskStatus {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Error = 'Error',
  Finished = 'Finished',
}

export enum TenantPermission {
  ViewLoanApplications = 'ViewLoanApplications',
  ViewRequestLinks = 'ViewRequestLinks',
  ViewDisbursements = 'ViewDisbursements',
  ViewEmployments = 'ViewEmployments',
  ViewAttendances = 'ViewAttendances',
  ViewAuditLogs = 'ViewAuditLogs',
  ManageEmployments = 'ManageEmployments',
  ManageRequestLinks = 'ManageRequestLinks',
  ManageAttendances = 'ManageAttendances',
  ManageUsers = 'ManageUsers',
}

export enum TenantUserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum TermAndConditionStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum UserOrderable {
  Id = 'Id',
  PhoneNumber = 'PhoneNumber',
  FullName = 'FullName',
}

export enum ValidationEntityOrderable {
  WhitelistWorkplace = 'WhitelistWorkplace',
  EmployeeId = 'EmployeeId',
  EmploymentCreatedDate = 'EmploymentCreatedDate',
  EmploymentLastModifiedDate = 'EmploymentLastModifiedDate',
}

export enum WorkerType {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  Seasonal = 'Seasonal',
  Internship = 'Internship',
  Contract = 'Contract',
  Trainee = 'Trainee',
}

export enum WorkingDayType {
  Weekday = 'Weekday',
  Weekend = 'Weekend',
}

export enum WorkplaceOrderable {
  Id = 'id',
  Index = 'index',
  WorkPlaceName = 'workPlaceName',
  WorkPlaceCode = 'workPlaceCode',
}

export enum CoreEmployeeOrderable {
  Id = 'id',
  Code = 'code',
}

export enum WaitlistCampaignStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum WaitlistCompanyOrderable {
  Id = 'id',
  CreatedDate = 'createdDate',
  DisplayOrder = 'displayOrder',
  DisplayName = 'displayName',
}

export enum WaitlistCompanyStage {
  WaitingForRegister = 'WaitingForRegister',
  WorkingWithEmployer = 'WorkingWithEmployer',
}

export enum WaitlistCompanyStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum WaitlistUserOrderable {
  Id = 'id',
  CreatedDate = 'createdDate',
}

export enum WaitlistUserStatus {
  Unverified = 'Unverified',
  Valid = 'Valid',
  Invalid = 'Invalid',
}

/** represent currency code */
export type Currency = any

/** represent date */
export type Date = any

/** represent decimal value */
export type Decimal = any

/** represent a time-based amount of time, mapped to java.time.Duration */
export type Duration = any

/** represent email address */
export type Email = any

export type I18nMessage = any

export type Instant = any

export type Json = any

export type LocalDate = any

export type LocalTime = any

/** represent monetary value */
export type Money = any

/** represent a date-time with a time-zone in the ISO-8601 calendar system, mapped to java.time.OffsetDateTime */
export type OffsetDateTime = any

export type PhoneNumber = any

/** represent fully-qualified uri */
export type Uri = any

/** represent nothing state, useful when define return type of delete operations */
export type Void = any

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

export interface ApplicationException {
  code: number

  message: string

  applicationCode?: Maybe<string>

  data?: Maybe<Json>
}

export interface ApplyVoucherLoanApplicationResponse {
  oldFee?: Maybe<Money>

  oldActuallyReceived?: Maybe<Money>

  voucher?: Maybe<Voucher>
}

export interface Attendance {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  status: AttendanceStatus

  date: Date

  checkinSecond?: Maybe<number>

  checkoutSecond?: Maybe<number>

  workingDayType: WorkingDayType

  workingTimeHours: number

  workingTimeRatePerHour: Money

  overtimeHours?: Maybe<number>

  overTimeRatePerHour?: Maybe<Money>

  submittedAt?: Maybe<Instant>

  employment?: Maybe<Employment>

  earnedBalance: Money

  type: AttendanceType

  vendorType?: Maybe<string>

  times: Maybe<LocalTime>[]
}

export interface AttendanceConnection {
  nodes: Attendance[]

  total: number

  limit: number

  offset: number

  sumShiftCount: Decimal
}

export interface AttendanceWarning {
  date?: Maybe<LocalDate>

  count?: Maybe<number>

  workplaceName?: Maybe<string>
}

export interface AttributeColumn {
  id: AttributeId

  title: string

  schema: AttributeSchema

  required: boolean

  unique: boolean

  valueMapping?: Maybe<AttributeColumnValueMapping[]>
}

export interface AttributeColumnValueMapping {
  key: string

  value: string
}

export interface AttributeId {
  group: string

  name: string
}

export interface AttributeSchema {
  type: AttributeType

  enum?: Maybe<string[]>
}

export interface AttributeValue {
  id: AttributeId

  value: string
}

export interface AuditLog {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  principal?: Maybe<AuditPrincipal>

  action: string

  arguments?: Maybe<Json>

  lanchi_processAttendanceFile?: Maybe<LanchiAuditLogProcessAttendanceFile>

  lanchi_processAttendanceFileV2?: Maybe<LanchiAuditLogProcessAttendanceFile>

  lanchi_processAttendanceFileV3?: Maybe<LanchiAuditLogProcessAttendanceFile>

  processAttendanceFile?: Maybe<AuditLogProcessAttendanceFile>

  processAttendanceFileV2?: Maybe<AuditLogProcessAttendanceFileV2>

  processEmploymentFile?: Maybe<AuditLogProcessEmploymentFile>

  processEmploymentFileV2?: Maybe<AuditLogProcessEmploymentFileV2>
}

export interface AuditLogConnection {
  nodes: AuditLog[]

  total: number

  limit: number

  offset: number
}

export interface AuditLogProcessAttendanceFile {
  storageObject?: Maybe<StorageObject>

  company?: Maybe<Company>
}

export interface AuditLogProcessAttendanceFileV2 {
  payload: AuditLogProcessAttendanceFileV2Payload
}

export interface AuditLogProcessAttendanceFileV2Payload {
  storageObject?: Maybe<StorageObject>

  group?: Maybe<CompanyEmploymentGroup>
}

export interface AuditLogProcessEmploymentFile {
  storageObject?: Maybe<StorageObject>

  company?: Maybe<Company>
}

export interface AuditLogProcessEmploymentFileV2 {
  payload: AuditLogProcessEmploymentFileV2Payload
}

export interface AuditLogProcessEmploymentFileV2Payload {
  storageObject?: Maybe<StorageObject>

  group?: Maybe<CompanyEmploymentGroup>
}

export interface AuditPrincipal {
  subject: string

  username?: Maybe<string>
}

export interface AuthResponse {
  accessToken: string

  expiresIn: Duration

  refreshToken?: Maybe<string>

  refreshExpiresIn?: Maybe<Duration>

  user: User
}

export interface Bank {
  id: string

  name: string

  slug: string

  code: string

  logo?: Maybe<StorageObject>

  transferCode?: Maybe<string>

  alias?: Maybe<string>

  vpbankBankCode?: Maybe<string>

  vpbankBankName?: Maybe<string>

  acbBankCode?: Maybe<string>

  acbBankName?: Maybe<string>

  acb247BankCode?: Maybe<string>

  acb247BankName?: Maybe<string>
}

export interface BankConnection {
  nodes: Bank[]

  total: number

  limit: number

  offset: number
}

export type BannerType = 'Svg' | 'Image'
export interface Banner {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  title: string

  content?: BannerContent

  action?: BannerAction

  status: BannerStatus

  associations: BannerAssociationConnection

  type: BannerType

  contentImage?: StorageObject
}

export interface BannerAction {
  noop?: Maybe<BannerActionNoop>

  popup?: Maybe<BannerActionPopup>

  client?: Maybe<BannerActionClient>

  external?: Maybe<BannerActionExternal>
}

export interface BannerActionClient {
  name: string
}

export interface BannerActionExternal {
  uri: Uri
}

export interface BannerActionNoop {
  noop?: Maybe<Void>
}

export interface BannerActionPopup {
  content: BannerContent
}

export interface BannerAssociation {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  banner: Banner

  company: Company

  description: string

  startTime: Instant

  endTime: Instant

  status: BannerAssociationStatus

  employmentFilter: BannerAssociationEmploymentFilter
}

export interface BannerAssociationConnection {
  nodes: BannerAssociation[]

  total: number

  limit: number

  offset: number
}

export interface BannerAssociationEmploymentFilter {
  employments?: Maybe<Employment[]>

  groups?: Maybe<CompanyEmploymentGroup[]>
}

export interface BannerAssociationResponse {
  bannerAssociation: BannerAssociation
}

export interface BannerConnection {
  nodes: Banner[]

  total: number

  limit: number

  offset: number
}

export interface BannerContent {
  html?: Maybe<BannerContentHtml>

  svg?: Maybe<BannerContentSvg>
}

export interface BannerContentHtml {
  content: string
}

export interface BannerContentSvg {
  content: string
}

export interface CalculateLoanFeeResponse {
  amount: Money

  fee: Money

  actuallyReceived: Money

  voucherAppliedResponse?: Maybe<ApplyVoucherLoanApplicationResponse>

  feeBeforePromotion?: Maybe<Money>
}

export interface CategoryConnection {
  nodes: CompanyCategory[]

  total: number

  limit: number

  offset: number
}

export interface CommunicationEvent {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  type: CommunicationEventType

  content?: Maybe<string>

  employment: Employment
}

export interface CommunicationEventChart {
  employeeId: string

  employmentName: string

  departmentName?: Maybe<string>

  seenDate?: Maybe<Instant>

  clappedDate?: Maybe<Instant>
}

export interface CommunicationEventLineChart {
  date: Instant

  total: number

  type: CommunicationEventType
}

export interface CommunicationEventPieChart {
  departmentId: string

  departmentName: string

  totalEmployments: number

  totalEvents: number
}

export interface CommunicationPost {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  publishedDate?: Maybe<Instant>

  scheduledDate?: Maybe<Instant>

  title?: Maybe<string>

  shortDescription?: Maybe<string>

  description?: Maybe<string>

  status: CommunicationPostStatus

  type?: Maybe<CommunicationPostType>

  sendToType?: Maybe<CommunicationPostSendTo>

  startDate?: Maybe<Instant>

  endDate?: Maybe<Instant>

  company: Company

  image?: Maybe<StorageObject>

  totalClaps: number

  totalViews: number

  employmentEvents?: Maybe<CommunicationEvent[]>

  sendToDepartments?: Maybe<Department[]>

  sendToIndividuals?: Maybe<Employment[]>

  chartDetailEvents: MutateCommunicationEventChartResponse

  lineChartByDates: MutateCommunicationEventLineResponse

  pieChartByDates: MutateCommunicationEventPipeResponse
}

export interface CommunicationPostConnection {
  nodes: CommunicationPost[]

  total: number

  limit: number

  offset: number
}

export interface CommunicationTemplate {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  name: string

  description?: Maybe<string>

  source: StorageObject

  status: CommunicationTemplateStatus
}

export interface Company {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  taxCode?: Maybe<string>

  name: string

  slug: string

  description?: Maybe<string>

  status: CompanyStatus

  logo?: Maybe<StorageObject>

  email?: Maybe<Email>

  address?: Maybe<string>

  code: string

  isIntegrated: boolean

  loanApplications: LoanApplicationConnection

  loanApplication: LoanApplication

  disbursements: DisbursementConnection

  contractCompanyName?: Maybe<string>

  contractCompanyTaxCode?: Maybe<string>

  contractCompanyAddress?: Maybe<string>

  shortName?: Maybe<string>

  recursiveDescendants: Company[]

  descendant?: Maybe<Company>

  portalDomain?: Maybe<Uri>

  communicationPosts: CommunicationPostConnection

  communicationPost: CommunicationPost

  bannerAssociations: BannerAssociationConnection

  bannerAssociation: BannerAssociation

  departments: DepartmentConnection

  employments: EmploymentConnection

  employment: Employment

  workplaces?: Maybe<WorkplaceConnection>

  loanApplicationStats: LoanApplicationStats

  employmentStats?: Maybe<EmploymentStats>

  loanApplicationCountGraph: LoanApplicationCountGraphEntry[]

  tenantUsers: TenantUserConnection

  tenantUser: TenantUser

  authRealm?: Maybe<string>

  authUrl?: Maybe<Uri>

  groupsAsTree: CompanyEmploymentGroupTreeNode[]

  auditLogs: AuditLogConnection

  auditLog: AuditLog

  roles: TenantRole[]

  role?: Maybe<TenantRole>

  features: CompanyFeatures
}

export interface CompanyCategory {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  name: string

  companies: CompanyConnection
}

export interface CompanyConfigExport {
  key: string

  value?: Maybe<string>
}

export interface CompanyConnection {
  nodes: Company[]

  total: number

  limit: number

  offset: number
}

export interface CompanyEmloymentGroupExport {
  fixedFee: Money

  transactionFeePercentage: number

  limitPercentageBySalaryPeriod: number

  name: string

  code?: Maybe<string>

  workerType: WorkerType

  dailyLimit: Money

  numberOfAdvanceBySalaryPeriod: number

  numberOfAdvanceByDay: number

  minimumFee: Money

  payPeriodStartDayInMonth: number

  payPeriodEndDayInMonth: number

  limitPerAdvance?: Maybe<Money>

  salaryPeriodFreeAmount?: Maybe<Money>

  shiftCountUnit: ShiftUnit

  extensions?: Maybe<Maybe<GroupExtensionExport>[]>

  workPeriods?: Maybe<Maybe<WorkPeriod>[]>

  subGroups?: Maybe<Maybe<CompanyEmloymentGroupExport>[]>
}

export interface CompanyEmploymentGroup {
  id: string

  company: Company

  createdDate?: Maybe<Instant>

  lastModifiedDate?: Maybe<Instant>

  fixedFee: Money

  transactionFeePercentage: number

  limitPercentageBySalaryPeriod: number

  name: string

  workerType: WorkerType

  dailyLimit: Money

  numberOfAdvanceBySalaryPeriod: number

  numberOfAdvanceByDay: number

  minimumFee: Money

  payPeriodStartDayInMonth: number

  payPeriodEndDayInMonth: number

  limitPerAdvance?: Maybe<Money>

  salaryPeriodFreeAmount?: Maybe<Money>

  shiftCountUnit: ShiftUnit

  attendanceFileProcessorQualifier?: Maybe<string>

  employmentFileProcessorQualifier?: Maybe<string>

  payslipCalculatorQualifier?: Maybe<string>
}

export interface CompanyEmploymentGroupTreeNode {
  parentId?: Maybe<string>

  group: CompanyEmploymentGroup
}

export interface CompanyExport {
  taxCode?: Maybe<string>

  name: string

  slug: string

  description?: Maybe<string>

  status: CompanyStatus

  logo?: Maybe<StorageObjectExport>

  email?: Maybe<Email>

  address?: Maybe<string>

  code: string

  isIntegrated: boolean

  contractCompanyName?: Maybe<string>

  contractCompanyTaxCode?: Maybe<string>

  contractCompanyAddress?: Maybe<string>

  shortName?: Maybe<string>

  configs?: Maybe<Maybe<CompanyConfigExport>[]>

  termAndConditions?: Maybe<Maybe<TermAndConditionExport>[]>

  workplaces?: Maybe<Maybe<Workplace>[]>

  employmentGroups?: Maybe<Maybe<CompanyEmloymentGroupExport>[]>

  departments?: Maybe<Department[]>

  children?: Maybe<Maybe<CompanyExport>[]>
}

export interface CompanyFeatures {
  noop?: Maybe<Void>

  intercom: boolean

  lanchi_onboarding: boolean

  autoLinkEnabled: boolean

  payslip: boolean

  widgetAttendanceVersion: CompanyFeaturesWidgetAttendanceVersion
}

export interface Contestant {
  name: string

  successTotal: number

  missedTotal: number
}

export interface CreateBatchFileResponse {
  storageObject: StorageObject

  skipIds: string[]
}

export interface CreateStorageObjectResponse {
  storageObject: StorageObject

  uploadUri: Uri
}

export interface CreateTenantUserResponse {
  user: TenantUser

  password: string
}

export interface Deduction {
  category: DeductionCategory

  amount: Money
}

export interface Department {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  name: string

  code: string

  phone?: Maybe<string>

  email?: Maybe<string>

  status: DepartmentStatus
}

export interface DepartmentConnection {
  nodes: Department[]

  total: number

  limit: number

  offset: number
}

export interface DisburseLoanApplicationResponse {
  loanApplication: LoanApplication

  disbursement: Disbursement
}

export interface Disbursement {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  loanApplication: LoanApplication

  amount: Money

  bankAccountId: string

  status: DisbursementStatus

  externalTransactionId: string

  rejectionReason?: Maybe<RejectDisbursementReason>

  rejectionMessage?: Maybe<string>

  disbursementDate?: Maybe<Instant>

  sourceBank?: Maybe<Bank>

  sourceBankAccount?: Maybe<string>
}

export interface DisbursementConnection {
  nodes: Disbursement[]

  total: number

  limit: number

  offset: number

  sumAmounts: Money[]
}

export interface Earning {
  category: EarningCategory

  amount: Money
}

/** All fields as Optional, depends on Company data & willingness to share */
export interface Employment {
  id: string

  user?: Maybe<User>

  company: Company

  employeeId?: Maybe<string>

  status?: Maybe<EmploymentStatus>

  fullName?: Maybe<string>

  fullNameNormalized?: Maybe<string>

  bankAccount?: Maybe<string>

  bankAccountMasked?: Maybe<string>

  bank?: Maybe<Bank>

  salary?: Maybe<Money>

  salaryPerHour?: Maybe<Money>

  salaryCyclePeriod?: Maybe<Duration>

  salaryCycleDayOfMonth?: Maybe<number>

  workingDayPerMonth?: Maybe<number>

  workingHourPerDay?: Maybe<number>

  email?: Maybe<Email>

  type?: Maybe<EmploymentType>

  currentBalance: Money

  earnedBalance: Money

  withdrawableBalance: Money

  dob?: Maybe<Date>

  lastUpdateAttendanceDate?: Maybe<Instant>

  isLinked: boolean

  grossSalary?: Maybe<Money>

  grossAllowance?: Maybe<Money>

  shiftCountNumber?: Maybe<number>

  shiftCountUnit?: Maybe<ShiftUnit>

  independenceDay2020_config: IndependenceDay2020EmploymentConfig

  rewards?: Maybe<Reward[]>

  communicationPosts: CommunicationPostConnection

  communicationPost: CommunicationPost

  countUnSeenCommunicationPost: number

  activeBanners: Banner[]

  lanchi_shiftCountOffset?: Maybe<number>

  lanchi_offGrantShiftCountThreshold?: Maybe<number>

  lanchi_grossSalary?: Maybe<Money>

  lanchi_allowance?: Maybe<Money>

  lanchi_totalCompensation?: Maybe<Money>

  registrationStatus: EmploymentRegistrationStatus

  attendances: AttendanceConnection

  currentDepartment?: Maybe<Department>

  group?: Maybe<CompanyEmploymentGroup>

  isAgreedOnLatestToc: boolean

  summaryAdvanceBySalaryPeriod: EmploymentSummaryAdvance

  summaryAdvanceByDay: EmploymentSummaryAdvance

  salaryPeriodStartDate: OffsetDateTime

  salaryPeriodEndDate: OffsetDateTime

  metadata?: Maybe<Json>

  agreement?: Maybe<EmploymentAgreement>

  invitor?: Maybe<Employment>

  linkDate?: Maybe<LocalDate>

  features: EmploymentFeature

  surveys?: Maybe<Survey[]>

  resignDate?: Maybe<LocalDate>

  workplace?: Maybe<Workplace>

  isPilotCompany: boolean

  loanApplication: LoanApplication

  loanApplications: LoanApplicationConnection

  loanable?: Maybe<boolean>

  vouchers?: Maybe<Maybe<Voucher>[]>

  survey?: Maybe<Survey>

  payslip: Payslip
  employeeWarningStatus:
    | 'Normal'
    | 'Warning'
    | 'InProgress'
    | 'Settled'
    | 'Archived'
  employeeWarningSource:
    | 'None'
    | 'Bot'
    | 'CanceledSettlement'
    | 'ManualResignClicked'
  leaveRequest?: LeaveRequest
}

export type LeaveRequest = {
  id: string
  createdDate?: Instant
  lastModifiedDate?: Instant
  archivedDueDate?: Instant
  LeaveRequestResignStatus?: 'NotConfirmed' | 'Confirmed'
  executionStatus?:
    | 'Pending'
    | 'InProgress'
    | 'Finished'
    | 'Archived'
    | 'Failed'
  resignDate?: LocalDate
  logs?: Array<LeaveRequestLog>
  note?: string
}

export type LeaveRequestLog = {
  id: string
  createdDate: Instant
  lastModifiedDate: Instant
  note: string
  changes: {
    changes: Array<{
      from: string
      to: string
      field:
        | 'ExecutionStatus'
        | 'ResignStatus'
        | 'ArchivedDueDate'
        | 'ResignDate'
        | 'Note'
        | 'ExecuteDueDate'
        | 'ResignStatusChangeSource'
    }>
  }
}

export interface EmploymentAdvanceFeature {
  notAllowToAdvance?: Maybe<boolean>

  notAllowToDisburse?: Maybe<boolean>
}

export interface EmploymentAgreement {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  status: EmploymentAgreementStatus

  toc: TermAndCondition

  agreementFile?: Maybe<StorageObject>

  agreementHtmlFile?: Maybe<StorageObject>

  agreedDate?: Maybe<Instant>

  sign?: Maybe<EmploymentAgreementSign>

  signObject?: Maybe<StorageObject>
}

export interface EmploymentAgreementSign {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  file: StorageObject
}

export interface EmploymentConnection {
  nodes: Employment[]

  total: number

  limit: number

  offset: number
}

export interface EmploymentFeature {
  advance?: Maybe<EmploymentAdvanceFeature>

  companyPay: boolean

  noVoucher: boolean

  limitByPeriod: boolean
}

export interface EmploymentStats {
  total: number
}

export interface EmploymentSummaryAdvance {
  count: number

  total: Money
}

export interface Flags {
  underConstruction: boolean

  serviceACBUnavailable: boolean

  serviceVPBUnavailable: boolean
}

export interface GroupExtensionExport {
  key: string

  value: string
}

export interface ImpersonateUserResponse {
  user: User

  accessToken: string

  expiresIn: Duration
}

export interface IndependenceDay2020EmploymentConfig {
  remaining: number
}

export interface IndependenceDay2020Prize {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  type: IndependenceDay2020PrizeType
}

export interface LanchiLinkRequest {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  requestLink: RequestLink

  status: LanchiLinkRequestStatus

  rejectMessage?: Maybe<string>

  approvedBy?: Maybe<string>

  expectedCompany: Company

  note?: Maybe<LanchiValidationErrCode>
}

export interface LanchiRequestLinkConnection {
  nodes: LanchiLinkRequest[]

  total: number

  limit: number

  offset: number
}

export interface LoanApplication {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  employment: Employment

  status: LoanApplicationStatus

  amount: Money

  amountValue: Decimal

  amountCurrency: Currency

  disbursementBank: Bank

  disbursementAccount: string

  disbursementAccountName: string

  fee: Money

  feeValue: Decimal

  feeCurrency: Currency

  actuallyReceived: Money

  actuallyReceivedValue: Decimal

  actuallyReceivedCurrency: Currency

  rejectionReason?: Maybe<RejectLoanApplicationReason>

  rejectionMessage?: Maybe<string>

  code?: Maybe<string>

  purpose?: Maybe<string[]>

  senderBankAccount: string

  bankDisburseStatement: I18nMessage

  disbursement?: Maybe<Disbursement>

  appliedVoucher?: Maybe<Voucher>
}

export interface LoanApplicationBatch {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  title: string

  loanApplications: LoanApplicationConnection

  loanApplication: LoanApplication
}

export interface LoanApplicationBatchConnection {
  nodes: LoanApplicationBatch[]

  total: number

  limit: number

  offset: number
}

export interface LoanApplicationConnection {
  nodes: LoanApplication[]

  total: number

  limit: number

  offset: number

  sumAmounts: Money[]

  sumFees: Money[]

  sumActuallyReceived: Money[]
}

export interface LoanApplicationCountGraphEntry {
  x: Instant

  y: number
}

export interface LoanApplicationStats {
  total: number

  sumAmounts: Money[]

  sumFees: Money[]
}

export interface Message {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  user: User

  title: I18nMessage

  content: I18nMessage

  tags: string[]

  status: MessageStatus

  group?: Maybe<MessageGroup>
}

export interface MessageConnection {
  nodes: Message[]

  total: number

  limit: number

  offset: number
}

export interface MessageGroup {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  messages?: Maybe<Message[]>

  loanApplication?: Maybe<LoanApplication>
}

export interface MutateAttendanceResponse {
  attendance?: Maybe<Attendance>
}

export interface MutateBannerResponse {
  banner: Banner
}

export interface MutateCommunicationEventChartResponse {
  nodes?: Maybe<CommunicationEventChart[]>

  total: number

  limit: number

  offset: number
}

export interface MutateCommunicationEventLineResponse {
  nodes?: Maybe<CommunicationEventLineChart[]>
}

export interface MutateCommunicationEventPipeResponse {
  nodes?: Maybe<CommunicationEventPieChart[]>
}

export interface MutateCommunicationPostEventResponse {
  communicationEvent: CommunicationEvent
}

export interface MutateCommunicationPostResponse {
  communication: CommunicationPost
}

export interface MutateCompanyEmploymentGroupResponse {
  group: CompanyEmploymentGroup
}

export interface MutateCompanyResponse {
  company: Company
}

export interface MutateEmploymentAgreementResponse {
  agreement: EmploymentAgreement
}

export interface MutateEmploymentResponse {
  employment: Employment
}

export interface MutateLoanApplicationBatchResponse {
  loanApplicationBatch: LoanApplicationBatch
}

export interface MutateLoanApplicationResponse {
  loanApplication: LoanApplication
}

export interface MutateMessageResponse {
  message: Message
}

export interface MutateRatingResponse {
  rating: Rating
}

export interface MutateRequestLinkResponse {
  request?: Maybe<RequestLink>
}

export interface MutateSurveyResponse {
  survey?: Maybe<Survey>
}

export interface MutateTaskResponse {
  task?: Maybe<Task>
}

export interface MutateTenantRoleResponse {
  role: TenantRole
}

export interface MutateTenantUserResponse {
  user: TenantUser
}

export interface MutateUserResponse {
  user: User
}

export interface MutateVoucherResponse {
  voucher?: Maybe<Voucher>
}

export interface Mutation {
  independenceDay2020_acquirePrize: IndependenceDay2020Prize

  createReward: Reward

  createVoucher_V2?: Maybe<Void>

  createCommunicationPost: MutateCommunicationPostResponse

  updateCommunicationPost: MutateCommunicationPostResponse

  deleteCommunicationPost?: Maybe<Void>

  publishCommunicationPost: MutateCommunicationPostResponse

  unpublishedCommunicationPost: MutateCommunicationPostResponse

  saveAsDraftCommunicationPost: MutateCommunicationPostResponse

  createCommunicationTemplate: CommunicationTemplate

  createCommunicationPostEventClap: MutateCommunicationPostEventResponse

  createCommunicationPostEventSeen: MutateCommunicationPostEventResponse

  createBannerAssociation: BannerAssociationResponse

  updateBannerAssociation: BannerAssociationResponse

  createBanner: MutateBannerResponse

  updateBanner: MutateBannerResponse

  waitlist_createCompany: WaitlistCompanyMutateResponse

  waitlist_updateCompany: WaitlistCompanyMutateResponse

  waitlist_updateCompanyStageWorkingWithEmployer: WaitlistCompanyMutateResponse

  waitlist_createCampaign: WaitlistCampaignResponse

  waitlist_updateCampaign: WaitlistCampaignResponse

  waitlist_addCompaniesToCampaign?: Maybe<Void>

  waitlist_removeCompaniesFromCampaign?: Maybe<Void>

  waitlist_registerUser: WaitlistUserMutateResponse

  waitlist_updateUserInvitor: WaitlistUserMutateResponse

  haseca_processAttendanceFile: LanchiProcessResponse

  haseca_processEmploymentFile: LanchiProcessResponse

  kangaroo_linkEmployment: MutateEmploymentResponse

  kangaroo_syncAttendances?: Maybe<Void>

  curnon_updateAttendance?: Maybe<Void>

  curnon_autoSyncAttendances?: Maybe<Void>

  lanchi_processAttendanceFile?: Maybe<Void>

  lanchi_processAttendanceFileV2: LanchiProcessResponse

  lanchi_processAttendanceFileV3: LanchiProcessResponse

  lanchi_finalApproveRequestLinks?: Maybe<Void>

  lanchi_rejectRequestLinks?: Maybe<Void>

  lanchi_processEmploymentFile_V2?: Maybe<Void>

  lanchi_processEmploymentFile_V3: LanchiProcessResponse
  /** lanchi_finalApproveRequestLinks(ids: [ID!]!): Void */
  lanchi_processApproveEmploymentFile_V2: LanchiProcessResponse

  lanchi_processValidationEntitiesFile: LanchiProcessResponse

  frt_syncEmployees?: Maybe<Void>

  frt_syncAttendances?: Maybe<Void>

  vtq_linkEmployment: MutateEmploymentResponse

  annam__exchangeVoucher: MutateVoucherResponse

  logivan_linkEmployment: MutateEmploymentResponse

  createAttendance: MutateAttendanceResponse

  batchApproveAttendances?: Maybe<Void>

  processAttendanceFile?: Maybe<Void>

  processApprovedAttendances?: Maybe<Void>

  processAttendanceFileV2: ProcessAttendanceFileV2Response

  updateGroupAttendanceFileProcessor: UpdateGroupAttendanceFileProcessorResponse

  processWorkplaceAttendanceFile?: Maybe<Void>

  createTokenForProcessWorkplaceAttendanceFile: AuthResponse

  markAsRead: MutateMessageResponse

  markAllAsRead?: Maybe<Void>

  createEmploymentRewardMessage: MutateMessageResponse

  sendMessage: MutateMessageResponse

  sendMessageToEmployments?: Maybe<Void>

  linkEmployment: MutateEmploymentResponse

  assignEmploymentToGroup: MutateEmploymentResponse

  unlinkEmployment?: Maybe<MutateEmploymentResponse>

  processEmploymentFile?: Maybe<Void>

  generateEmploymentAgreement: MutateEmploymentAgreementResponse

  agreeTermAndCondition: MutateEmploymentAgreementResponse

  saveInvitor: boolean

  tryAutoLinkEmployment: TryAutoLinkEmploymentResponse

  signAgreement?: Maybe<Void>

  sendUserOtp?: Maybe<Void>

  processEmploymentFileV2: ProcessEmploymentFileV2Response

  deactivateEmployment?: Maybe<Void>

  updateGroupEmploymentFileProcessor: UpdateGroupEmploymentFileProcessorResponse

  updateGroupPayslipCalculator: UpdateGroupPayslipCalculatorResponse

  migrateEmploymentOLAP?: Maybe<Void>

  system_syncEmploymentTenant?: Maybe<Void>

  exportCompany?: Maybe<CompanyExport>

  importCompany?: Maybe<Void>

  createTenantUser: CreateTenantUserResponse

  updateTenantUser: MutateTenantUserResponse

  deleteTenantUser?: Maybe<Void>

  resetTenantUserPassword: ResetTenantUserPasswordResponse

  setTenantUserRole: MutateTenantUserResponse

  updateBatchedEmployments?: Maybe<Void>

  importAPIAttendances?: Maybe<Void>

  createImportJob: MutateTaskResponse

  updateImportJob: MutateTaskResponse

  finishUpdateEmployments?: Maybe<Void>

  createCompany: MutateCompanyResponse

  updateCompany: MutateCompanyResponse

  deleteCompany?: Maybe<Void>

  activateCompany: MutateCompanyResponse

  deactivateCompany: MutateCompanyResponse

  createCompanyEmploymentGroup: MutateCompanyEmploymentGroupResponse

  createActiveTermAndConditionCompany: TermAndCondition

  sendCutOffMessages?: Maybe<Void>

  dangerouslyDeleteCompany?: Maybe<Void>

  system_syncCompanyAuth?: Maybe<Void>

  system_syncCompanyTenant?: Maybe<Void>

  generateCompanyContract?: Maybe<TermAndCondition>

  updateFlagUnderConstruction?: Maybe<Void>

  updateFlagServiceACBUnavailable?: Maybe<Void>

  updateFlagServiceVPBUnavailable?: Maybe<Void>

  createLoanApplication: MutateLoanApplicationResponse

  approveLoanApplication: MutateLoanApplicationResponse

  rejectLoanApplication: MutateLoanApplicationResponse

  lockLoanApplicationForDisbursement?: Maybe<LoanApplication[]>

  batchUpdateDisbursement?: Maybe<Void>

  disburseLoanApplication: DisburseLoanApplicationResponse

  createVoucher: MutateVoucherResponse

  createCompanyLinkEmploymentRequest?: Maybe<MutateRequestLinkResponse>

  approveCompanyLinkEmploymentRequests?: Maybe<Void>

  rejectCompanyLinkEmploymentRequest?: Maybe<Void>

  upsertRating: MutateRatingResponse

  createEmploymentSurvey: MutateSurveyResponse

  deleteSurvey?: Maybe<Void>

  closeCompanyGroupWorkPeriod?: Maybe<Void>

  system_closeAllExpiredWorkPeriods?: Maybe<Void>

  createLoanApplicationBatch: MutateLoanApplicationBatchResponse

  createBatchFile: CreateBatchFileResponse

  createTenantRole: MutateTenantRoleResponse

  updateTenantRole: MutateTenantRoleResponse

  requestLogin: RequestLoginResponse

  requestDeviceToken: RequestDeviceTokenResponse

  login: AuthResponse

  loginRefreshToken: AuthResponse

  verifyUserRegistration: VerifyUserRegistrationResponse

  finalizeUserRegistration: AuthResponse

  requestChangePassword: RequestChangePasswordResponse

  verifyChangePasswordRequest: VerifyChangePasswordRequestResponse

  finalizeChangePasswordRequest: AuthResponse

  updateProfile: MutateUserResponse

  updateUser: MutateUserResponse

  impersonateUser: ImpersonateUserResponse

  submitCompanyEmployeeWaitList?: Maybe<WaitList>

  bi_invokeFunction: Json

  core_importEmployees?: Maybe<Void>

  exchangeRefreshToken: AuthResponse

  registerNotificationToken?: Maybe<Void>

  unregisterNotificationToken?: Maybe<Void>

  createStorageObject: CreateStorageObjectResponse

  createStorageObjectFromUri: CreateStorageObjectResponse
}

export interface Payslip {
  earnings: Earning[]

  deductions: Deduction[]
}

export interface ProcessAttendanceFileV2Response {
  taskId: string
}

export interface ProcessEmploymentFileV2Response {
  taskId: string
}

export interface Query {
  independenceDay2020_topContestants: Contestant[]

  communicationTemplateImages?: Maybe<CommunicationTemplate[]>

  timeSlices: TimeSlice[]

  banners: BannerConnection

  banner: Banner

  waitlist_companies: WaitlistCompanyConnection

  waitlist_company: WaitlistCompany

  waitlist_campaignByCode: WaitlistCampaign

  waitlist_users: WaitlistUserConnection

  waitlist_user: WaitlistUser

  gs25_syncAttendances?: Maybe<Void>

  gs25_syncResignations?: Maybe<Void>

  gs25_syncEmploymentsV2?: Maybe<Void>

  gs25_migrateEmployments?: Maybe<Void>

  curnon_syncEmployments?: Maybe<Void>

  lanchi_requestLinks: LanchiRequestLinkConnection

  lanchi_requestLink: LanchiLinkRequest

  lanchi_totalValidationEntities: number

  lanchi_validationEntities: ValidationEntityConnection

  attendances: AttendanceConnection

  attendance: Attendance

  attendanceFileProcessorQualifiers: Maybe<string>[]

  employments: EmploymentConnection

  employment: Employment

  employmentFileProcessorQualifiers: string[]

  payslipCalculatorQualifiers: string[]

  bank: Bank

  bankBySlug: Bank

  banks: BankConnection

  companies: CompanyConnection

  company: Company

  companyBySlug: Company

  flags: Flags

  loanApplication: LoanApplication

  loanApplications: LoanApplicationConnection

  calculateLoanFee: CalculateLoanFeeResponse

  disbursements: DisbursementConnection

  disbursement: Disbursement

  requestLinks: RequestLinkConnection

  requestLink: RequestLink

  loanApplicationBatches: LoanApplicationBatchConnection

  loanApplicationBatch: LoanApplicationBatch

  companyCategories: CategoryConnection

  companyCategory: CompanyCategory

  viewer: Viewer

  profile: User

  users: UserConnection

  user: User

  version: string

  subject?: Maybe<Subject>

  _service?: Maybe<_Service>
}

export interface Rating {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  ratingNumber?: Maybe<number>

  ratingIcon?: Maybe<string>

  tags?: Maybe<string[]>

  description?: Maybe<string>
}

export interface Realm {
  code: string
}

export interface RequestChangePasswordResponse {
  requestId: string
}

export interface RequestDeviceTokenResponse {
  deviceToken: string
}

export interface RequestLink {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  bank?: Maybe<Bank>

  employeeId: string

  socialId?: Maybe<string>

  socialIdIssuedPlace?: Maybe<string>

  socialIdIssuedDate?: Maybe<LocalDate>

  bankAccount?: Maybe<string>

  status: RequestLinkStatus

  dataSharingAgreementFile?: Maybe<StorageObject>

  user: User

  company: Company

  fullName?: Maybe<string>

  rejectMessage?: Maybe<string>

  employment?: Maybe<Employment>

  email?: Maybe<Email>

  employeeCode: string

  lanchi_requestLink: LanchiLinkRequest
}

export interface RequestLinkConnection {
  nodes: RequestLink[]

  total: number

  limit: number

  offset: number
}

export interface RequestLoginResponse {
  isNew: boolean

  token: string
}

export interface ResetTenantUserPasswordResponse {
  user: TenantUser

  password: string
}

export interface Reward {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  type: RewardType

  status: RewardStatus

  data: Json
}

export interface StorageObject {
  id: string

  filename: string

  contentType?: Maybe<string>

  uri: Uri

  thumbnail: StorageObject
}

export interface StorageObjectExport {
  filename?: Maybe<string>

  contentType?: Maybe<string>

  content?: Maybe<string>
}

export interface Subject {
  id: string

  realm: Realm

  resourceAccess: string[]
}

export interface Survey {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  surveyId: string

  data: Json
}

export interface Task {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  rowCount?: Maybe<number>

  successRowCount?: Maybe<number>

  failedRowCount?: Maybe<number>

  failedPayload?: Maybe<Json>

  jobType?: Maybe<ImportJobType>

  jobMethod?: Maybe<ImportJobMethod>

  key?: Maybe<string>

  bucket?: Maybe<string>

  company: Company

  status?: Maybe<TaskStatus>
}

export interface TenantRole {
  name: string

  description?: Maybe<string>

  permissions: TenantPermission[]
}

export interface TenantUser {
  id: string

  username: string

  email?: Maybe<Email>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  status: TenantUserStatus

  role?: Maybe<string>
}

export interface TenantUserConnection {
  nodes: TenantUser[]

  total: number

  limit: number

  offset: number
}

export interface TermAndCondition {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  file: StorageObject
}

export interface TermAndConditionExport {
  status?: Maybe<TermAndConditionStatus>

  file?: Maybe<StorageObjectExport>
}

export interface TimeSlice {
  from: OffsetDateTime

  to: OffsetDateTime

  loanApplicationsByCreatedDate: LoanApplicationConnection

  loanApplicationsByLastModifiedDate: LoanApplicationConnection
}

export interface TryAutoLinkEmploymentResponse {
  employment?: Maybe<Employment>
}

export interface UpdateGroupAttendanceFileProcessorResponse {
  group: CompanyEmploymentGroup
}

export interface UpdateGroupEmploymentFileProcessorResponse {
  group: CompanyEmploymentGroup
}

export interface UpdateGroupPayslipCalculatorResponse {
  group: CompanyEmploymentGroup
}

export interface User {
  id: string
  /** createdDate: Instant! lastModifiedDate: Instant! phone: PhoneNumber! */
  phoneNumber: PhoneNumber
  /** socialId: SocialId */
  fullName?: Maybe<string>
  /** firstName: String lastName: String status: UserStatus! */
  accountId: string

  waitlist_users: WaitlistUserConnection

  waitlist_user: WaitlistUser

  messages: MessageConnection

  message: Message

  employments: EmploymentConnection

  employment: Employment

  currentEmployment?: Maybe<Employment>

  requestLinks: RequestLinkConnection

  requestLink: RequestLink

  rating?: Maybe<Rating>

  downloadFlag?: Maybe<boolean>

  waitList?: Maybe<WaitList[]>
}

export interface UserConnection {
  nodes: User[]

  total: number

  limit: number

  offset: number
}

export interface ValidationEntity {
  id: string

  fullName: string

  employeeId: string

  workPlace: string

  employment?: Maybe<Employment>
}

export interface ValidationEntityConnection {
  nodes: ValidationEntity[]

  total: number

  limit: number

  offset: number
}

export interface VerifyChangePasswordRequestResponse {
  token: string
}

export interface VerifyUserRegistrationResponse {
  token?: Maybe<string>
}

export interface Viewer {
  user?: Maybe<User>

  tenantPermissions: TenantPermission[]

  company?: Maybe<Company>

  core_tenant: CoreTenant
}

export interface Voucher {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  minAmount: number

  maxAmount: number

  remain?: Maybe<number>

  total?: Maybe<number>

  maxUsageByEmployment?: Maybe<number>

  fromDate: Instant

  toDate: Instant

  description?: Maybe<string>

  fixedDiscount?: Maybe<number>

  unlimitedDiscountAmount: boolean

  unlimitedDiscountTime: boolean

  code: string
}

export interface WaitList {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  fullName: string

  employeeCode: string

  company: Company
}

export interface WorkPeriod {
  type?: Maybe<string>

  entries?: Maybe<Maybe<WorkPeriodEntry>[]>
}

export interface WorkPeriodEntry {
  startDate?: Maybe<Instant>

  expectedEndDate?: Maybe<Instant>

  actualEndDate?: Maybe<Instant>

  status?: Maybe<EntryStatus>
}

export interface Workplace {
  id: string

  name: string

  code: string

  attendanceWarning: AttendanceWarning[]

  employments: EmploymentConnection

  loanApplications: LoanApplicationConnection
}

export interface WorkplaceConnection {
  limit: number

  offset: number

  nodes: Workplace[]

  total: number
}

export interface _Service {
  sdl: string
}

export interface CoreEmployee {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  code: string

  attributes: AttributeValue[]
}

export interface CoreEmployeeConnection {
  nodes: CoreEmployee[]

  total: number

  limit: number

  offset: number
}

export interface CoreTenant {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  code: string

  ewa_employeeImportColumns: AttributeColumn[]

  employees: CoreEmployeeConnection

  employee: CoreEmployee
}

export interface LanchiAuditLogProcessAttendanceFile {
  storageObject?: Maybe<StorageObject>
}

export interface LanchiProcessError {
  line: number

  error: ApplicationException
}

export interface LanchiProcessResponse {
  errors: LanchiProcessError[]
}

export interface WaitlistCampaign {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  code: string

  displayName: string

  status: WaitlistCampaignStatus

  companies: WaitlistCompanyConnection
}

export interface WaitlistCampaignResponse {
  campaign: WaitlistCampaign
}

export interface WaitlistCompany {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  code: string

  displayName: string

  status: WaitlistCompanyStatus

  userCountGoal: number

  userCountSeeding: number

  stage: WaitlistCompanyStage

  displayOrder: number

  publicRegisterCount: number

  logo?: Maybe<StorageObject>

  waitlist_users: WaitlistUserConnection

  waitlist_user: WaitlistUser
}

export interface WaitlistCompanyConnection {
  nodes: WaitlistCompany[]

  total: number

  limit: number

  offset: number
}

export interface WaitlistCompanyMutateResponse {
  company: WaitlistCompany
}

export interface WaitlistUser {
  id: string

  createdDate: Instant

  lastModifiedDate: Instant

  company: WaitlistCompany

  user: User

  invitor?: Maybe<WaitlistUser>

  status: WaitlistUserStatus

  invitees: WaitlistUserConnection
}

export interface WaitlistUserConnection {
  nodes: WaitlistUser[]

  total: number

  limit: number

  offset: number
}

export interface WaitlistUserMutateResponse {
  user: WaitlistUser
}

// ====================================================
// Arguments
// ====================================================

export interface AssociationsBannerArgs {
  query: BannerAssociationQuery
}
export interface EmploymentEventsCommunicationPostArgs {
  employmentId: string
}
export interface ChartDetailEventsCommunicationPostArgs {
  query?: Maybe<CommunicationEventChartQuery>
}
export interface LineChartByDatesCommunicationPostArgs {
  filter?: Maybe<CommunicationEventChartFilter>
}
export interface PieChartByDatesCommunicationPostArgs {
  filter?: Maybe<CommunicationEventChartFilter>
}
export interface LoanApplicationsCompanyArgs {
  query?: LoanApplicationQuery
}
export interface LoanApplicationCompanyArgs {
  id: string
}
export interface DisbursementsCompanyArgs {
  query?: DisbursementQuery
}
export interface DescendantCompanyArgs {
  id: string
}
export interface CommunicationPostsCompanyArgs {
  query?: Maybe<CommunicationPostQuery>
}
export interface CommunicationPostCompanyArgs {
  id: string
}
export interface BannerAssociationsCompanyArgs {
  query: BannerAssociationQuery
}
export interface BannerAssociationCompanyArgs {
  id: string
}
export interface DepartmentsCompanyArgs {
  query?: DepartmentQuery
}
export interface EmploymentsCompanyArgs {
  query?: CompanyEmploymentQuery
}
export interface EmploymentCompanyArgs {
  id: string
}
export interface WorkplacesCompanyArgs {
  query?: Maybe<WorkplaceQuery>
}
export interface LoanApplicationStatsCompanyArgs {
  filter?: Maybe<LoanApplicationQueryFilter>
}
export interface EmploymentStatsCompanyArgs {
  filter?: Maybe<EmploymentQueryFilter>
}
export interface LoanApplicationCountGraphCompanyArgs {
  query: LoanApplicationCountGraphQuery
}
export interface TenantUsersCompanyArgs {
  query: TenantUserQuery
}
export interface TenantUserCompanyArgs {
  id: string
}
export interface AuditLogsCompanyArgs {
  query?: AuditLogQuery
}
export interface AuditLogCompanyArgs {
  id: string
}
export interface RoleCompanyArgs {
  name: string
}
export interface CompaniesCompanyCategoryArgs {
  query?: CompanyQuery
}
export interface CommunicationPostsEmploymentArgs {
  query?: Maybe<CommunicationPostQuery>
}
export interface CommunicationPostEmploymentArgs {
  id: string
}
export interface AttendancesEmploymentArgs {
  query: AttendanceQuery
}
export interface SummaryAdvanceBySalaryPeriodEmploymentArgs {
  from: Instant

  to: Instant
}
export interface SummaryAdvanceByDayEmploymentArgs {
  date: Instant
}
export interface LoanApplicationEmploymentArgs {
  id: string
}
export interface LoanApplicationsEmploymentArgs {
  query?: LoanApplicationQuery
}
export interface LoanApplicationsLoanApplicationBatchArgs {
  query: LoanApplicationQuery
}
export interface LoanApplicationLoanApplicationBatchArgs {
  id: string
}
export interface IndependenceDay2020AcquirePrizeMutationArgs {
  id: string
}
export interface CreateRewardMutationArgs {
  payload: CreateRewardPayload
}
export interface CreateVoucherV2MutationArgs {
  payload: CreateVoucherV2Payload
}
export interface CreateCommunicationPostMutationArgs {
  companyId: string

  payload: CreateCommunicationPostPayload
}
export interface UpdateCommunicationPostMutationArgs {
  communicationId: string

  payload: UpdateCommunicationPostPayload
}
export interface DeleteCommunicationPostMutationArgs {
  communicationId: string
}
export interface PublishCommunicationPostMutationArgs {
  communicationId: string
}
export interface UnpublishedCommunicationPostMutationArgs {
  communicationId: string
}
export interface SaveAsDraftCommunicationPostMutationArgs {
  communicationId: string
}
export interface CreateCommunicationTemplateMutationArgs {
  payload: CreateCommunicationTemplatePayload
}
export interface CreateCommunicationPostEventClapMutationArgs {
  employmentId: string

  communicationId: string
}
export interface CreateCommunicationPostEventSeenMutationArgs {
  employmentId: string

  communicationId: string
}
export interface CreateBannerAssociationMutationArgs {
  payload: CreateBannerAssociationPayload
}
export interface UpdateBannerAssociationMutationArgs {
  id: string

  payload: UpdateBannerAssociationPayload
}
export interface CreateBannerMutationArgs {
  payload: CreateBannerPayload
}
export interface UpdateBannerMutationArgs {
  id: string

  payload: UpdateBannerPayload
}
export interface WaitlistCreateCompanyMutationArgs {
  payload: WaitlistCreateCompanyPayload
}
export interface WaitlistUpdateCompanyMutationArgs {
  id: string

  payload: WaitlistUpdateCompanyPayload
}
export interface WaitlistUpdateCompanyStageWorkingWithEmployerMutationArgs {
  id: string
}
export interface WaitlistCreateCampaignMutationArgs {
  payload: WaitlistCreateCampaignPayload
}
export interface WaitlistUpdateCampaignMutationArgs {
  id: string

  payload: WaitlistUpdateCampaignPayload
}
export interface WaitlistAddCompaniesToCampaignMutationArgs {
  id: string

  companyIds: string[]
}
export interface WaitlistRemoveCompaniesFromCampaignMutationArgs {
  id: string

  companyIds: string[]
}
export interface WaitlistRegisterUserMutationArgs {
  payload: WaitlistRegisterUserPayload
}
export interface WaitlistUpdateUserInvitorMutationArgs {
  id: string

  payload: WaitlistUpdateUserInvitorPayload
}
export interface HasecaProcessAttendanceFileMutationArgs {
  payload: HasecaProcessAttendanceFilePayload
}
export interface HasecaProcessEmploymentFileMutationArgs {
  payload: HasecaProcessEmploymentFilePayload
}
export interface KangarooLinkEmploymentMutationArgs {
  payload?: Maybe<KangarooLinkEmploymentPayload>
}
export interface CurnonUpdateAttendanceMutationArgs {
  storageId: string
}
export interface LanchiProcessAttendanceFileMutationArgs {
  payload: LanchiProcessAttendanceFilePayload
}
export interface LanchiProcessAttendanceFileV2MutationArgs {
  payload: LanchiProcessAttendanceFilePayload
}
export interface LanchiProcessAttendanceFileV3MutationArgs {
  payload: LanchiProcessAttendanceFilePayload
}
export interface LanchiFinalApproveRequestLinksMutationArgs {
  payload: LanchiApproveRequestPayload[]
}
export interface LanchiRejectRequestLinksMutationArgs {
  ids?: Maybe<string[]>

  rejectMessage?: Maybe<string>
}
export interface LanchiProcessEmploymentFileV2MutationArgs {
  payload: LanchiProcessEmploymentFilePayload
}
export interface LanchiProcessEmploymentFileV3MutationArgs {
  payload: LanchiProcessEmploymentFilePayload
}
export interface LanchiProcessApproveEmploymentFileV2MutationArgs {
  payload: ProcessApproveEmployeeFilePayload
}
export interface LanchiProcessValidationEntitiesFileMutationArgs {
  payload?: Maybe<ProcessValidationFormFilePayload>
}
export interface VtqLinkEmploymentMutationArgs {
  companyId: string

  payload: VtqLinkEmploymentPayload
}
export interface AnnamExchangeVoucherMutationArgs {
  payload: ExchangeVoucherAnnamPayload
}
export interface LogivanLinkEmploymentMutationArgs {
  payload: LogivanLinkEmploymentPayload
}
export interface CreateAttendanceMutationArgs {
  payload: CreateAttendancePayload
}
export interface BatchApproveAttendancesMutationArgs {
  ids: string[]
}
export interface ProcessAttendanceFileMutationArgs {
  storageId: string

  companyId: string
}
export interface ProcessAttendanceFileV2MutationArgs {
  payload: ProcessAttendanceFileV2Payload
}
export interface UpdateGroupAttendanceFileProcessorMutationArgs {
  id: string

  payload: UpdateGroupAttendanceFileProcessorPayload
}
export interface ProcessWorkplaceAttendanceFileMutationArgs {
  id: string

  payload: ProcessWorkplaceAttendanceFilePayload
}
export interface CreateTokenForProcessWorkplaceAttendanceFileMutationArgs {
  id: string
}
export interface MarkAsReadMutationArgs {
  id: string
}
export interface CreateEmploymentRewardMessageMutationArgs {
  employmentId: string

  payload: CreateEmploymentMessagePayload
}
export interface SendMessageMutationArgs {
  payload: SendMessagePayload
}
export interface SendMessageToEmploymentsMutationArgs {
  payload: SendMessageToEmploymentsPayload
}
export interface LinkEmploymentMutationArgs {
  payload: UserLinkEmploymentPayload
}
export interface AssignEmploymentToGroupMutationArgs {
  payload?: Maybe<AssignEmploymentToGroupPayload>
}
export interface UnlinkEmploymentMutationArgs {
  id?: Maybe<string>
}
export interface ProcessEmploymentFileMutationArgs {
  storageId: string

  companyId: string
}
export interface GenerateEmploymentAgreementMutationArgs {
  employmentId: string

  qualifier?: Maybe<string>
}
export interface AgreeTermAndConditionMutationArgs {
  employmentId: string
}
export interface SaveInvitorMutationArgs {
  employmentId: string

  invitorPhone: PhoneNumber
}
export interface TryAutoLinkEmploymentMutationArgs {
  payload: TryAutoLinkEmploymentPayload
}
export interface SignAgreementMutationArgs {
  payload?: Maybe<EmploymentSignOtpPayload>
}
export interface ProcessEmploymentFileV2MutationArgs {
  payload: ProcessEmploymentFileV2Payload
}
export interface DeactivateEmploymentMutationArgs {
  id: string
}
export interface UpdateGroupEmploymentFileProcessorMutationArgs {
  id: string

  payload: UpdateGroupEmploymentFileProcessorPayload
}
export interface UpdateGroupPayslipCalculatorMutationArgs {
  id: string

  payload: UpdateGroupPayslipCalculatorPayload
}
export interface ExportCompanyMutationArgs {
  code?: Maybe<string>
}
export interface ImportCompanyMutationArgs {
  id: string

  import: CompanyImport
}
export interface CreateTenantUserMutationArgs {
  companyId: string

  payload: CreateTenantUserPayload
}
export interface UpdateTenantUserMutationArgs {
  companyId: string

  id: string

  payload: UpdateTenantUserPayload
}
export interface DeleteTenantUserMutationArgs {
  companyId: string

  id: string
}
export interface ResetTenantUserPasswordMutationArgs {
  companyId: string

  id: string
}
export interface SetTenantUserRoleMutationArgs {
  companyId: string

  id: string

  role: string
}
export interface UpdateBatchedEmploymentsMutationArgs {
  companyId: string

  payload?: Maybe<CreateCompanyEmploymentPayload[]>

  jobId: string
}
export interface ImportApiAttendancesMutationArgs {
  companyId: string

  payload?: Maybe<ImportApiAttendancePayload[]>

  jobId: string
}
export interface CreateImportJobMutationArgs {
  companyId: string

  payload: ImportJobPayload
}
export interface UpdateImportJobMutationArgs {
  id: string

  payload: UpdateProgressJobPayload
}
export interface FinishUpdateEmploymentsMutationArgs {
  payload: FinishUpdateEmploymentJobPayload
}
export interface CreateCompanyMutationArgs {
  payload: CreateCompanyPayload
}
export interface UpdateCompanyMutationArgs {
  id: string

  payload: UpdateCompanyPayload
}
export interface DeleteCompanyMutationArgs {
  id: string
}
export interface ActivateCompanyMutationArgs {
  id: string
}
export interface DeactivateCompanyMutationArgs {
  id: string
}
export interface CreateCompanyEmploymentGroupMutationArgs {
  payload: CreateCompanyEmploymentGroupPayload
}
export interface CreateActiveTermAndConditionCompanyMutationArgs {
  companyId: string

  payload: CreateCompanyTermAndConditionPayload
}
export interface SendCutOffMessagesMutationArgs {
  payload: CutoffCompanyMessagePayload
}
export interface DangerouslyDeleteCompanyMutationArgs {
  id: string
}
export interface SystemSyncCompanyAuthMutationArgs {
  id: string
}
export interface GenerateCompanyContractMutationArgs {
  id: string
}
export interface UpdateFlagUnderConstructionMutationArgs {
  value: boolean
}
export interface UpdateFlagServiceAcbUnavailableMutationArgs {
  value: boolean
}
export interface UpdateFlagServiceVpbUnavailableMutationArgs {
  value: boolean
}
export interface CreateLoanApplicationMutationArgs {
  payload: CreateLoanApplicationPayload
}
export interface ApproveLoanApplicationMutationArgs {
  id: string
}
export interface RejectLoanApplicationMutationArgs {
  id: string

  payload: RejectLoanApplicationPayload
}
export interface LockLoanApplicationForDisbursementMutationArgs {
  query?: LoanApplicationQuery
}
export interface BatchUpdateDisbursementMutationArgs {
  payload: BatchDisbursementPayload[]
}
export interface DisburseLoanApplicationMutationArgs {
  id: string

  payload: DisburseLoanApplicationPayload
}
export interface CreateVoucherMutationArgs {
  payload: CreateVoucherPayload
}
export interface CreateCompanyLinkEmploymentRequestMutationArgs {
  companyId: string

  payload: CreateCompanyRequestLinkEmployment

  fake?: Maybe<boolean>
}
export interface ApproveCompanyLinkEmploymentRequestsMutationArgs {
  ids: string[]
}
export interface RejectCompanyLinkEmploymentRequestMutationArgs {
  ids: string[]
}
export interface UpsertRatingMutationArgs {
  payload: CreateRatingPayload
}
export interface CreateEmploymentSurveyMutationArgs {
  payload?: Maybe<CreateSurveyPayload>
}
export interface DeleteSurveyMutationArgs {
  id: string
}
export interface CloseCompanyGroupWorkPeriodMutationArgs {
  id: string
}
export interface CreateLoanApplicationBatchMutationArgs {
  payload: CreateLoanApplicationBatchPayload
}
export interface CreateBatchFileMutationArgs {
  payload: CreateBatchFilePayload
}
export interface CreateTenantRoleMutationArgs {
  companyId: string

  payload: CreateRolePayload
}
export interface UpdateTenantRoleMutationArgs {
  companyId: string

  name: string

  payload: UpdateRolePayload
}
export interface RequestLoginMutationArgs {
  payload: RequestLoginPayload
}
export interface RequestDeviceTokenMutationArgs {
  payload: RequestDeviceTokenPayload
}
export interface LoginMutationArgs {
  payload: LoginPayload
}
export interface LoginRefreshTokenMutationArgs {
  payload: LoginRefreshToken
}
export interface VerifyUserRegistrationMutationArgs {
  payload: VerifiyUserRegistrationPayload
}
export interface FinalizeUserRegistrationMutationArgs {
  payload: FinaliseUserRegistrationPayload
}
export interface RequestChangePasswordMutationArgs {
  phoneNumber: PhoneNumber
}
export interface VerifyChangePasswordRequestMutationArgs {
  payload: VerifiyChangePasswordRequestPayload
}
export interface FinalizeChangePasswordRequestMutationArgs {
  payload: FinalizeChangePasswordRequestPayload
}
export interface UpdateProfileMutationArgs {
  payload?: Maybe<UpdateUserPayload>
}
export interface UpdateUserMutationArgs {
  id: string

  payload?: Maybe<UpdateUserPayload>
}
export interface ImpersonateUserMutationArgs {
  payload: ImpersonateUserPayload
}
export interface SubmitCompanyEmployeeWaitListMutationArgs {
  companyId: string

  payload: CreateEmployeeWaitListPayload
}
export interface BiInvokeFunctionMutationArgs {
  function: string

  body: Json
}
export interface CoreImportEmployeesMutationArgs {
  payloads: CoreImportEmployeePayload[]
}
export interface ExchangeRefreshTokenMutationArgs {
  token: string
}
export interface RegisterNotificationTokenMutationArgs {
  payload: RegisterNotificationTokenPayload
}
export interface UnregisterNotificationTokenMutationArgs {
  payload: UnregisterNotificationTokenPayload
}
export interface CreateStorageObjectMutationArgs {
  payload: CreateStorageObjectPayload
}
export interface CreateStorageObjectFromUriMutationArgs {
  payload: CreateStorageObjectFromUriPayload
}
export interface TimeSlicesQueryArgs {
  payload: TimeSlicesPayload
}
export interface BannersQueryArgs {
  query: BannerQuery
}
export interface BannerQueryArgs {
  id: string
}
export interface WaitlistCompaniesQueryArgs {
  query: WaitlistCompanyQuery
}
export interface WaitlistCompanyQueryArgs {
  id: string
}
export interface WaitlistCampaignByCodeQueryArgs {
  code: string
}
export interface WaitlistUsersQueryArgs {
  query: WaitlistUserQuery
}
export interface WaitlistUserQueryArgs {
  id: string
}
export interface Gs25SyncAttendancesQueryArgs {
  from?: Maybe<Instant>

  to?: Maybe<Instant>
}
export interface LanchiRequestLinksQueryArgs {
  query?: LanchiRequestLinkQuery
}
export interface LanchiRequestLinkQueryArgs {
  id: string
}
export interface LanchiValidationEntitiesQueryArgs {
  query?: Maybe<ValidationEntityQuery>
}
export interface AttendancesQueryArgs {
  query: AttendanceQuery
}
export interface AttendanceQueryArgs {
  id: string
}
export interface EmploymentsQueryArgs {
  query?: EmploymentQuery
}
export interface EmploymentQueryArgs {
  id: string
}
export interface BankQueryArgs {
  id: string
}
export interface BankBySlugQueryArgs {
  slug: string
}
export interface BanksQueryArgs {
  query?: BankQuery
}
export interface CompaniesQueryArgs {
  query?: CompanyQuery
}
export interface CompanyQueryArgs {
  id: string
}
export interface CompanyBySlugQueryArgs {
  slug: string
}
export interface LoanApplicationQueryArgs {
  id: string
}
export interface LoanApplicationsQueryArgs {
  query?: LoanApplicationQuery
}
export interface CalculateLoanFeeQueryArgs {
  payload: CalculateLoanFeePayload
}
export interface DisbursementsQueryArgs {
  query?: DisbursementQuery
}
export interface DisbursementQueryArgs {
  id: string
}
export interface RequestLinksQueryArgs {
  query?: RequestLinkQuery
}
export interface RequestLinkQueryArgs {
  id: string
}
export interface LoanApplicationBatchesQueryArgs {
  query: LoanApplicationBatchQuery
}
export interface LoanApplicationBatchQueryArgs {
  id: string
}
export interface CompanyCategoriesQueryArgs {
  query?: CategoryQuery
}
export interface CompanyCategoryQueryArgs {
  id: string
}
export interface UsersQueryArgs {
  query: UserQuery
}
export interface UserQueryArgs {
  id: string
}
export interface ThumbnailStorageObjectArgs {
  name: string
}
export interface ResourceAccessSubjectArgs {
  resource?: Maybe<string>
}
export interface LoanApplicationsByCreatedDateTimeSliceArgs {
  query: LoanApplicationQuery
}
export interface LoanApplicationsByLastModifiedDateTimeSliceArgs {
  query: LoanApplicationQuery
}
export interface WaitlistUsersUserArgs {
  query: WaitlistUserQuery
}
export interface WaitlistUserUserArgs {
  id: string
}
export interface MessagesUserArgs {
  query: MessageQuery
}
export interface MessageUserArgs {
  id: string
}
export interface EmploymentsUserArgs {
  query?: EmploymentQuery
}
export interface EmploymentUserArgs {
  id: string
}
export interface RequestLinksUserArgs {
  query?: RequestLinkQuery
}
export interface RequestLinkUserArgs {
  id: string
}
export interface AttendanceWarningWorkplaceArgs {
  filter?: Maybe<AttendanceWarningQueryFilter>
}
export interface EmploymentsWorkplaceArgs {
  query?: EmploymentQuery
}
export interface LoanApplicationsWorkplaceArgs {
  query?: LoanApplicationQuery
}
export interface AttributesCoreEmployeeArgs {
  ids: AttributeIdPayload[]
}
export interface EmployeesCoreTenantArgs {
  query: CoreEmployeeQuery
}
export interface EmployeeCoreTenantArgs {
  id: string
}
export interface CompaniesWaitlistCampaignArgs {
  query: WaitlistCompanyQuery
}
export interface WaitlistUsersWaitlistCompanyArgs {
  query: WaitlistUserQuery
}
export interface WaitlistUserWaitlistCompanyArgs {
  id: string
}
export interface InviteesWaitlistUserArgs {
  query: WaitlistUserQuery
}

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string
      name: string
      possibleTypes: {
        name: string
      }[]
    }[]
  }
}

export enum CellularNetworkItemType {
  Card = 'Card',
  Data = 'Data',
  TopUp = 'TopUp',
}

const result: IntrospectionResultData = {
  __schema: {
    types: [],
  },
}

export default result
