import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { t } from 'ttag'
import moment, { Moment } from 'moment'

import { Table, Card, DatePicker, Button } from 'antd'
import {
  InputTableHeader,
  InputGroupTableHeader,
  SelectTableHeader,
  DatePickerTableHeader,
} from '../table/CustomTable'
import {
  LoanApplication,
  DisbursementStatus,
  DisbursementQueryFilter,
  Money,
  Bank,
  LoanApplicationStatus,
  LoanApplicationType,
} from '../../graphql/models'
import { CURRENCY_VND, DATE_FORMAT } from '../constants'
import { ColumnStatus } from '../table/ColmnStatus'
import { ColumnDate } from '../table/ColumDate'
import { disabledDate, formatMoney, currentMoment } from '../../utils/helper'
import { useExportEntity, ExportEntityType } from '../hooks/useExportEntity'
import ButtonLink from '../ButtonLink'
import { DEFAULT_PAGE_SIZE, useTable } from '../hooks/useTable'
import { GET_COMPANIES } from '../Company/CompanyList'
import { useQuery } from 'react-apollo'
import { SelectCompany } from '../input/SelectCompany'
import { ColumnLoanApplicationType } from '../table/ColumnLoanApplicationType'

// export const GET_TRANSACTIONS = gql`
//   query disbursements($query: DisbursementQuery) {
//     disbursements(query: $query) {
//       total
//       nodes {
//         id
//         loanApplication {
//           id
//           createdDate
//           code
//           status
//           employment {
//             id
//             employeeId
//             fullName
//           }
//           amount
//           fee
//         }
//         amount
//         bankAccountId
//         externalTransactionId
//         status
//         sourceBank {
//           id
//           alias
//         }
//         sourceBankAccount
//       }
//       total
//       limit
//       offset
//     }
//   }
// `

export const GET_TRANSACTIONS = gql`
  query getTransactions($query: LoanApplicationQuery) {
    loanApplications(query: $query) {
      nodes {
        id
        type
        code
        createdDate
        status
        disbursementAccount
        receivableAmount
        fee
        employerFee
        amount
        employment {
          employeeId
          fullName
        }
        disbursement {
          amount
          bankAccountId
          externalTransactionId
          status
          sourceBank {
            id
            name
            slug
            code
            alias
          }
          sourceBankAccount
        }
      }
      total
      limit
      offset
    }
  }
`

export const TransactionList = () => {
  const [exportLoading, exportEntity] = useExportEntity()

  const [month, setMonth] = useState<Moment>(currentMoment())
  const [date, setDate] = useState<string>()

  const [loading, error, data, filter, page, setFilter, setPage] =
    useTable<DisbursementQueryFilter>(GET_TRANSACTIONS, {
      filter: {
        ...(month
          ? {
              createdDate: {
                from: month.startOf('month').toISOString(),
                to: month.endOf('month').toISOString(),
              },
            }
          : null),
      },
      orderDirection: `DESC`,
      orderBy: `LastModifiedDate`,
    })

  const companiesResponse = useQuery(GET_COMPANIES, {
    variables: { query: { limit: 100, offset: 0 } },
  })

  const { loanApplicationCode, employeeId, employmentName, statuses, types } =
    filter as any

  function onChangeCompany(value: string) {
    if (value) {
      setFilter('companyIds', [value])
    } else {
      setFilter('companyIds', [])
    }
  }

  function onChangeMonth(date: any) {
    setMonth(date)
    setDate('')
    const time = moment(date, 'MM/YYYY')
    const from = time.startOf('month').toISOString()
    const to = time.endOf('month').toISOString()
    setFilter('createdDate', {
      from,
      to,
    })
  }

  useEffect(() => {
    onChangeMonth(month)
  }, [month])

  function onChangeDate(date: any) {
    if (date === null) {
      setDate('')
      onChangeMonth(month)
      return
    }
    setDate(date)
    const from = date.startOf('day').toISOString()
    const to = date.endOf('day').toISOString()
    setFilter('createdDate', {
      from,
      to,
    })
  }

  function onChangeMoney(fromAmount: any, toAmount: any) {
    const from = fromAmount ? `${fromAmount} ${CURRENCY_VND}` : undefined
    const to = toAmount ? `${toAmount} ${CURRENCY_VND}` : undefined
    setFilter('amount', { from, to })
  }

  const onChangeType = (key: string, value: string) => {
    if (value) {
      setFilter(key, [value])
    } else {
      setFilter(key, undefined)
    }
  }

  async function handleExport(e: any) {
    exportEntity(ExportEntityType.Transaction, filter)
  }
  const columns = [
    {
      title: InputTableHeader({
        value: loanApplicationCode,
        key: 'loanApplicationCode',
        inputType: 'text',
        name: t`Advance Application Code`,
        onPressEnter: setFilter,
      }),
      render: (loanApplication: any) => {
        return (
          <ButtonLink
            title={loanApplication.code}
            routing={`/vertical/loan-applications/${loanApplication.id}/detail?status=Transactions`}
            size="middle"
            style={{ padding: 0, whiteSpace: 'nowrap' }}
          />
        )
      },
      width: 250,
    },
    {
      title: InputTableHeader({
        value: employeeId,
        key: 'employeeId',
        inputType: 'text',
        name: t`Employee Id`,
        onPressEnter: setFilter,
      }),
      dataIndex: 'employment',
      render: (employment: any) => {
        return employment.employeeId
      },
      width: 200,
    },
    {
      title: InputTableHeader({
        value: employmentName,
        key: 'employmentName',
        inputType: 'text',
        name: t`Employee Name`,
        onPressEnter: setFilter,
      }),
      dataIndex: 'employment',
      render: (employment: any) => {
        return employment.fullName
      },
      width: 300,
    },
    {
      // title: InputTableHeader({
      //   value: disbursementAccount,
      //   key: 'disbursementAccount',
      //   inputType: 'text',
      //   name: t`Disbursement Account`,
      //   onPressEnter: setFilter,
      // }),
      title: t`Disbursement Account`,
      dataIndex: 'disbursement',
      key: 'disbursement.bankAccountId',
      render: (disbursement: any | null) => disbursement?.bankAccountId,
      width: 200,
    },
    {
      title: t`Advance Amount`,
      dataIndex: 'amount',
      key: 'amount',
      render: (amountValue: string) => {
        return formatMoney(amountValue)
      },
      width: 250,
    },
    {
      // title: InputGroupTableHeader({
      //   fromValue: amount ? amount?.from : undefined,
      //   toValue: amount ? amount?.to : undefined,
      //   onPressEnter: onChangeMoney,
      //   name: t`Transfer Amount`,
      // }),
      title: t`Transfer Amount`,
      dataIndex: 'disbursement',
      key: 'disbursement.amount',
      render: (disbursement: any | null) => {
        return formatMoney(disbursement?.amount)
      },
      width: 250,
    },
    {
      title: t`Employee Fee`,
      dataIndex: 'fee',
      key: 'fee',
      render: (fee: string) => {
        return formatMoney(fee)
      },
      width: 200,
    },
    {
      title: t`Employer Fee`,
      dataIndex: 'employerFee',
      key: 'employerFee',
      render: (employerFee: string) => {
        return formatMoney(employerFee)
      },
      width: 200,
    },
    {
      title: t`Receivable Amounts`,
      dataIndex: 'receivableAmount',
      key: 'receivableAmount',
      render: (receivableAmount: string) => {
        return formatMoney(receivableAmount)
      },
      width: 200,
    },
    {
      // title: InputTableHeader({
      //   value: externalTransactionId,
      //   key: 'externalTransactionId',
      //   inputType: 'text',
      //   name: t`External Ref`,
      //   onPressEnter: setFilter,
      // }),
      title: t`External Ref`,
      dataIndex: 'disbursement',
      key: 'disbursement.externalTransactionId',
      render: (disbursement: any | null) => {
        return disbursement?.externalTransactionId
      },
      width: 350,
    },
    {
      title: t`Advanced Application Time`,
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (loanApplicationCreatedDate: string) => (
        <ColumnDate date={loanApplicationCreatedDate} format={'HH:mm:ss'} />
      ),
      width: 150,
    },
    {
      title: DatePickerTableHeader({
        onChange: onChangeDate,
        name: t`Advanced Application Date`,
        config: {
          value: date ? moment(date, 'DD/MM/YYYY') : '',
          disabledDate: (current: Moment) =>
            month && disabledDate(current, month, 'MM/YYYY'),
        },
      }),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (loanApplicationCreatedDate: string) => (
        <ColumnDate date={loanApplicationCreatedDate} format={'DD/MM/YYYY'} />
      ),
      width: 150,
    },
    {
      title: SelectTableHeader({
        value: statuses,
        key: 'statuses',
        options: [
          { value: LoanApplicationStatus.Approved, text: t`Approved` },
          { value: LoanApplicationStatus.Closed, text: t`Closed` },
          { value: LoanApplicationStatus.Disbursed, text: t`Disbursed` },
          {
            value: LoanApplicationStatus.LockForDisbursement,
            text: t`LockForDisbursement`,
          },
          { value: LoanApplicationStatus.Pending, text: t`Pending` },
        ],
        name: t`Status`,
        onChange: setFilter,
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return <ColumnStatus status={status} />
      },
    },
    {
      title: t`Source Bank`,
      dataIndex: 'disbursement',
      key: 'disbursement.sourceBank',
      render: (disbursement: any | null) => {
        return disbursement?.sourceBank?.alias
      },
      width: 150,
    },
    {
      title: t`Source Bank Account`,
      dataIndex: 'disbursement',
      key: 'disbursement.sourceBankAccount',
      render: (disbursement: any | null) => {
        return disbursement?.sourceBankAccount
      },
      width: 150,
    },
    {
      title: SelectTableHeader({
        value: types && types.length > 0 ? types[0] : undefined,
        key: 'types',
        options: [
          { value: LoanApplicationType.Advance, text: t`Advance` },
          { value: LoanApplicationType.TopUp, text: t`Topup` },
        ],
        name: t`Loan Applications Type`,
        onChange: onChangeType,
      }),
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => {
        return <ColumnLoanApplicationType type={type} />
      },
      width: 250,
    },
  ]

  return (
    <Card>
      <div style={{ display: 'flex' }}>
        <div className="d-flex justify-content-between pb-3">
          {companiesResponse.data && (
            <div className="ant-row ant-form-item">
              <div className="ant-form-item-label">
                <label>Company</label>
              </div>
              <div className="ant-form-item-control">
                <SelectCompany
                  companies={companiesResponse.data.companies.nodes}
                  onChange={onChangeCompany}
                />
              </div>
            </div>
          )}

          <div className="ant-row ant-form-item" style={{ marginLeft: 10 }}>
            <div className="ant-form-item-label">
              <label>Transaction Month</label>
            </div>
            <div className="ant-form-item-control">
              <DatePicker
                style={{ width: 450 }}
                onChange={onChangeMonth}
                picker="month"
                defaultValue={currentMoment()}
                format="MM/YYYY"
                disabled={!month && date ? true : false}
              />
            </div>
          </div>
        </div>

        <div
          className="ant-form ant-form-horizontal"
          style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={handleExport}
            loading={exportLoading}
            disabled={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>

      <Table
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: () => (!data ? 0 : data.loanApplications.total),
          total: !data ? 0 : data.loanApplications.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={!data ? [] : data.loanApplications.nodes}
        columns={columns}
        loading={loading}
        size="middle"
        bordered={true}
        rowKey="id"
        scroll={{ x: 3000 }}
      />
    </Card>
  )
}
