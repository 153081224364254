import React from 'react'
import { Tag } from 'antd'

type ColumnRegistrationProps = {
  isLinked: boolean
  key?: string
}
export const ColumnRegistration: React.FC<ColumnRegistrationProps> = (
  props,
) => {
  const { isLinked, key } = props
  let color = ''

  switch (isLinked) {
    case true:
      color = 'green'
      break
    case false:
      color = 'red'
      break
    default:
      color = '#4a505c'
  }

  return (
    <Tag key={key} color={color}>
      {isLinked ? 'Registered' : 'Not Registered'}
    </Tag>
  )
}
