import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Drawer, Calendar, Badge, ConfigProvider, Card, Statistic } from 'antd'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import viVN from 'antd/lib/locale/vi_VN'
import './EmploymentAttendanceDrawer.css'
import { AttendanceType } from '../../graphql/models'
import { ArrowUpOutlined } from '@ant-design/icons'

interface EmploymentAttendanceDrawerProps {
  onClose: () => void
  visible: boolean
  employmentId: string | null
}

export const EMPLOYMENT_ATTENDANCES = gql`
  query employment(
    $id: ID!
    $query: AttendanceQuery!
    $countedQuery: AttendanceQuery!
    $recordMissingQuery: AttendanceQuery!
    $unpaidAbsentQuery: AttendanceQuery!
  ) {
    employment(id: $id) {
      employeeId
      fullName
      shiftCountNumber
      shiftCountUnit
      attendances(query: $query) {
        total
        offset
        limit
        nodes {
          id
          createdDate
          lastModifiedDate
          status
          date
          checkinSecond
          checkoutSecond
          workingDayType
          workingTimeHours
          workingTimeRatePerHour
          overtimeHours
          overTimeRatePerHour
          submittedAt
          earnedBalance
          type
          vendorType
          times
        }
      }
      countedAttendances: attendances(query: $countedQuery) {
        total
        offset
        limit
        nodes {
          id
          createdDate
          lastModifiedDate
          status
          date
          checkinSecond
          checkoutSecond
          workingDayType
          workingTimeHours
          workingTimeRatePerHour
          overtimeHours
          overTimeRatePerHour
          submittedAt
          earnedBalance
          type
          vendorType
          times
        }
      }
      recordMissingAttendances: attendances(query: $recordMissingQuery) {
        total
        offset
        limit
        nodes {
          id
          createdDate
          lastModifiedDate
          status
          date
          checkinSecond
          checkoutSecond
          workingDayType
          workingTimeHours
          workingTimeRatePerHour
          overtimeHours
          overTimeRatePerHour
          submittedAt
          earnedBalance
          type
          vendorType
          times
        }
      }
      unpaidAbsentAttendances: attendances(query: $unpaidAbsentQuery) {
        total
        offset
        limit
        nodes {
          id
          createdDate
          lastModifiedDate
          status
          date
          checkinSecond
          checkoutSecond
          workingDayType
          workingTimeHours
          workingTimeRatePerHour
          overtimeHours
          overTimeRatePerHour
          submittedAt
          earnedBalance
          type
          vendorType
          times
        }
      }
    }
  }
`

const initState = {
  selectedDate: moment(),
}

export default ({
  onClose,
  visible,
  employmentId,
}: EmploymentAttendanceDrawerProps) => {
  const [selectedDate, setSelectedDate] = useState(initState.selectedDate)

  const onSelectDate = (date: Moment) => {
    setSelectedDate(date.clone())
  }

  const { dateRangeFrom, dateRangeTo } = useMemo(() => {
    return {
      dateRangeFrom: selectedDate.clone().startOf('month'),
      dateRangeTo: selectedDate.clone().endOf('month'),
    }
  }, [selectedDate])

  const { data, error, loading, networkStatus, refetch } = useQuery(
    EMPLOYMENT_ATTENDANCES,
    {
      skip: !employmentId || !selectedDate,
      variables: {
        id: employmentId,
        query: {
          limit: 50,
          offset: 0,
          filter: {
            dateRange: {
              from: dateRangeFrom.format('YYYY-MM-DD'),
              to: dateRangeTo.format('YYYY-MM-DD'),
            },
          },
        },
        countedQuery: {
          limit: 50,
          offset: 0,
          filter: {
            dateRange: {
              from: dateRangeFrom.format('YYYY-MM-DD'),
              to: dateRangeTo.format('YYYY-MM-DD'),
            },
            types: [
              AttendanceType.Standard,
              AttendanceType.HalfTime,
              AttendanceType.PaidAbsent,
            ],
          },
        },
        recordMissingQuery: {
          limit: 50,
          offset: 0,
          filter: {
            dateRange: {
              from: dateRangeFrom.format('YYYY-MM-DD'),
              to: dateRangeTo.format('YYYY-MM-DD'),
            },
            types: [AttendanceType.RecordMissing],
          },
        },
        unpaidAbsentQuery: {
          limit: 50,
          offset: 0,
          filter: {
            dateRange: {
              from: dateRangeFrom.format('YYYY-MM-DD'),
              to: dateRangeTo.format('YYYY-MM-DD'),
            },
            types: [AttendanceType.UnpaidAbsent],
          },
        },
      },
      onError: () => {
        console.log('e', error)
      },
      onCompleted: (data) => {
        console.log('data completed: ', data)
      },
    },
  )

  const getTitlePopUp = useCallback((attendance) => {
    switch (attendance.type) {
      case AttendanceType.Standard:
        return `Đủ công`
      case AttendanceType.HalfTime:
        return `Nửa công`
      case AttendanceType.PaidAbsent:
        return `Nghỉ có lương`
      case AttendanceType.RecordMissing:
        return `Chưa có công`
      case AttendanceType.UnpaidAbsent:
        return `Ngày nghỉ`
      default:
        return `Unknown`
    }
  }, [])

  const dateCellRender = (date: Moment) => {
    if (!data) {
      return ''
    }
    if (data?.employment?.attendances?.nodes) {
      const attendanceNode = _.find(data?.employment?.attendances?.nodes, {
        date: date.clone().format('YYYY-MM-DD'),
      })
      if (attendanceNode) {
        const color = [
          AttendanceType.Standard,
          AttendanceType.HalfTime,
          AttendanceType.PaidAbsent,
        ].includes(attendanceNode.type)
          ? '#41ba7a'
          : AttendanceType.RecordMissing === attendanceNode.type
          ? '#FFD166'
          : '#a9a9a9'
        return (
          <div>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontWeight: 'bold',
                color,
              }}
            >
              {getTitlePopUp(attendanceNode)}
            </p>
            {attendanceNode.times
              .filter((t: any) => t !== null)
              .map((t: string, i: number) => {
                console.log('time: ', t)
                return (
                  <Badge
                    key={t}
                    color={color}
                    text={t}
                    style={{ display: 'block' }}
                  />
                )
              })}
          </div>
        )
      }
      return date.isBetween(dateRangeFrom, dateRangeTo) ? (
        <p
          style={{
            padding: 0,
            margin: 0,
            fontWeight: 'bold',
          }}
        >
          {`No data`}
        </p>
      ) : (
        ''
      )
    }
    return ''
  }

  useEffect(() => {
    setSelectedDate(initState.selectedDate)
    refetch().catch((e) => {
      console.error(e)
    })
  }, [employmentId])

  useEffect(() => {
    if (visible) {
      refetch().catch((e) => {
        console.error(e)
      })
    }
  }, [visible])

  return (
    <Drawer
      width={'60vw'}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      headerStyle={{ fontSize: 'xx-large', fontWeight: 900 }}
    >
      <Card
      // title={}
      >
        <div>
          <h5 className="m-0">
            {`${data?.employment?.employeeId} - `}
            <span
              style={{ color: '#41ba7a' }}
            >{`${data?.employment?.fullName}`}</span>
          </h5>
          <h5 className="mt-3 mb-3">
            {`Tổng công `}
            <span style={{ color: '#41ba7a' }}>{`${dateRangeFrom.format(
              'DD/MM',
            )} - ${dateRangeTo.format('DD/MM')}`}</span>
          </h5>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Card style={{ marginRight: 20 }}>
              <Statistic
                title={`Công đã ghi nhận:`}
                value={data?.employment?.countedAttendances?.total}
                precision={1}
                valueStyle={{ color: '#41ba7a' }}
                style={{ display: 'inline-block' }}
              />
            </Card>
            <Card style={{ marginRight: 20 }}>
              <Statistic
                title={`Chấm công thiếu:`}
                value={data?.employment?.recordMissingAttendances?.total}
                precision={1}
                valueStyle={{ color: '#FFD166' }}
                style={{ display: 'inline-block' }}
              />
            </Card>
            <Card style={{ marginRight: 20 }}>
              <Statistic
                title={`Nghỉ không lương:`}
                value={data?.employment?.unpaidAbsentAttendances?.total}
                precision={1}
                valueStyle={{ color: '#a9a9a9' }}
                style={{ display: 'inline-block' }}
              />
            </Card>
          </div>
        </div>
      </Card>
      <ConfigProvider locale={viVN}>
        <Calendar
          value={selectedDate}
          onSelect={onSelectDate}
          dateCellRender={dateCellRender}
        />
      </ConfigProvider>
    </Drawer>
  )
}
