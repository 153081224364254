import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Alert, Spin, Card, DatePicker } from 'antd'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
  CreateCompanyEmploymentPayload,
  EmploymentStatus,
  EmploymentType,
} from '../../graphql/models'
import { CustomInput } from '../form/CustomForm'
import { withRouter, useHistory } from 'react-router-dom'
import moment from 'moment'
import { GET_COMPANIES } from '../Company/CompanyList'
import { SelectCompany } from '../input/SelectCompany'
import { formatMoney } from '../../utils/helper'

export const CREATE_COMPANY_EMPLOYMENT = gql`
  mutation createCompanyEmployment(
    $companyId: ID!
    $payload: CreateCompanyEmploymentPayload!
  ) {
    createCompanyEmployment(companyId: $companyId, payload: $payload) {
      employment {
        id
      }
    }
  }
`

const EmploymentTypes = [
  EmploymentType.CasualTerm,
  EmploymentType.FixedTerm,
  EmploymentType.FullTime,
  EmploymentType.PartTime,
  EmploymentType.PermanentTerm,
  EmploymentType.Student,
  EmploymentType.Unknown,
]
const InnerFormSchema = Yup.object().shape({
  startDate: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  socialId: Yup.string(),
  bankAccountMasked: Yup.string().required('Required'),
  employeeId: Yup.string().required('Required'),
  salary: Yup.string().required('Required'),
  status: Yup.mixed()
    .oneOf([EmploymentStatus.Active, EmploymentStatus.Inactive])
    .required('Required'),
  type: Yup.mixed().oneOf(EmploymentTypes).required('Required'),
  fullName: Yup.string().required('Required'),
})

type CreateCompanyEmploymentFormProps = {
  history: any
  companyId: string
}
const CreateCompanyEmploymentForm: React.FC<CreateCompanyEmploymentFormProps> = ({
  history,
  companyId,
}) => {
  // const { message, setMessage } = useMessageAlert()
  const [loading, setLoading] = useState(false)
  const [createCompanyEmployment] = useMutation(CREATE_COMPANY_EMPLOYMENT)
  const { register, handleSubmit, errors } = useForm<
    CreateCompanyEmploymentPayload
  >({ validationSchema: InnerFormSchema })

  useEffect(() => {
    register({ name: 'startDate' })
  })

  const onSubmit = async (payload: CreateCompanyEmploymentPayload) => {
    try {
      setLoading(true)
      await createCompanyEmployment({
        variables: {
          companyId,
          payload: {
            ...payload,
            startDate: moment(payload.startDate).toISOString(),
            salary: formatMoney(payload.salary),
          },
        },
      })
      setLoading(false)
      history.push('/vertical/companies/')
    } catch (e) {
      setLoading(false)
      // setMessage(e.message, 'error')
    }
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <MessageAlert
          message={message.message}
          messageType={message.messageType}
        /> */}

        <CustomInput
          name="employeeId"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="salary"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} type="number" />
          )}
        </CustomInput>

        <CustomInput
          name="fullName"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="startDate"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => <DatePicker />}
        </CustomInput>

        <CustomInput
          name="phoneNumber"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} type="number" />
          )}
        </CustomInput>

        <CustomInput
          name="socialId"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="bankAccountMasked"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="status"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <select {...props} name={name} ref={register}>
              {[EmploymentStatus.Active, EmploymentStatus.Inactive].map(
                (value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ),
              )}
            </select>
          )}
        </CustomInput>

        <CustomInput
          name="type"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <select {...props} name={name} ref={register}>
              {EmploymentTypes.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
          )}
        </CustomInput>

        <input
          type="submit"
          className="ant-btn ant-btn-primary"
          value={loading ? 'Submiting...' : 'Submit'}
          style={{ marginRight: '8px' }}
          disabled={loading}
        />
      </form>
    </Card>
  )
}

// type CreateCompanyEmploymentProps = RouteComponentProps & {
//   companyId: string
// }
const CreateCompanyEmployment: React.FC = () => {
  const [companyId, setCompanyId] = useState()
  const history = useHistory()
  const { loading, error, data } = useQuery(GET_COMPANIES, {
    variables: { query: { limit: 100, offset: 0 } },
  })

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  if (loading) {
    return <Spin size="small" />
  }

  return (
    <>
      <Card title="Company">
        <SelectCompany
          companies={data.companies.nodes}
          onChange={setCompanyId}
        />
      </Card>
      {companyId && (
        <CreateCompanyEmploymentForm history={history} companyId={companyId!} />
      )}
    </>
  )
}

export default withRouter(CreateCompanyEmployment)
