import React, { useEffect, useContext } from 'react'
import { IPageProps, IPageData } from '../../interfaces/page-data'
import { Playground, store } from 'graphql-playground-react'
import { Provider } from 'react-redux'
import { KeycloakContext } from '../../context/KeycloakContext'
import { API_URL } from '../../graphql/constants'

const PlaygroundPage: React.FunctionComponent<IPageProps> = (props) => {
  const keycloak = useContext(KeycloakContext)

  const { onSetPage } = props

  const pageData: IPageData = {
    title: 'Users',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'Playground',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <>
      <Provider store={store}>
        <Playground
          headers={{
            Authorization: `Bearer ${keycloak?.token}`,
          }}
          endpoint={`${API_URL}/graphql`}
          // platformToken={keycloak?.token}
          // onSuccess={() => {
          //   console.log('here')
          // }}
        />
      </Provider>
    </>
  )
}

export default PlaygroundPage
