import { ApolloError, ApolloQueryResult, NetworkStatus } from 'apollo-boost'
import { DocumentNode } from 'graphql'
import { useState } from 'react'
import { useQuery } from 'react-apollo'
import {
  BannerAssociationQueryFilter,
  CompanyQueryFilter,
  DisbursementQueryFilter,
  EmploymentQueryFilter,
  LoanApplicationQueryFilter,
  UserQueryFilter,
} from '../../graphql/models'

export const DEFAULT_PAGE_SIZE = 15
export const DEFAULT_LIMIT = 15
export const DEFAULT_OFFSET = 0

type TableFilter =
  | CompanyQueryFilter
  | EmploymentQueryFilter
  | UserQueryFilter
  | DisbursementQueryFilter
  | LoanApplicationQueryFilter
  | BannerAssociationQueryFilter
type UserTableResponse<T> = [
  boolean,
  ApolloError | undefined,
  any,
  T,
  number,
  (key: string, value: any) => void,
  (number: number) => void,
  () => void,
  any,
  (value: any) => void,
  (
    variables?:
      | ({
          query: {
            filter: T
            limit: number
            offset: number
          }
        } & T)
      | undefined,
  ) => Promise<ApolloQueryResult<any>>,
  NetworkStatus,
]

export function useTable<T extends TableFilter, OtherQueryProperties = any>(
  query: DocumentNode,
  queryProperties?: { filter: T } & OtherQueryProperties,
  defaultQueryVariables?: any,
): UserTableResponse<T> {
  const [page, setPage] = useState<number>(1)
  const defaultFilter = queryProperties?.filter
  const [filter, setFilter] = useState<T>(defaultFilter || ({} as T))

  const [queryVariables, setQueryVariables] = useState<T>(
    defaultQueryVariables || ({} as any),
  )

  const limit = DEFAULT_LIMIT

  const offset = limit * (page - 1)

  const { loading, error, data, refetch, networkStatus } = useQuery(query, {
    variables: {
      query: { limit, offset, ...queryProperties, filter },
      ...queryVariables,
    },
  })

  function setKeyValue(key: string, value: any) {
    if (filter) {
      setFilter({ ...filter, [key]: value })
      setPage(1)
    }
  }

  function setNewQueryVariables(value: any) {
    if (queryVariables) {
      setQueryVariables({ ...value })
      setPage(1)
    }
  }

  function resetFilter() {
    setFilter(defaultFilter || ({} as T))
  }

  return [
    loading,
    error,
    data,
    filter,
    page,
    setKeyValue,
    setPage,
    resetFilter,
    queryVariables,
    setNewQueryVariables,
    refetch,
    networkStatus,
  ]
}
