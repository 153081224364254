import React, { useEffect, useState, useMemo } from 'react'
import { ApolloProvider } from 'react-apollo'
import { Route, Redirect, BrowserRouter } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import 'antd/dist/antd.css'
import '@nano/ninettheme/build/js/static/lib/index.css'
import './App.css'
import { routes } from './routes'
import Keycloak, { KeycloakInstance } from 'keycloak-js'
import { KEYCLOAK_URL, API_URL } from './graphql/constants'
import { Publisher } from './graphql/helpers/Publisher'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject, HttpLink, InMemoryCache } from 'apollo-boost'
import { ApolloLink, Observable } from 'apollo-link' // add Observable
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import fragmentMatcher from './graphql/helpers/fragmentMatcher'
import { Spin } from 'antd'
import { KeycloakContext } from './context/KeycloakContext'

// const { setUrl, setHeaders } = require('nano-mock-data')

export type ClientType = ApolloClient<NormalizedCacheObject>

export enum HttpProtocolEnum {
  HTTP = 'http',
  HTTPS = 'https',
}

const App: React.FC = () => {
  const [keycloak, setKeycloak] = useState<KeycloakInstance | undefined>()

  useEffect(() => {
    const keycloak = Keycloak({
      realm: 'nano-admin',
      url: KEYCLOAK_URL,
      clientId: 'sso-client',
    })
    keycloak
      .init({
        // flow: 'implicit',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
      })
      .success((auth: any) => {
        if (!auth) {
          keycloak.login()
        }
        setKeycloak(keycloak)
      })
      .error((e) => {
        window.alert('Authenticated Failed: ' + e)
      })
  }, [])

  const { client } = useMemo(() => {
    if (keycloak && keycloak.token) {
      const clientPublisher = new Publisher<ClientType>()

      const errorLink = onError(
        // @ts-ignore
        ({ graphQLErrors, networkError, response, operation, forward }) => {
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${JSON.stringify(
                  message,
                )}, Location: ${locations}, Path: ${path}`,
              ),
            )
          }
          if (networkError) {
            console.log('[networkError]', JSON.stringify(networkError))
          }
          const context = operation.getContext()
          const needRefreshToken =
            (context && context.response && context.response.status === 401) ||
            (graphQLErrors &&
              graphQLErrors[0] &&
              graphQLErrors[0]?.extensions?.code === 401)
          if (needRefreshToken) {
            return new Observable((observer) => {
              keycloak
                .updateToken(5)
                .success(function (refreshed) {
                  if (refreshed) {
                    const oldHeaders = operation.getContext().headers
                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        Authorization: `Bearer ${keycloak?.token}`,
                      },
                    })
                    // retry the request, returning the new observable
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    }

                    // Retry last failed request
                    forward(operation).subscribe(subscriber)
                  } else {
                    console.log('Token is still valid')
                  }
                })
                .error((error: any) => {
                  // No refresh or client token available, we force user to login
                  observer.error(error)
                })
            })
          }
        },
      )

      const authLink = setContext(async (_, { headers }) => {
        try {
          return {
            headers: {
              ...headers,
              ...(keycloak?.token
                ? {
                    Authorization: `Bearer ${keycloak?.token}`,
                  }
                : {}),
              'Accept-Language': 'vi-VN',
            },
          }
        } catch (_) {
          // do nothing
        }
        return {
          headers,
        }
      })

      const httpLink = new HttpLink({
        uri: `${API_URL}/graphql`,
      })

      // console.log('Websocket token: ', keycloak?.token)
      // const wsLink = new WebSocketLink({
      //   uri: `${WSS_URL}/subscriptions`,
      //   options: {
      //     reconnect: true,
      //     connectionParams: () => ({
      //       headers: {
      //         Authorization: [`Bearer ${keycloak?.token}`],
      //       },
      //     }),
      //   },
      // })

      // let finalLink: ApolloLink = split(
      //   // split based on operation type
      //   ({ query }) => {
      //     const definition = getMainDefinition(query)
      //     return (
      //       definition.kind === 'OperationDefinition' &&
      //       definition.operation === 'subscription'
      //     )
      //   },
      //   wsLink,
      //   httpLink,
      // )

      const cache = new InMemoryCache({
        fragmentMatcher,
      })

      const client = new ApolloClient({
        link: ApolloLink.from([errorLink, authLink, httpLink]),
        cache: cache,
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
          },
          watchQuery: {
            fetchPolicy: 'no-cache',
          },
        },
      })

      // setUrl(`${API_URL}/graphql`)
      // setHeaders({
      //   Authorization: `Bearer ${keycloak?.token}`,
      // })

      clientPublisher.publish(client)

      return {
        client: client,
      }
    }

    return {
      client: null,
    }
  }, [keycloak])

  return client && keycloak && keycloak.token ? (
    <ApolloProvider client={client}>
      <KeycloakContext.Provider value={keycloak}>
        <DndProvider backend={HTML5Backend}>
          <div className="App">
            <BrowserRouter>
              {routes.map((route, i) => (
                <Route
                  key={i}
                  path={route.path}
                  component={() => <route.component />}
                />
              ))}
              <Route
                exact
                path="/"
                render={() => <Redirect exact to="/vertical/dashboard/" />}
              />
            </BrowserRouter>
          </div>
        </DndProvider>
      </KeycloakContext.Provider>
    </ApolloProvider>
  ) : (
    <div
      style={{ width: '100%', height: '100%' }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spin size="large" />
    </div>
  )
}

export default App
