import React, { ReactNode, useEffect } from 'react'
import { Form as FormAntd } from 'antd'
import { InputProps } from 'antd/lib/input'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

function camelToString(text: string) {
  const regexText = text.replace(/([A-Z])/g, (match) => ` ${match}`)
  return regexText
    .split('.')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')
}

type CustomInputProps = {
  name: string
  errors: any
  children: (props: InputProps, name: string, register?: any) => ReactNode
  required: boolean
  register?: any
  label?: string
} & InputProps

function useCombinedRefs(...refs: any[]) {
  const targetRef = React.useRef()

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export function CustomInput({
  name,
  errors,
  children,
  register,
  required = false,
  label,
  style,
  ...rest
}: CustomInputProps) {
  const innerRef = React.useRef(null)
  const combinedRef = useCombinedRefs(register, innerRef)
  const itemLabel = label
    ? `${label}${required ? '*' : ''}`
    : `${camelToString(name)}${required ? '*' : ''}`

  return (
    <FormAntd.Item
      label={itemLabel}
      labelAlign="left"
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      validateStatus={errors[name] && 'error'}
      help={
        <span className="error-message">
          {errors[name] && errors[name].message}
        </span>
      }
    >
      {children(rest, name, combinedRef)}
    </FormAntd.Item>
  )
}
