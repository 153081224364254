import { Card, Tabs } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import SoftPin from './Softpin'
import TopupConfig from './TopupConfig'

interface TenantDetailProps {}

interface ITab {
  title: string
  component: JSX.Element
}

const TenantDetail: React.FC<TenantDetailProps> = () => {
  const { id } = useParams<{ id: string }>()

  const tabs: ITab[] = [
    {
      title: 'Topup Config',
      component: <TopupConfig tenantId={id} />,
    },
    {
      title: 'Soft Pin',
      component: <SoftPin tenantId={id} />,
    },
  ]

  return (
    <>
      <Card style={{ background: 'white', minHeight: '90vh' }}>
        <Tabs defaultActiveKey="0">
          {tabs.map((tab) => (
            <Tabs.TabPane
              tab={tab.title}
              key={tab.title}
              forceRender={true}
              style={{ padding: 30 }}
            >
              {tab.component}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Card>
    </>
  )
}

export default TenantDetail
