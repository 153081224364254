import JoditEditor from 'jodit-react'
import React from 'react'

interface MessageEditorProps {
  content: string
  changeContent: (newContent: string) => void
}

export default ({ content, changeContent }: MessageEditorProps) => {
  const config: any = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    enter: 'DIV',
    disablePlugins:
      'print,preview,table,select-cells,table-keyboard-navigation,resize-cells,file,media,video,image,image-properties,image-processor,paste,clipboard,paste-storage,drag-and-drop,color,drag-and-drop-element,enter,error-messages,font,format-block,fullsize,hotkeys,iframe,indent,hr,inline-popup,justify,limit,search,resizer,redo-undo,placeholder,ordered-list,mobile,resize-handler,stat,sticky,symbols,tooltip,xpath,copy-format,wrap-text-nodes,clean-html,bold,class-span,delete,autofocus,about,add-new-line',
    buttons: 'source,bold,italic,underline,link',
    placeholder: 'start typing text ...',
  }

  const onBlur = (event: any) => {
    const editorValue = event
    changeContent(editorValue)
  }

  return (
    <JoditEditor
      config={config}
      value={content}
      onBlur={onBlur} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {
        console.log('new Content: ', newContent)
      }}
    />
  )
}
