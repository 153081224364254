import { CopyOutlined, DeliveredProcedureOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Alert, Button, Card, DatePicker, Modal, Radio, Table } from 'antd'
import gql from 'graphql-tag'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import {
  LoanApplication,
  LoanApplicationBatch,
  LoanApplicationQueryFilter,
  LoanApplicationStatus,
  StorageObject,
} from '../../graphql/models'
import { currentMoment, disabledDate } from '../../utils/helper'
import ButtonLink from '../ButtonLink'
import { GET_COMPANIES } from '../Company/CompanyList'
import { CURRENCY_VND, LAYOUT_PATH } from '../constants'
import { ExportEntityType, useExportEntity } from '../hooks/useExportEntity'
import { DEFAULT_PAGE_SIZE, useTable } from '../hooks/useTable'
import { SelectCompany } from '../input/SelectCompany'
import { ColumnStatus } from '../table/ColmnStatus'
import { ColumnDate } from '../table/ColumDate'
import {
  DatePickerTableHeader,
  InputGroupTableHeader,
  InputTableHeader,
} from '../table/CustomTable'
import { UpdateDisburse } from './UpdateDisburse'

const { confirm } = Modal

export const GET_LOAN_APPLICATIONS_BY_EMPLOYMENT = gql`
  query employment($id: ID!, $query: LoanApplicationQuery) {
    employment(id: $id) {
      fullName
      loanApplications(query: $query) {
        nodes {
          id
          createdDate
          lastModifiedDate
          employment {
            id
            fullName
            employeeId
            bankAccountMasked
            company {
              name
            }
          }
          status
          amount
          disbursementAccount
          fee
          actuallyReceived
        }
      }
    }
  }
`

export const GET_LOAN_APPLICATIONS = gql`
  query loanApplications($query: LoanApplicationQuery) {
    loanApplications(query: $query) {
      total
      nodes {
        id
        code
        createdDate
        lastModifiedDate
        employment {
          id
          fullName
          employeeId
          company {
            id
            name
          }
          bank {
            name
          }
          bankAccountMasked
        }
        status
        amount
        disbursementAccount
        fee
        actuallyReceived
      }
    }
  }
`

export const CREATE_LOAN_APPLICATION_BATCH = gql`
  mutation createLoanApplicationBatch(
    $title: String!
    $filter: LoanApplicationQueryFilter
  ) {
    createLoanApplicationBatch(payload: { title: $title, filter: $filter }) {
      loanApplicationBatch {
        id
        loanApplications {
          total
          sumAmounts
          sumFees
        }
      }
    }
  }
`

export const CREATE_BATCH_FILE = gql`
  mutation createBatchFile(
    $batchId: ID!
    $filename: String!
    $qualifier: String!
  ) {
    createBatchFile(
      payload: { batchId: $batchId, filename: $filename, qualifier: $qualifier }
    ) {
      storageObject {
        uri
      }
    }
  }
`

type LoadApplicationListProps = {
  status: LoanApplicationStatus
}

// const formatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: CURRENCY_VND,
// })

export function LoadApplicationList({ status }: LoadApplicationListProps) {
  const [createdLoanBatch, setCreatedLoanBatch] = useState<
    LoanApplicationBatch | undefined
  >()
  const [isCreatedLoanBatch, setIsCreatedLoanBatch] = useState<boolean>(false)
  const [createdBatchFile, setCreatedBatchFile] = useState<
    StorageObject | undefined
  >()
  const [isCreatedBatchFile, setIsCreatedBatchFile] = useState<boolean>(false)
  const [qualifier, setQualifier] = useState<
    'VPBankV1BatchFileCreator' | 'ACBV1BatchFileCreator'
  >('VPBankV1BatchFileCreator')

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  useEffect(() => {
    setCreatedBatchFile(undefined)
    setIsCreatedBatchFile(false)
  }, [qualifier])

  const [exportLoading, exportEntity] = useExportEntity()

  const [month, setMonth] = useState<Moment>(currentMoment())
  const [date, setDate] = useState<string>()

  const [
    loading,
    error,
    data,
    filter,
    page,
    setFilter,
    setPage,
    resetFilter,
    tableRefetch,
  ] = useTable<LoanApplicationQueryFilter>(GET_LOAN_APPLICATIONS, {
    filter: {
      statuses: [status],
      ...(month
        ? {
            createdDate: {
              from: month.startOf('month').toISOString(),
              to: month.endOf('month').toISOString(),
            },
          }
        : null),
    },
  })

  const {
    bankAccount,
    createdDate,
    employeeId,
    employmentName,
    loanApplicationCode,
    money,
    statuses,
  } = filter

  useEffect(() => {
    if (statuses && !statuses.includes(status)) {
      resetFilter()
      setDate('')
      setMonth(currentMoment())
    }
  }, [status])

  const companiesResponse = useQuery(GET_COMPANIES, {
    variables: { query: { limit: 100, offset: 0 } },
  })

  const [createLoanApplicationBatch] = useMutation(
    CREATE_LOAN_APPLICATION_BATCH,
  )

  const [createBatchFile] = useMutation(CREATE_BATCH_FILE)

  function onChangeMonth(date: any) {
    setMonth(date)
    setDate('')
    const time = moment(date, 'MM/YYYY')
    const from = time.startOf('month').toISOString()
    const to = time.endOf('month').toISOString()
    setFilter('createdDate', {
      from,
      to,
    })
  }

  function onChangeDate(date: any) {
    if (date === null) {
      setDate('')
      onChangeMonth(month)
      return
    }
    setDate(date)
    const from = date.startOf('day').toISOString()
    const to = date.endOf('day').toISOString()
    setFilter('createdDate', {
      from,
      to,
    })
  }

  useEffect(() => {
    onChangeMonth(month)
  }, [month])

  function onChangeMoney(fromAmount: any, toAmount: any) {
    const from = fromAmount ? `${fromAmount} ${CURRENCY_VND}` : undefined
    const to = toAmount ? `${toAmount} ${CURRENCY_VND}` : undefined
    setFilter('money', { from, to })
  }

  function onChangeCompany(value: string) {
    if (value) {
      setFilter('companyIds', [value])
    } else {
      setFilter('companyIds', [])
    }
  }

  async function handleExport(e: any) {
    exportEntity(ExportEntityType.LoanApplication, filter)
  }

  const handleOk = () => {
    setConfirmLoading(true)
    createLoanApplicationBatch({
      variables: {
        title: currentMoment().format('YYYY-MM-DD_HH:mm:ss_LoanBatch'),
        filter: {
          statuses: [LoanApplicationStatus.Approved],
        },
      },
    })
      .then((response) => {
        setIsCreatedLoanBatch(true)
        setCreatedLoanBatch(
          response.data.createLoanApplicationBatch.loanApplicationBatch,
        )
      })
      .catch((error) => {
        window.alert(error)
      })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setConfirmLoading(false)
    setCreatedLoanBatch(undefined)
    setIsCreatedLoanBatch(false)
    setCreatedBatchFile(undefined)
    setIsCreatedBatchFile(false)
    // refetch list
    tableRefetch()
  }

  function handleApprovedExport() {
    setIsModalVisible(true)
  }

  const columns = [
    {
      title: InputTableHeader({
        value: loanApplicationCode,
        key: 'loanApplicationCode',
        inputType: 'text',
        name: 'Loan Application Code',
        onPressEnter: setFilter,
      }),
      key: 'code',
      render: (props: LoanApplication) => (
        <div>
          <ButtonLink
            title={props.code}
            routing={`/vertical/loan-applications/${props.id}/detail?status=${props.status}`}
            size="middle"
          />
          -{' '}
          <Button
            size="small"
            type="primary"
            onClick={() => {
              navigator.clipboard.writeText(String(props.code))
            }}
          >
            <CopyOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: InputTableHeader({
        value: employeeId,
        key: 'employeeId',
        inputType: 'text',
        name: 'Employee Id',
        onPressEnter: setFilter,
      }),
      dataIndex: 'employment',
      key: 'employment.id',
      render: (employment: any) => {
        return employment.employeeId
      },
    },
    {
      title: InputTableHeader({
        value: employmentName,
        key: 'employmentName',
        inputType: 'text',
        name: 'Employment Name',
        onPressEnter: setFilter,
      }),
      dataIndex: 'employment',
      render: (employment: any) => {
        return employment.fullName
      },
    },
    {
      title: InputTableHeader({
        value: bankAccount,
        key: 'bankAccount',
        inputType: 'text',
        name: 'Disbursement Account',
        onPressEnter: setFilter,
      }),
      dataIndex: 'disbursementAccount',
      key: 'disbursementAccount',
    },
    {
      title: 'Bank',
      dataIndex: 'employment',
      render: (employment: any) => {
        return employment.bank ? employment.bank.name : ''
      },
    },
    {
      title: InputGroupTableHeader({
        fromValue: money ? money.from : undefined,
        toValue: money ? money.to : undefined,
        onPressEnter: onChangeMoney,
        name: 'Amount',
      }),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: DatePickerTableHeader({
        onChange: onChangeDate,
        name: 'Created_at',
        config: {
          value: date ? moment(date, 'DD/MM/YYYY') : '',
          disabledDate: (current: Moment) =>
            month && disabledDate(current, month),
        },
      }),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (createdDate: string) => <ColumnDate date={createdDate} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return <ColumnStatus status={status} />
      },
    },
    {
      title: 'Action',
      key: 'operation',
      with: '100',
      render: (props: LoanApplication) => {
        return (
          <ButtonLink
            title="Edit"
            routing={`/vertical/loan-applications/${props.id}/detail?status=${props.status}`}
            layout={LAYOUT_PATH}
          />
        )
      },
    },
  ]

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <Card>
      <div style={{ display: 'flex' }}>
        <div className="d-flex justify-content-between pb-3">
          {companiesResponse.data && (
            <div className="ant-column ant-form-item m-0">
              <div className="ant-form-item-label">
                <label>Company</label>
              </div>
              <div className="ant-form-item-control">
                <SelectCompany
                  companies={companiesResponse.data.companies.nodes}
                  onChange={onChangeCompany}
                />
              </div>
            </div>
          )}

          <div
            className="ant-column ant-form-item m-0"
            style={{ paddingLeft: 10 }}
          >
            <div className="ant-form-item-label">
              <label>Advance Month</label>
            </div>
            <div className="ant-form-item-control">
              <DatePicker
                style={{ width: 450 }}
                onChange={onChangeMonth}
                picker="month"
                defaultValue={month}
                format="MM/YYYY"
                disabled={!month && date ? true : false}
                value={month}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <h6
            style={{
              display: 'inline-block',
              paddingRight: '10px',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Tổng số giao dịch / Tổng số tiền:
          </h6>
          <h5
            style={{
              display: 'inline-block',
              color: '#41ba7a',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Total: .... //TODO
          </h5>
        </div>
        <div className="d-flex">
          {status === LoanApplicationStatus.LockForDisbursement && (
            <UpdateDisburse />
          )}

          <div
            className="ant-form ant-form-horizontal"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {status === LoanApplicationStatus.Approved &&
              data &&
              data.loanApplications.total > 0 && (
                <Button onClick={handleApprovedExport}>Export Txt</Button>
              )}
            <Modal
              title="Do you want to lock loan applications?"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              confirmLoading={confirmLoading}
              cancelText="Cancel"
              maskClosable={false}
              keyboard={false}
              cancelButtonProps={{
                // danger: true,
                style: {
                  display: confirmLoading ? 'none' : 'inline-block',
                },
              }}
            >
              <div>
                When clicked the OK button, all approved loan applications{' '}
                <span>will be locked</span>
              </div>
              {isCreatedLoanBatch && createdLoanBatch && (
                <div className="d-flex flex-column">
                  <h6 className="mb-2">Select Bank Format</h6>
                  <Radio.Group
                    onChange={(e) => setQualifier(e.target.value)}
                    value={qualifier}
                    style={{ marginBottom: 10 }}
                  >
                    <Radio value={'VPBankV1BatchFileCreator'}>VP Bank</Radio>
                    <Radio value={'ACBV1BatchFileCreator'}>ACB</Radio>
                  </Radio.Group>
                  <Button
                    style={{ marginBottom: 20 }}
                    type="primary"
                    icon={<DeliveredProcedureOutlined />}
                    onClick={() => {
                      createBatchFile({
                        variables: {
                          batchId: createdLoanBatch && createdLoanBatch.id,
                          filename:
                            qualifier === 'VPBankV1BatchFileCreator'
                              ? 'batch.txt'
                              : 'batch.xls',
                          qualifier,
                        },
                      })
                        .then((response) => {
                          setCreatedBatchFile(
                            response.data.createBatchFile.storageObject,
                          )
                          setIsCreatedBatchFile(true)
                        })
                        .catch((error) => {
                          window.alert(error)
                        })
                    }}
                  >
                    {`Generate ${
                      qualifier === 'VPBankV1BatchFileCreator'
                        ? 'VPBANK'
                        : 'ACB'
                    } file`}
                  </Button>
                  {isCreatedBatchFile && createdBatchFile && (
                    <a
                      href={`${createdBatchFile && createdBatchFile.uri}`}
                      target="_blank"
                      download={true}
                    >
                      {`Download ${
                        qualifier === 'VPBankV1BatchFileCreator'
                          ? 'VPBANK'
                          : 'ACB'
                      } file`}
                    </a>
                  )}
                </div>
              )}
            </Modal>
          </div>
          <Button
            onClick={handleExport}
            loading={exportLoading}
            disabled={exportLoading}
            style={{ marginLeft: 10 }}
          >
            Export
          </Button>
        </div>
      </div>

      <Table
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: () => (!data ? 0 : data.loanApplications.total),
          total: !data ? 0 : data.loanApplications.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={!data ? [] : data.loanApplications.nodes}
        columns={columns}
        loading={loading}
        size="middle"
        bordered={true}
        rowKey="id"
        scroll={{ x: 2200, y: 2000 }}
      />
    </Card>
  )
}
