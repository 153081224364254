import { LockOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Result,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from 'antd'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { sendNotification } from '../../utils/notification'
import { GET_COMPANY } from './CompanyDetail'

export interface IntergrationProps {
  id: string
}

const INVOKE_LAMBDA_FUNCTION = gql`
  mutation invokeLambdaFunction($function: String!, $body: JSON!) {
    bi_invokeFunction(function: $function, body: $body)
  }
`

const serviceKey = {
  office: '1Office',
  other: 'other',
}

const intergrationServices = [
  { name: '1Office', key: serviceKey.office, lamdaFunction: 'oneOfficeSync' },
  { name: 'Dịch vụ khác...', key: serviceKey.other, lamdaFunction: '' },
]

const Intergration: React.FC<IntergrationProps> = ({ id }) => {
  const [selected, setSelected] = useState('')
  const [tableData, setTableData] = useState([])

  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { id },
  })

  const [invokeLambdaFunc, { loading: invokeLoading }] = useMutation(
    INVOKE_LAMBDA_FUNCTION,
  )

  const titles: { [key: string]: string } = {
    fullName: 'Họ và tên',
    employeeId: 'Mã NV',
    gender: 'Giới tính',
    socialId: 'CMT/Căn cước/Hộ chiếu',
    socialIdIssuedDate: 'Ngày cấp',
    socialIdIssuedPlace: 'Nơi cấp',
    phoneNumber: 'Điện thoại',
    email: 'Email',
    departmentName: 'Phòng ban',
    salary: 'Lương vị trí',
    dob: 'Ngày sinh',
    startDate: 'Ngày vào',
    offDate: 'Ngày nghỉ việc',
    status: 'Active',
    bankAccount: 'Số tài khoản',
    bankAccountName: 'Tên tài khoản',
    bankName: 'Ngân hàng',
    // workplaceName: '',
  }

  const columns = Object.keys(titles).map((titleKey) => ({
    title: titles[titleKey],
    dataIndex: titleKey,
    key: titleKey,
    render: (val: string = '') => (
      <div style={{ padding: '10px 0' }}>{val}</div>
    ),
  }))

  const handleSync = async (values: { [key: string]: string }) => {
    try {
      const response = await invokeLambdaFunc({
        variables: {
          function: 'oneOfficeSync',
          body: {
            ...values,
            url:
              values.url.slice(-1) === '/'
                ? values.url.slice(0, -1)
                : values.url,
          },
        },
      })

      if (response.data?.bi_invokeFunction?.errorMessage) {
        sendNotification({
          type: 'error',
          message: 'Something wrong',
        })

        return
      }

      setTableData(response.data?.bi_invokeFunction?.employees)
      console.log(response)

      sendNotification({
        type: 'success',
        message: 'Tích hợp thành công',
      })
    } catch (error) {
      sendNotification({
        type: 'error',
        message: 'Something wrong',
      })
    }
  }

  const handleSelect = (value: string) => setSelected(value)

  const renderIntergrationForm = () => {
    if (!selected) {
      return null
    }

    if (selected === serviceKey.other) {
      return (
        <Result
          icon={<SmileOutlined style={{ color: '#08554E' }} />}
          title="To be continued..."
        />
      )
    }

    if (selected === serviceKey.office) {
      return (
        <Row
          style={{
            justifyContent: 'center',
            minHeight: 400,
          }}
        >
          <Divider />
          <Form
            initialValues={{
              url: '',
              username: '',
              password: '',
            }}
            onFinish={handleSync}
            style={{ width: '60%' }}
            name="advanced_search"
            className="ant-advanced-search-form"
            labelCol={{
              xs: 24,
              xl: 8,
              xxl: 6,
            }}
            wrapperCol={{
              xs: 24,
              xl: 16,
              xxl: 18,
            }}
            labelAlign="right"
          >
            <Form.Item
              name="url"
              rules={[
                {
                  required: true,
                  message: 'Không được để trống',
                },
              ]}
              label="Domain 1Office"
            >
              <Input
                placeholder="Nhập địa chỉ web 1Office"
                style={{ height: 36 }}
              />
            </Form.Item>

            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Không được để trống',
                },
              ]}
              label="Tên đăng nhập"
            >
              <Input placeholder="Nhập tên đăng nhập" style={{ height: 36 }} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Không được để trống',
                },
              ]}
              label="Mật khẩu"
            >
              <Input
                type="password"
                placeholder="Nhập mật khẩu"
                style={{ height: 36 }}
              />
            </Form.Item>

            <Row
              style={{
                justifyContent: 'flex-end',
              }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={invokeLoading}
                >
                  Đồng bộ dữ liệu
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Row>
      )
    }

    return null
  }

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  if (loading) {
    return <Spin />
  }

  return (
    <>
      <Row
        style={{
          alignItems: 'center',
        }}
      >
        <Typography.Title level={4} style={{ color: '#08554E' }}>
          Chọn dịch vụ:
        </Typography.Title>
        <Select
          showSearch
          style={{ width: 400, marginLeft: 16 }}
          placeholder="Tìm kiếm dịch vụ muốn tích hợp"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleSelect}
        >
          {intergrationServices.map((service) => (
            <Select.Option value={service.key}>{service.name}</Select.Option>
          ))}
        </Select>
      </Row>
      {renderIntergrationForm()}
      {tableData && tableData.length > 0 && (
        <>
          <Divider />
          <Row style={{ marginBottom: 20, marginTop: 20 }}>
            <Col
              span={16}
              style={{
                color: '#08554E',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                letterSpacing: 0.2,
              }}
            >
              Dữ liệu nhân viên của 1Office bạn muốn tích hợp
            </Col>
            <Col span={8} />
          </Row>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{ x: columns.length * 170 }}
            pagination={{
              showSizeChanger: false,
              pageSize: 16,
              position: ['bottomCenter'],
            }}
            size="middle"
            rowKey="employeeId"
            bordered
          />
        </>
      )}
    </>
  )
}

export default Intergration
