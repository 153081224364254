import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { Card } from 'antd'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { CreateCompanyPayload, CompanyStatus } from '../../graphql/models'
import { CustomInput } from '../form/CustomForm'
import { withRouter, useHistory } from 'react-router-dom'
import { CompanyForm } from './CompanyDetail'
import { COMPANY_UPDATE_INFO_SUCCESS } from '../constants'
import {
  useMessageAlert,
  MESSAGE_ALERT_STATUS,
  MessageAlert,
  MessageAlertType,
} from '../alert/MessageAlert'
import { CustomButton } from '../form/CustomButton'
import { UploadImage } from '../upload/Upload'

export const CREATE_COMPANY = gql`
  mutation createCompany($payload: CreateCompanyPayload!) {
    createCompany(payload: $payload) {
      company {
        id
        taxCode
        name
        shortName
        slug
        description
        status
        email
        address
        logo {
          uri
        }
        paymentDayInMonth
        paymentMonthAfterCutoff
        contractNo
        reportReceiverEmails
      }
    }
  }
`

const InnerFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  shortName: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  description: Yup.string(),
  taxCode: Yup.string(),
  status: Yup.string().required('Required'),
  contractCompanyName: Yup.string().trim(),
  contractCompanyTaxCode: Yup.string().trim(),
  contractCompanyAddress: Yup.string().trim(),
})

const CreateCompanyForm: React.FC<{ history: any }> = ({ history }) => {
  const [logoId, setLogoId] = useState<string>('')
  const [imageUrl, setImageUrl] = useState('')
  const [state, dispatch] = useMessageAlert()
  const [createCompany] = useMutation(CREATE_COMPANY)
  const { register, handleSubmit, errors } = useForm<CreateCompanyPayload>({
    validationSchema: InnerFormSchema,
  })
  const onSubmit = async ({
    name,
    shortName,
    slug,
    description,
    status,
    email,
    address,
    contractCompanyName,
    contractCompanyTaxCode,
    contractCompanyAddress,
    contractNo,
    paymentDayInMonth,
    paymentMonthAfterCutoff,
    reportReceiverEmails,
  }: CreateCompanyPayload) => {
    try {
      dispatch({ type: 'pending' })
      await createCompany({
        variables: {
          payload: {
            name,
            shortName,
            slug,
            description,
            status,
            logoId,
            ...(address ? { address } : null),
            ...(email ? { email } : null),
            ...(contractCompanyName ? { contractCompanyName } : null),
            ...(contractCompanyTaxCode ? { contractCompanyTaxCode } : null),
            ...(contractCompanyAddress ? { contractCompanyAddress } : null),
            ...(contractNo ? { contractNo } : null),
            ...(paymentDayInMonth ? { paymentDayInMonth } : null),
            ...(paymentMonthAfterCutoff ? { paymentMonthAfterCutoff } : null),
            ...(reportReceiverEmails
              ? { reportReceiverEmails: reportReceiverEmails.split(',') }
              : null),
          },
        },
      })
      dispatch({
        type: 'success',
        message: COMPANY_UPDATE_INFO_SUCCESS,
      })
      history.push('/vertical/companies')
    } catch (e: any) {
      dispatch({ type: 'error', message: e.message })
    }
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        {state.status === MESSAGE_ALERT_STATUS.Resolved && (
          <MessageAlert
            message={state.message}
            messageType={MessageAlertType.Success}
            config={{ closable: false }}
          />
        )}
        {state.status === MESSAGE_ALERT_STATUS.Rejected && (
          <MessageAlert
            message={state.message}
            messageType={MessageAlertType.Error}
            config={{ closable: false }}
          />
        )}

        <CustomInput
          name="Avatar"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <UploadImage
              dispatch={dispatch}
              setLogoId={setLogoId}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
            />
          )}
        </CustomInput>

        <CompanyForm errors={errors} register={register} />
        <CustomInput
          name="status"
          errors={errors}
          register={register}
          required
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <select {...props} name={name} ref={register}>
              {[CompanyStatus.Active, CompanyStatus.Inactive].map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
          )}
        </CustomInput>

        <CustomButton
          type="primary"
          value={
            state.status === MESSAGE_ALERT_STATUS.Pending
              ? 'Submiting...'
              : 'Submit'
          }
          disabled={state.status === MESSAGE_ALERT_STATUS.Pending}
        />
      </form>
    </Card>
  )
}

const CreateCompany: React.FC = () => {
  const history = useHistory()
  return <CreateCompanyForm history={history} />
}

export default withRouter(CreateCompany)
