import React, { useEffect } from 'react'
import { IPageProps, IPageData } from '../../interfaces/page-data'
import { useParams } from 'react-router-dom'
import { LoanApplicationBatchesList } from '../../components/LoanApplicationBatches/LoanApplicationBatchesList'

export const LoanApplicationBatchesPage: React.FC<IPageProps> = (props) => {
  const { onSetPage } = props
  const { slug } = useParams()

  const pageData: IPageData = {
    title: 'Loan Application Batches',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: '/vertical/dashboard',
      },
      {
        title: `Loan Application Batches`,
      },
    ],
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-12">
          <LoanApplicationBatchesList />
        </div>
      </div>
    </>
  )
}
