import React, { useState } from 'react'
import moment from 'moment'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Alert, Spin, Card, Row, Col, Button, Radio } from 'antd'
import { useForm } from 'react-hook-form'
import { LoanApplicationStatus, LoanApplication } from '../../graphql/models'
import { CustomInput } from '../form/CustomForm'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { EntityStatusTree } from '../interface'
import { ColumnStatus } from '../table/ColmnStatus'
import { LoanApplicationReject } from './LoanApplicationReject'
import { LoanApplicationApprove } from './LoanApplicationApprove'
import { LoanApplicationDisburse } from './LoanApplicationDisburse'
import { DATE_FORMAT } from '../constants'
import ButtonLink from '../ButtonLink'
import { formatMoney } from '../../utils/helper'

export const GET_LOAN_APPLICATION = gql`
  query loanApplication($id: ID!) {
    loanApplication(id: $id) {
      id
      code
      createdDate
      status
      disbursementBank {
        code
      }
      disbursementAccount
      amountValue
      amountCurrency
      feeValue
      feeCurrency
      actuallyReceivedValue
      actuallyReceivedCurrency
      rejectionMessage
      employment {
        id
        fullName
        company {
          name
        }
        user {
          fullName
        }
        currentBalance
        earnedBalance
        withdrawableBalance
        metadata
      }
    }
  }
`

type LoanApplicationDetailProps = RouteComponentProps & {
  id: string
}

export const LoanApplicationStatusTree: EntityStatusTree<LoanApplicationStatus> = {
  [LoanApplicationStatus.Approved]: {
    from: [LoanApplicationStatus.Pending],
    to: [LoanApplicationStatus.Closed],
  },
  [LoanApplicationStatus.Closed]: {
    from: [
      LoanApplicationStatus.Pending,
      LoanApplicationStatus.Approved,
      LoanApplicationStatus.Disbursed,
    ],
    to: [],
  },
  [LoanApplicationStatus.Defaulted]: {
    from: [LoanApplicationStatus.Disbursed],
    to: [],
  },
  [LoanApplicationStatus.LockForDisbursement]: {
    from: [LoanApplicationStatus.Approved],
    to: [LoanApplicationStatus.Disbursed, LoanApplicationStatus.Closed],
  },
  [LoanApplicationStatus.Disbursed]: {
    from: [LoanApplicationStatus.LockForDisbursement],
    to: [LoanApplicationStatus.Closed],
  },
  [LoanApplicationStatus.Pending]: {
    from: [],
    to: [LoanApplicationStatus.Approved, LoanApplicationStatus.Closed],
  },
  [LoanApplicationStatus.Repaid]: {
    from: [LoanApplicationStatus.Disbursed],
    to: [],
  },
}

function LoanApplicationDetailForm(props: LoanApplication & { history: any }) {
  const browserHistory = useHistory()
  const [status, setStatus] = useState<LoanApplicationStatus>()
  const [currentStatus, setCurrentStatus] = useState<LoanApplicationStatus>(
    props.status,
  )
  const { register, errors } = useForm<LoanApplication>({
    defaultValues: {
      ...props,
      createdDate: moment(props.createdDate).format(DATE_FORMAT),
      disbursementBank: {
        ...props.disbursementBank,
        code: props.disbursementBank.code.toUpperCase(),
      },
      amount: formatMoney(+props.amountValue),
      fee: formatMoney(+props.feeValue),
      actuallyReceived: formatMoney(+props.actuallyReceivedValue),
    },
  })

  const onClickStatus = (e: any) => {
    setStatus(e.target.value)
  }

  return (
    <Card>
      <div>
        <CustomInput
          name="code"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        <CustomInput
          name="employment.fullName"
          errors={errors}
          register={register}
          required={false}
          label="Employment"
          className="ant-input"
        >
          {() => {
            return (
              <ButtonLink
                title={props.employment.fullName}
                routing={`/vertical/employments/${props.employment.id}/detail`}
                style={{ fontSize: 'large' }}
              />
            )
          }}
        </CustomInput>

        <CustomInput
          name="employment.company.name"
          errors={errors}
          register={register}
          required={false}
          label="Company"
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        <CustomInput
          name="employment.metadata.departmentName"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Deparment"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        <CustomInput
          name="disbursementBank.code"
          errors={errors}
          register={register}
          required={false}
          label="Bank Name"
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        <CustomInput
          name="disbursementAccount"
          errors={errors}
          register={register}
          required={false}
          label="Bank Account"
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        <CustomInput
          name="amount"
          errors={errors}
          register={register}
          required={false}
          label="Amount"
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        <CustomInput
          name="fee"
          errors={errors}
          register={register}
          required={false}
          label="Fee"
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        <CustomInput
          name="actuallyReceived"
          errors={errors}
          register={register}
          required={false}
          label="Actual Receive"
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        <CustomInput
          name="createdDate"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} disabled />
          )}
        </CustomInput>

        {currentStatus === LoanApplicationStatus.Closed && (
          <CustomInput
            name="rejectionMessage"
            errors={errors}
            register={register}
            required={false}
            className="ant-input"
          >
            {(props: any, name, register: any) => (
              <input {...props} name={name} ref={register} disabled />
            )}
          </CustomInput>
        )}

        <CustomInput
          name="status"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <ColumnStatus status={currentStatus} />
          )}
        </CustomInput>

        <Row className="ant-form-item">
          <Col sm={8} />
          <Radio.Group onChange={onClickStatus}>
            {LoanApplicationStatusTree[currentStatus].to.map((item, index) => (
              <Radio value={item} key={index}>
                {item}
              </Radio>
            ))}
          </Radio.Group>
        </Row>

        {status && (
          <div className="ant-form-item">
            {status === LoanApplicationStatus.Closed && (
              <LoanApplicationReject
                id={props.id}
                status={status}
                setCurrentStatus={setCurrentStatus}
                history={props.history}
              />
            )}
            {status === LoanApplicationStatus.Approved && (
              <LoanApplicationApprove
                id={props.id}
                status={status}
                setCurrentStatus={setCurrentStatus}
                history={props.history}
              />
            )}
            {status === LoanApplicationStatus.Disbursed && (
              <LoanApplicationDisburse
                id={props.id}
                status={status}
                setCurrentStatus={setCurrentStatus}
                history={props.history}
              />
            )}
          </div>
        )}

        <Button
          type="primary"
          onClick={() => {
            browserHistory.goBack()
          }}
        >
          Back
        </Button>
      </div>
    </Card>
  )
}

function LoanApplicationDetail(props: LoanApplicationDetailProps) {
  const history = useHistory()
  const { id } = props
  const { loading, error, data } = useQuery(GET_LOAN_APPLICATION, {
    variables: { id },
  })

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  if (loading) {
    return <Spin />
  }

  if (!data) {
    return <Alert message="Data is empty" />
  }

  return (
    <LoanApplicationDetailForm {...data.loanApplication} history={history} />
  )
}

export default withRouter(LoanApplicationDetail)
