import { Button, Input, message, Modal, Radio, Upload, Alert } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { DraggerProps } from 'antd/lib/upload/Dragger'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import {
  ExecutionResult,
  MutationFunctionOptions,
  useMutation,
} from 'react-apollo'
import {
  BannerStatus,
  CreateBannerAssociationPayload,
} from '../../graphql/models'
import { useMessageAlert, MessageAlertType } from '../alert/MessageAlert'
import { SEND_OPTIONS } from '../Company/BannerAssocationDrawer'
import { UploadImage } from '../upload/Upload'

const { Dragger } = Upload
interface CreateBannerProps {
  onCreatedBanner?: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<ExecutionResult<any>>
  confirmMessage?: string
  createBannerAssociationPayload?: CreateBannerAssociationPayload
  sendOption?: SEND_OPTIONS
  company: string | null
}

export const CREATE_STORAGE_OBJECT = gql`
  mutation createStorageObject($payload: CreateStorageObjectPayload!) {
    createStorageObject(payload: $payload) {
      uploadUri
      storageObject {
        id
      }
    }
  }
`

export const CREATE_BANNER = gql`
  mutation createBanner($payload: CreateBannerPayload!) {
    createBanner(payload: $payload) {
      banner {
        id
        title
      }
    }
  }
`

const initState = {
  inputTitleValue: '',
  inputContentValue: '',
}
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}

export default ({
  onCreatedBanner,
  confirmMessage,
  createBannerAssociationPayload,
  sendOption,
  company,
}: CreateBannerProps) => {
  const [createBanner] = useMutation(CREATE_BANNER)
  const [bannerType, setBannerType] = useState<'Svg' | 'Image'>('Svg')
  const [image, setImage] = useState<RcFile | null>(null)
  const [base64Preview, setBase64Preview] = useState<string | null>(null)
  const [inputTitleValue, setInputTitleValue] = useState<string>(
    initState.inputTitleValue,
  )
  const [state, dispatch] = useMessageAlert()
  const [logoId, setLogoId] = useState<string>('')
  const [imageUrl, setImageUrl] = useState('')
  const getBase64 = (file: File) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setBase64Preview(String(reader.result))
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }
  const props: DraggerProps = {
    name: 'file',
    multiple: false,

    beforeUpload(file, fileList) {
      setImage(fileList[0])
      var fileReader = new FileReader()
      getBase64(file)
      return false
    },
    showUploadList: false,
  }

  const onChangeInputTitle = (e: any) => {
    setInputTitleValue(e.target.value)
  }

  const [inputContentValue, setInputContentValue] = useState<string>(
    initState.inputContentValue,
  )

  const onChangeInputContent = (e: any) => {
    setInputContentValue(e.target.value)
  }

  const onCreateBanner = () => {
    if (
      !createBannerAssociationPayload?.startTime ||
      !createBannerAssociationPayload?.endTime
    ) {
      Modal.warning({
        title: '*Required',
        content: 'Display Time must be entered',
      })
      return
    }
    if (
      inputTitleValue.length === 0 ||
      (inputContentValue.length === 0 && bannerType === 'Svg') ||
      (logoId.length === 0 && bannerType === 'Image')
    ) {
      Modal.warning({
        title: '*Required',
        content: 'Banner Title & Content must be entered',
      })
      return
    }
    Modal.confirm({
      title: 'Warning',
      content: confirmMessage || 'Are you sure to create banner?',
      onOk() {
        let params: any = {
          action: {
            noop: {
              noop: null,
            },
          },
          status: BannerStatus.Active,
          title: inputTitleValue,
        }
        if (bannerType === 'Svg') {
          params = {
            ...params,
            content: {
              svg: {
                content: inputContentValue.trim(),
              },
            },
            type: 'Svg',
          }
        } else {
          params = {
            ...params,
            contentImageId: logoId,
            type: 'Image',
          }
        }
        createBanner({
          variables: {
            payload: params,
          },
        })
          .then((banner) => {
            console.log(
              'created banner: ',
              banner?.data?.createBanner?.banner.id,
            )
            message.success('Create banner successfully')
            if (
              onCreatedBanner &&
              typeof onCreatedBanner === 'function' &&
              createBannerAssociationPayload
            ) {
              onCreatedBanner({
                variables: {
                  payload: {
                    ...createBannerAssociationPayload,
                    bannerId: banner?.data?.createBanner?.banner.id,
                  },
                },
              })
                .then(() => {
                  message.success('Create association successfully')
                })
                .catch(() => {
                  message.error('Failed to create association')
                })
            }
          })
          .catch(() => {
            message.error('Failed to create banner')
          })
      },
      onCancel() {
        console.log('Cancel Create Banner')
      },
    })
  }

  const resetToInitState = () => {
    // console.log('reset to init state: ', initState)
    setInputTitleValue(initState.inputTitleValue)
    setInputContentValue(initState.inputContentValue)
  }

  useEffect(() => {
    resetToInitState()
  }, [sendOption, company])
  console.log(image)
  return (
    <>
      <h6 className="label" style={{ marginBottom: 10 }}>
        Title:
      </h6>
      <Input
        value={inputTitleValue}
        onChange={onChangeInputTitle}
        name="title"
        placeholder="Title"
      />
      <h6 style={{ marginBottom: 10 }}>Banner Type</h6>
      <Radio.Group
        value={bannerType}
        onChange={(val) => setBannerType(val.target.value)}
      >
        <Radio style={radioStyle} value={'Svg'}>
          SVG
        </Radio>
        <Radio style={radioStyle} value={'Image'}>
          PNG
        </Radio>
      </Radio.Group>
      {bannerType === 'Svg' ? (
        <>
          <h6 className="label" style={{ marginBottom: 10 }}>
            Content:
          </h6>
          <Input.TextArea
            rows={3}
            style={{ height: 'auto' }}
            value={inputContentValue}
            onChange={onChangeInputContent}
          ></Input.TextArea>
        </>
      ) : (
        <>
          <UploadImage
            dispatch={dispatch}
            setLogoId={setLogoId}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            ratio={315 / 150}
          ></UploadImage>

          {state.messageType === MessageAlertType.Error && (
            <Alert message={state.message} type="error" />
          )}
        </>
      )}
      <h6 className="label" style={{ marginBottom: 10 }}>
        Preview:
      </h6>
      {bannerType === 'Svg' ? (
        <div dangerouslySetInnerHTML={{ __html: inputContentValue }} />
      ) : (
        <>
          {base64Preview && (
            <div style={{ width: '100%' }}>
              <img src={base64Preview}></img>
            </div>
          )}
        </>
      )}
      <Button type="primary" onClick={onCreateBanner} style={{ marginTop: 10 }}>
        Create
      </Button>
    </>
  )
}
