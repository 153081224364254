import { useQuery } from '@apollo/react-hooks'
import { Card, Col, Row, Spin, Timeline } from 'antd'
import gql from 'graphql-tag'
import _ from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { LeaveRequestToolDetail } from '../../components/LeaveRequestTool/LeaveRequestToolDetail/LeaveRequestToolDetail'
import { Employment, LeaveRequestLog } from '../../graphql/models'
import { IPageData, IPageProps } from '../../interfaces/page-data'

export const GET_EMPLOYMENT = gql`
  query employment($id: ID!) {
    employment(id: $id) {
      fullName
      dob
      employeeWarningStatus
      employeeWarningSource
      user {
        phoneNumber
      }
      company {
        name
      }
      id
      type
      status
      bank {
        name
      }

      leaveRequest {
        id
        logs {
          id
          createdDate
          lastModifiedDate
          changes {
            changes {
              from
              to
              field
            }
          }
        }
        note
      }
      bankAccount
      employeeId
      currentBalance
      earnedBalance
      withdrawableBalance
      metadata
    }
  }
`

export const LeaveRequestToolDetailPage: React.FC<IPageProps> = (props) => {
  const { onSetPage } = props
  const pageData: IPageData = {
    title: 'Leave Request Page',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: '/vertical/dashboard',
      },
      {
        title: 'Leave request tool detail',
      },
    ],
  }

  useEffect(() => onSetPage(pageData), [])
  const { id } = useParams<{ id: string }>()
  const { loading, error, data, refetch } = useQuery(GET_EMPLOYMENT, {
    variables: { id },
  })
  const formattedLogs = useMemo(() => {
    if (loading || !data) {
      return []
    }
    const logs = (data.employment as Employment)?.leaveRequest?.logs
      ?.filter(
        (log) =>
          log.changes.changes.filter(
            (change) =>
              change.field === 'Note' ||
              change.field === 'ExecutionStatus' ||
              change.field === 'ResignStatus' ||
              change.field === 'ResignStatusChangeSource',
          ).length > 0,
      )
      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))

    return _.groupBy(logs, (i) => moment(i.createdDate).format('DD/MM/YYYY'))
  }, [data])

  const renderLog = (log: LeaveRequestLog) => {
    let content = null

    if (log.changes.changes.find((change) => change.field === 'Note')) {
      content = (
        <>
          Ghi chú của nhân viên {data.employment.fullName} đã được cập nhật: "
          {log.changes.changes[0].to}"
        </>
      )
    }
    if (
      log.changes.changes.find((change) => change.field === 'ExecutionStatus')
    ) {
      const executionChange = log.changes.changes.find(
        (change) => change.field === 'ExecutionStatus',
      )
      const isResignChange = log.changes.changes.find(
        (change) => change.field === 'ResignStatusChangeSource',
      )

      if (!isResignChange) {
        switch (executionChange?.to) {
          case 'Normal':
            content = (
              <>
                {data.employment.fullName} đã được cập nhật trạng thái "Đang làm
                việc"
              </>
            )
            break
          case 'Warning':
            content = (
              <>
                {data.employment.fullName} đã được chuyển trạnng thái "Cần xử
                lý"
              </>
            )

            break
          case 'InProgress':
            content = <>{data.employment.fullName} đã bắt đầu tất toán</>

            break
          case 'Settled':
            content = (
              <>{data.employment.fullName} đã được tất toán thành công</>
            )
            break
          case 'Archived':
            content = <>{data.employment.fullName} đã được lưu trữ</>

            break
          case 'Finished':
            content = (
              <>{data.employment.fullName} đã được tất toán thành công</>
            )
            break
          default:
            break
        }
      } else {
        switch (isResignChange?.to) {
          case 'CanceledSettlement':
            content = (
              <>{data.employment.fullName} đã được huỷ tất hoạt tất toán</>
            )
            break

          default:
            break
        }
      }
    }
    if (
      log.changes.changes.find((change) => change.field === 'ArchivedDueDate')
    ) {
      const archiveDate = log.changes.changes.find(
        (change) => change.field === 'ArchivedDueDate',
      )

      content = (
        <>
          {data.employment.fullName} đã được lưu trữ tới ngày{' '}
          {archiveDate && moment(archiveDate.to).format('DD/MM/YYYY')}
        </>
      )
    }

    if (
      log.changes.changes.find(
        (change) => change.field === 'ResignStatusChangeSource',
      )
    ) {
      content = <>{data.employment.fullName} đã được kích hoạt báo nghỉ</>
    }
    return (
      <p>
        {moment(log.createdDate).format('HH:ss') + ' - '} {content}
      </p>
    )
  }
  return (
    <>
      {loading || !data ? (
        <Spin size="large"></Spin>
      ) : (
        <Row>
          <Col xs={24} sm={16}>
            <Card>
              <LeaveRequestToolDetail {...data.employment} refetch={refetch} />
            </Card>
          </Col>
          <Col xs={24} sm={8} style={{ paddingLeft: '10px' }}>
            <Card>
              <div style={{ marginTop: '16px' }}>
                <h2>Lịch sử thao tác</h2>
              </div>

              {Object.entries(formattedLogs).map(([key, value]) => {
                return (
                  <Fragment key={key}>
                    <h4>{key}</h4>
                    <Timeline
                      style={{ paddingBottom: 0, marginBottom: '-20px' }}
                    >
                      {value
                        .sort(
                          (a, b) =>
                            new Date(b.createdDate) - new Date(a.createdDate),
                        )
                        .map((log) => {
                          return (
                            <Timeline.Item color="red" key={log.id}>
                              {renderLog(log)}
                            </Timeline.Item>
                          )
                        })}
                    </Timeline>
                  </Fragment>
                )
              })}
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}
