import { Checkbox, Modal, Select, Tag, Typography } from 'antd'
import { Col } from 'antd'
import { Button, Card, Form, Input, Row } from 'antd'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { FC } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import { CircleLoader } from 'react-spinners'

interface SendOtpProps {}

const GET_PROFILE_FROM_PHONE = gql`
  query getProfileFromPhone($phoneNumber: PhoneNumber!) {
    profileFromPhone(phoneNumber: $phoneNumber) {
      employeeId
      fullName
    }
  }
`

const SEND_OTP = gql`
  mutation sendOtp($phone: PhoneNumber!, $type: String) {
    sendOtpPortal(phone: $phone, type: $type)
  }
`

const otpType = [
  { name: 'Voice', key: 'voice' },
  { name: 'SMS', key: 'sms' },
]

const convertPhoneNumber = (phone: string) => `+84${phone.substring(1)}`

const SendOtp: FC<SendOtpProps> = () => {
  const [resendOtp, setResendOtp] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const [form] = Form.useForm()

  const [getProfile, { loading, error, data }] = useLazyQuery(
    GET_PROFILE_FROM_PHONE,
  )

  const [
    sendOtp,
    { loading: submitLoading, error: submitError, data: submitData },
  ] = useMutation(SEND_OTP)

  const onSubmit = async (values: { [key: string]: string }) => {
    const response = await sendOtp({
      variables: {
        ...values,
        phone: convertPhoneNumber(values.phone),
        type: resendOtp ? values.type : '',
      },
    })

    // console.log(response)
    setModalVisible(true)
  }

  const handleCloseModal = () => setModalVisible(false)

  // const handleGetProfile = (e: any) => {
  //   getProfile({ variables: { phoneNumber: 'bulldog' } })
  //   console.log(e.target.value, 'xxx')
  // }

  return (
    <Card
      style={{
        padding: 30,
        minHeight: '80vh',
      }}
    >
      <Form
        initialValues={{
          phone: '',
          type: '',
        }}
        onFinish={onSubmit}
        style={{
          width: '60%',
        }}
        // labelCol={{
        //   xs: 24,
        //   xl: 8,
        //   xxl: 6,
        // }}
        // wrapperCol={{
        //   xs: 24,
        //   xl: 16,
        //   xxl: 18,
        // }}
      >
        <div style={{ minHeight: 120 }}>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: 'Không được để trống',
              },
              {
                pattern: new RegExp(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g),
                message: 'Phải là số điện thoại',
              },
            ]}
            label="Telephone"
          >
            <Input
              placeholder="Nhập địa số điện thoại nhận OTP"
              // onChange={handleGetProfile}
              style={{ height: 36 }}
            />
          </Form.Item>

          <Row gutter={[24, 16]}>
            <Col span={3}>
              <Checkbox
                value={resendOtp}
                onChange={() => {
                  setResendOtp(!resendOtp)
                }}
              >
                Resend OTP
              </Checkbox>
            </Col>

            <Col span={21}>
              <Form.Item name="type" rules={[]} label="Type">
                <Select
                  disabled={!resendOtp}
                  style={{ width: '100%' }}
                  placeholder="Chọn hình thức gửi OTP"
                >
                  {otpType.map((type) => (
                    <Select.Option value={type.key} key={type.key}>
                      {type.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Row
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Send OTP
            </Button>
          </Form.Item>
        </Row>
      </Form>

      <Modal
        title="Your OTP is....."
        visible={modalVisible}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        footer={[
          <Button type="primary" onClick={handleCloseModal}>
            Okey go back!
          </Button>,
        ]}
      >
        <Row gutter={[24, 16]}>
          <Col span={20}>
            <p
              style={{
                fontSize: 40,
                fontWeight: 800,
                color: '#08554E',
              }}
            >
              {submitData?.sendOtpPortal}
            </p>

            <p>Wait for 1 minutes to resend pls ^^</p>
          </Col>
          <Col span={4}>
            <CircleLoader color="#08554E" />
          </Col>
        </Row>
      </Modal>
    </Card>
  )
}

export default SendOtp
