import React, { useState, useEffect, useRef } from 'react'
import gql from 'graphql-tag'
import { Moment } from 'moment'

import {
  Employment,
  Bank,
  EmploymentStatus,
  EmploymentQueryFilter,
  LoanApplicationConnection,
  AttendanceConnection,
  Money,
  LoanApplicationStatus,
} from '../../graphql/models'
import { Table, Card, Button, DatePicker, Modal, Slider, Spin } from 'antd'
import ButtonLink from '../ButtonLink'
import { InputTableHeader, SelectTableHeader } from '../table/CustomTable'
import { useTable, DEFAULT_PAGE_SIZE } from '../hooks/useTable'
import { useQuery } from 'react-apollo'
import {
  formatMoney,
  getDates,
  notify,
  currentMoment,
} from '../../utils/helper'
import { ColumnRegistration } from '../table/ColumnRegistration'
import { GET_COMPANIES } from '../Company/CompanyList'
import { SelectCompany } from '../input/SelectCompany'

// @ts-ignore
const {
  batchCreateAttendances,
  createCompanyEmployments,
} = require('nano-mock-data')

export const GET_EMPLOYMENTS = gql`
  query employments(
    $query: EmploymentQuery
    $attendancesFilter: AttendanceQueryFilter
    $loanApplicationsQuery: LoanApplicationQuery
  ) {
    employments(query: $query) {
      total
      nodes {
        id
        employeeId
        bank {
          name
          alias
        }
        attendances(query: { filter: $attendancesFilter }) {
          total
        }
        loanApplications(query: $loanApplicationsQuery) {
          total
          sumAmounts
        }
        status
        salary
        isLinked
        fullName
        bankAccount
        currentBalance
        earnedBalance
        withdrawableBalance
        metadata
      }
      total
      limit
      offset
    }
  }
`

export const GET_EMPLOYMENTS_VARIABLES = {
  attendancesFilter: {
    submittedAt: {
      from: currentMoment().startOf('month').toISOString(),
      to: currentMoment().endOf('day').toISOString(),
    },
  },
  loanApplicationsQuery: {
    filter: {
      statuses: [LoanApplicationStatus.Disbursed],
      createdDate: {
        from: currentMoment().startOf('month').toISOString(),
        to: currentMoment().endOf('month').toISOString(),
      },
    },
  },
}

export type GenerateEmploymentListProps = {}

export function GenerateEmploymentList() {
  const [
    loading,
    error,
    data,
    filter,
    page,
    setFilter,
    setPage,
    resetFilter,
    queryVariables,
    setNewQueryVariables,
    refetch,
  ] = useTable<EmploymentQueryFilter>(
    GET_EMPLOYMENTS,
    {
      filter: {
        statuses: [EmploymentStatus.Active],
      },
    },
    GET_EMPLOYMENTS_VARIABLES,
  )

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const hasSelected = selectedRowKeys.length > 0
  useEffect(() => {
    setSelectedRowKeys([])
  }, [data])

  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [dates, setDates] = useState<Moment[]>([])

  useEffect(() => {
    setDates([])
  }, [isModalVisible])

  const companiesResponse = useQuery(GET_COMPANIES, {
    variables: { query: { limit: 100, offset: 0 } },
  })

  const [companyId, setCompanyId] = useState()

  const [eeNumber, setEeNumber] = useState(5)
  const [salaryRange, setSalaryRange] = useState([50, 80])
  const [isGenerating, setGenerating] = useState<boolean>(false)

  const {
    bankAccountSearch,
    bankSearch,
    employeeIdSearch,
    fullNameSearch,
    isLinked,
  } = filter

  const columns = [
    {
      title: InputTableHeader({
        value: employeeIdSearch,
        key: 'employeeIdSearch',
        inputType: 'text',
        name: 'Employee Id',
        onPressEnter: setFilter,
      }),
      key: 'employeeId',
      dataIndex: 'employeeId',
    },
    {
      title: InputTableHeader({
        value: fullNameSearch,
        key: 'fullNameSearch',
        inputType: 'text',
        name: 'Name',
        onPressEnter: setFilter,
      }),
      key: 'fullName',
      render: (props: Employment) => (
        <ButtonLink
          title={props.fullName}
          routing={`employments/${props.id}/detail`}
          size="middle"
          style={{ padding: 0, whiteSpace: 'nowrap' }}
        />
      ),
    },
    {
      title: 'Department',
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata: any) => {
        if (metadata) {
          return metadata.departmentName || ''
        }
      },
      width: 250,
    },
    {
      title: 'Approved Attendances',
      dataIndex: 'attendances',
      key: 'attendances',
      render: (attendances: AttendanceConnection) => {
        return attendances.total
      },
      width: 100,
    },
    {
      title: 'Salary',
      dataIndex: 'salary',
      key: 'salary',
      render: (salary: Money) => {
        return formatMoney(salary)
      },
      width: 120,
    },
    {
      title: 'Earned Balance',
      dataIndex: 'earnedBalance',
      key: 'earnedBalance',
      render: (earnedBalance: Money) => {
        return formatMoney(earnedBalance)
      },
      width: 120,
    },
    {
      title: 'Total Advanced Amount',
      dataIndex: 'loanApplications',
      key: 'loanApplications',
      render: (loanApplications: LoanApplicationConnection) => {
        return formatMoney(loanApplications.sumAmounts[0])
      },
      width: 120,
    },
    {
      title: 'Current Balance',
      dataIndex: 'currentBalance',
      key: 'currentBalance',
      render: (currentBalance: Money) => {
        return formatMoney(currentBalance)
      },
      width: 120,
    },
    {
      title: 'Withdrawable Balance',
      dataIndex: 'withdrawableBalance',
      key: 'withdrawableBalance',
      render: (withdrawableBalance: Money) => {
        return formatMoney(withdrawableBalance)
      },
      width: 120,
    },
    {
      title: InputTableHeader({
        value: bankSearch,
        key: 'bankSearch',
        inputType: 'text',
        name: 'Bank',
        onPressEnter: setFilter,
      }),
      dataIndex: 'bank',
      render: (bank: Bank) => {
        return bank && bank.alias
      },
      // width: 200,
    },
    {
      title: InputTableHeader({
        value: bankAccountSearch,
        key: 'bankAccountSearch',
        inputType: 'text',
        name: 'Bank Account',
        onPressEnter: setFilter,
      }),
      dataIndex: 'bankAccount',
      key: 'bankAccount',
      // width: 250,
    },
    {
      title: SelectTableHeader({
        value: isLinked,
        key: 'isLinked',
        options: [
          { value: true, text: 'Registered' },
          { value: false, text: 'Not Registered' },
        ],
        name: 'Registration Status',
        onChange: setFilter,
      }),
      dataIndex: 'isLinked',
      key: 'isLinked',
      render: (isLinked: boolean) => {
        return <ColumnRegistration isLinked={isLinked} />
      },
    },
  ]

  function onChangeCompany(value: string) {
    if (value) {
      setFilter('companyIds', [value])
    } else {
      setFilter('companyIds', [])
    }
  }

  return (
    <>
      {/* <div className="col-4">
        <Card title={`Generate Employees`}>
          <Spin spinning={isGenerating ? true : false}>
            {companiesResponse.data && (
              <SelectCompany
                companies={companiesResponse.data.companies.nodes}
                onChange={setCompanyId}
                width="100%"
              />
            )}
            {companyId && (
              <div className="d-flex flex-column">
                <div className="d-flex flex-row">
                  <h5 className="pr-3" style={{ color: '#41ba7a' }}>
                    Number of Employees:{' '}
                  </h5>
                  <h5>{`${eeNumber} employees`}</h5>
                </div>
                <Slider
                  marks={{
                    10: '10',
                    500: '500',
                  }}
                  step={10}
                  min={10}
                  max={500}
                  defaultValue={10}
                  onAfterChange={(value: any) => setEeNumber(value)}
                />
                <div className="d-flex flex-row">
                  <h5 className="pr-3" style={{ color: '#41ba7a' }}>
                    Salary Range:{' '}
                  </h5>
                  <h5>{`Từ ${Number(salaryRange[0] * 0.1).toFixed(
                    1,
                  )} - ${Number(salaryRange[1] * 0.1).toFixed(1)} triệu`}</h5>
                </div>
                <Slider
                  range={true}
                  marks={{ 10: '1tr', 150: '15tr' }}
                  step={1}
                  min={10}
                  max={150}
                  defaultValue={[50, 80]}
                  onAfterChange={(value) => setSalaryRange(value as any)}
                  tipFormatter={(value) =>
                    value ? `${Number(value * 0.1).toFixed(1)} triệu` : null
                  }
                />
                <Button
                  type="primary"
                  onClick={() => {
                    setGenerating(true)
                    createCompanyEmployments(
                      companyId,
                      eeNumber,
                      salaryRange[0],
                      salaryRange[1],
                    )
                      .then(() => {
                        setGenerating(false)
                        notify(
                          'Generate successfully',
                          `Generated ${eeNumber} employees`,
                        )
                        setCompanyId(undefined)
                        refetch()
                      })
                      .catch((error: any) => {
                        setGenerating(false)
                        notify('Generated Error', `Can not generate employees`)
                        setCompanyId(undefined)
                        refetch()
                      })
                  }}
                >
                  Generate
                </Button>
              </div>
            )}
          </Spin>
        </Card>
      </div> */}
      <div className="col-12">
        <Card>
          <div className="row d-flex justify-content-between mb-3">
            {companiesResponse.data && (
              <div className="ant-row ant-form-item m-0">
                <div className="ant-form-item-label">
                  <label>Company</label>
                </div>
                <div className="ant-form-item-control">
                  <SelectCompany
                    companies={companiesResponse.data.companies.nodes}
                    onChange={onChangeCompany}
                  />
                </div>
              </div>
            )}
            {hasSelected && (
              <div className="d-flex flex-column justify-content-end">
                <Button
                  onClick={() => {
                    setModalVisible(!isModalVisible)
                  }}
                  type="primary"
                >
                  Update Attendances
                  {hasSelected ? ` for ${selectedRowKeys.length} employee` : ''}
                </Button>
                <span style={{ marginLeft: 8 }} />
              </div>
            )}

            <Modal
              title="Update Attendances"
              visible={isModalVisible}
              onOk={() => {
                setConfirmLoading(true)
                batchCreateAttendances(
                  selectedRowKeys[0],
                  getDates(dates[0], dates[1]),
                )
                  .then(() => {
                    setConfirmLoading(false)
                    setModalVisible(false)
                    refetch()
                  })
                  .catch((error: any) => {
                    console.info(error)
                    setConfirmLoading(false)
                    setModalVisible(false)
                    refetch()
                  })
              }}
              onCancel={() => {
                setModalVisible(false)
              }}
              confirmLoading={confirmLoading}
            >
              Add more attendances:{' '}
              <DatePicker.RangePicker
                value={dates as any}
                onCalendarChange={(value) => {
                  setDates(value as any)
                }}
              />
            </Modal>

            <Table
              pagination={{
                pageSize: DEFAULT_PAGE_SIZE,
                showSizeChanger: false,
                showTotal: () => (!data ? 0 : data.employments.total),
                total: !data ? 0 : data.employments.total,
                onChange: (page: number, pageSize?: number) => {
                  setPage(page)
                },
                current: page,
              }}
              dataSource={!data ? [] : data.employments.nodes}
              columns={columns}
              loading={loading}
              size="middle"
              bordered={true}
              rowKey="id"
              scroll={{ x: 1800 }}
              rowSelection={{
                hideSelectAll: true,
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                  if (selectedRowKeys.length === 0) {
                    setSelectedRowKeys(selectedRowKeys as any)
                    return
                  }
                  setSelectedRowKeys([
                    selectedRowKeys[selectedRowKeys.length - 1],
                  ])
                },
              }}
            />
          </div>
        </Card>
      </div>
    </>
  )
}
