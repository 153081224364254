import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { Button } from 'antd'
import { ButtonType } from 'antd/lib/button'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

interface ButtonLinkProps {
  routing: string
  title: string
  type?: ButtonType
  layout?: string
  size?: SizeType
  style?: object
}

const ButtonLink: React.FC<ButtonLinkProps | any> = (props) => {
  const { title, routing, type, size, style } = props
  const btnType = type ? type : 'link'
  return (
    <NavLink to={`${routing}`}>
      <Button
        type={btnType}
        size={size ? size : 'middle'}
        style={{ whiteSpace: 'nowrap', padding: 0, ...style }}
      >
        {title}
      </Button>
    </NavLink>
  )
}

export default withRouter(ButtonLink)
