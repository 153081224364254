import React, { useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Button } from 'antd'
import { UploadImport } from '../upload/Upload'
import {
  useMessageAlert,
  MESSAGE_ALERT_STATUS,
  MessageAlert,
  MessageAlertType,
} from '../alert/MessageAlert'

export function UpdateDisburse() {
  const [visible, setVisible] = useState(false)
  const [state, dispatch] = useMessageAlert()

  function hideModal() {
    setVisible(false)
  }

  function showModal() {
    setVisible(true)
  }

  return (
    <div>
      <Button onClick={showModal}>Import</Button>
      <Modal
        title="Update Disburse"
        visible={visible}
        onCancel={hideModal}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
      >
        {state.status === MESSAGE_ALERT_STATUS.Resolved && (
          <MessageAlert
            message={state.message}
            messageType={MessageAlertType.Success}
            config={{ closable: false }}
          />
        )}
        {state.status === MESSAGE_ALERT_STATUS.Rejected && (
          <MessageAlert
            message={state.message}
            messageType={MessageAlertType.Error}
            config={{ closable: false }}
          />
        )}
        <UploadImport dispatch={dispatch} />
      </Modal>
    </div>
  )
}
