import React, { useEffect, useContext } from 'react'

import { IPageProps, IPageData } from '../../interfaces/page-data'
import { KeycloakContext } from '../../context/KeycloakContext'
import EditableBannerList from '../../components/Banner/EditableBannerList'

const BannerPage: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage, getPageData } = props

  const pageData: IPageData = {
    title: 'Users',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'Banner',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <EditableBannerList />
      </div>
    </div>
  )
}

export default BannerPage
