import React, { useState, useEffect, useMemo } from 'react'
import gql from 'graphql-tag'

import {
  Company,
  CompanyStatus,
  CompanyQueryFilter,
  CreateCompanyPayload,
} from '../../graphql/models'
import { Table, Alert, Card, Button, Modal, Input } from 'antd'
import * as Yup from 'yup'
import ButtonLink from '../ButtonLink'
import { ColumnStatus } from '../table/ColmnStatus'
import { InputTableHeader, SelectTableHeader } from '../table/CustomTable'

import { useTable, DEFAULT_PAGE_SIZE } from '../hooks/useTable'
import { notify } from '../../utils/helper'
import {
  useMessageAlert,
  MESSAGE_ALERT_STATUS,
  MessageAlert,
  MessageAlertType,
} from '../../components/alert/MessageAlert'
import { CustomInput } from '../../components/form/CustomForm'
import { UploadImage } from '../../components/upload/Upload'
import { CustomButton } from '../../components/form/CustomButton'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-apollo'

export const CREATE_COMPANY = gql`
  mutation createCompany($payload: CreateCompanyPayload!) {
    createCompany(payload: $payload) {
      company {
        id
        taxCode
        name
        slug
        description
        status
        email
        address
        logo {
          uri
        }
      }
    }
  }
`

export const GET_COMPANIES = gql`
  query companies($query: CompanyQuery) {
    companies(query: $query) {
      total
      nodes {
        id
        lastModifiedDate
        name
        createdDate
        taxCode
        slug
        status
        logo {
          uri
        }
        email
        address
      }
      total
      limit
      offset
    }
  }
`

const DANGEROUSLY_DELETE_COMPANY = gql`
  mutation dangerouslyDeleteCompany($id: ID!) {
    dangerouslyDeleteCompany(id: $id)
  }
`

const InnerFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  slug: Yup.string().required('Required'),
  description: Yup.string(),
  taxCode: Yup.string(),
  status: Yup.string().required('Required'),
})

export function GenerateCompanyList() {
  const [
    loading,
    error,
    data,
    filter,
    page,
    setFilter,
    setPage,
    resetFilter,
    queryVariables,
    setNewQueryVariables,
    refetch,
  ] = useTable<CompanyQueryFilter>(GET_COMPANIES)
  const {
    slugSearch,
    nameSearch,
    emailSearch,
    addressSearch,
    statuses,
  } = filter

  const [dangerouslyDeleteCompany] = useMutation(DANGEROUSLY_DELETE_COMPANY)

  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const [inputConfirm, setInputConfirm] = useState<string>('')
  useEffect(() => {
    setInputConfirm('')
    setConfirmLoading(false)
  }, [isModalVisible])

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const { hasSelected } = useMemo(
    () => ({
      hasSelected: selectedRowKeys.length > 0,
    }),
    [selectedRowKeys],
  )
  useEffect(() => {
    setSelectedRowKeys([])
  }, [data])

  const [logoId, setLogoId] = useState<string>('')
  const [imageUrl, setImageUrl] = useState('')
  const [state, dispatch] = useMessageAlert()
  const [createCompany] = useMutation(CREATE_COMPANY)
  const { register, handleSubmit, errors, reset } = useForm<
    CreateCompanyPayload
  >({
    validationSchema: InnerFormSchema,
  })

  const onSubmit = async ({
    name,
    slug,
    description,
    status,
    email,
    address,
  }: CreateCompanyPayload) => {
    try {
      await createCompany({
        variables: {
          payload: { name, slug, description, status, logoId, email, address },
        },
      })
      setLogoId('')
      setImageUrl('')
      reset()
      refetch()
    } catch (e) {
      refetch()
      notify('Error', e.message, 'error')
    }
  }

  const columns = [
    {
      title: InputTableHeader({
        value: slugSearch,
        key: 'slugSearch',
        inputType: 'text',
        name: 'Slug',
        onPressEnter: setFilter,
      }),
      key: 'slug',
      dataIndex: 'slug',
    },
    {
      title: InputTableHeader({
        value: nameSearch,
        key: 'nameSearch',
        inputType: 'text',
        name: 'Name',
        onPressEnter: setFilter,
      }),
      key: 'name',
      render: (props: Company) => (
        <ButtonLink
          title={props.name}
          routing={`/vertical/companies/${props.id}/detail`}
          size="middle"
        />
      ),
    },
    {
      title: InputTableHeader({
        value: emailSearch,
        key: 'emailSearch',
        inputType: 'text',
        name: 'Email',
        onPressEnter: setFilter,
      }),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: InputTableHeader({
        value: addressSearch,
        key: 'addressSearch',
        inputType: 'text',
        name: 'Address',
        onPressEnter: setFilter,
      }),
      key: 'address',
      dataIndex: 'address',
    },
    {
      title: SelectTableHeader({
        value: statuses,
        key: 'statuses',
        options: [
          { value: CompanyStatus.Active, text: 'Active' },
          { value: CompanyStatus.Inactive, text: 'Inactive' },
        ],
        name: 'Status',
        onChange: setFilter,
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return <ColumnStatus status={status} />
      },
    },
  ]

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <div
      className="row"
      style={{
        display: 'flex',
        alignItems: 'stretch',
      }}
    >
      <div className="col-4 d-flex">
        <Card title="Create Company" style={{ flexGrow: 1 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {state.status === MESSAGE_ALERT_STATUS.Resolved && (
              <MessageAlert
                message={state.message}
                messageType={MessageAlertType.Success}
                config={{ closable: false }}
              />
            )}
            {state.status === MESSAGE_ALERT_STATUS.Rejected && (
              <MessageAlert
                message={state.message}
                messageType={MessageAlertType.Error}
                config={{ closable: false }}
              />
            )}
            <CustomInput
              name="Avatar"
              errors={errors}
              register={register}
              className="ant-input"
              required
            >
              {(props: any, name, register: any) => (
                <UploadImage
                  dispatch={dispatch}
                  setLogoId={setLogoId}
                  imageUrl={imageUrl}
                  setImageUrl={setImageUrl}
                />
              )}
            </CustomInput>
            <CustomInput
              name="slug"
              errors={errors}
              register={register}
              required
              className="ant-input"
            >
              {(props: any, name, register: any) => (
                <input {...props} name={name} ref={register} />
              )}
            </CustomInput>
            <CustomInput
              name="name"
              errors={errors}
              register={register}
              required
              className="ant-input"
            >
              {(props: any, name, register: any) => (
                <input {...props} name={name} ref={register} />
              )}
            </CustomInput>
            <CustomInput
              name="status"
              errors={errors}
              register={register}
              required
              className="ant-input"
            >
              {(props: any, name, register: any) => (
                <select {...props} name={name} ref={register}>
                  {[CompanyStatus.Active].map((value) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </select>
              )}
            </CustomInput>
            <CustomButton
              type="primary"
              value={
                state.status === MESSAGE_ALERT_STATUS.Pending
                  ? 'Submiting...'
                  : 'Submit'
              }
              disabled={state.status === MESSAGE_ALERT_STATUS.Pending}
            />
          </form>
        </Card>
      </div>

      <div className="col-8 d-flex">
        <Card title="List Companies" style={{ flexGrow: 1 }}>
          <Modal
            title="Remove Attendace"
            visible={isModalVisible}
            onCancel={() => {
              setModalVisible(false)
            }}
            onOk={() => {
              if (inputConfirm !== selectedRowKeys[0]) {
                notify('Not match', 'Company Slug is not matched', 'warning')
                return
              }
              setConfirmLoading(true)
              if (data) {
                dangerouslyDeleteCompany({
                  variables: {
                    id: data.companies.nodes.filter(
                      (company: Company) => company.slug === selectedRowKeys[0],
                    )[0].id,
                  },
                })
                  .then(() => {
                    setConfirmLoading(false)
                    setModalVisible(false)
                    refetch()
                  })
                  .catch((error) => {
                    console.error(error)
                    setConfirmLoading(false)
                    refetch()
                    notify('Error', 'Can not delete company', 'error')
                  })
              }
            }}
            confirmLoading={confirmLoading}
          >
            <h6>
              Enter company slug{' "'}
              <span style={{ color: '#ff4e46' }}>{selectedRowKeys[0]}</span>
              {'" '} to confirm delete:
            </h6>
            <Input
              placeholder="company slug"
              value={inputConfirm}
              onChange={(e) => {
                setInputConfirm(e.target.value)
              }}
            />
          </Modal>
          <div style={{ position: 'relative' }}>
            <Table
              pagination={{
                pageSize: DEFAULT_PAGE_SIZE,
                showSizeChanger: false,
                showTotal: () => (!data ? 0 : data.companies.total),
                total: !data ? 0 : data.companies.total,
                onChange: (page: number, pageSize?: number) => {
                  setPage(page)
                },
                current: page,
              }}
              dataSource={!data ? [] : data.companies.nodes}
              columns={columns}
              loading={loading}
              size="middle"
              bordered
              rowKey="slug"
              // scroll={{ x: 1000 }}
              rowSelection={{
                hideSelectAll: true,
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                  if (selectedRowKeys.length === 0) {
                    setSelectedRowKeys(selectedRowKeys)
                    return
                  }
                  setSelectedRowKeys([
                    selectedRowKeys[selectedRowKeys.length - 1],
                  ])
                },
              }}
            />
            <div
              className="d-flex justify-content-between pb-3"
              style={{ position: 'absolute', left: 0, bottom: 0 }}
            >
              {
                <Button
                  onClick={() => {
                    setModalVisible(true)
                  }}
                  danger
                  style={{
                    visibility: hasSelected ? 'visible' : 'hidden',
                  }}
                >
                  Delete
                </Button>
              }
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
