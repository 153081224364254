import React, { useEffect } from 'react'

import { IPageProps, IPageData } from '../../interfaces/page-data'
import { EmploymentList } from '../../components/Employment/EmploymentList'
import { Card } from 'antd'
import ButtonLink from '../../components/ButtonLink'
import { currentTime } from '../../utils/helper'

const EmploymentsPage: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props

  const pageData: IPageData = {
    title: 'Employments',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'employments',
      },
      {
        title: 'Employments',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <EmploymentList />
      </div>
    </div>
  )
}

export default EmploymentsPage
