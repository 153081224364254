import { useLocation } from 'react-router-dom'
import { MutationFunctionOptions, ExecutionResult } from 'react-apollo'

export function useQueryUrl() {
  return new URLSearchParams(useLocation().search)
}

export function useUpdateStatus<T>(
  fn: (
    options?: MutationFunctionOptions<any, Record<string, any>>,
  ) => Promise<ExecutionResult<any>>,
) {
  const onSubmit = (id: string, payload: T) => {
    return fn({
      variables: { id, payload },
    })
  }

  return {
    onSubmit,
  }
}
