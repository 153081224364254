import React from 'react'
import classnames from 'classnames'
import { ButtonType } from 'antd/lib/button'

type CustomButtonProps = {
  value: string
  disabled: boolean
  type: ButtonType
  size?: 'sm'
  handleClick?: () => void
}
export function CustomButton({
  value,
  disabled,
  size,
  type,
  handleClick,
}: CustomButtonProps) {
  return (
    <button
      value={value}
      disabled={disabled}
      onClick={() => handleClick}
      className={classnames(`ant-btn ant-btn-${type}`, {
        'ant-btn-sm': size && size === 'sm',
      })}
    >
      {value}
    </button>
  )
}
