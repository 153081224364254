import React, { createContext } from 'react'
import { KeycloakInstance } from 'keycloak-js'

export const KeycloakContext = createContext<KeycloakInstance | undefined>(
  undefined,
)

export const redirectUri =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '')
