import { Button, Col, Row } from 'antd'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { useMutation } from 'react-apollo'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Employment } from '../../../graphql/models'
import EmploymentAttendanceDrawer from '../../Employment/EmploymentAttendanceDrawer'
import { CustomInput } from '../../form/CustomForm'

const InnerFormSchema = Yup.object().shape({
  employeeId: Yup.string().required('Required'),
  fullName: Yup.string().required('Required'),
  bankAccount: Yup.string(),
})
const UPDATE_LEAVE_REQUEST = gql`
  mutation updateLeaveRequest(
    $leaveRequestId: ID!
    $payload: LeaveRequestUpdatePayload!
  ) {
    updateLeaveRequest(leaveRequestId: $leaveRequestId, payload: $payload) {
      leaveRequest {
        archivedDueDate
        id
      }
    }
  }
`

export const GET_OR_CREATE_LEAVE_REQUEST = gql`
  mutation getOrCreateLeaveRequest($employmentId: ID!) {
    getOrCreateLeaveRequest(employmentId: $employmentId) {
      leaveRequest {
        id
        createdDate
        executionStatus
        resignStatus
        archivedDueDate
        resignDate
        note
      }
    }
  }
`

export const LeaveRequestToolDetail = (props: any) => {
  const [getOrCreateLeaveRequest] = useMutation(GET_OR_CREATE_LEAVE_REQUEST)
  const [updateLeaveRequest] = useMutation(UPDATE_LEAVE_REQUEST)
  const { register, handleSubmit, errors, getValues } = useForm<Employment>({
    validationSchema: InnerFormSchema,
    defaultValues: { ...props },
  })
  const [loading, setLoading] = useState(false)
  const [attendanceDrawerVisible, setAttendanceDrawerVisible] = useState(false)
  const showAttendanceDrawer = () => {
    setAttendanceDrawerVisible(true)
  }
  const onCloseAttendanceDrawer = () => {
    setAttendanceDrawerVisible(false)
  }

  const onSave = async () => {
    try {
      setLoading(true)
      const leaveRequestData = await getOrCreateLeaveRequest({
        variables: {
          employmentId: props.id,
        },
      })

      await updateLeaveRequest({
        variables: {
          leaveRequestId:
            leaveRequestData.data.getOrCreateLeaveRequest.leaveRequest.id,
          payload: {
            note: String(getValues('leaveRequest.note')).trim(),
          },
        },
      })
      await props.refetch()
      await setLoading(false)
    } catch (error) {}
  }
  return (
    <>
      <div>
        <CustomInput
          name="fullName"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          readOnly
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>
        <CustomInput
          name="dob"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Birthday"
          readOnly
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>
        <CustomInput
          name="user.phoneNumber"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Phone Number"
          readOnly
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>
        <CustomInput
          name="company.name"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Company"
          readOnly
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="employeeId"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Employee Id"
          readOnly
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="leaveRequest.note"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Ghi chú"
        >
          {(props: any, name, register: any) => (
            <textarea {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <Row>
          <Col xs={24} sm={8}>
            <label
              style={{
                color: '#41ba7a',
                fontSize: 'large',
              }}
            >
              Bảng công:
            </label>
          </Col>
          <Button onClick={() => showAttendanceDrawer()}>Xem bảng công</Button>
        </Row>
        <Row>
          <Button
            type="primary"
            onClick={onSave}
            loading={loading}
            style={{ marginTop: '16px' }}
          >
            Lưu
          </Button>
        </Row>
      </div>
      <EmploymentAttendanceDrawer
        onClose={onCloseAttendanceDrawer}
        visible={attendanceDrawerVisible}
        employmentId={props.id}
      />
    </>
  )
}
