export const SETTINGS = {
  boxed: false, // boolean
  layout: 'vertical', // horizontal | vertical
  sidebarBg: '#ffffff', // string
  sidebarBg2: '#ffffff', // string
  sidebarColor: '#000000', // string
  sidebarAccentColor: '#08554e', // string
  sidebarAccentContrastColor: '#ffffff', // string
  sidebarOpened: false, // boolean
  topbarBg: '#ffffff', // string
  topbarColor: '#4a505c', // string
}
