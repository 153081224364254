import React from 'react'
import { FC, useEffect } from 'react'
import SendOtp from '../../components/SendOtp/SendOtp'
import { IPageData, IPageProps } from '../../interfaces/page-data'

const OtpPage: FC<IPageProps> = (props) => {
  const { onSetPage } = props

  const pageData: IPageData = {
    title: 'Otp',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'otp',
      },
      {
        title: 'Otp',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        <SendOtp />
      </div>
    </div>
  )
}

export default OtpPage
