import { Card, Collapse, Table } from 'antd'
import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { Banner, BannerOrderable, OrderDirection } from '../../graphql/models'
import { DEFAULT_PAGE_SIZE, useTable } from '../hooks/useTable'
import EditBannerDrawer from './EditBannerDrawer'

const GET_BANNERS = gql`
  query banners($query: BannerQuery!) {
    banners(query: $query) {
      total
      limit
      offset
      nodes {
        id
        title
        type
        content {
          svg {
            content
          }
        }
        contentImage {
          id
          uri
        }
      }
    }
  }
`

const { Panel } = Collapse

export default () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])

  const onCloseDrawer = () => {
    setSelectedRowKeys([])
  }

  const { hasSelected } = useMemo(
    () => ({
      hasSelected: selectedRowKeys.length > 0,
    }),
    [selectedRowKeys],
  )

  const [
    loading,
    error,
    data,
    filter,
    page,
    setFilter,
    setPage,
    resetFilter,
    queryVariables,
    setNewQueryVariables,
    refetch,
  ] = useTable(GET_BANNERS, {
    filter: {},
    orderBy: BannerOrderable.CreatedDate,
    orderDirection: OrderDirection.Desc,
  })
  const columns = [
    { title: 'ID', key: 'id', dataIndex: 'id' },
    {
      title: 'Title',
      key: 'title',
      render: (banner: Banner) => {
        return banner.title
      },
    },
    {
      title: 'Content',
      key: 'content',
      render: (banner: Banner) => {
        if (banner.type === 'Svg') {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: banner.content?.svg?.content || '',
              }}
            />
          )
        }

        return (
          <img
            src={banner.contentImage?.uri}
            style={{ objectFit: 'contain', width: '100%', height: '250px' }}
          ></img>
        )
      },
    },
  ]

  return (
    <>
      <Card>
        <Table
          pagination={{
            pageSize: DEFAULT_PAGE_SIZE,
            showSizeChanger: false,
            showTotal: () => (!data ? 0 : data.banners.total),
            total: !data ? 0 : data.banners.total,
            onChange: (page: number, pageSize?: number) => {
              setPage(page)
            },
            current: page,
          }}
          dataSource={!data ? [] : data.banners.nodes}
          columns={columns}
          loading={loading}
          size="middle"
          bordered={true}
          rowKey="id"
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
              if (selectedRowKeys.length === 0) {
                setSelectedRowKeys(selectedRowKeys as any)
                return
              }
              setSelectedRowKeys([selectedRowKeys[selectedRowKeys.length - 1]])
            },
          }}
        />
        <EditBannerDrawer
          onClose={onCloseDrawer}
          visible={hasSelected}
          bannerId={selectedRowKeys[0]}
          onUpdate={() => {
            refetch()
          }}
        />
      </Card>
    </>
  )
}
