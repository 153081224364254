import React from 'react'
import { t } from 'ttag'
import { Tag } from 'antd'

type ColumnUsernameProps = {
  username?: string | null
  key?: string
  style?: Object
}
export const ColumnUsername: React.FC<ColumnUsernameProps> = (props) => {
  const { username, key, style } = props
  let color = ''

  switch (username) {
    case 'IT':
    case 'it':
      color = 'orange'
      break
    case 'HR':
    case 'hr':
      color = 'blue'
      break
    case 'viewer':
      color = 'green'
      break
    default:
      color = 'whitesmoke'
  }

  return (
    <Tag key={key} color={color} style={style}>
      {username}
    </Tag>
  )
}
