import React, { useState } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'

import {
  Employment,
  Company,
  Bank,
  EmploymentStatus,
  EmploymentQueryFilter,
  LoanApplicationConnection,
  AttendanceConnection,
  Money,
  LoanApplicationStatus,
} from '../../graphql/models'
import { Table, Alert, Card, Button, DatePicker, message } from 'antd'
import ButtonLink from '../ButtonLink'
import { ColumnStatus } from '../table/ColmnStatus'
import { InputTableHeader, SelectTableHeader } from '../table/CustomTable'
import { ExportEntityType, useExportEntity } from '../hooks/useExportEntity'
import { useTable, DEFAULT_PAGE_SIZE, DEFAULT_LIMIT } from '../hooks/useTable'
import { useQuery, useMutation } from 'react-apollo'
import { formatMoney, currentMoment } from '../../utils/helper'
import { ColumnRegistration } from '../table/ColumnRegistration'
import { GET_COMPANIES } from '../Company/CompanyList'
import { SelectCompany } from '../input/SelectCompany'
import EmploymentAttendanceDrawer from './EmploymentAttendanceDrawer'
import { isMobileSafari, isDesktop, isIOS, isMobile } from 'react-device-detect'

export const GET_EMPLOYMENTS = gql`
  query employments(
    $query: EmploymentQuery
    $attendancesFilter: AttendanceQueryFilter
    $loanApplicationsQuery: LoanApplicationQuery
  ) {
    employments(query: $query) {
      total
      nodes {
        id
        employeeId
        bank {
          name
          alias
        }
        attendances(query: { filter: $attendancesFilter }) {
          total
        }
        loanApplications(query: $loanApplicationsQuery) {
          total
          sumAmounts
        }
        status
        salary
        isLinked
        fullName
        bankAccount
        currentBalance
        earnedBalance
        withdrawableBalance
        metadata
        user {
          id
        }
      }
      total
      limit
      offset
    }
  }
`

export const IMPERSONATE_USER = gql`
  mutation impersonateUser($payload: ImpersonateUserPayload!) {
    impersonateUser(payload: $payload) {
      accessToken
    }
  }
`

export const GET_EMPLOYMENTS_VARIABLES = {
  attendancesFilter: {
    dateRange: {
      from: currentMoment().startOf('month').format('YYYY-MM-DD'),
      to: currentMoment().endOf('month').format('YYYY-MM-DD'),
    },
  },
  loanApplicationsQuery: {
    filter: {
      statuses: [LoanApplicationStatus.Disbursed],
      createdDate: {
        from: currentMoment().startOf('month').toISOString(),
        to: currentMoment().endOf('month').toISOString(),
      },
    },
  },
}

const getWebVuiApp = () => {
  switch ((window as any).API_URL) {
    case 'https://api.vuiapp.vn':
      return 'https://m.vuiapp.vn'
    case 'https://api.sandbox.vuiapp.vn':
      return 'https://m.sandbox.vuiapp.vn'
    case 'https://api.canary.vuiapp.nanofin.tech':
      return 'https://m.canary.vuiapp.nanofin.tech'
    case 'https://api.vuiapp.nanofin.tech':
      return 'https://m.vuiapp.nanofin.tech'
    default:
      return 'https://m.sandbox.vuiapp.vn'
  }
}

export type EmploymentListProps = {
  currentTime?: string
  companyId?: string
}
export function EmploymentList({
  currentTime,
  companyId,
}: EmploymentListProps) {
  const [selectedEmployment, setSelectedEmployment] = useState<string | null>(
    null,
  )
  const [exportLoading, exportEntity] = useExportEntity()
  const [loading, error, data, filter, page, setFilter, setPage] = useTable<
    EmploymentQueryFilter
  >(
    GET_EMPLOYMENTS,
    {
      filter: {
        statuses: [EmploymentStatus.Active],
        ...(companyId ? { companyId } : {}),
      },
    },
    GET_EMPLOYMENTS_VARIABLES,
  )

  const companiesResponse = useQuery(GET_COMPANIES, {
    variables: { query: { limit: 100, offset: 0 } },
  })

  const [impersonateUser] = useMutation(IMPERSONATE_USER)

  const {
    bankAccountSearch,
    bankSearch,
    employeeIdSearch,
    fullNameSearch,
    isLinked,
  } = filter

  async function handleExport(e: any) {
    exportEntity(ExportEntityType.Employment, filter)
  }

  const onChangeCompany = (value: string) => {
    if (value) {
      setFilter('companyIds', [value])
    } else {
      setFilter('companyIds', [])
    }
  }

  const [attendanceDrawerVisible, setAttendanceDrawerVisible] = useState(false)

  const showAttendanceDrawer = () => {
    setAttendanceDrawerVisible(true)
  }
  const onCloseAttendanceDrawer = () => {
    setAttendanceDrawerVisible(false)
  }

  const handleImpersonate = async (employmentId: any) => {
    if (!employmentId) {
      return
    }
    const hide = message.loading(
      `Impersonating employment ${employmentId}...`,
      0,
    )
    await impersonateUser({
      variables: {
        payload: {
          userId: employmentId,
        },
      },
    })
      .then((data) => {
        if (isDesktop) {
          window.open(
            `${getWebVuiApp()}?accessToken=${
              data.data?.impersonateUser.accessToken
            }`,
            '_blank',
          )
        } else if (isMobile) {
          if (isMobileSafari) {
            window.location.assign(
              `${getWebVuiApp()}?accessToken=${
                data.data?.impersonateUser.accessToken
              }`,
            )
          } else {
            window.open(
              `${getWebVuiApp()}?accessToken=${
                data.data?.impersonateUser.accessToken
              }`,
              '_blank',
            )
          }
        }
        hide()
      })
      .catch((err) => {
        console.error('error: ', err)
        hide()
      })
  }

  const columns = [
    {
      title: InputTableHeader({
        value: employeeIdSearch,
        key: 'employeeIdSearch',
        inputType: 'text',
        name: 'Employee Id',
        onPressEnter: setFilter,
      }),
      key: 'employeeId',
      render: (props: Employment) => (
        <span
          onClick={async (e) => {
            if (e.altKey && isDesktop) {
              if (!props.user?.id) {
                message.error('Not registered user')
                return
              }
              await handleImpersonate(props.user?.id)
            }
          }}
        >
          {props.employeeId}
        </span>
      ),
    },
    {
      title: InputTableHeader({
        value: fullNameSearch,
        key: 'fullNameSearch',
        inputType: 'text',
        name: 'Name',
        onPressEnter: setFilter,
      }),
      key: 'fullName',
      render: (props: Employment) => (
        <ButtonLink
          title={props.fullName}
          routing={`employments/${props.id}/detail`}
          size="middle"
          style={{ padding: 0, whiteSpace: 'nowrap' }}
        />
      ),
    },
    {
      title: SelectTableHeader({
        value: isLinked,
        key: 'isLinked',
        options: [
          { value: true, text: 'Registered' },
          { value: false, text: 'Not Registered' },
        ],
        name: 'Registration Status',
        onChange: setFilter,
      }),
      dataIndex: 'isLinked',
      key: 'isLinked',
      render: (isLinked: boolean) => {
        return <ColumnRegistration isLinked={isLinked} />
      },
    },
    {
      title: 'Department',
      dataIndex: 'metadata',
      key: 'metadata',
      render: (metadata: any) => {
        if (metadata) {
          return metadata.departmentName || ''
        }
      },
      width: 250,
    },
    {
      title: 'Detail Attendance',
      key: 'operation',
      render: (props: Employment) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setSelectedEmployment(props.id)
              showAttendanceDrawer()
            }}
          >
            <i className="icofont-calendar"></i>
            Show Attendance
          </Button>
        )
      },
    },
    {
      title: 'Current Attendances',
      dataIndex: 'attendances',
      key: 'attendances',
      render: (attendances: AttendanceConnection) => {
        return attendances.total
      },
      width: 100,
    },
    {
      title: 'Salary',
      dataIndex: 'salary',
      key: 'salary',
      render: (salary: Money) => {
        return formatMoney(salary)
      },
      width: 120,
    },
    {
      title: 'Earned Balance',
      dataIndex: 'earnedBalance',
      key: 'earnedBalance',
      render: (earnedBalance: Money) => {
        return formatMoney(earnedBalance)
      },
      width: 120,
    },
    {
      title: 'Total Advanced Amount',
      dataIndex: 'loanApplications',
      key: 'loanApplications',
      render: (loanApplications: LoanApplicationConnection) => {
        return formatMoney(loanApplications.sumAmounts[0])
      },
      width: 120,
    },
    {
      title: 'Current Balance',
      dataIndex: 'currentBalance',
      key: 'currentBalance',
      render: (currentBalance: Money) => {
        return formatMoney(currentBalance)
      },
      width: 120,
    },
    {
      title: 'Withdrawable Balance',
      dataIndex: 'withdrawableBalance',
      key: 'withdrawableBalance',
      render: (withdrawableBalance: Money) => {
        return formatMoney(withdrawableBalance)
      },
      width: 120,
    },
    {
      title: InputTableHeader({
        value: bankSearch,
        key: 'bankSearch',
        inputType: 'text',
        name: 'Bank',
        onPressEnter: setFilter,
      }),
      dataIndex: 'bank',
      render: (bank: Bank) => {
        return bank && bank.alias
      },
      // width: 200,
    },
    {
      title: InputTableHeader({
        value: bankAccountSearch,
        key: 'bankAccountSearch',
        inputType: 'text',
        name: 'Bank Account',
        onPressEnter: setFilter,
      }),
      dataIndex: 'bankAccount',
      key: 'bankAccount',
      // width: 250,
    },
  ]

  return (
    <Card>
      <div className="d-flex justify-content-between pb-3">
        {companiesResponse.data && (
          <div className="ant-row ant-form-item m-0">
            <div className="ant-form-item-label">
              <label>Company</label>
            </div>
            <div className="ant-form-item-control">
              <SelectCompany
                companies={companiesResponse.data.companies.nodes}
                onChange={onChangeCompany}
              />
            </div>
          </div>
        )}

        <Button
          onClick={handleExport}
          loading={exportLoading}
          disabled={exportLoading}
        >
          Export
        </Button>
      </div>

      <Table
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: () => (!data ? 0 : data.employments.total),
          total: !data ? 0 : data.employments.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={!data ? [] : data.employments.nodes}
        columns={columns}
        loading={loading}
        size="middle"
        bordered={true}
        rowKey="id"
        scroll={{ x: 1800 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: async (event) => {
              if (isIOS && isMobile) {
                if (!record.user?.id) {
                  message.error('Not registered user')
                  return
                }
                await handleImpersonate(record?.user?.id)
              }
            }, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          }
        }}
      />
      <EmploymentAttendanceDrawer
        onClose={onCloseAttendanceDrawer}
        visible={attendanceDrawerVisible}
        employmentId={selectedEmployment}
      />
    </Card>
  )
}
