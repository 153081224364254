import React, { useState } from 'react'
import gql from 'graphql-tag'
import t from 'ttag'

import {
  Company,
  CompanyStatus,
  CompanyQueryFilter,
} from '../../graphql/models'
import { Table, Alert, Card, Button, Drawer } from 'antd'
import ButtonLink from '../ButtonLink'
import { ColumnStatus } from '../table/ColmnStatus'
import { InputTableHeader, SelectTableHeader } from '../table/CustomTable'
import { ExportEntityType, useExportEntity } from '../hooks/useExportEntity'
import { useTable, DEFAULT_PAGE_SIZE } from '../hooks/useTable'
import { NavLink } from 'react-router-dom'
import SendMessageDrawer from './SendMessageDrawer'
import BannerAssocationDrawer from './BannerAssocationDrawer'

export const GET_COMPANIES = gql`
  query companies($query: CompanyQuery) {
    companies(query: $query) {
      total
      nodes {
        id
        lastModifiedDate
        shortName
        name
        createdDate
        taxCode
        slug
        status
        logo {
          uri
        }
        email
        address
      }
      total
      limit
      offset
    }
  }
`

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      createdDate
      taxCode
      name
      shortName
      slug
      description
      status
      logo {
        uri
      }
      email
      address
      contractCompanyName
      contractCompanyTaxCode
      contractCompanyAddress
    }
  }
`

export function CompanyList() {
  const [messageDrawerVisible, setMessageDrawerVisible] = useState(false)
  const [bannerDrawerVisible, setBannerDrawerVisible] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null)

  const [exportLoading, exportEntity] = useExportEntity()
  const [loading, error, data, filter, page, setFilter, setPage] =
    useTable<CompanyQueryFilter>(GET_COMPANIES)

  const { slugSearch, nameSearch, emailSearch, addressSearch, statuses } =
    filter

  async function handleExport(e: any) {
    exportEntity(ExportEntityType.Company, filter)
  }

  const showMessageDrawer = () => {
    setMessageDrawerVisible(true)
  }
  const onCloseMessageDrawer = () => {
    setMessageDrawerVisible(false)
  }

  const showBannerDrawer = () => {
    setBannerDrawerVisible(true)
  }
  const onCloseBannerDrawer = () => {
    setBannerDrawerVisible(false)
  }

  const columns = [
    {
      title: InputTableHeader({
        value: slugSearch,
        key: 'slugSearch',
        inputType: 'text',
        name: 'Slug',
        onPressEnter: setFilter,
      }),
      key: 'slug',
      render: (props: Company) => (
        <ButtonLink
          title={props.slug}
          routing={`/vertical/companies/${props.id}/detail`}
          size="middle"
        />
      ),

      width: '20%',
    },
    {
      title: InputTableHeader({
        value: nameSearch,
        key: 'nameSearch',
        inputType: 'text',
        name: 'Name',
        onPressEnter: setFilter,
      }),
      key: 'name',
      dataIndex: 'name',
      // render: (props: Company) => (
      //   <ButtonLink
      //     title={props.name}
      //     routing={`/vertical/companies/${props.id}/detail`}
      //     size="middle"
      //   />
      // ),

      width: '35%',
    },
    {
      title: SelectTableHeader({
        value: statuses,
        key: 'statuses',
        options: [
          { value: CompanyStatus.Active, text: 'Active' },
          { value: CompanyStatus.Inactive, text: 'Inactive' },
          { value: CompanyStatus.Pilot, text: 'Pilot' },
        ],
        name: 'Status',
        onChange: setFilter,
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return <ColumnStatus status={status} />
      },
      width: '15%',
    },
    {
      title: 'Action',
      key: 'operation',
      render: (props: Company) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setSelectedCompany(props.id)
              showMessageDrawer()
            }}
          >
            <i className="icofont-mail"></i>
            Send Message
          </Button>
        )
      },
      width: '15%',
    },
    {
      title: 'Action',
      key: 'operation',
      render: (props: Company) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setSelectedCompany(props.id)
              showBannerDrawer()
            }}
          >
            <i className="icofont-image"></i>
            Show Banner
          </Button>
        )
      },
      width: '15%',
    },
  ]

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <Card>
      <div className="d-flex justify-content-between pb-3">
        <NavLink to="/vertical/companies/create">
          <Button type="primary">Create Company</Button>
        </NavLink>
        <Button
          onClick={handleExport}
          loading={exportLoading}
          disabled={exportLoading}
        >
          Export
        </Button>
      </div>

      <Table
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: () => (!data ? 0 : data.companies.total),
          total: !data ? 0 : data.companies.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={!data ? [] : data.companies.nodes}
        columns={columns}
        loading={loading}
        size="middle"
        bordered
        rowKey="id"
        scroll={{ y: 500 }}
      />
      <SendMessageDrawer
        company={selectedCompany}
        onClose={onCloseMessageDrawer}
        visible={messageDrawerVisible}
      />
      <BannerAssocationDrawer
        company={selectedCompany}
        onClose={onCloseBannerDrawer}
        visible={bannerDrawerVisible}
      />
    </Card>
  )
}
