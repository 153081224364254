import React, { useEffect } from 'react'
import { IPageProps, IPageData } from '../../interfaces/page-data'
import { LoadApplicationList } from '../../components/LoanApplication/LoanApplicationList'
import { LoanApplicationStatus } from '../../graphql/models'
import { useParams } from 'react-router-dom'

export const LoanApplicationPage: React.FC<IPageProps> = (props) => {
  const { onSetPage } = props
  const { slug } = useParams()

  const pageData: IPageData = {
    title: 'Loan Applications',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: '/vertical/dashboard',
      },
      {
        title: slug ? `Loan Applications: ${slug}` : 'Loan Applications',
      },
    ],
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-12">
          <LoadApplicationList
            status={
              (slug as LoanApplicationStatus) || LoanApplicationStatus.Pending
            }
          />
        </div>
      </div>
    </>
  )
}
