import React, { useState, useRef, RefObject, useEffect } from 'react'
import { Input, Select, DatePicker } from 'antd'
import { t } from 'ttag'
import Icon from '@ant-design/icons'
import moment from 'moment'
import { removeCurrency } from '../../utils/helper'

interface ITableHeaderParams {
  value: any
  name: string
  inputType?: string
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | any
  onPressEnter?: ((key: any, value: any) => void) | undefined
  key?: string
}
interface IInputGroupHeaderParams {
  fromValue: any
  toValue: any
  name: string
  onChange?: ((from: any, to: any) => void) | any
  onPressEnter?: ((from: any, to: any) => void) | undefined
  key?: string
}

interface ISelectTableHeaderParams extends ITableHeaderParams {
  options: { value: any; text: string }[]
  onChange?: (key: any, value: any, option?: any) => void
}
interface IDatePickerTableHeaderParams {
  name: string
  onChange?: (date: moment.Moment | null, dateString: string) => void
  config?: any
}

export const InputTableHeader = ({
  value,
  name,
  onChange,
  onPressEnter,
  inputType = 'text',
  key,
}: ITableHeaderParams) => {
  const inputEl = useRef<Input>(value)
  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.setValue(value)
    }
  })
  return (
    <div
      style={{
        padding: 8,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <p style={{ marginBottom: 8 }}>{name}</p>
      <Input
        ref={inputEl}
        size="small"
        type={inputType}
        placeholder={t`Search` + ' ' + `${name}`}
        onChange={(e) => {
          if (inputEl.current && e.type === 'click') {
            if (onPressEnter) onPressEnter(key, inputEl.current.input.value)
          }
        }}
        onPressEnter={(e) => {
          if (inputEl.current) {
            if (onPressEnter) onPressEnter(key, inputEl.current.input.value)
          }
        }}
        allowClear
      />
    </div>
  )
}
export const SelectTableHeader = ({
  options,
  key,
  value,
  name,
  onChange,
}: ISelectTableHeaderParams) => {
  const [inputValue, setInputValue] = useState(value)
  return (
    <div
      style={{
        padding: 8,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <p style={{ marginBottom: 8 }}>{name}</p>
      <Select
        size="middle"
        value={inputValue}
        placeholder={t`Search` + ' ' + `${name}`}
        defaultActiveFirstOption={false}
        onChange={(e) => {
          setInputValue(e)
          if (onChange) onChange(key, e)
        }}
        notFoundContent={null}
        style={{ minWidth: 188 }}
        allowClear
      >
        {options.map((d, index) => (
          <Select.Option key={index} value={d.value}>
            {d.text}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export const DatePickerTableHeader = ({
  name,
  onChange,
  config,
}: IDatePickerTableHeaderParams) => {
  return (
    <div
      style={{
        padding: 8,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <p style={{ marginBottom: 8 }}>{name}</p>
      <DatePicker
        defaultOpen={false}
        size="middle"
        style={{ width: 188 }}
        onChange={(date: moment.Moment | null, dateString: string) => {
          if (onChange) onChange(date, dateString)
        }}
        picker="date"
        disabledDate={config.disabledDate || []}
        disabled={config.disabled || false}
        value={config.value}
        format="DD/MM/YYYY"
      />
    </div>
  )
}

export const InputGroupTableHeader = ({
  fromValue,
  toValue,
  name,
  onChange,
  onPressEnter,
  key,
}: IInputGroupHeaderParams) => {
  const fromEl = useRef<Input>(fromValue)
  const toEl = useRef<Input>(toValue)

  useEffect(() => {
    fromEl.current.setValue(removeCurrency(fromValue) || '')
  }, [fromValue])

  useEffect(() => {
    toEl.current.setValue(removeCurrency(toValue) || '')
  }, [toValue])

  return (
    <div
      style={{
        padding: 8,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <p style={{ marginBottom: 8 }}>{name}</p>
      <Input.Group compact={true} style={{ minWidth: 250 }}>
        <Input
          ref={fromEl}
          size="small"
          style={{
            width: 100,
            textAlign: 'center',
            borderRightWidth: 0,
            paddingLeft: 5,
            paddingRight: 5,
          }}
          placeholder={t`Minimum`}
          onChange={(e) => {
            if (fromEl.current && toEl.current && e.type === 'click') {
              if (onPressEnter) onPressEnter(0, toEl.current.input.value)
            }
          }}
          onPressEnter={(e) => {
            if (fromEl.current && toEl.current) {
              if (onPressEnter) {
                onPressEnter(
                  fromEl.current.input.value,
                  toEl.current.input.value,
                )
              }
            }
          }}
          allowClear
          type="number"
          min={0}
        />
        <span style={{ padding: '0 10px' }}> - </span>
        <Input
          ref={toEl}
          size="small"
          className="site-input-right"
          style={{
            width: 100,
            textAlign: 'center',
            borderLeftWidth: 0,
            paddingLeft: 5,
            paddingRight: 5,
          }}
          onChange={(e) => {
            if (fromEl.current && toEl.current && e.type === 'click') {
              if (onPressEnter) onPressEnter(0, 0)
            }
          }}
          onPressEnter={() => {
            if (fromEl.current && toEl.current) {
              if (onPressEnter) {
                onPressEnter(
                  fromEl.current.input.value,
                  toEl.current.input.value,
                )
              }
            }
          }}
          allowClear
          placeholder={t`Maximum`}
          type="number"
          min={1}
        />
      </Input.Group>
    </div>
  )
}
