import React from 'react'

interface TenantTableCellProps {
  value: string
}

const TenantTableCell: React.FC<TenantTableCellProps> = ({ value }) => {
  return <div style={{ padding: '8px 5px' }}>{value}</div>
}

export default TenantTableCell
