import moment, { Moment } from 'moment'
import { DATE_FORMAT } from '../components/constants'
import { Modal } from 'antd'

export const VND_CURRENCY = 'đ'

declare global {
  interface Window {
    currentTimeOverriden?: Date
  }
}

export function formatMoney(money: number | string, currency?: string) {
  if (!money) {
    return `0${currency ? currency : VND_CURRENCY}`
  }
  if (typeof money === 'number') {
    return `${new Intl.NumberFormat('vi-VN').format(money)}${
      currency ? currency : VND_CURRENCY
    }`
  }
  return `${money.replace(/VND/g, '').trim()}${
    currency ? currency : VND_CURRENCY
  }`
}

export function removeCurrency(money: string | undefined | null) {
  if (!money) {
    return null
  }
  return `${money.replace(/VND/g, '').trim()}`
}

export function formatDate(dateString: string, format: string = DATE_FORMAT) {
  return moment(dateString).format(format)
}

export function disabledDate(
  current: Moment,
  selectedMonth: Moment = currentMoment(),
  format?: string,
) {
  return (
    current < moment(selectedMonth, format).startOf('month') ||
    current > moment(selectedMonth, format).endOf('month')
  )
}

export function currentTime(): Date {
  const currentTimeOverriden = localStorage.getItem('current-time-overriden')
  if (!currentTimeOverriden) {
    return new Date()
  }
  return new Date(+currentTimeOverriden)
}

export function currentMoment(): Moment {
  return moment(currentTime())
}

export function notify(
  message: string,
  content: string,
  type?: 'success' | 'warning' | 'info' | 'error',
  secondsToGo?: number,
) {
  const modal = Modal[type || 'success']({
    title: message,
    content: content,
  })
  setTimeout(() => {
    modal.destroy()
  }, (secondsToGo || 3) * 1000)
}

export function getDates(startDate: Moment, stopDate: Moment) {
  const dateArray = []
  const currentDate = startDate.clone()
  while (currentDate.isSameOrBefore(stopDate)) {
    dateArray.push(currentDate.format('YYYY-MM-DD'))
    currentDate.add(1, 'days')
  }
  return dateArray
}
