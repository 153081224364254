import React, { useReducer } from 'react'
import { Alert } from 'antd'

export enum MessageAlertType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export enum MESSAGE_ALERT_STATUS {
  Init = 'Init',
  Pending = 'Pending',
  Resolved = 'Resolved',
  Rejected = 'Rejected',
}

const messageAlertDefaultState: MessageAlertState = {
  status: MESSAGE_ALERT_STATUS.Init,
  message: '',
  messageType: MessageAlertType.Info,
}

type MessageAlertState = {
  status: MESSAGE_ALERT_STATUS
  message: string
  messageType: MessageAlertType
}
type MessageAlertAction = {
  type: 'success' | 'error' | 'pending'
  message?: string
}
function messageAlertReducer(
  state: MessageAlertState,
  action: MessageAlertAction,
): MessageAlertState {
  switch (action.type) {
    case 'pending':
      return {
        ...state,
        status: MESSAGE_ALERT_STATUS.Pending,
        message: '',
        messageType: MessageAlertType.Info,
      }
    case 'success':
      return {
        ...state,
        status: MESSAGE_ALERT_STATUS.Resolved,
        message: action.message || '',
        messageType: MessageAlertType.Success,
      }
    case 'error':
      return {
        ...state,
        status: MESSAGE_ALERT_STATUS.Rejected,
        message: action.message || '',
        messageType: MessageAlertType.Error,
      }
    default: {
      throw new Error(`Unhandler action type ${action.type}`)
    }
  }
}

export function useMessageAlert(): [
  MessageAlertState,
  React.Dispatch<MessageAlertAction>,
] {
  const [state, dispatch] = useReducer(
    messageAlertReducer,
    messageAlertDefaultState,
  )

  return [state, dispatch]
}

export function MessageAlert(props: {
  message: string
  messageType: MessageAlertType
  config?: {
    showIcon?: boolean
    closable?: boolean
  }
}) {
  const { message, messageType, config } = props
  return <Alert message={message} type={messageType} {...config} />
}
