import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd'
import BigNumber from 'bignumber.js'
import gql from 'graphql-tag'
import React from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { PacmanLoader } from 'react-spinners'
import { CellularNetworkItemType } from '../../graphql/models'
import { deepen } from '../../helpers/object'
import { sendNotification } from '../../utils/notification'
import './softpin.css'
import { get } from 'lodash'

interface TopupConfigProps {
  tenantId: string
}

const GET_TOPUP_CONFIG_BY_TENANT_ID = gql`
  query getTopupConfigByTenantId($id: ID!, $type: CellularNetworkItemType) {
    core_tenant(id: $id) {
      code
      topUp_config {
        limitation
        isUsable
        cardUsable
        cellularNetworkConfigs {
          name
          type
          status
          order
          items(type: $type) {
            amount
            discount
            status
            type
          }
        }
      }
    }
  }
`

const GET_DEFAULT_TOPUP_CONFIG = gql`
  query getDefaultTopUpConfig($type: CellularNetworkItemType) {
    getDefaultTopUpConfig(type: $type)
  }
`

const UPSERT_TENANT_TOP_UP_CONFIG = gql`
  mutation ($id: ID!, $payload: TenantTopUpConfigPayload!) {
    upsertTenantTopUpConfig(tenantId: $id, payload: $payload)
  }
`

const formatCurrency = (money: string | { value: number }) => {
  return typeof money === 'object'
    ? money.value
    : Number(money.split(' ')[0].match(/\d/g)?.join(''))
}

const Label: React.FC<{ value: string }> = ({ value }) => (
  <Typography.Text strong>{value}</Typography.Text>
)

const filteredCards = [300000, 400000]

const SoftPin: React.FC<TopupConfigProps> = ({ tenantId }) => {
  const { loading, data, error, refetch } = useQuery(
    GET_TOPUP_CONFIG_BY_TENANT_ID,
    {
      variables: {
        id: tenantId,
        type: CellularNetworkItemType.Card,
      },
      fetchPolicy: 'no-cache',
    },
  )

  const {
    loading: defaultConfigLoading,
    data: defaultConfigData,
    error: defaultConfigError,
  } = useQuery(GET_DEFAULT_TOPUP_CONFIG, {
    fetchPolicy: 'no-cache',
    variables: {
      type: 'Card',
    },
  })

  const [upsertTenantTopUpConfig, { loading: upsertTenantTopUpConfigLoading }] =
    useMutation(UPSERT_TENANT_TOP_UP_CONFIG)

  if (loading || defaultConfigLoading) {
    return (
      <div
        style={{
          height: '60vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PacmanLoader loading={true} color="#08554E" />
      </div>
    )
  }
  const selectOption = () => {
    return defaultConfigData.getDefaultTopUpConfig
      .sort((a: any, b: any) => {
        return a.name.localeCompare(b.name)
      })
      .map((networkOperator: any, index: number) => {
        return (
          <Select.Option
            key={`select-${networkOperator.name}-${index}`}
            value={index + 1}
          >
            {index + 1}
          </Select.Option>
        )
      })
  }
  const onSubmit = async (value: any) => {
    const formObject = deepen(value)

    const cellularNetworkPayloads = Object.entries(
      formObject.networkOperator,
    ).map(([key, netWorkValue]: [string, any], index) => {
      return {
        name: key,
        type: key,
        order: netWorkValue.order,
        thumbnailId: null,
        status: 'Active',
        items: Object.entries(netWorkValue.card).map(
          ([cardKey, cardValue]: [string, any]) => {
            return {
              amount: {
                value: cardKey,
                currency: 'VND',
              },
              discount: new BigNumber(cardValue).dividedBy(100).toNumber(),
              status: 'Active',
              type: 'Card',
            }
          },
        ),
      }
    })

    const payload = {
      networkItemType: CellularNetworkItemType.Card,
      cardUsable: formObject.cardUsable,
      isUsable: data?.core_tenant?.topUp_config
        ? data.core_tenant.topUp_config.isUsable
        : false,
      limitation: {
        value:
          formatCurrency(data.core_tenant.topUp_config.limitation) || 1000000,
        currency: 'VND',
      },
      cellularNetworkPayloads: cellularNetworkPayloads,
    }

    try {
      await upsertTenantTopUpConfig({
        variables: {
          id: tenantId,
          payload: payload,
        },
      })
      sendNotification({
        type: 'success',
        message: 'Updated successfully',
      })
    } catch (error) {
      sendNotification({
        type: 'error',
        message: get(
          error,
          'graphQLErrors[0].extensions.applicationCode',
          'Something went wrong',
        ),
      })
    }
  }
  let initForm = {
    cardUsable: data?.core_tenant?.topUp_config
      ? data.core_tenant.topUp_config.cardUsable
      : false,
  }

  if (data?.core_tenant?.topUp_config) {
    data.core_tenant.topUp_config.cellularNetworkConfigs.forEach(
      (networkOperator: any) => {
        let object: any = {
          [`networkOperator.${networkOperator.name}.status`]:
            networkOperator.status === 'Active',
          [`networkOperator.${networkOperator.name}.order`]:
            networkOperator.order,
        }
        if (networkOperator.items.length > 0) {
          networkOperator.items
            .filter((card: any) => {
              return !filteredCards.includes(formatCurrency(card.amount))
            })
            .forEach((card: any) => {
              object = {
                ...object,
                [`networkOperator.${networkOperator.name}.card.${formatCurrency(
                  card.amount,
                )}`]: new BigNumber(card.discount).multipliedBy(100).toNumber(),
              }
            })
        } else {
          defaultConfigData.getDefaultTopUpConfig
            .find((i: any) => i.name === networkOperator.name)
            ?.items?.filter((card: any) => {
              return !filteredCards.includes(formatCurrency(card.amount))
            })
            ?.forEach((card: any) => {
              object = {
                ...object,
                [`networkOperator.${networkOperator.name}.card.${formatCurrency(
                  card.amount,
                )}`]: new BigNumber(card.discount).multipliedBy(100).toNumber(),
              }
            })
        }
        initForm = {
          ...initForm,
          ...object,
        }
      },
    )
  } else {
    defaultConfigData.getDefaultTopUpConfig.forEach((networkOperator: any) => {
      let object: any = {
        [`networkOperator.${networkOperator.name}.status`]:
          networkOperator.status === 'Active',
        [`networkOperator.${networkOperator.name}.order`]: 1,
      }

      networkOperator.items
        .filter((card: any) => {
          return !filteredCards.includes(card.amount.value)
        })
        .forEach((card: any) => {
          object = {
            ...object,
            [`networkOperator.${networkOperator.name}.card.${card.amount.value}`]:
              new BigNumber(card.discount).multipliedBy(100).toNumber(),
          }
        })
      initForm = {
        ...initForm,
        ...object,
      }
    })
  }

  return (
    <div className="soft-pin-config">
      <Row style={{ marginBottom: 20 }}>
        <Typography.Text
          strong
          italic
          style={{ color: '#08554E', fontSize: 20 }}
        >{`Tenant ${data.core_tenant.code}`}</Typography.Text>
      </Row>

      <Form size="middle" initialValues={initForm} onFinish={onSubmit}>
        <Form.Item
          label="Softpin Useable"
          valuePropName="checked"
          name="cardUsable"
        >
          <Switch checkedChildren="enable" unCheckedChildren="disable" />
        </Form.Item>

        <Typography.Text
          style={{
            fontSize: 18,
            color: '#41ba7a',
            fontWeight: 600,
            marginBottom: 20,
          }}
          className="ant-form-item-label"
        >
          Network operators:
        </Typography.Text>

        <Row gutter={[24, 16]}>
          {defaultConfigData.getDefaultTopUpConfig
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name)
            })
            .map((networkOperator: any) => {
              return (
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  xl={{ span: 8 }}
                  key={networkOperator.name}
                >
                  <Card
                    title={
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: 10,
                        }}
                      >
                        <div>{networkOperator.name}</div>
                        <Form.Item
                          name={`networkOperator.${networkOperator.name}.order`}
                          label="Order"
                        >
                          <Select style={{ width: 120 }}>
                            {selectOption()}
                          </Select>
                        </Form.Item>
                      </div>
                    }
                  >
                    <Row gutter={[24, 16]}>
                      {networkOperator.items
                        .filter((card: any) => {
                          return !filteredCards.includes(card.amount.value)
                        })
                        .map((card: any) => {
                          return (
                            <Col
                              xs={{ span: 24 }}
                              xl={{ span: 12 }}
                              key={`networkOperator.${networkOperator.name}.card.${card.amount.value}`}
                            >
                              <Form.Item
                                name={`networkOperator.${networkOperator.name}.card.${card.amount.value}`}
                                label={`Card ${String(
                                  card.amount.value,
                                ).substring(
                                  0,
                                  String(card.amount.value).length - 3,
                                )}k`}
                              >
                                <InputNumber
                                  min={0}
                                  max={100}
                                  formatter={(value) =>
                                    value ? `${value}%` : ''
                                  }
                                  // parser={(value: any) => value.replace('%', '')}
                                />
                              </Form.Item>
                            </Col>
                          )
                        })}
                    </Row>
                  </Card>
                </Col>
              )
            })}
        </Row>

        <Button
          loading={upsertTenantTopUpConfigLoading}
          disabled={upsertTenantTopUpConfigLoading}
          style={{
            marginTop: 40,
            backgroundColor: '#08554E',
            color: 'white',
          }}
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default SoftPin
