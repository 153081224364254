import React from 'react'
import {
  MessageAlert,
  MessageAlertType,
  useMessageAlert,
  MESSAGE_ALERT_STATUS,
} from '../alert/MessageAlert'
import { useForm } from 'react-hook-form'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'
import { LoanApplicationStatus } from '../../graphql/models'
import { LOAN_APPLICATION_UPDATE_STATUS_SUCCESS } from '../constants'
import { CustomButton } from '../form/CustomButton'
import { Button } from 'antd'

export const APPROVE_LOAN_APPLICATION = gql`
  mutation approveLoanApplication($id: ID!) {
    approveLoanApplication(id: $id) {
      loanApplication {
        id
        status
      }
    }
  }
`

type LoanApplicationApproveProps = {
  id: string
  status: LoanApplicationStatus.Approved
  setCurrentStatus: (status: LoanApplicationStatus.Approved) => void
  history: any
}
export function LoanApplicationApprove({
  id,
  status,
  setCurrentStatus,
  history,
}: LoanApplicationApproveProps) {
  const [approveLoanApplication] = useMutation(APPROVE_LOAN_APPLICATION)
  const [state, dispatch] = useMessageAlert()
  const { handleSubmit } = useForm()

  const onSubmit = async () => {
    try {
      dispatch({ type: 'pending' })
      await approveLoanApplication({ variables: { id } })
      dispatch({
        type: 'success',
        message: LOAN_APPLICATION_UPDATE_STATUS_SUCCESS,
      })
      setCurrentStatus(status)
    } catch (err) {
      dispatch({ type: 'error', message: err.message })
    }
  }

  return (
    <>
      {(state.status === MESSAGE_ALERT_STATUS.Init ||
        state.status === MESSAGE_ALERT_STATUS.Pending) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="ant-div-right">
            <CustomButton
              type="primary"
              value={
                state.status === MESSAGE_ALERT_STATUS.Pending
                  ? 'Submiting...'
                  : 'Submit'
              }
              disabled={state.status === MESSAGE_ALERT_STATUS.Pending}
            />
          </div>
        </form>
      )}
    </>
  )
}
