import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import { Alert, Card, Spin, Button, Modal, notification } from 'antd'
import * as Yup from 'yup'
import { Employment, EmploymentStatus } from '../../graphql/models'
import { CustomInput } from '../form/CustomForm'
import { useForm } from 'react-hook-form'
import { ColumnStatus } from '../table/ColmnStatus'
import { GroupStatusBtn } from '../form/GroupStatusBtn'
import { EntityStatusTree } from '../interface'

export const GET_EMPLOYMENT = gql`
  query employment($id: ID!) {
    employment(id: $id) {
      fullName
      dob
      user {
        phoneNumber
      }
      company {
        name
      }
      id
      type
      status
      bank {
        name
      }
      bankAccount
      employeeId
      currentBalance
      earnedBalance
      withdrawableBalance
      metadata
    }
  }
`

export const UPDATE_EMPLOYMENT = gql`
  mutation updateEmployment($id: ID!, $payload: UpdateEmploymentPayload!) {
    updateEmployment(id: $id, payload: $payload) {
      employment {
        id
      }
    }
  }
`

export const ACTIVATE_COMPANY_EMPLOYMENT = gql`
  mutation activateCompanyEmployment($id: ID!, $companyId: ID!) {
    activateCompanyEmployment(id: $id, companyId: $companyId) {
      employment {
        id
      }
    }
  }
`

export const DEACTIVATE_COMPANY_EMPLOYMENT = gql`
  mutation deactivateEmployment($id: ID!) {
    deactivateEmployment(id: $id)
  }
`

export const EmploymentStatusTree: EntityStatusTree<EmploymentStatus> = {
  [EmploymentStatus.Active]: {
    from: [EmploymentStatus.Inactive],
    to: [EmploymentStatus.Inactive],
  },
  [EmploymentStatus.Inactive]: {
    from: [EmploymentStatus.Active],
    to: [EmploymentStatus.Active],
  },
  [EmploymentStatus.Unapproved]: {
    from: [],
    to: [EmploymentStatus.Active, EmploymentStatus.Inactive],
  },
}

const InnerFormSchema = Yup.object().shape({
  employeeId: Yup.string().required('Required'),
  fullName: Yup.string().required('Required'),
  bankAccount: Yup.string(),
})

type EmploymentDetailProps = {
  id: string
}

const EmploymentDetailForm: React.FC<any> = (props) => {
  const browserHistory = useHistory()
  const [status, setStatus] = useState(props.status)
  // const { message, setMessage } = useMessageAlert()
  const [loading, setLoading] = useState(false)
  const [activateCompanyEmployment] = useMutation(ACTIVATE_COMPANY_EMPLOYMENT)
  const [deactivateCompanyEmployment] = useMutation(
    DEACTIVATE_COMPANY_EMPLOYMENT,
  )
  const { register, handleSubmit, errors, getValues } = useForm<Employment>({
    validationSchema: InnerFormSchema,
    defaultValues: { ...props },
  })

  return (
    <Card>
      <div>
        <CustomInput
          name="fullName"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="dob"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Birthday"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="user.phoneNumber"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Phone Number"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="company.name"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Company"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="metadata.departmentName"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Deparment"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="employeeId"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Employee Id"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="type"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Contract Type"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="bankAccount"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="earnedBalance"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="currentBalance"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="withdrawableBalance"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
          label="Withdrawable"
        >
          {(props: any, name, register: any) => (
            <input {...props} name={name} ref={register} />
          )}
        </CustomInput>

        <CustomInput
          name="status"
          errors={errors}
          register={register}
          required={false}
          className="ant-input"
        >
          {(formProps: any, name, register: any) => (
            <>
              <ColumnStatus status={props.status} />
              {props.status === 'Active' && (
                <Button
                  danger
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    Modal.confirm({
                      title: 'Warning',
                      content: 'Do you want to deactive this employment?',
                      onOk() {
                        deactivateCompanyEmployment({
                          variables: {
                            id: props.id,
                          },
                        })
                          .then(() => {
                            browserHistory.go(0)
                          })
                          .catch(() => {
                            notification.error({
                              message: 'Error',
                              description: `Failed to deactive employment`,
                            })
                          })
                      },
                      onCancel() {
                        console.log('Cancel')
                      },
                    })
                  }}
                >
                  Deactive
                </Button>
              )}
            </>
          )}
        </CustomInput>

        <Button
          type="primary"
          onClick={() => {
            browserHistory.goBack()
          }}
        >
          Back
        </Button>
      </div>
    </Card>
  )
}

export const EmploymentDetail: React.FC<EmploymentDetailProps> = (props) => {
  const { id } = props
  const { loading, error, data, refetch } = useQuery(GET_EMPLOYMENT, {
    variables: { id },
  })

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  if (loading) {
    return <Spin />
  }

  if (!data) {
    return <Alert message="Data is empty" />
  }

  return <EmploymentDetailForm {...data.employment} refetch={refetch} />
}
