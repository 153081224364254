import React from 'react'
import gql from 'graphql-tag'

import { Table, Alert, Card, Button } from 'antd'
import { InputTableHeader } from '../table/CustomTable'
import { Employment, UserQueryFilter } from '../../graphql/models'
import { useExportEntity, ExportEntityType } from '../hooks/useExportEntity'
import { useTable, DEFAULT_PAGE_SIZE } from '../hooks/useTable'

export const GET_USERS = gql`
  query users($query: UserQuery) {
    users(query: $query) {
      total
      nodes {
        id
        phoneNumber
        fullName
        currentEmployment {
          fullName
          status
          employeeId
          dob
          company {
            name
          }
        }
      }
      total
      limit
      offset
    }
  }
`

export function UserList() {
  const [exportLoading, exportEntity] = useExportEntity()
  const [loading, error, data, filter, page, setFilter, setPage] = useTable<
    UserQueryFilter
  >(GET_USERS)
  const { companyName, fullName, phoneNumber } = filter

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  async function handleExport(e: any) {
    exportEntity(ExportEntityType.User, filter)
  }

  const columns = [
    {
      title: 'Employee Id',
      dataIndex: 'currentEmployment',
      render: (currentEmployment: Employment) => {
        return currentEmployment && currentEmployment.employeeId
      },
    },
    {
      title: InputTableHeader({
        value: fullName,
        key: 'fullName',
        inputType: 'text',
        name: 'Employment Name',
        onPressEnter: setFilter,
      }),
      dataIndex: 'currentEmployment',
      render: (currentEmployment: Employment) => {
        return currentEmployment && currentEmployment.fullName
      },
    },
    {
      title: InputTableHeader({
        value: companyName,
        key: 'companyName',
        inputType: 'text',
        name: 'Current Company',
        onPressEnter: setFilter,
      }),
      dataIndex: 'currentEmployment',
      render: (currentEmployment: Employment) => {
        return currentEmployment && currentEmployment.company.name
      },
    },
    {
      title: 'Birthday',
      key: 'birthday',
      dataIndex: 'currentEmployment',
      render: (currentEmployment: Employment) => {
        return currentEmployment && currentEmployment.dob
      },
    },
    {
      title: InputTableHeader({
        value: phoneNumber,
        key: 'phoneNumber',
        inputType: 'text',
        name: 'Phone Number',
        onPressEnter: setFilter,
      }),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Status',
      dataIndex: 'currentEmployment',
      render: (currentEmployment: Employment) => {
        return currentEmployment && currentEmployment.status
      },
    },
  ]

  return (
    <Card>
      <div style={{ display: 'flex' }}>
        <div
          className="ant-form ant-form-horizontal"
          style={{ width: '60%' }}
        ></div>

        <div
          className="ant-form ant-form-horizontal"
          style={{
            width: '40%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}
        >
          <Button
            onClick={handleExport}
            loading={exportLoading}
            disabled={exportLoading}
          >
            Export
          </Button>
        </div>
      </div>
      <Table
        pagination={{
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
          showTotal: () => (!data ? 0 : data.users.total),
          total: !data ? 0 : data.users.total,
          onChange: (page: number, pageSize?: number) => {
            setPage(page)
          },
          current: page,
        }}
        dataSource={!data ? [] : data.users.nodes}
        columns={columns}
        loading={loading}
        size="middle"
        bordered
        rowKey="id"
        scroll={{ x: 1200, y: 500 }}
      />
    </Card>
  )
}
