export function deepen(obj: { [x: string]: any }) {
  const result: {
    [x: string]: any
  } = {}

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.')

    // Create sub-objects along path as needed
    let target = result
    while (parts.length > 1) {
      const part = parts.shift()
      if (part) {
        target = target[part] = target[part] || {}
      }
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath]
  }

  return result
}
