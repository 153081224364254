import React from 'react'
import moment from 'moment'
import { DATE_FORMAT } from '../constants'

type ColumnDateProps = {
  date: string
  format?: string
}
export const ColumnDate: React.FC<ColumnDateProps> = (props) => {
  const { date, format } = props
  const formatDate = moment(date).format(format || DATE_FORMAT)
  return <>{formatDate}</>
}
