import VerticalLayout from './layouts/Vertical/Vertical'
import PlaygroundPage from './pages/PlaygroundPage/PlaygroundPage'
import BannerPage from './pages/BannerPage/BannerPage'
import { CompanyDetailPage } from './pages/CompanyPage/CompanyDetailPage'
import CompanyPage from './pages/CompanyPage/CompanyPage'
import { CreateCompanyPage } from './pages/CompanyPage/CreateCompanyPage'
import DashboardPage from './pages/DashboardPage/Dashboard'
import { CreateEmploymentPage } from './pages/EmploymentsPage/CreateEmploymentPage'
import { EmploymentDetailPage } from './pages/EmploymentsPage/EmploymentDetailPage'
import EmploymentsPage from './pages/EmploymentsPage/EmploymentsPage'
import GenerateDataPage from './pages/GenerateDataPage/GenerateDataPage'
import { LeaveRequestToolDetailPage } from './pages/LeaveRequestToolPage/LeaveRequestToolDetailPage'
import { LeaveRequestToolPage } from './pages/LeaveRequestToolPage/LeaveRequestToolPage'
import { LoanApplicationBatchesPage } from './pages/LoanApplicationBatchesPage/LoanApplicationBatchesPage'
import { LoanApplicationDetailPage } from './pages/LoanApplicationPage/LoanApplicationDetailPage'
import { LoanApplicationPage } from './pages/LoanApplicationPage/LoanApplicationPage'
import OtpPage from './pages/OtpPage/OtpPage'
import TenantDetailPage from './pages/TenantPage/TenantDetailPage'
import TenantPage from './pages/TenantPage/TenantPage'
import TransactionPage from './pages/TransactionPage/TransactionPage'

export interface IRoute {
  path: string
  component: any
  exact?: boolean
  children?: IRoute[]
}

export const defaultRoutes: IRoute[] = [
  {
    path: '/dashboard',
    component: DashboardPage,
  },
  {
    path: '/playground',
    component: PlaygroundPage,
  },
  {
    path: '/banner',
    component: BannerPage,
  },
  {
    path: '/employments',
    component: EmploymentsPage,
  },
  {
    path: '/employments/create',
    component: CreateEmploymentPage,
  },
  {
    path: '/employments/:slug/detail',
    component: EmploymentDetailPage,
  },
  {
    path: '/loan-batches',
    component: LoanApplicationBatchesPage,
  },
  {
    path: '/loan-applications',
    component: LoanApplicationPage,
  },
  {
    path: '/loan-applications/status/:slug',
    component: LoanApplicationPage,
  },
  {
    path: '/loan-applications/:slug/detail',
    component: LoanApplicationDetailPage,
  },
  {
    path: '/tenants',
    component: TenantPage,
  },
  {
    path: '/otp',
    component: OtpPage,
  },
  {
    path: '/tenants/:id/detail',
    component: TenantDetailPage,
  },
  {
    path: '/companies',
    component: CompanyPage,
  },
  {
    path: '/companies/create',
    component: CreateCompanyPage,
  },
  {
    path: '/companies/:slug/detail',
    component: CompanyDetailPage,
  },
  {
    path: '/companies/:slug/create-employment',
    component: CreateEmploymentPage,
  },
  {
    path: '/transactions',
    component: TransactionPage,
  },

  {
    path: '/generate',
    component: GenerateDataPage,
  },
  {
    path: '/leave-request-tool/status/:slug',
    component: LeaveRequestToolPage,
  },
  {
    path: '/leave-request-tool/:id/detail',
    component: LeaveRequestToolDetailPage,
  },
]

export const routes: IRoute[] = [
  {
    path: '/vertical',
    component: VerticalLayout,
    children: defaultRoutes,
  },
]
