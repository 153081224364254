import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LeaveRequestTool from '../../components/LeaveRequestTool/LeaveRequestTool'
import { IPageData, IPageProps } from '../../interfaces/page-data'

export const LeaveRequestToolPage: React.FC<IPageProps> = (props) => {
  const { slug } = useParams<{ slug: string }>()

  const { onSetPage } = props
  const pageData: IPageData = {
    title: 'Loan Applications',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: '/vertical/dashboard',
      },
      {
        title: slug ? `Leave request tool: ${slug}` : 'Leave request tool',
      },
    ],
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-12">
          <LeaveRequestTool status={slug || 'pending'} key={slug} />
        </div>
      </div>
    </>
  )
}
