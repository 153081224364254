import React from 'react'
import * as Yup from 'yup'
import {
  MessageAlert,
  MessageAlertType,
  useMessageAlert,
  MESSAGE_ALERT_STATUS,
} from '../alert/MessageAlert'
import { CustomInput } from '../form/CustomForm'
import { useForm } from 'react-hook-form'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'
import {
  LoanApplicationStatus,
  RejectLoanApplicationReason,
  RejectLoanApplicationPayload,
} from '../../graphql/models'
import { LOAN_APPLICATION_UPDATE_STATUS_SUCCESS } from '../constants'
import { CustomButton } from '../form/CustomButton'
import { Button } from 'antd'

export const REJECT_LOAN_APPLICATION = gql`
  mutation rejectLoanApplication(
    $id: ID!
    $payload: RejectLoanApplicationPayload!
  ) {
    rejectLoanApplication(id: $id, payload: $payload) {
      loanApplication {
        id
        status
      }
    }
  }
`

const FormRejectStatusSchema = Yup.object().shape({
  message: Yup.string().required('Required'),
})

type LoanApplicationRejectProps = {
  id: string
  status: LoanApplicationStatus.Closed
  setCurrentStatus: (status: LoanApplicationStatus.Closed) => void
  history: any
}
export function LoanApplicationReject({
  id,
  status,
  setCurrentStatus,
  history,
}: LoanApplicationRejectProps) {
  const [rejectLoanApplication] = useMutation(REJECT_LOAN_APPLICATION)
  const [state, dispatch] = useMessageAlert()
  const { register, handleSubmit, errors } = useForm<
    RejectLoanApplicationPayload
  >({
    validationSchema: FormRejectStatusSchema,
  })

  const onSubmit = async ({ message }: RejectLoanApplicationPayload) => {
    try {
      dispatch({ type: 'pending' })
      await rejectLoanApplication({
        variables: {
          id,
          payload: { message, reason: RejectLoanApplicationReason.Unknown },
        },
      })
      dispatch({
        type: 'success',
        message: LOAN_APPLICATION_UPDATE_STATUS_SUCCESS,
      })
      setCurrentStatus(status)
    } catch (err) {
      dispatch({ type: 'error', message: err.message })
    }
  }

  return (
    <>
      {state.status === MESSAGE_ALERT_STATUS.Resolved && (
        <>
          <MessageAlert
            message={state.message}
            messageType={MessageAlertType.Success}
            config={{ showIcon: true, closable: false }}
          />
        </>
      )}
      {state.status === MESSAGE_ALERT_STATUS.Rejected && (
        <>
          <MessageAlert
            message={state.message}
            messageType={MessageAlertType.Error}
            config={{ showIcon: true, closable: false }}
          />
        </>
      )}
      {(state.status === MESSAGE_ALERT_STATUS.Init ||
        state.status === MESSAGE_ALERT_STATUS.Pending) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            name="message"
            errors={errors}
            register={register}
            required
            label="Reason"
            className="ant-input"
          >
            {(props: any, name, register: any) => (
              <textarea
                {...props}
                name={name}
                ref={register}
                rows="5"
                placeholder="Reject resaon"
              ></textarea>
            )}
          </CustomInput>

          <div className="ant-div-right">
            <CustomButton
              type="primary"
              value={
                state.status === MESSAGE_ALERT_STATUS.Pending
                  ? 'Submiting...'
                  : 'Submit'
              }
              disabled={state.status === MESSAGE_ALERT_STATUS.Pending}
            />
          </div>
        </form>
      )}
    </>
  )
}
