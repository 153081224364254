import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ConnectedRouter } from 'connected-react-router'
import { history, store } from './redux/store'
import { Provider } from 'react-redux'

ReactDOM.render(
  <Provider store={store()}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
