import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import gql from 'graphql-tag'
import { t } from 'ttag'

import {
  Employment,
  Bank,
  EmploymentQueryFilter,
  Money,
  AttendanceConnection,
  EmploymentSummaryAdvance,
  LoanApplicationStatus,
  LoanApplicationConnection,
  EmploymentStatus,
  Company,
  AuditLogConnection,
  AuditLog,
  AuditLogOrderable,
  OrderDirection,
} from '../../graphql/models'
import {
  Table,
  Alert,
  Card,
  Button,
  Skeleton,
  Tree,
  Radio,
  Form,
  notification,
} from 'antd'
import ButtonLink from '../ButtonLink'
import { InputTableHeader, SelectTableHeader } from '../table/CustomTable'
import { ExportEntityType, useExportEntity } from '../hooks/useExportEntity'
import { ColumnRegistration } from '../table/ColumnRegistration'
import { useTable, DEFAULT_PAGE_SIZE } from '../hooks/useTable'
import { formatMoney, currentMoment } from '../../utils/helper'
import { ColumnDate } from '../table/ColumDate'
import { ColumnUsername } from '../table/ColumnUsername'
import { useMutation, useQuery } from 'react-apollo'
import { arrayToTree } from 'performant-array-to-tree'
import _ from 'lodash'

export const GET_COMPANY_GROUP = gql`
  query company($companyId: ID!) {
    company(id: $companyId) {
      groupsAsTree {
        parentId
        group {
          id
          attendanceFileProcessorQualifier
          employmentFileProcessorQualifier
          payslipCalculatorQualifier
          name
        }
      }
    }
  }
`

export const GET_ATTENDANCE_QUALIFIERS = gql`
  query {
    attendanceFileProcessorQualifiers
  }
`

export const GET_EMPLOYMENT_QUALIFIERS = gql`
  query {
    employmentFileProcessorQualifiers
  }
`

export const GET_PAYSLIP_QUALIFIERS = gql`
  query {
    payslipCalculatorQualifiers
  }
`

export const UPDATE_GROUP_ATTENDANCE_QUALIFIER = gql`
  mutation updateGroupAttendanceFileProcessor(
    $id: ID!
    $payload: UpdateGroupAttendanceFileProcessorPayload!
  ) {
    updateGroupAttendanceFileProcessor(id: $id, payload: $payload) {
      group {
        id
        name
        attendanceFileProcessorQualifier
        employmentFileProcessorQualifier
      }
    }
  }
`

export const UPDATE_GROUP_EMPLOYMENT_QUALIFIER = gql`
  mutation updateGroupEmploymentFileProcessor(
    $id: ID!
    $payload: UpdateGroupEmploymentFileProcessorPayload!
  ) {
    updateGroupEmploymentFileProcessor(id: $id, payload: $payload) {
      group {
        id
        name
        attendanceFileProcessorQualifier
        employmentFileProcessorQualifier
      }
    }
  }
`

export const UPDATE_GROUP_PAYSLIP_QUALIFIER = gql`
  mutation updateGroupPayslipCalculator(
    $id: ID!
    $payload: UpdateGroupPayslipCalculatorPayload!
  ) {
    updateGroupPayslipCalculator(id: $id, payload: $payload) {
      group {
        id
        name
        attendanceFileProcessorQualifier
        employmentFileProcessorQualifier
        payslipCalculatorQualifier
      }
    }
  }
`

export function CompanyGroup(props: { id: string }) {
  const {
    loading: groupsLoading,
    data: groupsData,
    error: groupsError,
    refetch: groupsRefetch,
  } = useQuery(GET_COMPANY_GROUP, {
    variables: {
      companyId: props.id,
    },
  })

  const [
    updateGroupAttendanceQualifier,
    { data: updateGroupAttendanceQualifierData },
  ] = useMutation(UPDATE_GROUP_ATTENDANCE_QUALIFIER)
  const [
    updateGroupEmploymentQualifier,
    { data: updateGroupEmploymentQualifierData },
  ] = useMutation(UPDATE_GROUP_EMPLOYMENT_QUALIFIER)

  const [
    updateGroupPayslipQualifier,
    { data: updateGroupPayslipQualifierData },
  ] = useMutation(UPDATE_GROUP_PAYSLIP_QUALIFIER)

  const {
    loading: attendanceQualifiersLoading,
    data: attendanceQualifiersData,
    error: attendanceQualifiersError,
  } = useQuery(GET_ATTENDANCE_QUALIFIERS)
  const {
    loading: employmentQualifiersLoading,
    data: employmentQualifiersData,
    error: employmentQualifiersError,
  } = useQuery(GET_EMPLOYMENT_QUALIFIERS)
  const {
    loading: payslipQualifiersLoading,
    data: payslipQualifiersData,
    error: payslipQualifiersError,
  } = useQuery(GET_PAYSLIP_QUALIFIERS)

  const { treeData } = useMemo(() => {
    if (!groupsLoading && groupsData) {
      return {
        treeData: arrayToTree(
          _.map(groupsData?.company?.groupsAsTree, (item) => {
            return {
              ...item,
              title: item.group.name,
              key: item.group.id,
              attendanceFileProcessorQualifier:
                item.group.attendanceFileProcessorQualifier,
              employmentFileProcessorQualifier:
                item.group.employmentFileProcessorQualifier,
            }
          }),
          {
            id: 'group.id',
            parentId: 'parentId',
            dataField: null,
          },
        ),
      }
    }
    return {
      treeData: undefined,
    }
  }, [groupsData])

  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([])

  const onCheck = (checked: any, info: any) => {
    console.log('Checked: ', checked)
    console.log('info: ', info)
    setCheckedKeys(
      _.last(checked.checked) ? [_.last(checked.checked) as any] : [],
    )
  }

  const { selectedNode } = useMemo(() => {
    if (!groupsLoading && groupsData && checkedKeys.length) {
      return {
        selectedNode: _.find(groupsData?.company?.groupsAsTree, {
          group: {
            id: checkedKeys[0],
          },
        }),
      }
    }
    return {
      selectedNode: undefined,
    }
  }, [treeData, checkedKeys])

  const onChangeAttendanceQualifier = useCallback(
    (e) => {
      updateGroupAttendanceQualifier({
        variables: {
          id: selectedNode.group.id,
          payload: {
            qualifier: e.target.value,
          },
        },
      })
        .then(() => {
          groupsRefetch()
          notification.success({
            message: 'Success',
            description: `${selectedNode.group.name} Attendance Qualifier changed to ${e.target.value}`,
          })
        })
        .catch(() => {
          groupsRefetch()
          notification.error({
            message: 'Error',
            description: `${selectedNode.group.name} Attendance Qualifier failed to change to ${e.target.value}`,
          })
        })
    },
    [selectedNode],
  )

  const onChangeEmploymentQualifier = useCallback(
    (e) => {
      updateGroupEmploymentQualifier({
        variables: {
          id: selectedNode.group.id,
          payload: {
            qualifier: e.target.value,
          },
        },
      })
        .then(() => {
          groupsRefetch()
          notification.success({
            message: 'Success',
            description: `${selectedNode.group.name} Employment Qualifier changed to ${e.target.value}`,
          })
        })
        .catch(() => {
          groupsRefetch()
          notification.error({
            message: 'Error',
            description: `${selectedNode.group.name} Employment Qualifier failed to change to ${e.target.value}`,
          })
        })
    },
    [selectedNode],
  )

  const onChangePayslipQualifier = useCallback(
    (e) => {
      updateGroupPayslipQualifier({
        variables: {
          id: selectedNode.group.id,
          payload: {
            qualifier: e.target.value,
          },
        },
      })
        .then(() => {
          groupsRefetch()
          notification.success({
            message: 'Success',
            description: `${selectedNode.group.name} Employment Qualifier changed to ${e.target.value}`,
          })
        })
        .catch(() => {
          groupsRefetch()
          notification.error({
            message: 'Error',
            description: `${selectedNode.group.name} Employment Qualifier failed to change to ${e.target.value}`,
          })
        })
    },
    [selectedNode],
  )

  return (
    <>
      <div className="row d-flex align-items-stretch">
        <div className="col-6 col-md-6 col-sm-12 d-flex">
          <Card title="As Tree" style={{ flexGrow: 1 }}>
            {!groupsLoading && groupsData && (
              <Tree
                defaultExpandAll={true}
                checkable={true}
                checkStrictly={true}
                selectable={false}
                treeData={treeData as any}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
              />
            )}
          </Card>
        </div>

        <div className="col-6 col-md-6 col-sm-12">
          <Card title="Group Detail">
            {selectedNode && (
              <>
                <Form.Item
                  label="Attendance Qualifier"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                  style={{ margin: 0, marginBottom: 5 }}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    value={selectedNode.group.attendanceFileProcessorQualifier}
                    onChange={onChangeAttendanceQualifier}
                  >
                    <Radio.Button value={null}>Null</Radio.Button>
                    {!attendanceQualifiersLoading &&
                      attendanceQualifiersData &&
                      attendanceQualifiersData.attendanceFileProcessorQualifiers.map(
                        (qualifier: any) => (
                          <Radio.Button value={qualifier}>
                            {qualifier}
                          </Radio.Button>
                        ),
                      )}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Employment Qualifier"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                  style={{ margin: 0 }}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    value={selectedNode.group.employmentFileProcessorQualifier}
                    onChange={onChangeEmploymentQualifier}
                  >
                    <Radio.Button value={null}>Null</Radio.Button>
                    {!employmentQualifiersLoading &&
                      employmentQualifiersData &&
                      employmentQualifiersData.employmentFileProcessorQualifiers.map(
                        (qualifier: any) => (
                          <Radio.Button value={qualifier}>
                            {qualifier}
                          </Radio.Button>
                        ),
                      )}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Payslip Qualifier"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                  style={{ margin: 0, marginBottom: 5 }}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    value={selectedNode.group.payslipCalculatorQualifier}
                    onChange={onChangePayslipQualifier}
                  >
                    <Radio.Button value={null}>Null</Radio.Button>
                    {!payslipQualifiersLoading &&
                      payslipQualifiersData &&
                      payslipQualifiersData.payslipCalculatorQualifiers.map(
                        (qualifier: any) => (
                          <Radio.Button value={qualifier}>
                            {qualifier}
                          </Radio.Button>
                        ),
                      )}
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  )
}
