import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react'

import ReactEcharts from 'echarts-for-react'
import { t } from 'ttag'
import { Card, Progress, Skeleton, Spin, Radio } from 'antd'

import { IPageProps, IPageData } from '../../interfaces/page-data'
import gql from 'graphql-tag'
import { useQuery, useSubscription } from 'react-apollo'
import {
  Employment,
  Company,
  LoanApplication,
  LoanApplicationStatus,
  LoanApplicationOrderable,
  OrderDirection,
  EmploymentStatus,
} from '../../graphql/models'
import { PendingLoadApplicationList } from './PendingLoanApplicationList'
import { notify, currentMoment } from '../../utils/helper'
import { SelectCompany } from '../../components/input/SelectCompany'
import moment from 'moment'
import ChartTotalLoanApplications from './ChartTotalLoanApplications'

export const GET_EMPLOYMENTS = gql`
  query getEmployments($query: EmploymentQuery) {
    employments(query: $query) {
      total
    }
  }
`

export const GET_COMPANIES = gql`
  query companies($employmentQueryFilter: EmploymentQueryFilter) {
    companies {
      nodes {
        id
        name
        shortName
        employmentStats(filter: $employmentQueryFilter) {
          total
        }
      }
    }
  }
`

const EMPLOYMENT_LINKED_SUBSCRIPTION = gql`
  subscription emloymentSubscription {
    employmentLinked {
      employment {
        employeeId
        fullName
        company {
          id
          shortName
        }
      }
    }
  }
`
const EMPLOYMENT_UNLINKED_SUBSCRIPTION = gql`
  subscription emloymentSubscription {
    employmentUnlinked {
      employment {
        employeeId
        fullName
        company {
          id
          shortName
        }
      }
    }
  }
`

export const GET_LOAN_APPLICATION_COUNT_GRAPH = gql`
  query getLoanApplicationCountGraph(
    $companyId: ID!
    $query: LoanApplicationCountGraphQuery!
  ) {
    company(id: $companyId) {
      loanApplicationCountGraph(query: $query) {
        x
        y
      }
    }
  }
`

const LOAN_APPLICATION_SUBSCRIPTION = gql`
  subscription loanApplicationCreated {
    loanApplicationCreated {
      loanApplication {
        code
        amountValue
        employment {
          id
          fullName
          company {
            id
            shortName
          }
        }
      }
    }
  }
`

export const GET_PENDING_LOAN_APPLICATIONS = gql`
  query loanApplications($query: LoanApplicationQuery) {
    loanApplications(query: $query) {
      nodes {
        id
        employment {
          id
          fullName
          company {
            id
            shortName
          }
        }
        amount
      }
    }
  }
`

const pieStyle = {
  normal: {
    label: {
      color: '#000000',
      position: 'inner',
    },
    labelLine: {
      show: true,
    },
  },
}

const pieChartOptions = (registered: any, notRegistered: any) => ({
  grid: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  tooltip: {
    trigger: 'item',
    formatter: '{d}%',
  },
  series: [
    {
      name: 'pie',
      type: 'pie',
      clockwise: true,
      radius: [0, '90%'],
      data: [
        {
          value: registered,
          name: t`${registered} Regd`,
          itemStyle: {
            normal: {
              color: '#c1efc6',
              borderColor: '#7cdb86',
            },
          },
        },
        {
          value: notRegistered,
          name: t`${notRegistered} Not Reg`,
          itemStyle: {
            normal: {
              color: '#ffd8dc',
              borderColor: '#ed253d',
            },
          },
        },
      ],
      itemStyle: pieStyle,
    },
  ],
})

const Dashboard: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props

  const pageData: IPageData = {
    title: 'Dashboard',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'Dashboard',
      },
    ],
    fullFilled: true,
  }

  useEffect(() => onSetPage(pageData), [])

  const {
    loading: loadingPendingLoan,
    error: errorPendingLoan,
    data: dataPendingLoan,
    refetch: refetchPendingLoan,
  } = useQuery(GET_PENDING_LOAN_APPLICATIONS, {
    variables: {
      query: {
        filter: {
          statuses: [
            LoanApplicationStatus.Pending,
            LoanApplicationStatus.Approved,
            LoanApplicationStatus.LockForDisbursement,
          ],
        },
        orderBy: LoanApplicationOrderable.CreatedDate,
        orderDirection: OrderDirection.Desc,
      },
    },
    pollInterval: 60000,
  })

  const [companyId, setCompanyId] = useState()
  const [isTotalLoanByWeek, setIsTotalLoanByWeek] = useState<boolean>(true)

  const startOfMonth = useRef(currentMoment().startOf('month').toISOString())
  const startOfYear = useRef(currentMoment().startOf('year').toISOString())
  const endOfDay = useRef(currentMoment().endOf('day').toISOString())

  const {
    loading: loadingCountGraph,
    error: errorCountGraph,
    data: dataCountGraph,
    refetch: refetchCountGraph,
  } = useQuery(GET_LOAN_APPLICATION_COUNT_GRAPH, {
    skip: !companyId,
    variables: {
      companyId,
      query: {
        createdDateAfter: isTotalLoanByWeek
          ? startOfMonth.current
          : startOfYear.current,
        createdDateBefore: endOfDay.current,
        stepPeriod: isTotalLoanByWeek ? 'P1D' : 'P1M',
      },
    },
    pollInterval: 60000,
  })

  const companiesResponse = useQuery(GET_COMPANIES, {
    variables: { query: { limit: 100, offset: 0 } },
  })

  const linkAudioRef = useRef<HTMLAudioElement>(null)
  const unlinkAudioRef = useRef<HTMLAudioElement>(null)
  const advanceAudioRef = useRef<HTMLAudioElement>(null)

  const {
    loading: loadingLoanedEmployees,
    error: errorLoanedEmployees,
    data: dataLoanedEmployees,
    refetch: refetchLoanedEmployees,
  } = useQuery(GET_EMPLOYMENTS, {
    variables: {
      query: {
        filter: {
          isLoaned: true,
          isLinked: true,
          statuses: [EmploymentStatus.Active],
        },
      },
    },
  })

  const {
    loading: loadingLinkedEmployees,
    error: errorLinkedEmployees,
    data: dataLinkedEmployees,
    refetch: refetchLinkedEmployees,
  } = useQuery(GET_EMPLOYMENTS, {
    variables: {
      query: {
        filter: { isLinked: true, statuses: [EmploymentStatus.Active] },
      },
    },
  })

  const {
    loading: loadingAllEmployees,
    error: errorAllEmployees,
    data: dataAllEmployees,
    refetch: refetchAllEmployees,
  } = useQuery(GET_EMPLOYMENTS, {
    variables: {
      query: {
        filter: { statuses: [EmploymentStatus.Active] },
      },
    },
  })

  const {
    loading: regdCompaniesLoading,
    error: regdCompaniesError,
    data: regdCompaniesData,
    refetch: regdCompaniesRefecth,
  } = useQuery(GET_COMPANIES, {
    variables: {
      employmentQueryFilter: {
        isLinked: true,
        statuses: [EmploymentStatus.Active],
      },
    },
  })

  const {
    loading: unRegCompaniesLoading,
    error: unRegCompaniesError,
    data: unRegCompaniesData,
    refetch: unRegCompaniesRefetch,
  } = useQuery(GET_COMPANIES, {
    variables: {
      employmentQueryFilter: {
        isLinked: false,
        statuses: [EmploymentStatus.Active],
      },
    },
  })

  // const {
  //   data: newEmplomentData,
  //   loading: newEmplomentLoading,
  // } = useSubscription(EMPLOYMENT_LINKED_SUBSCRIPTION, {
  //   onSubscriptionData: ({ subscriptionData }) => {
  //     const employment: Employment =
  //       subscriptionData.data.employmentLinked.employment
  //     notify(
  //       'Welcome New User :)',
  //       `${employment.fullName} from ${employment.company.shortName}`,
  //     )
  //     if (linkAudioRef.current) {
  //       linkAudioRef.current.play()
  //     }
  //     // if (employment && employment.fullName) {
  //     //   speak(`VUI chào ${employment.fullName}`)
  //     // }
  //     refetchLoanedEmployees()
  //     refetchLinkedEmployees()
  //     refetchAllEmployees()
  //     regdCompaniesRefecth()
  //     unRegCompaniesRefetch()
  //   },
  // })

  // const {
  //   data: unEmplomentData,
  //   loading: unEmplomentLoading,
  // } = useSubscription(EMPLOYMENT_UNLINKED_SUBSCRIPTION, {
  //   onSubscriptionData: ({ subscriptionData }) => {
  //     const employment: Employment =
  //       subscriptionData.data.employmentUnlinked.employment
  //     notify(
  //       `Someone unlinked our user -_-`,
  //       `${employment.fullName} from ${employment.company.shortName}`,
  //     )
  //     if (unlinkAudioRef.current) {
  //       unlinkAudioRef.current.play()
  //     }
  //     refetchLoanedEmployees()
  //     refetchLinkedEmployees()
  //     refetchAllEmployees()
  //     regdCompaniesRefecth()
  //     unRegCompaniesRefetch()
  //   },
  // })

  // const {
  //   data: pendingLoanApplicationsData,
  //   loading: pendingLoanApplicationsLoading,
  // } = useSubscription(LOAN_APPLICATION_SUBSCRIPTION, {
  //   onSubscriptionData: ({ subscriptionData }) => {
  //     const loanApplication: LoanApplication =
  //       subscriptionData.data.loanApplicationCreated.loanApplication
  //     notify(
  //       `New Loan Application from ${loanApplication.employment.company.shortName}`,
  //       `${loanApplication.employment.fullName} advances ${loanApplication.amountValue}đ`,
  //     )
  //     if (advanceAudioRef.current) {
  //       advanceAudioRef.current.play()
  //     }
  //     refetchPendingLoan()
  //     if (companyId) {
  //       refetchCountGraph()
  //     }
  //   },
  // })

  return (
    <>
      <div className="row" style={{ display: 'flex', alignItems: 'stretch' }}>
        <div className="d-flex flex-column col-4">
          <Card>
            <div style={{ display: 'none' }}>
              <audio controls={true} ref={linkAudioRef}>
                <source src="/conguoimoinay.mp3" type="audio/mpeg" />
              </audio>
              <audio controls={true} ref={unlinkAudioRef}>
                <source src="/se_unlink.mp3" type="audio/mpeg" />
              </audio>
              <audio controls={true} ref={advanceAudioRef}>
                <source src="/codonemei.mp3" type="audio/mpeg" />
              </audio>
            </div>
            <div
              className="label-block d-flex justify-content-between align-items-baseline"
              style={{ fontSize: '1em' }}
            >
              <h5 className="label">{t`Registration Percent`}</h5>
              {dataLinkedEmployees && dataAllEmployees ? (
                <h3 style={{ color: '#41ba7a' }} className="value">
                  {dataAllEmployees?.employments?.total !== 0
                    ? `${Math.round(
                        (dataLinkedEmployees?.employments?.total /
                          dataAllEmployees?.employments?.total) *
                          100,
                      )}%`
                    : '0%'}
                </h3>
              ) : (
                <Skeleton />
              )}
            </div>
            {dataAllEmployees !== 0 ? (
              <Progress
                percent={Math.round(
                  (dataLinkedEmployees?.employments?.total /
                    dataAllEmployees?.employments?.total) *
                    100,
                )}
                showInfo={false}
                status="success"
                type="line"
                strokeWidth={50}
                className="mb-3"
              />
            ) : (
              <Skeleton />
            )}
            <div
              className="mt-3 d-flex flex-column pt-4"
              style={{ flexGrow: 1 }}
            >
              <div className="mb-2 d-flex flex-row justify-content-between">
                <h5
                  className="m-0"
                  style={{ color: '#41ba7a' }}
                >{t`Registered Employee`}</h5>
                {loadingLinkedEmployees || errorLinkedEmployees ? (
                  <Skeleton />
                ) : (
                  <h5 className="m-0" style={{ color: '#41ba7a' }}>
                    {dataLinkedEmployees?.employments?.total}
                  </h5>
                )}
              </div>
              <div className="mb-2 d-flex flex-row justify-content-between">
                <h5 className="m-0">{t`Total Employee`}</h5>
                {loadingAllEmployees || errorAllEmployees ? (
                  <Skeleton />
                ) : (
                  <h5 className="m-0">
                    {dataAllEmployees?.employments?.total}
                  </h5>
                )}
              </div>
            </div>
          </Card>
          <Card>
            <div
              className="label-block d-flex justify-content-between align-items-baseline"
              style={{ fontSize: '1em' }}
            >
              <h5 className="label">{t`Usage Percent`}</h5>
              {dataLoanedEmployees && dataLinkedEmployees ? (
                <h3 style={{ color: '#41ba7a' }} className="value">
                  {dataLinkedEmployees?.employments?.total !== 0
                    ? `${Math.round(
                        (dataLoanedEmployees?.employments?.total /
                          dataLinkedEmployees?.employments?.total) *
                          100,
                      )}%`
                    : '0%'}
                </h3>
              ) : (
                <Skeleton />
              )}
            </div>
            {dataLinkedEmployees !== 0 ? (
              <Progress
                percent={Math.round(
                  (dataLoanedEmployees?.employments?.total /
                    dataLinkedEmployees?.employments?.total) *
                    100,
                )}
                showInfo={false}
                status="success"
                type="line"
                strokeWidth={50}
                className="mb-3"
              />
            ) : (
              <Skeleton />
            )}
            <div
              className="mt-3 d-flex flex-column pt-4"
              style={{ flexGrow: 1 }}
            >
              <div className="mb-2 d-flex flex-row justify-content-between">
                <h5
                  className="m-0"
                  style={{ color: '#41ba7a' }}
                >{t`Advanced Employee`}</h5>
                {loadingLoanedEmployees || errorLoanedEmployees ? (
                  <Skeleton />
                ) : (
                  <h5 className="m-0" style={{ color: '#41ba7a' }}>
                    {dataLoanedEmployees?.employments?.total}
                  </h5>
                )}
              </div>
              <div className="mb-2 d-flex flex-row justify-content-between">
                <h5 className="m-0">{t`Registered Employee`}</h5>
                {loadingLinkedEmployees || errorLinkedEmployees ? (
                  <Skeleton />
                ) : (
                  <h5 className="m-0">
                    {dataLinkedEmployees?.employments?.total}
                  </h5>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div className="d-flex col-8">
          <Card
            className="animated fadeIn delay d-flex flex-column"
            style={{
              flexGrow: 1,
            }}
            bodyStyle={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {unRegCompaniesData &&
              regdCompaniesData &&
              regdCompaniesData.companies.nodes
                .filter(
                  (company: Company) =>
                    company &&
                    company.employmentStats &&
                    company.employmentStats.total > 0,
                )
                .map((company: Company) => {
                  const { id, shortName, employmentStats } = company
                  const unregd = unRegCompaniesData.companies.nodes.filter(
                    (regdCompany: Company) => regdCompany.id === id,
                  )[0] as Company
                  return (
                    <div key={id} style={{ width: '33%' }}>
                      <div className="d-flex flex-column">
                        <div style={{ textAlign: 'center' }}>
                          <h5
                            style={{
                              color: '#41ba7a',
                              borderRight: '3px solid #41ba7a',
                              display: 'inline-block',
                              paddingRight: 10,
                              marginBottom: 0,
                            }}
                          >
                            {`${shortName} `}
                          </h5>
                          <h5
                            style={{
                              paddingLeft: 10,
                              display: 'inline-block',
                              marginBottom: 0,
                            }}
                          >
                            {`${
                              (employmentStats?.total || 0) +
                              (unregd?.employmentStats?.total || 0)
                            } `}
                            <i className="icofont-ui-user" />
                          </h5>
                        </div>
                        <ReactEcharts
                          className="chart-container chart-container-h-50"
                          option={pieChartOptions(
                            employmentStats?.total,
                            unregd?.employmentStats?.total || 0,
                          )}
                        />
                      </div>
                    </div>
                  )
                })}
          </Card>
        </div>
      </div>
      <div className="row align-items-stretch d-flex">
        <div className="col col-5 d-flex" style={{ flexGrow: 1 }}>
          <PendingLoadApplicationList
            data={
              !dataPendingLoan ? [] : dataPendingLoan.loanApplications.nodes
            }
            loading={loadingPendingLoan}
            error={errorPendingLoan}
          />
        </div>
        <div className="col col-7" style={{ flexGrow: 1 }}>
          <Card>
            {companiesResponse.data ? (
              <SelectCompany
                companies={companiesResponse.data.companies.nodes}
                onChange={(id: any) => {
                  setCompanyId(id)
                }}
                width="100%"
              />
            ) : (
              <Spin />
            )}
            <Radio.Group
              onChange={(e) => setIsTotalLoanByWeek(e.target.value)}
              value={isTotalLoanByWeek}
              style={{ marginBottom: 25, marginTop: 25 }}
            >
              <Radio value={true}>{t`By Month`}</Radio>
              <Radio value={false}>{t`By Year`}</Radio>
            </Radio.Group>
            <ChartTotalLoanApplications
              data={dataCountGraph}
              isTotalLoanByWeek={isTotalLoanByWeek}
            />
          </Card>
        </div>
      </div>
    </>
  )
}

export default Dashboard
