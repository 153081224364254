import React, { useEffect } from 'react'
import { IPageProps, IPageData } from '../../interfaces/page-data'
import { useParams } from 'react-router-dom'
import LoanApplicationDetail from '../../components/LoanApplication/LoanApplicationDetail'
import { useQueryUrl } from '../../components/hooks/hooks'

export const LoanApplicationDetailPage: React.FC<IPageProps> = (props) => {
  const { slug } = useParams()
  const query = useQueryUrl()
  const { onSetPage } = props

  const status = query.get('status')
  const pageData: IPageData = {
    title: 'Loan Application Detail',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: '/vertical/dashboard',
      },
      {
        title: 'Loan Applications',
        route: `/vertical/loan-applications?status=${status}`,
      },
      {
        title: 'Loan Application detail',
      },
    ],
  }

  useEffect(() => onSetPage(pageData), [])

  return (
    <>
      <div className="row">
        <div className="col-8 col-xl-8">
          {slug && <LoanApplicationDetail id={slug} />}
        </div>
      </div>
    </>
  )
}
