import React, { useState, useEffect, useRef, useMemo } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  LoanApplication,
  LoanApplicationQueryFilter,
  LoanApplicationBatch,
  StorageObject,
  LoanApplicationBatchOrderable,
  OrderDirection,
  LoanApplicationStatus,
} from '../../graphql/models'
import { Table, Alert, Card, Modal, Radio, Tag, Button } from 'antd'
import ButtonLink from '../ButtonLink'
import { LAYOUT_PATH } from '../constants'
import { useExportEntity } from '../hooks/useExportEntity'
import { useTable, DEFAULT_PAGE_SIZE } from '../hooks/useTable'
import { CREATE_BATCH_FILE } from '../LoanApplication/LoanApplicationList'
import { DeliveredProcedureOutlined } from '@ant-design/icons'

export const GET_LOAN_APPLICATION_BATCHES = gql`
  query {
    loanApplicationBatches(
      query: { orderBy: createdDate, orderDirection: DESC }
    ) {
      nodes {
        id
        title
        loanApplications {
          nodes {
            status
            code
          }
          sumAmounts
          total
        }
      }
    }
  }
`

export function LoanApplicationBatchesList() {
  const [exportLoading, exportEntity] = useExportEntity()
  const [
    loading,
    error,
    data,
    filter,
    page,
    setFilter,
    setPage,
    resetFilter,
    queryVariables,
    setNewQueryVariables,
    tableRefetch,
  ] = useTable<LoanApplicationQueryFilter>(GET_LOAN_APPLICATION_BATCHES, {
    orderBy: LoanApplicationBatchOrderable.CreatedDate,
    orderDirection: OrderDirection.Desc,
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const { hasSelected } = useMemo(
    () => ({
      hasSelected: selectedRowKeys.length > 0,
    }),
    [selectedRowKeys],
  )

  const [createBatchFile] = useMutation(CREATE_BATCH_FILE)

  const [qualifier, setQualifier] = useState<
    'VPBankV1BatchFileCreator' | 'ACBV1BatchFileCreator'
  >('VPBankV1BatchFileCreator')

  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const [isCreatedBatchFile, setIsCreatedBatchFile] = useState<boolean>(false)
  const [createdBatchFile, setCreatedBatchFile] = useState<
    StorageObject | undefined
  >()

  const handleCancel = () => {
    setSelectedRowKeys([])
    setModalVisible(false)
    setConfirmLoading(false)

    setCreatedBatchFile(undefined)
    setIsCreatedBatchFile(false)

    tableRefetch()
  }

  useEffect(() => {
    setCreatedBatchFile(undefined)
    setIsCreatedBatchFile(false)
  }, [qualifier])

  const columns = [
    {
      title: 'Batch Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Batch Title',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Batch Status',
      key: 'Batch Status',
      render: (batch: LoanApplicationBatch) => {
        const lockLoanApplications = batch.loanApplications.nodes.filter(
          (loanApplication: LoanApplication) => {
            return (
              loanApplication.status ===
              LoanApplicationStatus.LockForDisbursement
            )
          },
        )
        if (lockLoanApplications.length > 0) {
          return (
            <Tag color={'red'} style={{ margin: 5 }}>
              Pending
            </Tag>
          )
        }
        return (
          <Tag color={'green'} style={{ margin: 5 }}>
            Completed
          </Tag>
        )
      },
    },
  ]

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <Card title="Loan Application Batches">
      <Modal
        title="Generate Batch File"
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        cancelText="Cancel"
        maskClosable={false}
        keyboard={false}
        okButtonProps={{
          // danger: true,
          style: {
            display: 'none',
          },
        }}
      >
        <div className="d-flex flex-column">
          <h6 className="mb-2">Select Bank Format</h6>
          <Radio.Group
            onChange={(e) => setQualifier(e.target.value)}
            value={qualifier}
            style={{ marginBottom: 10 }}
          >
            <Radio value={'VPBankV1BatchFileCreator'}>VP Bank</Radio>
            <Radio value={'ACBV1BatchFileCreator'}>ACB</Radio>
          </Radio.Group>
          <Button
            style={{ marginBottom: 20 }}
            type="primary"
            icon={<DeliveredProcedureOutlined />}
            onClick={() => {
              if (selectedRowKeys.length === 1) {
                setConfirmLoading(true)
                createBatchFile({
                  variables: {
                    batchId: selectedRowKeys[0],
                    filename:
                      qualifier === 'VPBankV1BatchFileCreator'
                        ? 'batch.txt'
                        : 'batch.xls',
                    qualifier,
                  },
                })
                  .then((response) => {
                    setCreatedBatchFile(
                      response.data.createBatchFile.storageObject,
                    )
                    setIsCreatedBatchFile(true)
                  })
                  .catch((err) => {
                    window.alert(error)
                  })
              }
            }}
          >
            {`Generate ${
              qualifier === 'VPBankV1BatchFileCreator' ? 'VPBANK' : 'ACB'
            } file`}
          </Button>
          {isCreatedBatchFile && createdBatchFile && (
            <a
              href={`${createdBatchFile && createdBatchFile.uri}`}
              target="_blank"
              download
            >
              {`Download ${
                qualifier === 'VPBankV1BatchFileCreator' ? 'VPBANK' : 'ACB'
              } file`}
            </a>
          )}
        </div>
      </Modal>

      <div style={{ position: 'relative' }}>
        <Table
          pagination={{
            pageSize: DEFAULT_PAGE_SIZE,
            showSizeChanger: false,
            showTotal: () => (!data ? 0 : data.loanApplicationBatches.total),
            total: !data ? 0 : data.loanApplicationBatches.total,
            onChange: (page: number, pageSize?: number) => {
              setPage(page)
            },
            current: page,
          }}
          dataSource={!data ? [] : data.loanApplicationBatches.nodes}
          columns={columns}
          loading={loading}
          size="middle"
          bordered={true}
          rowKey="id"
          rowSelection={{
            hideSelectAll: true,
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
              if (selectedRowKeys.length === 0) {
                setSelectedRowKeys(selectedRowKeys as any)
                return
              }
              setSelectedRowKeys([selectedRowKeys[selectedRowKeys.length - 1]])
            },
          }}
        />
        <div
          className="d-flex justify-content-between pb-3"
          style={{ position: 'absolute', right: 0, bottom: '100%' }}
        >
          {
            <Button
              onClick={() => {
                setModalVisible(true)
              }}
              style={{
                visibility: hasSelected ? 'visible' : 'hidden',
              }}
            >
              Download Batch File
            </Button>
          }
        </div>
      </div>
    </Card>
  )
}
